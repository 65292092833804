import { ApplicationUser, Project, Tenant } from "graphql/_Types";
import {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import { create, StoreApi, UseBoundStore, useStore } from "zustand";
import Axios from "axios";
import { getEnvVar } from "ideas.env";
import { getRequestHeaders } from "utils/getRequestHeaders";
import AppLoading from "components/AppLoading/AppLoading";
import { CallOutError } from "components/CallOutError/CallOutError";
import assert from "assert";
import { ContextOutOfBoundsError } from "providers/ContextOutOfBoundsError";
import { UserProjectAccessDjango } from "components/ModalProjectSharing/permissions";
import { useQuery } from "@tanstack/react-query";
import { queryKeys } from "django/queryKeys";

const getPermissions = async (projectId: Project["id"]) => {
  const baseUrl = getEnvVar("URL_LIBRARY_PROJECT")();
  const url = `${baseUrl}${projectId}/`;
  const headers = await getRequestHeaders();
  const { data } = await Axios.get<{
    clone_source: Project["cloneSourceId"];
    date_archived: Project["dateArchived"];
    date_created: Project["dateCreated"];
    date_data_deleted: Project["dateDataDeleted"];
    date_deleted: Project["dateDeleted"];
    date_last_active: Project["dateLastActive"];
    default_user_access_level: Project["defaultUserAccessLevel"];
    default_user_access_level_display: string;
    description: Project["description"];
    icon: Project["icon"];
    icon_color: Project["iconColor"];
    icon_text: Project["iconText"];
    id: Project["id"];
    key: Project["key"];
    name: Project["name"];
    prefix: Project["prefix"];
    short_description: Project["shortDescription"];
    status: Project["status"];
    tenant_id: Project["tenantId"];
    active_storage_size: Project["activeStorageSize"];
    archived_storage_size: Project["archivedStorageSize"];
    user: {
      id: ApplicationUser["id"];
      username: ApplicationUser["username"];
    };
    tenant: {
      id: Tenant["id"];
      key: Tenant["key"];
      name: Tenant["name"];
    };
    pinned: boolean | null;
    used_credits: Project["usedCredits"];
    permissions: {
      role_display: string;
      download: boolean;
      edit: boolean;
      execute: boolean;
      grant_access: boolean;
      upload: boolean;
      view: boolean;
    };
  }>(url, { headers });

  return data.permissions;
};

export type ProjectPermissions = {
  download: boolean;
  edit: boolean;
  execute: boolean;
  grant_access: boolean;
  upload: boolean;
  view: boolean;
};

export interface ProjectPermissionProviderProps {
  projectId: Project["id"];
  children: ReactNode;
}

type Permissions = Pick<
  UserProjectAccessDjango,
  "edit" | "execute" | "download" | "upload" | "view" | "grant_access"
> & { role_display: string };

type ProjectPermissionStoreState = {
  permissions: Permissions;
};

const ProjectPermissionContext = createContext<
  UseBoundStore<StoreApi<ProjectPermissionStoreState>> | undefined
>(undefined);

export const useProjectPermissionStore = <S,>(
  selector: (state: ProjectPermissionStoreState) => S,
) => {
  const store = useContext(ProjectPermissionContext);
  assert(
    store !== undefined,
    new ContextOutOfBoundsError("ProjectPermissionContext"),
  );

  return useStore(store, selector);
};

export const ProjectPermissionProvider = ({
  projectId,
  children,
}: ProjectPermissionProviderProps) => {
  const getPermissionsFn = useCallback(
    () => getPermissions(projectId),
    [projectId],
  );

  const {
    /*
      Only show loading app-blocking loading state on initial fetch.
      Calls to manually refetch (permissions modal) can handle loading state there.
    */
    isInitialLoading: isLoadingQuery,
    isLoadingError: isQueryInitialFetchError,
    data,
  } = useQuery({
    queryFn: getPermissionsFn,
    queryKey: queryKeys.permissionsProvider(projectId),
    refetchOnMount: "always",
  });

  const [isLoadingStore, setIsLoadingStore] = useState(true);
  const [store, setStore] =
    useState<UseBoundStore<StoreApi<ProjectPermissionStoreState>>>();

  /**
   * Turn off store loading state if initial fetch fails
   */
  useEffect(() => {
    if (isQueryInitialFetchError) {
      setIsLoadingStore(false);
    }
  }, [isQueryInitialFetchError]);

  /**
   * Build and update the store
   */
  useEffect(() => {
    if (data !== undefined) {
      if (store === undefined) {
        setStore(() =>
          create(() => ({
            permissions: { ...data },
          })),
        );
        setIsLoadingStore(false);
      } else {
        store.setState({ permissions: { ...data } });
      }
    }
  }, [data, store]);

  /**
      Only block children on errors with initial loading.
      Refetch errors can notify users on the component calling for the refetch.
      For example, if the permissions modal updates permissions but the refetch fails,
      we can notify the user that something went wrong on the modal toast, but still render
      the project page with the current permissions.
   */
  if (store !== undefined) {
    return (
      <ProjectPermissionContext.Provider value={store}>
        {children}
      </ProjectPermissionContext.Provider>
    );
  }

  if (isLoadingQuery || isLoadingStore) {
    return <AppLoading />;
  }

  return <CallOutError />;
};
