import {
  EuiSearchBar,
  EuiSearchBarOnChangeArgs,
  EuiSearchBarProps,
  Query,
} from "@inscopix/ideas-eui";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useUserContext } from "providers/UserProvider/UserProvider";
import { useIdeasSearchParams } from "./useIdeasSearchParams";

/**
 * Hook to manage EuiSearchBar state
 */
export const useEuiSearchBar = () => {
  const searchBarRef = useRef<EuiSearchBar>(null);
  const { getParam, setParam } = useIdeasSearchParams();

  const currentUser = useUserContext((s) => s.currentUser);

  const [error, setError] = useState<EuiSearchBarOnChangeArgs["error"]>(null);
  const [query, setQuery] = useState<Query>();

  const userFilter: NonNullable<EuiSearchBarProps["filters"]>[0] = useMemo(
    () => ({
      type: "field_value_toggle",
      field: "user",
      name: "Only Mine",
      value: currentUser.username,
    }),
    [currentUser.username],
  );

  const roleFilter: NonNullable<EuiSearchBarProps["filters"]>[0] = useMemo(
    () => ({
      type: "field_value_selection",
      field: "role",
      name: "Role",
      multiSelect: "or",
      operator: "exact",
      autoSortOptions: false,
      filterWith: (role) => {
        return true;
      },
      options: ["Owner", "Admin", "Editor", "Viewer"].map((key) => {
        return {
          value: key,
          name: key,
        };
      }),
    }),
    [],
  );

  const onChange = useCallback(
    ({ query, error: incomingError }: EuiSearchBarOnChangeArgs) => {
      if (query?.text !== undefined) {
        // only debounce params with actual values
        if (query.text !== "") {
          setParam("SEARCH", query.text);
        } else {
          setParam("SEARCH", query.text);
        }
      }
      if (incomingError) {
        setError(error);
      } else {
        setError(null);
        if (query !== null) {
          setQuery(query);
        }
      }
    },
    [error, setParam],
  );

  /**
   * When loading the page, fill the search bar with the param from the route
   * If it's an invalid query, clear the param
   */
  useEffect(() => {
    const searchValueInRoute = getParam("SEARCH");

    /**
     * Eui's search bar is not behaving like a controlled component even
     * though the docs suggest it should. Under some conditions, when the query
     * that is fed to the component is set to undefined using setQuery, the text
     * in the search bar remains populated. This is a workaround to clear the search
     * bar text when we manually reset the query
     */
    if (searchValueInRoute === null) {
      setQuery(undefined);
      searchBarRef.current?.setState((state) => {
        const newState = { ...state };
        newState.queryText = "";
        return newState;
      });

      return;
    }

    if (query === undefined && searchValueInRoute !== null) {
      try {
        const parsedQuery = EuiSearchBar.Query.parse(searchValueInRoute);
        setQuery(parsedQuery);
      } catch (err) {
        setParam("SEARCH", "");
      }
    }
  }, [query, setParam, getParam, onChange]);

  const executeQuery = useCallback(
    <T extends object>(
      ...args: Parameters<typeof EuiSearchBar.Query.execute<T>>
    ) => EuiSearchBar.Query.execute(...args),
    [],
  );

  return {
    onChange,
    query,
    error,
    userFilter,
    executeQuery,
    searchBarRef,
    roleFilter,
  };
};
