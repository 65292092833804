import { EuiFlexGroup, EuiSearchBar } from "@inscopix/ideas-eui";
import { GridReadyEvent } from "ag-grid-community";
import {
  ProjectsGrid,
  ProjectsGridColDef,
  ProjectsGridProps,
  ProjectsGridRowDatum,
} from "components/ProjectsTable/ProjectsGrid";
import { ProjectsGridCellRendererOrgAdminActions } from "components/ProjectsTable/ProjectsGridCellRendererOrgAdminActions";
import { useEuiSearchBar } from "hooks/useEuiSearchBar";
import { useMemo } from "react";
import { isDefined } from "utils/isDefined";

export interface ProjectsGridSearchableProps
  extends Pick<ProjectsGridProps, "suppressColumns"> {
  projects: ProjectsGridProps["projects"];
  onGridReady: (event: GridReadyEvent<ProjectsGridRowDatum>) => void;
}

export const ProjectsGridSearchable = ({
  projects,
  onGridReady,
  suppressColumns,
}: ProjectsGridSearchableProps) => {
  const { onChange, query, searchBarRef, executeQuery, roleFilter } =
    useEuiSearchBar();
  const filteredProjects = useMemo(
    () => (isDefined(query) ? executeQuery(query, projects) : projects),
    [executeQuery, projects, query],
  );

  const actions = useMemo(() => {
    const colDefActions: ProjectsGridColDef = {
      colId: "actions",
      cellRenderer: ProjectsGridCellRendererOrgAdminActions,
      width: 40,
      wrapHeaderText: true,
      resizable: false,
      sortable: false,
      pinned: "right",
      csvValueGetter: null,
    };
    return colDefActions;
  }, []);

  return (
    <EuiFlexGroup
      direction="column"
      style={{ height: "100%", minHeight: 400 }}
      gutterSize="s"
    >
      <EuiSearchBar
        ref={searchBarRef}
        onChange={onChange}
        query={query}
        filters={[roleFilter]}
        box={{ placeholder: "Find a project...", incremental: true }}
      />
      <ProjectsGrid
        projects={filteredProjects}
        actions={actions}
        suppressColumns={suppressColumns}
        onGridReady={onGridReady}
        suppressPinnedRows
      />
    </EuiFlexGroup>
  );
};
