/** @jsxImportSource @emotion/react */

import { css } from "@emotion/react";
import { isNonNullish } from "utils/isNonNullish";
import { ModalToolCellStatusParamProps } from "../ModalToolCellStatusParam/ModalToolCellStatusParam";
import { CellStatuses } from "components/CellStatusEditor/CellStatusEditor.types";
import { ButtonToolCellStatusParam } from "./ButtonToolCellStatusParam";
import { parseCellStatusParamData } from "components/CellStatusEditor/CellStatusEditor.helpers";
import { memo } from "react";
import {
  ToolParamsGridRowDatum,
  ToolParamValue,
} from "../ToolParamsGrid.types";
import { useValueValidator } from "../ToolParamsGridValueValidatorContext";
import { isDefined } from "utils/isDefined";
import { isEqual } from "lodash";

export interface GridRendererToolCellStatusParamProps
  extends Pick<ModalToolCellStatusParamProps, "toolParam"> {
  rowId: ToolParamsGridRowDatum["id"];
  value: ToolParamValue;
}

export const GridRendererToolCellStatusParam = memo(
  function GridRendererToolCellStatusParam({
    toolParam,
    value,
    rowId,
  }: GridRendererToolCellStatusParamProps) {
    const validationError = useValueValidator(
      (s) => s.errorsByRowId[rowId]?.[toolParam.key],
      isEqual,
    );

    let isInvalidCellStatuses = false;
    let cellStatuses: CellStatuses | undefined;

    if (isNonNullish(value)) {
      try {
        cellStatuses = parseCellStatusParamData(value);
      } catch (err) {
        isInvalidCellStatuses = true;
      }
    }

    return (
      <div
        css={css`
          background-color: ${isDefined(validationError)
            ? "rgba(255, 0, 0, 0.05)"
            : "inherit"};
          display: flex;
          justify-content: space-between;
          align-items: center;
          height: 100%;
          width: 100%;
        `}
      >
        <span
          css={css`
            overflow: hidden;
            text-overflow: ellipsis;
            margin-right: 5px;
          `}
        >
          {(value ?? "").toString()}
        </span>

        <ButtonToolCellStatusParam
          initialCellStatuses={cellStatuses}
          isInvalidInitialCellStatuses={isInvalidCellStatuses}
          rowId={rowId}
          toolParam={toolParam}
        />
      </div>
    );
  },
);
