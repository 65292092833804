import { File as DrsFile, useModalCreateSeriesQuery } from "graphql/_Types";
import { isEqual, orderBy } from "lodash";
import { useCallback, useMemo, useState } from "react";
import { SERIES_TIMING_KEYS } from "types/MetadataMap";
import { ModalCreateSeriesFile } from "./ModalCreateSeries";

type TFile = ModalCreateSeriesFile & {
  startTime: number | undefined;
  index: number;
};

export const useModalCreateSeriesData = (drsFileIds: DrsFile["id"][]) => {
  const [seriesName, setSeriesName] = useState<string>("");

  const [loading, setLoading] = useState(true);

  const [files, _setFiles] = useState<TFile[]>();

  const setFiles = useCallback(
    (callback: (prev: Omit<TFile, "index">[]) => Omit<TFile, "index">[]) => {
      _setFiles((prev) =>
        callback(prev ?? []).map((file, index) => ({ ...file, index })),
      );
    },
    [],
  );

  useModalCreateSeriesQuery({
    variables: {
      drsFileIds,
      metadataKeys: Object.values(SERIES_TIMING_KEYS),
    },
    fetchPolicy: "network-only",
    onCompleted: (data) => {
      /**
       * Don't update files after initial load
       */
      if (files !== undefined) {
        return;
      }
      const filesData = data?.drsFiles?.nodes?.map((file) => {
        // https://github.com/inscopix/ideas-python-utils/blob/main/ideas/utils.py#L298
        const startTimeNum = file.metadata.nodes.find(
          (m) => m.metadatum?.key === SERIES_TIMING_KEYS.num,
        )?.metadatum?.activeValue;
        const startTimeDem = file.metadata.nodes.find(
          (m) => m.metadatum?.key === SERIES_TIMING_KEYS.dem,
        )?.metadatum?.activeValue;

        const startTime =
          typeof startTimeNum === "number" && typeof startTimeDem === "number"
            ? startTimeNum / startTimeDem
            : undefined;

        return {
          ...file,
          startTime,
        };
      });

      if (files === undefined && filesData !== undefined) {
        const canOrderChronologically = filesData.every(
          (file) => file.startTime !== undefined,
        );

        /**
         * Sort by start time if metadata exists, otherwise sort alphabetically as a fallback
         */
        const sortedFiles = canOrderChronologically
          ? orderBy(filesData, [(file) => file.startTime], ["asc"])
          : orderBy(filesData, [(file) => file.name], ["asc"]);

        setFiles(() => sortedFiles);
        setSeriesName(sortedFiles[0].name);
      }
      setLoading(false);
    },
  });

  /**
   * Indicates whether files can be ordered chronologically (contain sufficient metadata)
   */
  const canOrderChronologically = useMemo(
    () => files?.every((file) => file.startTime !== undefined) ?? false,
    [files],
  );

  /**
   * Indicates whether files are ordered chronologically (since they can be reordered)
   */
  const isOrderedChronologically = useMemo(() => {
    if (!canOrderChronologically || files === undefined) {
      return false;
    }

    return isEqual(
      orderBy(files, [(file) => file.startTime], ["asc"]).map(({ id }) => id),
      files.map(({ id }) => id),
    );
  }, [canOrderChronologically, files]);

  if (loading) {
    return {
      loading,
      error: undefined,
      files: undefined,
      isOrderedChronologically: false,
      canOrderChronologically: false,
      setFiles,
      seriesName,
      setSeriesName,
    };
  }

  if (files !== undefined) {
    return {
      loading: false,
      error: false,
      files,
      isOrderedChronologically,
      canOrderChronologically,
      setFiles,
      seriesName,
      setSeriesName,
    };
  }

  return {
    loading: false,
    error: true,
    files,
    isOrderedChronologically: false,
    canOrderChronologically: false,
    setFiles,
    seriesName,
    setSeriesName,
  };
};
