import { Project, Tenant } from "graphql/_Types";
import { isDefined } from "utils/isDefined";
import { uuid } from "utils/uuid";
import { getAdminProjectsDjango } from "./libraryProjectDjango";

const PROJECT_SHARE_ID = uuid();
const GET_ALL_PROJECTS_ID = uuid();
const PERMISSIONS_PROVIDER = uuid();
const ADMIN_PROJECTS = uuid();
const ADMIN_PROJECT = uuid();
export const queryKeys = {
  getAllProjects: (
    regions?: "internal" | "external",
    tenantId?: Tenant["id"],
  ) => [GET_ALL_PROJECTS_ID, tenantId, regions].filter(isDefined),
  getAdminProjects: (
    filterParams?: Parameters<typeof getAdminProjectsDjango>[0],
  ) => [ADMIN_PROJECTS, filterParams].filter(isDefined),
  getAdminProject: (projectId: Project["id"]) => [ADMIN_PROJECT, projectId],
  modalProjectShare: (projectId: Project["id"]) => [
    PROJECT_SHARE_ID,
    projectId,
  ],
  permissionsProvider: (projectId: Project["id"]) => [
    PERMISSIONS_PROVIDER,
    projectId,
  ],
};
