import { useDatasetDataContext } from "pages/project/dataset/DatasetDataProvider";
import { useCallback } from "react";
import { File as DrsFile } from "graphql/_Types";
import { evictCacheFragment } from "utils/cache-fragments";
import { useDrsFileScheduleDataDeleteDjango } from "hooks/useDrsFileScheduleDataDeleteDjango";
import { useFileUploadContext } from "stores/upload/FileUploadProvider";
import { useProjectPermissionStore } from "pages/project/ProjectPermissionProvider";

type DeleteColumnParams = {
  fileId: DrsFile["id"];
};

export const useActionDataDeleteFile = () => {
  const { datasetMode } = useDatasetDataContext();
  const cancelFileUploads = useFileUploadContext((s) => s.cancelFileUploads);
  const { drsFileScheduleDataDelete } = useDrsFileScheduleDataDeleteDjango();
  const hasEditPermission = useProjectPermissionStore(
    (s) => s.permissions["edit"],
  );
  const getActionDef = useCallback(
    (params: DeleteColumnParams) => {
      const dateDeleted = new Date().toISOString();

      return {
        onEnqueue: () => {
          cancelFileUploads([params.fileId]);
          evictCacheFragment({
            __typename: "File",
            id: params.fileId,
          });
        },

        onDequeue: () => {
          return drsFileScheduleDataDelete(params.fileId, {
            date_data_deleted: dateDeleted,
          });
        },
      };
    },
    [cancelFileUploads, drsFileScheduleDataDelete],
  );

  const isDisabled = datasetMode.type !== "current" || !hasEditPermission;

  return {
    getActionDef,
    isDisabled,
  };
};
