import {
  ModalTaskLogs,
  ModalTaskLogsProps,
} from "components/ButtonViewTaskLogs/ModalTaskLogs";
import { ModalError } from "components/ModalError/ModalError";
import { ModalLoading } from "components/ModalLoading/ModalLoading";
import { File as DrsFile } from "graphql/_Types";
import { useEffect, useState } from "react";
import { useGetFileProcessFailedTask } from "../../../hooks/useProcessFileFailedTaskError";

export interface ModalTaskLogsFileProcessingProps {
  fileId: DrsFile["id"];
  onClose: () => void;
}

export const ModalTaskLogsFileProcessing = ({
  fileId,
  onClose,
}: ModalTaskLogsFileProcessingProps) => {
  const { getFileProcessTask } = useGetFileProcessFailedTask();
  const [task, setTask] = useState<ModalTaskLogsProps["task"]>();
  const [isError, setIsError] = useState(false);

  useEffect(() => {
    getFileProcessTask(fileId)
      .then((task) => setTask(task))
      .catch(() => setIsError(true));
  }, [fileId, getFileProcessTask]);

  if (isError) {
    return <ModalError onClose={onClose} />;
  }

  if (task === undefined) {
    return <ModalLoading onClose={onClose} />;
  }

  return <ModalTaskLogs onClose={onClose} task={task} />;
};
