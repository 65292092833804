import {
  EuiSuperSelect,
  EuiSuperSelectOption,
  EuiText,
} from "@inscopix/ideas-eui";
import { useEffect } from "react";
import { isDefined } from "utils/isDefined";
import { isNonNullish } from "utils/isNonNullish";
import { ModalDandiState } from "./ModalDandi.types";
import { GridApi } from "ag-grid-community";

interface ModalDandiFieldDataColumnProps {
  gridApi: GridApi | undefined;
  dataColumnId: ModalDandiState["dataColumnId"];
  setDataColumnId: (dataColumnId: ModalDandiState["dataColumnId"]) => void;
  columnIds: string[];
}

/** Component that data column select field. */
export const ModalDandiFieldDataColumn = ({
  gridApi,
  dataColumnId,
  setDataColumnId,
  columnIds,
}: ModalDandiFieldDataColumnProps) => {
  // Auto-select the data column if there is only one
  useEffect(() => {
    if (columnIds.length === 1 && dataColumnId === "") {
      setDataColumnId(columnIds[0]);
    }
  }, [columnIds, dataColumnId, setDataColumnId]);

  const options: EuiSuperSelectOption<string>[] = columnIds
    .map((columnId) => {
      const column = gridApi?.getColumn(columnId);
      return column;
    })
    .filter(isNonNullish)
    .map((column) => {
      const colDef = column.getColDef();
      const colGroupDef = column.getParent()?.getColGroupDef();
      const colId = column.getColId();

      /* If the column belongs to a column group, display the group header name
         as the option's title and the column header name as the subtitle. If the
         column does not belong to a column group, display the column header
         name as the option's title and do not display a subtitle. */
      const title = colGroupDef?.headerName ?? colDef.headerName;
      const subtitle = isDefined(colGroupDef?.headerName)
        ? colDef.headerName
        : undefined;

      return {
        inputDisplay: title,
        dropdownDisplay: (
          <>
            <strong>{title}</strong>
            {subtitle !== undefined && (
              <EuiText size="s" color="subdued">
                <p>{subtitle}</p>
              </EuiText>
            )}
          </>
        ),
        value: colId,
      };
    });

  const hasOptions = options.length !== 0;

  return (
    <EuiSuperSelect<string>
      options={options}
      placeholder={
        hasOptions ? "Select a data column" : "No NWB data columns found"
      }
      disabled={!hasOptions}
      valueOfSelected={dataColumnId}
      onChange={setDataColumnId}
    />
  );
};
