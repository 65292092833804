import { FlyoutDatasetNotes } from "components/FlyoutDatasetNotes/FlyoutDatasetNotes";
import { ContextOutOfBoundsError } from "providers/ContextOutOfBoundsError";
import {
  createContext,
  ReactNode,
  RefObject,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { FlyoutDatasetVersions } from "components/FlyoutDatasetVersions/FlyoutDatasetVersions";
import { FlyoutAnalysis } from "components/FlyoutAnalysis/FlyoutAnalysis";
import {
  FlyoutDrsFileInfo,
  FlyoutDrsFileInfoProps,
} from "components/FlyoutDrsFileInfo/FlyoutDrsFileInfo";
import { FlyoutFiles } from "components/FlyoutFiles/FlyoutFiles";
import { ModalExportDataset } from "components/ModalExportDataset/ModalExportDataset";
import { ModalCreateDatasetVersion } from "components/Dataset/ModalCreateDatasetVersion";
import ModalDatasetRunAnalysis, {
  ModalDatasetRunAnalysisProps,
} from "components/ModalDatasetRunAnalysis/ModalDatasetRunAnalysis";
import { RecordingsGridRowDatum } from "components/RecordingsGrid/RecordingsGrid.types";
import { ModalRenameMetadataColumn } from "components/ModalRenameMetadataColumn/ModalRenameMetadataColumn";
import {
  ModalDeleteDrsFile,
  ModalDeleteDrsFileProps,
} from "components/ModalDeleteDrsFile/ModalDeleteDrsFile";
import { ModalInsertRecordings } from "components/Dataset/ModalInsertRecordings/ModalInsertRecordings";
import { AgGridReact } from "ag-grid-react";
import {
  ModalConfirmDestructiveAction,
  ModalConfirmDestructiveActionProps,
} from "components/ModalConfirmDestructiveAction/ModalConfirmDestructiveAction";
import ModalCreateSeries, {
  ModalCreateSeriesProps,
} from "../../../components/ModalCreateSeries/ModalCreateSeries";
import {
  ModalArchiveDrsFile,
  ModalArchiveDrsFileProps,
} from "components/ModalArchiveDrsFile/ModalArchiveDrsFile";
import {
  ModalUnarchiveDrsFiles,
  ModalUnarchiveDrsFilesProps,
} from "components/ModalUnarchiveDrsFile/ModalUnarchiveDrsFile";
import {
  ModalViewLinkedMetadataColumn,
  ModalViewLinkedMetadataColumnProps,
} from "components/ModalViewLinkedMetadataColumn/ModalViewLinkedMetadataColumn";
import {
  ModalBreakSeries,
  ModalBreakSeriesProps,
} from "components/ModalBreakSeries/ModalBreakSeries";
import {
  ModalViewMetadataColumn,
  ModalViewMetadataColumnProps,
} from "components/ModalViewMetadataColumn/ModalViewMetadataColumn";
import {
  ModalViewDrsFileColumn,
  ModalViewDrsFileColumnProps,
} from "components/ModalViewDrsFileColumn/ModalViewDrsFileColumn";
import {
  ModalRenameDrsFile,
  ModalRenameDrsFileProps,
} from "components/ModalRenameDrsFile/ModalRenameDrsFile";
import {
  ModalDownloadFiles,
  ModalDownloadFilesProps,
} from "components/ModalDownloadFiles/ModalDownloadFiles";
import { isDefined } from "utils/isDefined";
import { isNonNull } from "utils/isNonNull";
import {
  ModalDandiRecordingsTable,
  ModalDandiRecordingsTableProps,
} from "components/ModalDandi/ModalDandiRecordingsTable";
import {
  ModalRemoveColumns,
  ModalRemoveColumnsProps,
} from "components/ModalRemoveColumns/ModalRemoveColumns";
import { ModalInsertColumn } from "components/ModalInsertColumn/ModalInsertColumn";
import { useSelectedDrsFileId } from "stores/useSelectedDrsFileId";
import {
  ModalAssignFile,
  ModalAssignFileProps,
} from "components/ModalAssignFile/ModalAssignFile";
import {
  ModalIdentifyFile,
  ModalIdentifyFileProps,
} from "components/ModalIdentifyFile/ModalIdentifyFile";

type RightFlyout =
  | {
      type: "fileInfo";
      props: Omit<FlyoutDrsFileInfoProps, "onClose"> & {
        drsFile: FlyoutDrsFileInfoProps["drsFile"] &
          ModalDeleteDrsFileProps["drsFiles"][number] &
          ModalArchiveDrsFileProps["drsFiles"][number];
      };
    }
  | {
      type: "runAnalysis";
    };

type BottomFlyout =
  | {
      type: "datasetInfo";
    }
  | {
      type: "datasetVersions";
    }
  | {
      type: "uploadedFiles";
    };

type DatasetFlyout = RightFlyout | BottomFlyout;

type DatasetModal =
  | {
      type: "runAnalysis";
      props: Pick<ModalDatasetRunAnalysisProps, "selectedRecordings"> & {
        onClose?: () => void;
      };
    }
  | {
      type: "exportDataset";
      props?: { onClose?: () => void };
    }
  | {
      type: "insertColumn";
      props?: { onClose?: () => void };
    }
  | {
      type: "confirmAction";
      props: Pick<ModalConfirmDestructiveActionProps, "onConfirm" | "body"> & {
        onClose?: () => void;
      };
    }
  | {
      type: "createVersion";
      props?: { onClose?: () => void };
    }
  | {
      type: "insertRecordings";
      props?: { onClose?: () => void };
    }
  | {
      type: "renameMetadataColumn";
      props?: { onClose?: () => void };
    }
  | {
      type: "deleteDrsFile";
      props: Omit<ModalDeleteDrsFileProps, "onClose"> & {
        onClose?: () => void;
      };
    }
  | {
      type: "archiveDrsFile";
      props: Omit<ModalArchiveDrsFileProps, "onClose"> & {
        onClose?: () => void;
      };
    }
  | {
      type: "unarchiveDrsFile";
      props: Omit<ModalUnarchiveDrsFilesProps, "onClose"> & {
        onClose?: () => void;
      };
    }
  | {
      type: "createSeries";
      props: Omit<ModalCreateSeriesProps, "onClose"> & { onClose?: () => void };
    }
  | {
      type: "viewLinkedMetadataColumn";
      props: Omit<ModalViewLinkedMetadataColumnProps, "onClose"> & {
        onClose?: () => void;
      };
    }
  | {
      type: "viewMetadataColumn";
      props: Omit<ModalViewMetadataColumnProps, "onClose"> & {
        onClose?: () => void;
      };
    }
  | {
      type: "viewDrsFileColumn";
      props: Omit<ModalViewDrsFileColumnProps, "onClose"> & {
        onClose?: () => void;
      };
    }
  | {
      type: "breakSeries";
      props: Omit<ModalBreakSeriesProps, "onClose"> & { onClose?: () => void };
    }
  | {
      type: "renameDrsFile";
      props: Omit<ModalRenameDrsFileProps, "onClose"> & {
        onClose?: () => void;
      };
    }
  | {
      type: "downloadFiles";
      props: Omit<ModalDownloadFilesProps, "onClose"> & {
        onClose?: () => void;
      };
    }
  | {
      type: "exportToDandi";
      props?: Omit<ModalDandiRecordingsTableProps, "onClose"> & {
        onClose?: () => void;
      };
    }
  | {
      type: "removeColumns";
      props: Omit<ModalRemoveColumnsProps, "onClose"> & {
        onClose?: () => void;
      };
    }
  | {
      type: "assignFile";
      props: Omit<ModalAssignFileProps, "onClose"> & {
        onClose?: () => void;
      };
    }
  | {
      type: "identifyFile";
      props: Omit<ModalIdentifyFileProps, "onClose"> & {
        onClose?: () => void;
      };
    };

type FlyoutStateBase = {
  node: ReactNode;
  toggleSize: () => void;
};

type BottomFlyoutState = Pick<BottomFlyout, "type"> &
  FlyoutStateBase & {
    height:
      | {
          size: "s";
          value: 400;
        }
      | {
          size: "l";
          value: 600;
        };
  };

type RightFlyoutState = Pick<RightFlyout, "type"> &
  FlyoutStateBase & {
    width:
      | {
          size: "s";
          value: 400;
        }
      | {
          size: "l";
          value: 600;
        };
  };

type ActiveFlyouts = ReadonlySet<NonNullable<DatasetFlyout>["type"]>;

export type DatasetLayoutContextValue = {
  rightFlyout: RightFlyoutState | null;
  bottomFlyout: BottomFlyoutState | null;
  openFlyout: (flyout: DatasetFlyout) => void;
  closeFlyout: (flyoutType: DatasetFlyout["type"]) => void;
  isFlyoutActive: (flyoutType: DatasetFlyout["type"]) => boolean;
  modal: ReactNode;
  openModal: (modal: DatasetModal) => void;
  gridRef: RefObject<AgGridReact<RecordingsGridRowDatum>>;
  // Attached the "Exports" button in the bottom bar
  buttonExportsRef: RefObject<HTMLButtonElement>;
};

const DatasetLayoutContext = createContext<
  DatasetLayoutContextValue | undefined
>(undefined);

interface DatasetLayoutProviderProps {
  children: ReactNode;
}

const flyoutPositionMap: Readonly<
  Record<RightFlyout["type"], "right"> & Record<BottomFlyout["type"], "bottom">
> = {
  datasetInfo: "bottom",
  datasetVersions: "bottom",
  fileInfo: "right",
  uploadedFiles: "bottom",
  runAnalysis: "right",
};

export const DatasetLayoutProvider = ({
  children,
}: DatasetLayoutProviderProps) => {
  const { selectDrsFile, deselectAll, deselectDrsFile, selectedDrsFileId } =
    useSelectedDrsFileId(
      ({ selectDrsFile, deselectDrsFile, deselectAll, selectedDrsFileId }) => ({
        selectDrsFile,
        deselectDrsFile,
        deselectAll,
        selectedDrsFileId,
      }),
    );

  /**
   * Clear selection state on unmount
   */
  useEffect(() => {
    return () => deselectAll();
  }, [deselectAll]);

  const [rightFlyout, setRightFlyout] = useState<RightFlyout | null>(null);
  const [bottomFlyout, setBottomFlyout] = useState<BottomFlyout | null>(null);
  const [rightFlyoutSize, setRightFlyoutSize] = useState<
    RightFlyoutState["width"]
  >({ size: "s", value: 400 });
  const [bottomFlyoutSize, setBottomFlyoutSize] = useState<
    BottomFlyoutState["height"]
  >({ size: "s", value: 400 });
  const buttonExportsRef = useRef<HTMLButtonElement>(null);

  const activeFlyouts: ActiveFlyouts = new Set(
    [rightFlyout, bottomFlyout].filter(isNonNull).map(({ type }) => type),
  );

  const isFlyoutActive = (flyoutType: DatasetFlyout["type"]) =>
    activeFlyouts.has(flyoutType);

  const [modal, setModal] = useState<ReactNode>(null);
  const gridRef = useRef<AgGridReact<RecordingsGridRowDatum>>(null);

  /**
   * Close info flyout if file is deselected by external action (e.g. deleting a file)
   */
  useEffect(() => {
    if (
      rightFlyout?.type === "fileInfo" &&
      selectedDrsFileId !== rightFlyout.props.drsFile.id
    ) {
      setRightFlyout(null);
    }
  }, [rightFlyout, selectedDrsFileId]);

  const getSetFlyout = (flyoutType: DatasetFlyout["type"]) => {
    const position = flyoutPositionMap[flyoutType];
    switch (position) {
      case "bottom":
        return setBottomFlyout;
      case "right":
        return setRightFlyout;
    }
  };

  const toggleBottomFlyoutSize = () => {
    setBottomFlyoutSize((prev) =>
      prev.size === "s" ? { size: "l", value: 600 } : { size: "s", value: 400 },
    );
  };

  const toggleRightFlyoutSize = () => {
    setRightFlyoutSize((prev) =>
      prev.size === "s" ? { size: "l", value: 600 } : { size: "s", value: 400 },
    );
  };

  /**
   * Opens a flyout
   * @param flyout
   */
  const openFlyout = (flyout: DatasetFlyout) => {
    const position = flyoutPositionMap[flyout.type];
    switch (position) {
      case "bottom":
        return setBottomFlyout(flyout as BottomFlyout);
      case "right":
        if (flyout.type === "fileInfo") {
          selectDrsFile(flyout.props.drsFile.id);
        }
        return setRightFlyout(flyout as RightFlyout);
    }
  };

  const getFlyoutNode = useCallback(
    (flyout: DatasetFlyout) => {
      const position = flyoutPositionMap[flyout.type];
      const handleClose = () => {
        switch (position) {
          case "bottom":
            setBottomFlyout(null);
            break;
          case "right":
            setRightFlyout(null);
            break;
        }
      };

      switch (flyout.type) {
        case "datasetInfo":
          return <FlyoutDatasetNotes onClose={handleClose} />;
        case "datasetVersions":
          return <FlyoutDatasetVersions onClose={handleClose} />;
        case "fileInfo":
          return (
            <FlyoutDrsFileInfo
              {...flyout.props}
              onClose={() => {
                deselectDrsFile(flyout.props.drsFile.id);
                handleClose();
              }}
            />
          );
        case "uploadedFiles":
          return <FlyoutFiles onClose={handleClose} />;
        case "runAnalysis":
          return <FlyoutAnalysis onClose={handleClose} />;
      }
    },
    [deselectDrsFile],
  );

  const rightFlyoutNode = useMemo(() => {
    if (isNonNull(rightFlyout)) {
      return getFlyoutNode(rightFlyout);
    }
  }, [getFlyoutNode, rightFlyout]);

  const bottomFlyoutNode = useMemo(() => {
    if (isNonNull(bottomFlyout)) {
      return getFlyoutNode(bottomFlyout);
    }
  }, [getFlyoutNode, bottomFlyout]);

  /**
   * Opens a modal
   * @param modal
   */
  const openModal = (modal: DatasetModal) => {
    const { type } = modal;
    const handleClose = () => {
      const onClose = modal.props?.onClose;
      if (isDefined(onClose)) {
        onClose();
      }
      setModal(null);
    };

    switch (type) {
      case "confirmAction":
        setModal(
          <ModalConfirmDestructiveAction
            onCancel={handleClose}
            onConfirm={(e) => {
              modal.props.onConfirm(e);
              handleClose();
            }}
            body={modal.props.body}
          />,
        );
        break;
      case "runAnalysis":
        setModal(
          <ModalDatasetRunAnalysis {...modal.props} onClose={handleClose} />,
        );
        break;
      case "exportDataset":
        setModal(<ModalExportDataset onClose={handleClose} />);
        break;
      case "insertColumn":
        setModal(<ModalInsertColumn onClose={handleClose} />);
        break;
      case "createVersion":
        setModal(<ModalCreateDatasetVersion onClose={handleClose} />);
        break;
      case "insertRecordings":
        setModal(<ModalInsertRecordings onClose={handleClose} />);
        break;
      case "renameMetadataColumn":
        setModal(<ModalRenameMetadataColumn onClose={handleClose} />);
        break;
      case "deleteDrsFile":
        setModal(<ModalDeleteDrsFile {...modal.props} onClose={handleClose} />);
        break;
      case "archiveDrsFile":
        setModal(
          <ModalArchiveDrsFile {...modal.props} onClose={handleClose} />,
        );
        break;
      case "unarchiveDrsFile":
        setModal(
          <ModalUnarchiveDrsFiles {...modal.props} onClose={handleClose} />,
        );
        break;
      case "createSeries":
        setModal(<ModalCreateSeries {...modal.props} onClose={handleClose} />);
        break;
      case "viewLinkedMetadataColumn":
        setModal(
          <ModalViewLinkedMetadataColumn
            {...modal.props}
            onClose={handleClose}
          />,
        );
        break;
      case "viewMetadataColumn":
        setModal(
          <ModalViewMetadataColumn {...modal.props} onClose={handleClose} />,
        );
        break;
      case "viewDrsFileColumn":
        setModal(
          <ModalViewDrsFileColumn {...modal.props} onClose={handleClose} />,
        );
        break;

      case "breakSeries":
        setModal(<ModalBreakSeries {...modal.props} onClose={handleClose} />);
        break;
      case "renameDrsFile":
        setModal(<ModalRenameDrsFile {...modal.props} onClose={handleClose} />);
        break;
      case "downloadFiles":
        setModal(<ModalDownloadFiles {...modal.props} onClose={handleClose} />);
        break;
      case "exportToDandi":
        setModal(
          <ModalDandiRecordingsTable
            {...modal.props}
            gridRef={gridRef}
            onClose={handleClose}
          />,
        );
        break;
      case "removeColumns":
        setModal(<ModalRemoveColumns {...modal.props} onClose={handleClose} />);
        break;
      case "assignFile":
        setModal(<ModalAssignFile {...modal.props} onClose={handleClose} />);
        break;
      case "identifyFile":
        setModal(<ModalIdentifyFile {...modal.props} onClose={handleClose} />);
        break;
    }
  };

  /**
   * Closes a flyout
   * @param flyout
   */
  const closeFlyout = (flyoutType: DatasetFlyout["type"]) => {
    const setFlyout = getSetFlyout(flyoutType);
    setFlyout(null);
  };

  return (
    <DatasetLayoutContext.Provider
      value={{
        closeFlyout,
        isFlyoutActive,
        openFlyout,
        rightFlyout:
          rightFlyout !== null
            ? {
                type: rightFlyout.type,
                node: rightFlyoutNode,
                width: rightFlyoutSize,
                toggleSize: toggleRightFlyoutSize,
              }
            : null,
        bottomFlyout:
          bottomFlyout !== null
            ? {
                type: bottomFlyout.type,
                node: bottomFlyoutNode,
                height: bottomFlyoutSize,
                toggleSize: toggleBottomFlyoutSize,
              }
            : null,
        openModal,
        modal,
        gridRef,
        buttonExportsRef,
      }}
    >
      {children}
    </DatasetLayoutContext.Provider>
  );
};

export const useDatasetLayoutContext = () => {
  const value = useContext(DatasetLayoutContext);

  if (value === undefined) {
    throw new ContextOutOfBoundsError("DatasetLayoutContext");
  }

  return value;
};
