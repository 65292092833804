import {
  EuiSuperSelect,
  EuiSuperSelectOption,
  EuiText,
} from "@inscopix/ideas-eui";
import { useEffect, useState } from "react";
import { getDandisets } from "./ModalDandi.helpers";
import { DandiServer, ModalDandiState } from "./ModalDandi.types";

interface ModalDandiFieldDandisetProps {
  apiKey: ModalDandiState["apiKey"];
  server: ModalDandiState["server"];
  dandisetId: ModalDandiState["dandisetId"];
  setDandisetId: (dandisetId: ModalDandiState["dandisetId"]) => void;
}

/** Component that dandiset select field. */
export const ModalDandiFieldDandiset = ({
  apiKey,
  server,
  dandisetId,
  setDandisetId,
}: ModalDandiFieldDandisetProps) => {
  const [options, setOptions] = useState<EuiSuperSelectOption<string>[]>();

  // Fetch the available dandisets any time the API key or server changes
  useEffect(() => {
    const fetchDandisets = async (apiKey: string, server: DandiServer) => {
      const dandisets = await getDandisets(apiKey, server);

      const newOptions = dandisets.map((dandiset) => {
        const { identifier } = dandiset;

        /* Extracts the name of the dandiset, displaying the name of most
           recently published version if available or the name of the draft
           otherwise */
        const name =
          dandiset.most_recent_published_version?.name ??
          dandiset.draft_version?.name ??
          "Unnamed";

        return {
          inputDisplay: name,
          value: identifier,
          dropdownDisplay: (
            <>
              <strong>{name}</strong>
              <EuiText size="s" color="subdued">
                <p>{identifier}</p>
              </EuiText>
            </>
          ),
        };
      });

      setOptions(newOptions);

      // Auto-select the dandiset if there is only one
      if (dandisets.length === 1) {
        setDandisetId(dandisets[0].identifier);
      }
    };

    setOptions(undefined);
    setDandisetId("");

    if (apiKey !== "") {
      void fetchDandisets(apiKey, server);
    }
  }, [apiKey, server, setDandisetId]);

  const isLoading = apiKey !== "" && options === undefined;

  /* HTML select elements have not concept of a "placeholder" in the same way
     other input form elements do. To replicate this behavior, we have to use
     hidden options. https://stackoverflow.com/a/5859221 */
  const placeholder = (() => {
    if (isLoading) {
      return "Fetching dandisets...";
    }

    if (apiKey === "" || options?.length === 0) {
      return "No dandisets found";
    }

    if (dandisetId === "") {
      return "Select a dandiset";
    }
  })();

  return (
    <EuiSuperSelect<string>
      options={options ?? []}
      valueOfSelected={dandisetId}
      placeholder={placeholder}
      isLoading={isLoading}
      disabled={options === undefined}
      onChange={setDandisetId}
      isInvalid={options?.length === 0}
    />
  );
};
