import { EuiButtonIcon } from "@inscopix/ideas-eui";
import { Tooltip } from "components/Tooltip/Tooltip";
import { useDataTableContext } from "pages/gdt/store/DataTableProvider";
import { useState } from "react";
import { addUtilityToastFailure } from "utils/addUtilityToastFailure";
import { addUtilityToastSuccess } from "utils/addUtilityToastSuccess";

interface QuickActionDeleteRowBaseProps {
  tableId: string;
  rowIds: string[];
  tooltipContent: string;
  isDisabled: boolean;
}

const QuickActionDeleteRowBase = ({
  tableId,
  rowIds,
  tooltipContent,
  isDisabled,
}: QuickActionDeleteRowBaseProps) => {
  const deleteRows = useDataTableContext((s) => s.deleteRows);
  const [isLoading, setIsLoading] = useState(false);

  const handleClick = async () => {
    setIsLoading(true);
    const { error } = await deleteRows({ tableId, rowIds });
    setIsLoading(false);

    if (error === undefined) {
      addUtilityToastSuccess("Row(s) deleted");
    } else {
      addUtilityToastFailure("Failed to delete row(s)");
    }
  };

  return (
    <Tooltip content={tooltipContent}>
      <EuiButtonIcon
        aria-label={tooltipContent}
        size="xs"
        iconType="minusInCircle"
        color="danger"
        onClick={() => void handleClick()}
        disabled={isDisabled}
        isLoading={isLoading}
      />
    </Tooltip>
  );
};

interface QuickActionDeleteRowProps {
  tableId: string;
  rowId: string;
}

export const QuickActionDeleteRow = ({
  tableId,
  rowId,
}: QuickActionDeleteRowProps) => {
  return (
    <QuickActionDeleteRowBase
      tooltipContent="Delete row"
      tableId={tableId}
      rowIds={[rowId]}
      isDisabled={false}
    />
  );
};

export const QuickActionDeleteSelectedRows = () => {
  const selectedTableId = useDataTableContext((s) => s.selectedTableId);
  const selectedRowIds = useDataTableContext((s) => s.selectedRowIds);

  if (selectedTableId === undefined) {
    return null;
  }

  return (
    <QuickActionDeleteRowBase
      tooltipContent="Delete selected rows"
      tableId={selectedTableId}
      rowIds={selectedRowIds}
      isDisabled={selectedRowIds.length === 0}
    />
  );
};
