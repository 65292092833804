import { EuiEmptyPrompt, EuiLoadingLogo } from "@inscopix/ideas-eui";
import {
  File as DrsFile,
  useGetFileMetadataByFileIdQuery,
} from "graphql/_Types";
import {
  CellStatusEditor,
  CellStatusEditorProps,
} from "components/CellStatusEditor/CellStatusEditor";
import { useMemo, useState } from "react";
import { isDefined } from "utils/isDefined";
import { CellStatus } from "components/CellStatusEditor/CellStatusEditor.types";
import { CellStatusEditorProvider } from "components/CellStatusEditor/CellStatusEditorProvider";
import { CellStatusEditorSpacingInfo } from "components/CellStatusEditor/CellStatusEditor.types";
import { useQcReport } from "./useQcReport";
import { CallOutError } from "components/CallOutError/CallOutError";
import { isNonNullish } from "utils/isNonNullish";
import { isUndefined } from "lodash";

export interface ModalToolCellStatusParamWithFileProps
  extends Pick<
    CellStatusEditorProps,
    "onAcceptStatuses" | "onCancel" | "isInvalidInitialCellStatuses"
  > {
  initialCellStatuses?: CellStatus[];
  cellSetFile: Pick<DrsFile, "id">;
  qcReportFile?: Pick<DrsFile, "id">;
  eventSetFile?: Pick<DrsFile, "id">;
  readOnly?: boolean;
}

export const ModalToolCellStatusParamWithFile = ({
  cellSetFile,
  qcReportFile,
  eventSetFile,
  onAcceptStatuses,
  initialCellStatuses,
  onCancel,
  isInvalidInitialCellStatuses,
  readOnly,
}: ModalToolCellStatusParamWithFileProps) => {
  const [error, setError] = useState<Error | undefined>();

  // get cell status metadata - we have to have this to work with the cell status editor
  const {
    data: cellSetMetadata,
    loading: cellSetMetadataLoading,
    error: cellSetMetadataError,
  } = useGetFileMetadataByFileIdQuery({
    fetchPolicy: "no-cache",
    variables: {
      drsFileId: cellSetFile.id,
      metadataFilter: {
        metadatumByMetadataId: {
          key: {
            in: [
              "isx.CellStatuses",
              "isx.CellNames",
              "ideas.spacingInfo.numPixels.x",
              "ideas.spacingInfo.numPixels.y",
            ],
          },
        },
      },
    },
  });

  // the QC report is optional, could be undefined
  const {
    data: qcReportData,
    loading: qcReportLoading,
    error: qcReportError,
  } = useQcReport(qcReportFile?.id);

  // get cell names and statuses from metadata
  // both are required to work with the cell status editor
  const cellInfo = useMemo(() => {
    if (cellSetMetadata === undefined) {
      return undefined;
    }
    try {
      // depending on how the series was created (uploaded file or tool) the metadata
      // might be only on the series parent file. If there is a series parent with
      // metadata present, use that metadata
      const isSeriesMetadata =
        cellSetMetadata.drsFile?.seriesParentFile !== null &&
        cellSetMetadata.drsFile?.seriesParentFile?.metadata?.nodes.find(
          ({ metadatum }) => metadatum?.key === "isx.CellNames",
        ) !== undefined;

      const cellNamesMetadatum = isSeriesMetadata
        ? cellSetMetadata.drsFile?.seriesParentFile?.metadata?.nodes.find(
            ({ metadatum }) => metadatum?.key === "isx.CellNames",
          )
        : cellSetMetadata.drsFile?.metadata.nodes.find(
            ({ metadatum }) => metadatum?.key === "isx.CellNames",
          );

      // Try to get the cell names and statuses from the metadata first, if that fails, try to get them from the QC report.
      // The QC report is optional and also the cell set is the more reliable source (eg. a mismatch QC report could be chosen) so
      // getting them from the cell set metadata is preferred so we can show an error now instead of failing during tool execution
      const cellNames = (cellNamesMetadatum?.metadatum?.activeValue ??
        qcReportData?.cellSet?.cellInfo.cellNames) as string[] | undefined;

      const cellStatusesMetadatum = isSeriesMetadata
        ? cellSetMetadata.drsFile?.seriesParentFile?.metadata.nodes.find(
            ({ metadatum }) => metadatum?.key === "isx.CellStatuses",
          )
        : cellSetMetadata.drsFile?.metadata.nodes.find(
            ({ metadatum }) => metadatum?.key === "isx.CellStatuses",
          );
      const cellStatuses = (cellStatusesMetadatum?.metadatum?.activeValue ??
        qcReportData?.cellSet?.cellInfo?.cellStatuses?.map((status) =>
          // QC report stores statuses as strings, we need to convert them to CellStatus
          status === "accepted"
            ? CellStatus.Accepted
            : status === "rejected"
            ? CellStatus.Rejected
            : CellStatus.Undecided,
        )) as CellStatus[] | undefined;

      if (isUndefined(cellNames) || isUndefined(cellStatuses)) {
        if (!qcReportLoading) {
          throw new Error("Failed to get cell names and statuses");
        }
        // might not have loaded the QC report yet - just return undefined for now
        return undefined;
      }

      return cellNames.map((name, index) => ({
        name,
        initialStatus:
          isDefined(initialCellStatuses) &&
          !isInvalidInitialCellStatuses &&
          initialCellStatuses.length === cellNames.length
            ? initialCellStatuses[index]
            : cellStatuses[index],
        index,
      }));
    } catch {
      setError(new Error("Failed to get cell names and statuses"));
      return undefined;
    }
  }, [
    cellSetMetadata,
    initialCellStatuses,
    isInvalidInitialCellStatuses,
    qcReportData,
    qcReportLoading,
  ]);

  // we need spacing info only if we don't have image data to work with
  // we need it to create an array of 0s the same size as the image would be
  const spacingInfo: CellStatusEditorSpacingInfo = useMemo(() => {
    const cellNumPixelsXMetadatum =
      cellSetMetadata?.drsFile?.metadata.nodes.find(
        ({ metadatum }) => metadatum?.key === "ideas.spacingInfo.numPixels.x",
      );
    const numPixelsXValue = cellNumPixelsXMetadatum?.metadatum?.activeValue;
    const numPixelsX = isNonNullish(numPixelsXValue)
      ? (numPixelsXValue as number)
      : undefined;
    const cellNumPixelsYMetadatum =
      cellSetMetadata?.drsFile?.metadata.nodes.find(
        ({ metadatum }) => metadatum?.key === "ideas.spacingInfo.numPixels.y",
      );
    const numPixelsYValue = cellNumPixelsYMetadatum?.metadatum?.activeValue;
    const numPixelsY = isNonNullish(numPixelsYValue)
      ? (numPixelsYValue as number)
      : undefined;
    return { numPixelsX, numPixelsY };
  }, [cellSetMetadata]);

  // cell info parsing errors and metadata errors are fatal here
  // qc report errors are not, so they are left out of this check
  if (error !== undefined || cellSetMetadataError) {
    return (
      <CallOutError>
        <p>Failed to load cell set data.</p>
        <p>{error?.message ?? cellSetMetadataError?.message}</p>
      </CallOutError>
    );
  }

  if (cellInfo === undefined || qcReportLoading || cellSetMetadataLoading) {
    return (
      <EuiEmptyPrompt
        icon={<EuiLoadingLogo logo="visualizeApp" size="xl" />}
        body={
          cellSetMetadataLoading ? (
            <p>Loading cell set information</p>
          ) : (
            <p>Loading cell metrics</p>
          )
        }
        hasBorder={false}
      />
    );
  }

  // a quick validation to make sure the number of cells in the QC report matches the number of cells in the cell set
  // if not, the user has probably selected the wrong QC report
  const isQcReportDataMatchingCellInfo = (() => {
    if (qcReportData === undefined) {
      return undefined;
    }
    if (qcReportData?.cellSet?.cellInfo.cellNames === undefined) {
      return undefined;
    }
    // only return false if we did read the QC report, but it has the wrong number of cells
    if (qcReportData.cellSet.cellInfo.cellNames.length !== cellInfo.length) {
      return false;
    }
    return true;
  })();

  // we should check if the saved cell statuses match the number of cells in the cell set
  // if not, the user has probably swapped the cell set file with a different one
  const isInvalidOrMismatchInitialCellStatuses =
    isInvalidInitialCellStatuses ||
    (isDefined(initialCellStatuses) &&
      initialCellStatuses?.length !== cellInfo.length);

  return (
    <CellStatusEditorProvider
      cellInfo={cellInfo}
      spacingInfo={spacingInfo}
      qcReportData={qcReportData}
      files={{ cellSetFile, eventSetFile, qcReportFile }}
    >
      <CellStatusEditor
        isQcReportDataMatchingCellInfo={isQcReportDataMatchingCellInfo}
        isInvalidInitialCellStatuses={isInvalidOrMismatchInitialCellStatuses}
        onCancel={onCancel}
        onAcceptStatuses={onAcceptStatuses}
        qcReportError={qcReportError}
        readOnly={readOnly}
      />
    </CellStatusEditorProvider>
  );
};
