import Axios from "axios";
import { Project, Tenant } from "graphql/_Types";
import { getEnvVar } from "ideas.env";
import { getRequestHeaders } from "utils/getRequestHeaders";
import { ProjectStatus } from "types/constants";
import assert from "assert";

export type ProjectScheduleDeleteDjangoInput = {
  date_deleted: string;
};

type ProjectScheduleDeleteDjangoResponse = {
  data: {
    id: Project["id"];
    date_deleted: Project["dateDeleted"];
    status: Project["status"];
    tenant_id: Tenant["id"];
  };
};

/**
 * Django function to delete a project
 */
export const projectScheduleDeleteDjango = async (
  id: Project["id"],
  input: ProjectScheduleDeleteDjangoInput,
) => {
  const url = `${getEnvVar("URL_PROJECT_SCHEDULE_DELETE")}${id}/`;
  const headers = await getRequestHeaders();
  const { data: projectDeletedData } = await Axios.patch<
    Project["id"],
    ProjectScheduleDeleteDjangoResponse
  >(url, { ...input, status: ProjectStatus["AVAILABLE"] }, { headers });

  const { status, date_deleted, tenant_id } = projectDeletedData;
  assert(
    status === (ProjectStatus["PENDING_SCHEDULE_DELETE"] as const) &&
      date_deleted !== null,
  );

  return { id, status, dateDeleted: date_deleted, tenantId: tenant_id };
};

/**
 * Django function to delete a project from the admin usage view
 */

export const projectScheduleDeleteAdminManageDjango = async (
  id: Project["id"],
  input: ProjectScheduleDeleteDjangoInput,
) => {
  const url = `${getEnvVar(
    "URL_LIBRARY_PROJECT_ADMIN_MANAGE",
  )}schedule_delete/${id}/`;
  const headers = await getRequestHeaders();
  const { data: projectDeletedData } = await Axios.patch<
    Project["id"],
    ProjectScheduleDeleteDjangoResponse
  >(url, { ...input, status: ProjectStatus["AVAILABLE"] }, { headers });

  const { status, date_deleted, tenant_id } = projectDeletedData;
  assert(
    status === (ProjectStatus["PENDING_SCHEDULE_DELETE"] as const) &&
      date_deleted !== null,
  );

  return { id, status, dateDeleted: date_deleted, tenantId: tenant_id };
};
