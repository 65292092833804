import {
  CellError,
  ErrorType,
  ExportedCellChange,
  FunctionArgumentType,
  FunctionPlugin,
  HyperFormula,
  ImplementedFunctions,
  Sheets,
  RowIdentifier,
  IdeasFile,
  SimpleRangeValue,
  EmptyValue,
  RoiFrame,
  Group,
} from "@inscopix/ideas-hyperformula";
import { GetTableDataResponse } from "./DataTableProvider.api";
import { chain, zip } from "lodash";
import { immer } from "zustand/middleware/immer";
import { DataTableStoreState } from "./DataTableProvider.types";
import assert from "assert";
import { isDefined } from "utils/isDefined";
import { ProcedureAst } from "@inscopix/ideas-hyperformula/typings/parser";
import { InterpreterState } from "@inscopix/ideas-hyperformula/typings/interpreter/InterpreterState";
import {
  InternalScalarValue,
  InterpreterValue,
  RawInterpreterValue,
  RawNoErrorScalarValue,
} from "@inscopix/ideas-hyperformula/typings/interpreter/InterpreterValue";
import { isToolRoiFrameParamValue } from "types/ToolRoiFrameParamValue/isToolRoiFrameParamValue";

/** Represents the `set` function used to modify a Zustand store */
type SetFn = Parameters<
  Parameters<typeof immer<DataTableStoreState, [], []>>[0]
>[0];

/** Represents the `get` function used to read a Zustand store */
type GetFn = Parameters<
  Parameters<typeof immer<DataTableStoreState, [], []>>[0]
>[1];

/**
 * Represents a cell value that is still loading.
 *
 * Since HyperFormula does not currently support async formulas, we use
 * a cell error to propagate the loading state to all dependent cells.
 */
export class CellErrorLoading extends CellError {
  static message = "Value is loading." as const;
  constructor() {
    super(ErrorType.NA, CellErrorLoading.message);
  }
}

/** Represents the context passed to all IDEAS formulas. */
type IdeasFunctionPluginContext = {
  engine: HyperFormula;
  get: GetFn;
};

/**
 * Compares two cell values and determines if they represent that same data.
 * @param a
 * @param b
 * @returns `true` if the cell values are equal, `false` otherwise.
 */
export const areCellValuesEqual = (
  a: InternalScalarValue,
  b: InternalScalarValue,
) => {
  if (
    typeof a === "boolean" ||
    typeof a === "number" ||
    typeof a === "string" ||
    typeof a === "symbol" ||
    a === null
  ) {
    return a === b;
  } else if (a instanceof IdeasFile && b instanceof IdeasFile) {
    return a.attrs.id === b.attrs.id;
  } else if (a instanceof RowIdentifier && b instanceof RowIdentifier) {
    return a.tableKey === b.tableKey && a.rowIndex === b.rowIndex;
  } else {
    return false;
  }
};

/** Custom formula plugin for IDEAS data types and operations */
export class IdeasFunctionPlugin extends FunctionPlugin {
  public static implementedFunctions: ImplementedFunctions = {
    FILE: {
      method: "file",
      parameters: [
        // File ID
        { argumentType: FunctionArgumentType.STRING },
      ],
    },

    GROUP: {
      method: "group",
      parameters: [
        // Value stored in the group
        { argumentType: FunctionArgumentType.ANY },
      ],
      repeatLastArgs: 1,
    },

    JOIN: {
      method: "join",
      parameters: [
        // Left cell value
        { argumentType: FunctionArgumentType.NOERROR },
        // Right column
        { argumentType: FunctionArgumentType.RANGE },
        // Source column
        { argumentType: FunctionArgumentType.RANGE },
      ],
    },

    METADATUM: {
      method: "metadatum",
      parameters: [
        // File
        { argumentType: FunctionArgumentType.NOERROR },
        // Metadatum key
        { argumentType: FunctionArgumentType.STRING },
      ],
    },

    ROW_IDENTIFIER: {
      method: "row_identifier",
      parameters: [
        // Table key
        { argumentType: FunctionArgumentType.STRING },
        // Row index
        { argumentType: FunctionArgumentType.NUMBER },
      ],
    },

    ROI_FRAME: {
      method: "roi_frame",
      parameters: [
        // Serialized shape definitions
        { argumentType: FunctionArgumentType.STRING },
      ],
    },

    ROW_INDEX: {
      method: "row_index",
      parameters: [],
    },

    TABLE_KEY: {
      method: "table_key",
      parameters: [],
    },
  };

  /**
   * Creates a new `IdeasFile` cell value.
   * @param ast
   * @param state
   * @returns A `CellErrorLoading` while the file data is being fetched and
   * an `IdeasFile` thereafter.
   */
  file(ast: ProcedureAst, state: InterpreterState): InterpreterValue {
    return this.runFunction(
      ast.args,
      state,
      this.metadata("FILE"),
      (fileId: string) => {
        const context = this.config.context as IdeasFunctionPluginContext;
        const { engine, get } = context;
        const file = get().getFile({
          fileId,
          onChange: () => {
            const formula = engine.getCellFormula(state.formulaAddress);
            engine.setCellContents(state.formulaAddress, formula);
          },
        });

        if (file === undefined) {
          return new CellErrorLoading();
        } else if (file instanceof Error) {
          return new CellError(ErrorType.ERROR, file.message);
        } else {
          return new IdeasFile({
            id: file.id,
            name: file.name,
            status: file.status,
            fileType: file.file_type,
            isSeries: file.is_series,
            source: "uploaded",
            processingStatus: file.processing_status,
            seriesParentId: file.series_parent_id,
          });
        }
      },
    );
  }

  /**
   * Creates a new `Group` cell value.
   * @param ast
   * @param state
   * @returns The group.
   */
  group(ast: ProcedureAst, state: InterpreterState): InterpreterValue {
    return this.runFunction(
      ast.args,
      state,
      this.metadata("GROUP"),
      (...values: RawInterpreterValue[]) => {
        const context = this.config.context as IdeasFunctionPluginContext;
        const { engine } = context;
        if (values.length === 0) {
          return EmptyValue;
        } else if (values.length === 1) {
          return values[0];
        } else {
          const exportedValues = values.map((val) => engine.exportValue(val));
          return new Group(exportedValues);
        }
      },
    );
  }

  /**
   * Creates a new `IdeasFile` cell value.
   * @param ast
   * @param state
   * @returns A `CellErrorLoading` while the file data is being fetched and
   * an `IdeasFile` thereafter.
   */
  join(ast: ProcedureAst, state: InterpreterState): InterpreterValue {
    return this.runFunction(
      ast.args,
      state,
      this.metadata("JOIN"),
      (
        leftCellValue: RawNoErrorScalarValue,
        rightCol: SimpleRangeValue,
        sourceCol: SimpleRangeValue,
      ) => {
        const context = this.config.context as IdeasFunctionPluginContext;
        const { engine } = context;

        if (rightCol.height !== sourceCol.height) {
          return new CellError(
            ErrorType.ERROR,
            "Right column and source column are different heights",
          );
        }

        if (rightCol.width() !== 1 || sourceCol.width() !== 1) {
          return new CellError(
            ErrorType.ERROR,
            "Right column and source column cannot span multiple columns",
          );
        }

        const rawValues =
          // Pair the values from the right column and source column together
          zip(rightCol.data, sourceCol.data)
            // Remove any pairs where the right column cell value is not the
            // same as the left column cell value. We already ensured that
            // the right column spans exactly one column, hence [0].
            .filter(([rightCellValues]) => {
              assert(isDefined(rightCellValues));
              return areCellValuesEqual(leftCellValue, rightCellValues[0]);
            })
            // Get the source column cell value from any pair with a match.
            // We already ensured that the source column spans exactly one
            // column, hence [0].
            .map(([_, sourceCellValues]) => {
              assert(isDefined(sourceCellValues));
              return sourceCellValues[0];
            });

        if (rawValues.length === 0) {
          return EmptyValue;
        } else if (rawValues.length === 1) {
          return rawValues[0];
        } else {
          const values = rawValues.map((val) => engine.exportValue(val));
          return new Group(values);
        }
      },
    );
  }

  /**
   * Reads metadatum from an `IdeasFile`.
   * @param ast
   * @param state
   * @returns A `CellErrorLoading` while the metadatum is being fetched and
   * the metadatum value thereafter.
   */
  metadatum(ast: ProcedureAst, state: InterpreterState): InterpreterValue {
    return this.runFunction(
      ast.args,
      state,
      this.metadata("METADATUM"),
      (file: RawNoErrorScalarValue, metadatumKey: string) => {
        if (file === EmptyValue || file === "") {
          return EmptyValue;
        }

        if (!(file instanceof IdeasFile)) {
          return new CellError(ErrorType.VALUE, "Wrong type of argument.");
        }

        const context = this.config.context as IdeasFunctionPluginContext;
        const { engine, get } = context;

        const metadatumValue = get().getFileMetadatum({
          fileId: file.attrs.id,
          metadatumKey,
          // Trigger the cell formula to be recalculated when the metadatum value changes
          onChange: () => {
            const formula = engine.getCellFormula(state.formulaAddress);
            engine.setCellContents(state.formulaAddress, formula);
          },
        });

        if (metadatumValue instanceof Error) {
          return new CellError(ErrorType.ERROR, metadatumValue.message);
        } else {
          return metadatumValue?.toString() ?? "";
        }
      },
    );
  }

  /**
   * Creates a new `RoiFrame` cell value from serialized shape data.
   * @param ast
   * @param state
   * @returns The ROI frame.
   */
  roi_frame(ast: ProcedureAst, state: InterpreterState): InterpreterValue {
    return this.runFunction(
      ast.args,
      state,
      this.metadata("ROI_FRAME"),
      (shapeData: string) => {
        try {
          // Serialized shape data contains escaped double quotes
          const unescapedShapeData = shapeData.replace(/\\"/g, '"');
          // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
          const shapes = JSON.parse(unescapedShapeData);
          assert(isToolRoiFrameParamValue(shapes));
          return new RoiFrame(shapes);
        } catch (error) {
          return new CellError(ErrorType.ERROR, "Invalid ROI frame");
        }
      },
    );
  }

  /**
   * Creates a new `RowIdentifier` cell value.
   * @param ast
   * @param state
   * @returns The row identifier.
   */
  row_identifier(ast: ProcedureAst, state: InterpreterState): InterpreterValue {
    return this.runFunction(
      ast.args,
      state,
      this.metadata("ROW_IDENTIFIER"),
      (tableKey: string, rowIndex: number) => {
        const { get } = this.config.context as IdeasFunctionPluginContext;
        const table = get()?.tables.find((table) => table.key === tableKey);
        const row = table?.rows.find((row) => row.index === rowIndex);
        return isDefined(row)
          ? new RowIdentifier(tableKey, rowIndex)
          : new CellError(ErrorType.ERROR, "Row does not exist");
      },
    );
  }

  /**
   * Gets the index of the row of the formula reference.
   * @param ast
   * @param state
   * @returns The row index.
   */
  row_index(ast: ProcedureAst, state: InterpreterState): InterpreterValue {
    return this.runFunction(ast.args, state, this.metadata("ROW_INDEX"), () => {
      const { engine, get } = this.config.context as IdeasFunctionPluginContext;
      const sheetId = state.formulaAddress.sheet;
      const rowId = state.formulaAddress.row;
      const tableKey = engine.getSheetName(sheetId);
      const table = get()?.tables.find((table) => table.key === tableKey);
      const row = table?.rows[rowId];
      return row?.index ?? new CellError(ErrorType.ERROR, "Row does not exist");
    });
  }

  /**
   * Gets the key of the table of the formula reference.
   * @param ast
   * @param state
   * @returns The table key.
   */
  table_key(ast: ProcedureAst, state: InterpreterState): InterpreterValue {
    return this.runFunction(ast.args, state, this.metadata("TABLE_KEY"), () => {
      const context = this.config.context as IdeasFunctionPluginContext;
      const sheetId = state.formulaAddress.sheet;
      const tableKey = context.engine.getSheetName(sheetId);
      return tableKey ?? new CellError(ErrorType.ERROR, "Table does not exist");
    });
  }
}

// Register english translations for each formula name
const IdeasFunctionPluginTranslations = {
  enGB: {
    FILE: "FILE",
    GROUP: "GROUP",
    JOIN: "JOIN",
    METADATUM: "METADATUM",
    ROW_IDENTIFIER: "ROW_IDENTIFIER",
    ROI_FRAME: "ROI_FRAME",
    ROW_INDEX: "ROW_INDEX",
    TABLE_KEY: "TABLE_KEY",
  },
};

// Register the IDEAS plugin with HyperFormula
HyperFormula.registerFunctionPlugin(
  IdeasFunctionPlugin,
  IdeasFunctionPluginTranslations,
);

/**
 * Creates a new instance of a HyperFormula spreadsheet engine.
 * @param sheets The initial cell formulas formatted as sheets.
 * @param set `set` function provided by the Zustand store.
 * @param get `get` function provided by the Zustand store.
 * @returns The spreadsheet engine.
 */
export const initSpreadsheetEngine = (
  data: GetTableDataResponse,
  set: SetFn,
  get: GetFn,
) => {
  // Format server data into HyperFormula sheets
  const sheets: Sheets = chain(data)
    .keyBy((table) => table.key)
    .mapValues((table) =>
      table.rows.map((row) => row.cells.map((cell) => cell.formula ?? "")),
    )
    .value();

  // Initialize a new HyperFormula engine instance
  const engine = HyperFormula.buildFromSheets(sheets);
  const config = {
    licenseKey: "gpl-v3",
    context: { engine, get } satisfies IdeasFunctionPluginContext,
  };
  engine.updateConfig(config);

  // Set the initial cell values in the store
  set((state) => {
    state.tables.forEach((table, tableIdx) => {
      table.rows.forEach((row, rowIdx) => {
        row.cells.forEach((cell, columnIdx) => {
          cell.value = engine.getCellValue({
            sheet: tableIdx,
            col: columnIdx,
            row: rowIdx,
          });
        });
      });
    });
  });

  // Initialize callback to update store state when cell values change
  engine.on("valuesUpdated", (changes) => {
    set((state) => {
      changes.forEach((change) => {
        if (change instanceof ExportedCellChange) {
          // Update cell value in store state
          const { address, newValue } = change;
          const tableKey = engine.getSheetName(address.sheet);
          const table = state.tables.find(({ key }) => key === tableKey);
          assert(isDefined(table));
          const cell = table.rows[address.row].cells[address.col];
          cell.value = newValue;
        }
      });
    });
  });

  return engine;
};
