/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { useState } from "react";
import { CellValue } from "@inscopix/ideas-hyperformula";
import {
  EuiListGroup,
  EuiListGroupItem,
  EuiPopover,
} from "@inscopix/ideas-eui";
import { CellValueRendererBase } from "../cell-value-renderers/CellValueRendererBase";

const styles = {
  popover: css`
    & {
      height: 100%;
      width: 100%;
      display: flex;
      align-items: center;
    }
  `,
  panel: css`
    max-height: max(200px, 50vh);
    overflow-y: scroll;
  `,
};

interface CellEditorBooleanProps {
  value: CellValue;
  onChangeFormula: (newFormula: string) => void;
  stopEditing: (cancel?: boolean) => void;
}

/** Cell editor for cells in boolean columns */
export const CellEditorBoolean = ({
  value,
  onChangeFormula,
  stopEditing,
}: CellEditorBooleanProps) => {
  const [newValue, setNewValue] = useState(value);

  return (
    <EuiPopover
      anchorPosition="downCenter"
      button={<CellValueRendererBase value={newValue} />}
      isOpen
      panelPaddingSize="none"
      css={styles.popover}
      closePopover={stopEditing}
      onKeyDown={(e) => {
        if (e.key === "Escape") {
          e.preventDefault();
          stopEditing(true);
        }
      }}
      display="block"
      repositionOnScroll={true}
      panelProps={{ paddingSize: "none" }}
    >
      <EuiListGroup css={styles.panel} maxWidth="100%">
        {[true, false].map((option) => (
          <EuiListGroupItem
            id={option.toString()}
            key={option.toString()}
            iconType={newValue === option ? "check" : "empty"}
            label={<CellValueRendererBase value={option} />}
            onClick={() => {
              if (newValue === option) {
                onChangeFormula("");
                setNewValue(null);
              } else {
                const newFormula = option === true ? "=TRUE()" : "=FALSE()";
                onChangeFormula(newFormula);
                setNewValue(option);
              }
            }}
          />
        ))}
      </EuiListGroup>
    </EuiPopover>
  );
};
