import assert from "assert";
import { useUpdatePageDatasetCache } from "components/Dataset/hooks/useUpdatePageDatasetCache";
import {
  DatasetRecordingsTableColumn,
  useCreateMetadataValueMutation,
} from "graphql/_Types";
import { useTenantContext } from "providers/TenantProvider/TenantProvider";
import { useDatasetDataContext } from "pages/project/dataset/DatasetDataProvider";
import { useCallback } from "react";
import { isDefined } from "utils/isDefined";
import { isNonNullish } from "utils/isNonNullish";
import { uuid } from "utils/uuid";
import { cloneDeep } from "lodash";
import { captureException } from "@sentry/react";
import { useUserContext } from "providers/UserProvider/UserProvider";
import { useProjectPermissionStore } from "pages/project/ProjectPermissionProvider";

type RenameColumnParams = {
  columnId: DatasetRecordingsTableColumn["id"];
  newName: string;
};

export const useActionRenameColumn = () => {
  const currentUser = useUserContext((s) => s.currentUser);
  const currentTenant = useTenantContext((s) => s.currentTenant);
  const { recordingsTable, dataset, datasetMode, project } =
    useDatasetDataContext();
  const { updateCache } = useUpdatePageDatasetCache(dataset.id);
  const [createMetadatumValue] = useCreateMetadataValueMutation();
  const hasEditPermission = useProjectPermissionStore(
    (s) => s.permissions["edit"],
  );
  const getActionDef = useCallback(
    (params: RenameColumnParams) => {
      const columns = recordingsTable.datasetRecordingsTableColumns.nodes;
      const column = columns.find(({ id }) => id === params.columnId);
      assert(isDefined(column), "Expected column to be defined");
      const prevColDef = column.colDef;
      const newColDef = { ...prevColDef, headerName: params.newName };

      const metadataValue = {
        id: uuid(),
        metadataId: column.colDefMetadatumId,
        value: newColDef,
        userId: currentUser.id,
        tenantId: currentTenant.id,
        dateCreated: new Date().toISOString(),
        taskId: null,
        projectId: project.id,
      };

      return {
        onEnqueue: () => {
          updateCache((prevData) => {
            const newData = cloneDeep(prevData);
            const table = newData.dataset.datasetRecordingsTable;
            const columns = table.datasetRecordingsTableColumns.nodes;
            const column = columns.find(({ id }) => id === params.columnId);
            assert(isDefined(column), "Expected column to be defined");
            column.activeColDef = newColDef;
            return newData;
          });
        },

        onDequeue: async () => {
          const { data } = await createMetadatumValue({
            variables: { metadataValue },
            onError: (err) => captureException(err),
          });
          const newMetadataValue = data?.createMetadataValue?.metadataValue;
          assert(
            isNonNullish(newMetadataValue),
            "Expected data to non nullish when creating metadatum value",
          );
          return newMetadataValue;
        },
      };
    },
    [
      createMetadatumValue,
      currentTenant.id,
      currentUser.id,
      project.id,
      recordingsTable.datasetRecordingsTableColumns.nodes,
      updateCache,
    ],
  );

  const isDisabled = datasetMode.type !== "current" || !hasEditPermission;

  return {
    getActionDef,
    isDisabled,
  };
};
