import { EuiButtonIcon } from "@inscopix/ideas-eui";
import { Tooltip } from "components/Tooltip/Tooltip";
import { useDataTableContext } from "pages/gdt/store/DataTableProvider";

export const QuickActionDownloadTableAsCsv = () => {
  const selectedTableId = useDataTableContext((s) => s.selectedTableId);
  const downloadTableAsCsv = useDataTableContext((s) => s.downloadTableAsCsv);

  const handleClick = () => {
    if (selectedTableId !== undefined) {
      downloadTableAsCsv({
        tableId: selectedTableId,
      });
    }
  };

  return (
    <Tooltip content="Download table as CSV">
      <EuiButtonIcon
        aria-label="Download table as CSV"
        size="xs"
        iconType="download"
        onClick={handleClick}
        disabled={selectedTableId === undefined}
      />
    </Tooltip>
  );
};
