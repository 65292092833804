import { AxiosError } from "axios";
import {
  EuiButton,
  EuiButtonEmpty,
  EuiButtonIcon,
  EuiFieldText,
  EuiFlexGrid,
  EuiFlexItem,
  EuiFormRow,
  EuiModal,
  EuiModalBody,
  EuiModalFooter,
  EuiModalHeader,
  EuiModalHeaderTitle,
  EuiSpacer,
  htmlIdGenerator,
} from "@inscopix/ideas-eui";
import { useState } from "react";
import { isValidEmail } from "@aws-amplify/ui";
import {
  getPendingInvitations,
  sendInvitations,
} from "../PanelTenantAccess/PanelTenantAccess.helpers";
import { useUserContext } from "providers/UserProvider/UserProvider";
import { useTenantContext } from "providers/TenantProvider/TenantProvider";
import { addUtilityToastFailure } from "utils/addUtilityToastFailure";
import { addUtilityToastSuccess } from "utils/addUtilityToastSuccess";
import { Tooltip } from "components/Tooltip/Tooltip";
import { hasDjangoErrorCode } from "utils/djangoError";

type Email = {
  id: string;
  address: string;
  isAlreadyInvited: boolean;
};

interface ModalAddTenantMembersProps {
  onClose: () => void;
}

/** Component that renders a modal for adding users to a tenant */
export const ModalAddTenantMembers = ({
  onClose,
}: ModalAddTenantMembersProps) => {
  const currentUserId = useUserContext((s) => s.currentUser.id);
  const currentTenantId = useTenantContext((s) => s.currentTenant.id);
  const allUsers = useTenantContext((s) => s.tenantUsers);
  const [emails, setEmails] = useState<Email[]>([]);
  const [input, setInput] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  /**
   * Formats an email address for consistent display.
   * @param address
   * @returns The formatted email address.
   */
  const formatEmail = (address: string) => {
    return address.trim().toLowerCase();
  };

  /**
   * Adds a new email address to state.
   * @param address
   */
  const addEmail = (address: string) => {
    const email: Email = {
      id: htmlIdGenerator()(),
      address: formatEmail(address),
      isAlreadyInvited: false,
    };
    setEmails((emails) => [...emails, email]);
  };

  /**
   * Removes an email address from state.
   * @param id
   */
  const removeEmail = (id: string) => {
    setEmails((emails) => emails.filter((email) => email.id !== id));
  };

  /**
   * Updates an email address in state.
   * @param id
   * @param address
   */
  const updateEmail = (id: string, address: string) => {
    setEmails((emails) =>
      emails.map((email) =>
        email.id === id
          ? {
              id: email.id,
              address: formatEmail(address),
              isAlreadyInvited: false,
            }
          : email,
      ),
    );
  };

  /**
   * Validates whether the email addresses in state are formatted correctly
   * and whether no active or invited users are reinvited.
   * @returns A `boolean` representing whether the email addresses are valid.
   */
  const validateEmails = async () => {
    const addresses = emails.map((email) => email.address);
    if (input !== "") {
      addresses.push(input);
    }

    const invitations = await getPendingInvitations({
      tenantId: currentTenantId,
    });

    const existingEmails = new Set([
      ...allUsers.map((user) => user.email),
      ...invitations.map((invitation) => invitation.email),
    ]);

    setEmails((emails) => {
      return emails.map((email) => ({
        ...email,
        isAlreadyInvited: existingEmails.has(email.address),
      }));
    });

    const isDuplicateEmail = addresses.some((address) =>
      existingEmails.has(address),
    );

    if (isDuplicateEmail) {
      return false;
    }

    if (!addresses.every(isValidEmail)) {
      return false;
    }

    return true;
  };

  /** Sends invites to all email addresses. */
  const handleSubmit = async () => {
    const addresses = emails.map((email) => email.address);

    // Add text field input to email list
    if (input !== "") {
      addresses.push(input);
      addEmail(input);
      setInput("");
    }

    try {
      setIsLoading(true);
      const areEmailsValid = await validateEmails();
      if (!areEmailsValid) {
        return;
      }

      await sendInvitations({
        inviterId: currentUserId,
        tenantId: currentTenantId,
        emails: addresses,
      });

      addUtilityToastSuccess("Users invited");
      onClose();
    } catch (error) {
      if (
        error instanceof AxiosError &&
        hasDjangoErrorCode(error, "tenant_seats_exceeded")
      ) {
        addUtilityToastFailure("Tenant seats exceeded");
      } else {
        // We don't have a specific reason
        addUtilityToastFailure("Failed to invite users");
      }
      onClose();
    } finally {
      setIsLoading(false);
    }
  };

  const isInvalid = emails.some(
    (email) => email.isAlreadyInvited || !isValidEmail(email.address),
  );

  return (
    <EuiModal onClose={onClose} style={{ width: 600 }}>
      <EuiModalHeader>
        <EuiModalHeaderTitle>Invite users</EuiModalHeaderTitle>
      </EuiModalHeader>

      <EuiModalBody>
        <EuiFormRow
          label="Emails"
          fullWidth
          isInvalid={isInvalid}
          error={
            isInvalid
              ? "One or more email addresses are invalid or have already been invited"
              : undefined
          }
        >
          <div>
            <EuiFlexGrid gutterSize="s" columns={2}>
              {emails.map((email) => {
                const tooltipMessage = (() => {
                  if (email.isAlreadyInvited) {
                    return "This email has already been invited";
                  }

                  if (!isValidEmail(email.address)) {
                    return "Invalid email address format";
                  }
                })();

                return (
                  <EuiFlexItem key={email.id}>
                    <Tooltip content={tooltipMessage}>
                      <EuiFieldText
                        title=""
                        value={email.address}
                        onChange={(e) => updateEmail(email.id, e.target.value)}
                        compressed
                        append={
                          <EuiButtonIcon
                            aria-label="Remove email"
                            color="text"
                            iconType="cross"
                            onClick={() => removeEmail(email.id)}
                          />
                        }
                        isInvalid={
                          !isValidEmail(email.address) || email.isAlreadyInvited
                        }
                      />
                    </Tooltip>
                  </EuiFlexItem>
                );
              })}
            </EuiFlexGrid>
            <EuiSpacer size="s" />
            <EuiFieldText
              autoFocus
              placeholder="Type or paste comma-separated email addresses..."
              value={input}
              onChange={(e) => setInput(formatEmail(e.target.value))}
              compressed
              fullWidth
              title=""
              onKeyDown={(e) => {
                if (["Enter", ",", " "].includes(e.key)) {
                  addEmail(input);
                  setInput("");
                }
              }}
              onPaste={(e) => {
                e.clipboardData
                  .getData("text/plain")
                  .split(",")
                  .map((address) => address.trim().toLowerCase())
                  .filter(
                    (address) =>
                      !emails.some((email) => email.address === address),
                  )
                  .forEach(addEmail);
                e.preventDefault();
              }}
            />
          </div>
        </EuiFormRow>
      </EuiModalBody>

      <EuiModalFooter>
        <EuiButtonEmpty onClick={onClose}>Cancel</EuiButtonEmpty>
        <EuiButton
          fill
          onClick={() => void handleSubmit()}
          isLoading={isLoading}
          disabled={(input === "" && emails.length === 0) || isInvalid}
        >
          Invite
        </EuiButton>
      </EuiModalFooter>
    </EuiModal>
  );
};
