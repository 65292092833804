import { memo } from "react";
import { UserAvatar } from "components/UserAvatar/UserAvatar";

interface CellRendererTaskUserProps {
  userId: string;
}

/**
 * Cell renderer that renders the avatar of the user who executed an analysis
 */
const CellRendererTaskUser = ({ userId }: CellRendererTaskUserProps) => {
  return <UserAvatar size="s" userId={userId} />;
};

export const CellRendererTaskUserMemo = memo(CellRendererTaskUser);
