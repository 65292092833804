import { EuiBasicTable, EuiTableComputedColumnType } from "@inscopix/ideas-eui";
import { useMemo } from "react";
import { ModalDandiState } from "./ModalDandi.types";

interface ModalDandiFieldFilesProps<T extends { id: string; size: string }> {
  files: T[];
  fields: ModalDandiState<T>;
  setFiles: (files: T[]) => void;
  columns: EuiTableComputedColumnType<T>[];
  isFileInColumn: (file: T, columnId: string) => boolean;
}

/** Component that file selection table field. */
export const ModalDandiFieldFiles = <T extends { id: string; size: string }>({
  files,
  fields,
  setFiles,
  columns,
  isFileInColumn,
}: ModalDandiFieldFilesProps<T>) => {
  const filesInDataColumn = useMemo(
    () => files.filter((file) => isFileInColumn(file, fields.dataColumnId)),
    [fields.dataColumnId, files, isFileInColumn],
  );

  return (
    <EuiBasicTable
      items={filesInDataColumn}
      columns={columns}
      isSelectable
      selection={{
        onSelectionChange: setFiles,
        selected: fields.files,
      }}
      itemId="id"
    />
  );
};
