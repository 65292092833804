import { EuiButtonIcon, htmlIdGenerator } from "@inscopix/ideas-eui";
import { addToast } from "components/GlobalToastList/GlobalToastList";
import { Tooltip } from "components/Tooltip/Tooltip";
import { useDataTableContext } from "pages/gdt/store/DataTableProvider";

interface QuickActionCopyRowBaseProps {
  tableId: string;
  rowIds: string[];
  tooltipContent: string;
  isDisabled: boolean;
}

const QuickActionCopyRowBase = ({
  tooltipContent,
  isDisabled,
}: QuickActionCopyRowBaseProps) => {
  // TODO: Implemented in downstream PR
  const handleClick = () => {
    addToast({
      id: htmlIdGenerator()(),
      title: "Not implemented",
      color: "warning",
      text: "Feature coming soon",
    });
  };

  return (
    <Tooltip content={tooltipContent}>
      <EuiButtonIcon
        aria-label={tooltipContent}
        size="xs"
        iconType="copy"
        onClick={handleClick}
        disabled={isDisabled}
      />
    </Tooltip>
  );
};

interface QuickActionCopyRowProps {
  tableId: string;
  rowId: string;
}

export const QuickActionCopyRow = ({
  tableId,
  rowId,
}: QuickActionCopyRowProps) => {
  return (
    <QuickActionCopyRowBase
      tooltipContent="Copy row"
      tableId={tableId}
      rowIds={[rowId]}
      isDisabled={false}
    />
  );
};

export const QuickActionCopySelectedRows = () => {
  const selectedTableId = useDataTableContext((s) => s.selectedTableId);
  const selectedRowIds = useDataTableContext((s) => s.selectedRowIds);

  if (selectedTableId === undefined) {
    return null;
  }

  return (
    <QuickActionCopyRowBase
      tooltipContent="Copy selected rows"
      tableId={selectedTableId}
      rowIds={selectedRowIds}
      isDisabled={selectedRowIds.length === 0}
    />
  );
};
