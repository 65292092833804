import { EuiFlexGroup, EuiIcon, EuiLink } from "@inscopix/ideas-eui";
import { useRouteMapContext } from "providers/RouteMapProvider/RouteMapProvider";
import { useDataTableContext } from "../store/DataTableProvider";

/**
 * Component that renders breadcrumbs for navigating to related pages from a
 * data table
 */
export const DataTableBreadcrumbs = () => {
  const routeMap = useRouteMapContext((s) => s.routeMap);
  const projectName = useDataTableContext((s) => s.project.name);

  return (
    <EuiLink color="subdued" to={routeMap.DASHBOARD.path}>
      <EuiFlexGroup gutterSize="xs" alignItems="center">
        <EuiIcon size="s" type="arrowLeft" />
        {projectName}
      </EuiFlexGroup>
    </EuiLink>
  );
};
