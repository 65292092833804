import { captureException } from "@sentry/react";
import { FileBrowserActions } from "components/FileBrowser/FileBrowser";
import { validateSeriesFiles } from "components/ModalCreateSeries/ModalCreateSeries";
import { ProjectLayoutContextValue } from "components/Project/ProjectLayoutProvider";
import { useDownloadDrsFileNonSeries } from "hooks/useDownloadDrsFileNonSeries";
import { DatasetLayoutContextValue } from "pages/project/dataset/DatasetLayoutProvider";
import { useMemo } from "react";
import { addUtilityToastFailure } from "utils/addUtilityToastFailure";
import { addUtilityToastSuccess } from "utils/addUtilityToastSuccess";

export const useGetFileBrowserActions = (
  openModal:
    | ProjectLayoutContextValue["openModal"]
    | DatasetLayoutContextValue["openModal"],
) => {
  const { downloadDrsFileNonSeries } = useDownloadDrsFileNonSeries();

  const actions: FileBrowserActions = useMemo(
    () => ({
      archive: {
        onExecute: (files) => {
          return new Promise((res) => {
            openModal({
              type: "archiveDrsFile",
              props: {
                drsFiles: files.map((file) => ({
                  ...file,
                  source: file.source.type,
                })),
                onComplete: ({ successfulFiles, errors }) => {
                  res({ successfulFiles, errors });
                },
                onClose: () => res({ successfulFiles: [], errors: [] }),
              },
            });
          });
        },
        suppressLoading: true,
      },
      createSeries: {
        onExecute: (files) =>
          new Promise((res) => {
            openModal({
              type: "createSeries",
              props: {
                selectedFileIds: files.map(({ id }) => id),
                onComplete: (consumedDrsFiles) =>
                  res({
                    successfulFiles: consumedDrsFiles,
                    errors: [],
                  }),
                onError: (error) =>
                  res({
                    successfulFiles: [],
                    errors: [error],
                  }),
                onClose: () => res({ successfulFiles: [], errors: [] }),
              },
            });
          }),
        suppressLoading: true,
        isDisabled: (files) =>
          !validateSeriesFiles(
            files.map((file) => ({
              ...file,
              source: file.source.type,
            })),
          ).isValid,
        disabledToolTip: (files) =>
          validateSeriesFiles(
            files.map((file) => ({
              ...file,
              source: file.source.type,
            })),
          ).validationError,
      },
      revertSeries: {
        onExecute: (files) =>
          new Promise((res) => {
            openModal({
              type: "breakSeries",
              props: {
                drsFiles: files.map(({ source, ...file }) => ({
                  ...file,
                  source: source.type,
                })),
                onComplete: ({ successfulFiles, errors }) =>
                  res({ successfulFiles, errors }),
                onClose: () => res({ successfulFiles: [], errors: [] }),
              },
            });
          }),
        suppressLoading: true,
        isDisabled: (files) => !files.some((file) => file.isSeries),
        disabledToolTip: "You must select a series to break it.",
      },
      delete: {
        onExecute: (files) =>
          new Promise((res) =>
            openModal({
              type: "deleteDrsFile",
              props: {
                drsFiles: files.map((file) => ({
                  ...file,
                  source: file.source.type,
                })),
                onComplete: (deleteResults) => {
                  res(deleteResults);
                },
                onClose: () => res({ successfulFiles: [], errors: [] }),
              },
            }),
          ),
        suppressLoading: true,
      },
      unarchive: {
        onExecute: (files) =>
          new Promise((res) =>
            openModal({
              type: "unarchiveDrsFile",
              props: {
                drsFiles: files.map((file) => ({
                  ...file,
                  source: file.source.type,
                })),
                onComplete: ({ successfulFiles, errors }) =>
                  res({ successfulFiles, errors }),
                onClose: () => res({ successfulFiles: [], errors: [] }),
              },
            }),
          ),
        suppressLoading: true,
      },
      rename: {
        onExecute: (files) =>
          new Promise((res) =>
            openModal({
              type: "renameDrsFile",
              props: {
                drsFile: files[0],
                onComplete: (fileRenamed) =>
                  res({
                    successfulFiles: [fileRenamed],
                    errors: [],
                  }),
                onError: (err) =>
                  res({
                    successfulFiles: [],
                    errors: [err],
                  }),
                onClose: () => res({ successfulFiles: [], errors: [] }),
              },
            }),
          ),
        suppressLoading: true,
        isDisabled: (files) => files.length > 1,
        disabledToolTip: "Select only a single file to rename",
      },
      download: {
        suppressLoading: (files) => {
          if (files.length > 0) {
            const file = files[0];
            return file.isSeries;
          }
          return false;
        },
        onExecute: (files) =>
          new Promise((res) => {
            // action is disabled if more than one file selected
            // update suppressLoading if you change this
            const file = files[0];
            if (file.isSeries) {
              openModal({
                type: "downloadFiles",
                props: {
                  files: file.seriesFiles,
                  onClose: () => res({ successfulFiles: [], errors: [] }),
                },
              });
              // opening the modal is considered successful for series files
              res({
                successfulFiles: files,
                errors: [],
              });
            } else {
              downloadDrsFileNonSeries({
                ...file,
                source: file.source.type,
              })
                .then(() => {
                  addUtilityToastSuccess("Successfully queued file download");
                  res({ successfulFiles: [file], errors: [] });
                })
                .catch((err) => {
                  captureException(err);
                  addUtilityToastFailure("Failed to queue file download");
                  res({
                    successfulFiles: [],
                    errors: [new Error("Failed to download file")],
                  });
                });
            }
          }),
        isDisabled: (files) => files.length > 1,
        disabledToolTip: "Only one file may be downloaded at a time",
      },
    }),
    [downloadDrsFileNonSeries, openModal],
  );

  return { actions };
};
