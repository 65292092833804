import {
  EuiButtonEmpty,
  EuiIcon,
  EuiPopover,
  EuiTreeView,
  EuiTreeViewProps,
} from "@inscopix/ideas-eui";
import { Column, ColumnGroup, IHeaderColumn } from "ag-grid-community";
import { isEmpty } from "lodash";
import { useDataTableContext } from "pages/gdt/store/DataTableProvider";
import { useState } from "react";

export const QuickActionJumpToColumn = () => {
  const gridApi = useDataTableContext((s) => s.gridApi);
  const selectedTableId = useDataTableContext((s) => s.selectedTableId);
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);

  /**
   * Create an array of EuiTreeView items from an AG grid column or column
   * group.
   * @param column
   * @returns An array of EuiTreeView items.
   */
  const createTreeItems = (
    column: IHeaderColumn,
  ): EuiTreeViewProps["items"] => {
    // Columns are converted to items that scroll the grid when clicked
    if (column instanceof Column) {
      const columnId = column.getColId();
      const columnName = column.getDefinition().headerName;

      if (columnName === undefined || columnName === "") {
        return [];
      } else {
        return [
          {
            id: columnId,
            label: columnName,
            callback: () => {
              setIsPopoverOpen(false);
              gridApi?.ensureColumnVisible(columnId, "start");
              // https://github.com/ag-grid/ag-grid/issues/5085
              setTimeout(() => {
                gridApi?.flashCells({ columns: [columnId] });
              }, 0);
              return columnId;
            },
          },
        ];
      }
    }
    // Column groups are converted to sub-trees that are collapsed or expanded
    // when clicked
    else if (column instanceof ColumnGroup) {
      const colGroupDef = column.getColGroupDef();
      const childItems = column.getChildren()?.flatMap(createTreeItems) ?? [];

      if (colGroupDef === null || isEmpty(colGroupDef)) {
        return childItems;
      } else {
        return [
          {
            label: colGroupDef?.headerName,
            id: column.getGroupId(),
            icon: <EuiIcon type="arrowRight" />,
            iconWhenExpanded: <EuiIcon type="arrowDown" />,
            isExpanded: true,
            children: childItems,
          },
        ];
      }
    }
    // IHeaderColumn can technically be implemented by any class but we only
    // support Column and ColumnGroup
    else {
      return [];
    }
  };

  return (
    <EuiPopover
      isOpen={isPopoverOpen}
      closePopover={() => setIsPopoverOpen(false)}
      anchorPosition="downCenter"
      button={
        <EuiButtonEmpty
          size="xs"
          iconType="arrowDown"
          iconSide="right"
          onClick={() => setIsPopoverOpen((isOpen) => !isOpen)}
          disabled={selectedTableId === undefined}
        >
          Jump to column
        </EuiButtonEmpty>
      }
    >
      <EuiTreeView
        aria-label="Columns"
        display="compressed"
        items={
          gridApi?.getAllDisplayedColumnGroups()?.flatMap(createTreeItems) ?? []
        }
      />
    </EuiPopover>
  );
};
