import {
  EuiButton,
  EuiButtonEmpty,
  EuiCallOut,
  EuiCheckbox,
  EuiFormRow,
  EuiListGroup,
  EuiListGroupItem,
  EuiModal,
  EuiModalBody,
  EuiModalFooter,
  EuiModalHeader,
  EuiModalHeaderTitle,
  EuiSpacer,
  EuiText,
} from "@inscopix/ideas-eui";
import { Column, ColumnGroup } from "ag-grid-community";
import { Fragment, useState } from "react";
import { useColumnDependents } from "./ModalRemoveColumns.hooks";
import { css } from "@emotion/react";
import { useDatasetAction } from "hooks/useDatasetAction/useDatasetAction";
import assert from "assert";
import {
  isDrsFileColumn,
  isMetadataColumn,
} from "components/RecordingsGrid/RecordingsGrid.helpers";
import { useProjectFilesStore } from "stores/project-files/ProjectFilesManager";
import { FileSource } from "graphql/_Types";
import { useSelectedDrsFileId } from "stores/useSelectedDrsFileId";
import { getDrsFileModifyPermissionByDrsFileAndAction } from "types/DrsFileModifyPermissions";
import { FileBadge } from "components/FileBadge/FileBadge";
import { useActionQueueStore } from "stores/ActionsQueue/ActionsQueueManager";
import { ModalSyncingDataset } from "components/ModalDeleteDrsFile/ModalSyncingDataset";

export type ModalRemoveColumnsProps = {
  targetColumn: Column | ColumnGroup;
  onClose: () => void;
};

export const ModalRemoveColumns = ({
  targetColumn,
  onClose,
}: ModalRemoveColumnsProps) => {
  // All columns affected by the removal of the target column or column group
  const allAffectedColumns = useColumnDependents(targetColumn);
  const deselectDrsFile = useSelectedDrsFileId((s) => s.deselectDrsFile);
  const [selections, setSelections] = useState(() => {
    const allColumnIds = allAffectedColumns.map(({ id }) => id);
    return new Set(allColumnIds);
  });
  const deleteColumnAction = useDatasetAction("deleteColumn");
  const dataDeleteFileAction = useDatasetAction("dataDeleteFile");
  // All files affected by the removal of the target column or column group
  const affectedFiles = useProjectFilesStore((s) => {
    return s.files.filter((file) => {
      const { columns } = file;
      const isUploadedFile = file.source === FileSource.Uploaded;
      const isAssignedToColumn = columns.some(({ id }) => selections.has(id));
      return isUploadedFile && isAssignedToColumn;
    });
  });
  const [isSubmitting, setIsSubmitting] = useState(false);

  const status = useActionQueueStore((s) => s.status);
  const areActionsSynced = status === "synced";

  const deletableFiles = affectedFiles.filter(
    (file) =>
      getDrsFileModifyPermissionByDrsFileAndAction(file, "DATA_DELETE")
        .isPermitted,
  );

  const undeletableFiles = affectedFiles.filter(
    (file) =>
      !getDrsFileModifyPermissionByDrsFileAndAction(file, "DATA_DELETE")
        .isPermitted,
  );

  /**
   * Toggles the checkbox state for a specified column
   * @param columnId
   */
  const toggleSelection = (columnId: string) => {
    setSelections((prevSelections) => {
      const newSelections = new Set(prevSelections);
      if (prevSelections.has(columnId)) {
        newSelections.delete(columnId);
      } else {
        newSelections.add(columnId);
      }
      return newSelections;
    });
  };

  const handleSubmit = async () => {
    setIsSubmitting(true);
    for (const columnId of Array.from(selections)) {
      const column = allAffectedColumns.find(({ id }) => id === columnId);
      assert(column !== undefined);

      deletableFiles.forEach((file) => {
        void dataDeleteFileAction.enqueue({ fileId: file.id });
        deselectDrsFile(file.id);
      });

      column.dependents.forEach((dependent) => {
        void deleteColumnAction.enqueue({ columnId: dependent.id });
      });

      await deleteColumnAction.enqueue({ columnId: column.id });
    }
    setIsSubmitting(false);
    onClose();
  };

  // Is data lost when removing these columns?
  const isDestructive = allAffectedColumns.some(
    (column) => isDrsFileColumn(column) || isMetadataColumn(column),
  );

  if (!areActionsSynced) {
    return <ModalSyncingDataset onClose={onClose} />;
  }

  return (
    <EuiModal onClose={onClose}>
      <EuiModalHeader>
        <EuiModalHeaderTitle>
          {isDestructive ? "Delete column(s)" : "Remove column(s)"}
        </EuiModalHeaderTitle>
      </EuiModalHeader>
      <EuiModalBody>
        <EuiFormRow label="Columns">
          <>
            {allAffectedColumns.map((column) => {
              const helpText = (() => {
                switch (column.colDef.type) {
                  case "drsFile":
                    return "All files in this column will be deleted.";
                  case "metadata":
                    return "Data in this column will be lost.";
                  case "analysisResult":
                  case "linkedMetadata":
                    return undefined;
                }
              })();

              return (
                <Fragment key={column.id}>
                  <EuiSpacer size="xs" />
                  <EuiCheckbox
                    id={column.id}
                    onChange={() => toggleSelection(column.id)}
                    checked={selections.has(column.id)}
                    label={<span>{column.name}</span>}
                    disabled={isSubmitting}
                  />
                  {helpText !== undefined && (
                    <EuiText
                      size="xs"
                      color="danger"
                      style={{ marginLeft: 24 }}
                    >
                      <p>{helpText}</p>
                    </EuiText>
                  )}
                  {column.dependents.map((dependent) => {
                    return (
                      <>
                        <EuiSpacer size="xs" />
                        <EuiCheckbox
                          id={dependent.id}
                          onChange={() => undefined}
                          checked={selections.has(column.id)}
                          css={css`
                            margin-left: 24px;
                            * {
                              color: rgb(52, 55, 65);
                            }
                          `}
                          disabled
                          label={<span>{dependent.name}</span>}
                        />
                      </>
                    );
                  })}
                  {column.dependents.length > 0 && (
                    <EuiText
                      size="xs"
                      color="subdued"
                      style={{ marginLeft: 48 }}
                    >
                      <p>
                        These columns will be removed if the parent column is
                        removed.
                      </p>
                    </EuiText>
                  )}
                </Fragment>
              );
            })}
          </>
        </EuiFormRow>
        {undeletableFiles.length > 0 && (
          <>
            <EuiSpacer />
            <EuiCallOut
              title="The following files are currently uploading/processing and will not be deleted from the dataset:"
              color="danger"
              iconType="warning"
            >
              <EuiListGroup gutterSize="none">
                {undeletableFiles.map((file) => (
                  <EuiListGroupItem
                    key={file.id}
                    label={<FileBadge drsFile={file} />}
                  />
                ))}
              </EuiListGroup>
            </EuiCallOut>
          </>
        )}
      </EuiModalBody>
      <EuiModalFooter>
        <EuiButtonEmpty onClick={onClose}>Cancel</EuiButtonEmpty>
        <EuiButton
          onClick={() => void handleSubmit()}
          disabled={selections.size === 0}
          color={isDestructive ? "danger" : "warning"}
          isLoading={isSubmitting}
        >
          {isDestructive ? "Delete" : "Remove"}
        </EuiButton>
      </EuiModalFooter>
    </EuiModal>
  );
};
