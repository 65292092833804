import axios from "axios";
import { getEnvVar } from "ideas.env";
import { getRequestHeaders } from "utils/getRequestHeaders";
import { useTenantContext } from "providers/TenantProvider/TenantProvider";
import { captureException } from "@sentry/react";
import { useCallback } from "react";
import { useHasActiveContract } from "stores/contract/useHasActiveContract";

export type OpenContract = {
  id: number;
  active: boolean;
  start_date: string;
  end_date: string;
};

export const useFetchAndSetHasActiveContract = () => {
  const {
    activeContract,
    upcomingContract,
    setActiveContract,
    setUpcomingContract,
  } = useHasActiveContract();

  const currentTenant = useTenantContext((s) => s.currentTenant);

  /**
   * Check if the tenant has an active or upcoming contract
   */
  const fetchAndSetContracts = useCallback(async () => {
    try {
      // Fetch open contracts
      const headers = await getRequestHeaders();
      const { data } = await axios.get<{ contracts: OpenContract[] }>(
        `${getEnvVar("URL_TENANT_OPEN_CONTRACTS")}${currentTenant.id}/`,
        { headers },
      );

      // Set active contract to first active contract
      const activeContract = data.contracts.find((c) => c.active);
      setActiveContract(activeContract);

      // Set upcoming contract to first inactive contract
      const upcomingContract = data.contracts.find((c) => !c.active);
      setUpcomingContract(upcomingContract);
    } catch (error) {
      captureException(error);
    }
  }, [currentTenant.id, setActiveContract, setUpcomingContract]);

  // Reset active and upcoming contracts
  const resetContracts = useCallback(() => {
    setActiveContract(undefined);
    setUpcomingContract(undefined);
  }, [setActiveContract, setUpcomingContract]);

  return {
    activeContract,
    upcomingContract,
    fetchAndSetContracts,
    resetContracts,
  };
};
