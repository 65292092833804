import { EuiFlexGroup, EuiFlexItem, EuiIcon } from "@inscopix/ideas-eui";
import { Tooltip, TooltipProps } from "components/Tooltip/Tooltip";
import { omit } from "lodash";
import { ReactElement, ReactNode } from "react";
import { Except } from "type-fest";

/** Props for the wrapped component (i.e. the component consumed by the HOC) */
type WrappedProps = {
  // EUI is inconsistent about using `disabled` and `isDisabled` props
  // It takes a pessimistic approach of if any of these two props are true, the component is disabled
  disabled?: boolean;
  title?: string;
};

/** Props for the enhanced component (i.e. the component returned by the HOC) */
type EnhancedProps<P> = P & {
  // Optional tooltip to display when the user has sufficient permissions
  defaultTooltip?: ReactNode;
  // Suppress the default permission tooltip
  suppressPermissionTooltip?: boolean;
  // Optional props passed to `defaultTooltip`
  tooltipProps?: Except<TooltipProps, "children" | "content" | "delayShow">;
  isPermitted?: boolean;
  isDisabled?: boolean;
};

const PermissionDeniedTooltip = () => (
  <EuiFlexGroup alignItems="center" direction="column" gutterSize="none">
    <EuiFlexItem>
      <EuiIcon type="lock" color="#ffd700" />
    </EuiFlexItem>
    <EuiFlexItem>
      <span>You do not have permission to perform this action.</span>
    </EuiFlexItem>
    <EuiFlexItem>
      <span>Contact the project administrator to gain access.</span>
    </EuiFlexItem>
  </EuiFlexGroup>
);

/**
 * HOC that wraps a project component in a tooltip and disables it if the user
 * does not have sufficient permissions within the current project.
 * @param WrappedComponent The project component to enhance.
 * @returns The enhanced component.
 */
export const withUserPermissionBase = <P extends WrappedProps>(
  /* Wrapped components must be function components to allow for generic type
     arguments to be propagated. TypeScript does not support this for classes. */
  WrappedComponent: (props: P) => ReactElement | null,
) => {
  const EnhancedComponent = (props: EnhancedProps<P>) => {
    return (
      <Tooltip
        {...props.tooltipProps}
        content={
          props.isPermitted || props.suppressPermissionTooltip ? (
            props.defaultTooltip
          ) : (
            <PermissionDeniedTooltip />
          )
        }
        delayShow={props.isPermitted ? undefined : 750} // delay the lock tooltip
      >
        <WrappedComponent
          // TODO: Investigate better way of ensuring not overlap between wrapped and enhanced props
          {...(omit(props, "defaultTooltip") as P)}
          disabled={!props.isPermitted || props.disabled || props.isDisabled}
          // Prevent a title from displaying at the same time as the tooltip
          title={undefined}
        />
      </Tooltip>
    );
  };
  return EnhancedComponent;
};
