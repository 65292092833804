import {
  EuiButtonEmpty,
  EuiButtonIcon,
  EuiFlexGroup,
  EuiFlexItem,
  EuiPopover,
} from "@inscopix/ideas-eui";
import { ModalChangeUserRole } from "components/ModalChangeUserRole/ModalChangeUserRole";
import { ModalRemoveTenantMember } from "components/ModalRemoveTenantMember/ModalRemoveTenantMember";
import { TenantStoreState } from "providers/TenantProvider/TenantProvider.types";
import { useUserContext } from "providers/UserProvider/UserProvider";
import { ReactNode, useState } from "react";

interface PanelTenantAccessUserActionsProps {
  user: TenantStoreState["tenantUsers"][number];
}

export const PanelTenantAccessUserActions = ({
  user,
}: PanelTenantAccessUserActionsProps) => {
  const currentUser = useUserContext((s) => s.currentUser);
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);
  const togglePopover = () => setIsPopoverOpen((isOpen) => !isOpen);
  const closePopover = () => setIsPopoverOpen((isOpen) => !isOpen);
  const [modal, setModal] = useState<ReactNode>(null);
  const closeModal = () => setModal(null);

  const handleRemove = () => {
    setModal(<ModalRemoveTenantMember user={user} onClose={closeModal} />);
  };

  const handleSetRoleMember = () => {
    closePopover();
    setModal(
      <ModalChangeUserRole
        user={user}
        newRoleKey="member"
        onClose={closeModal}
      />,
    );
  };

  const handleSetRoleAdmin = () => {
    closePopover();
    setModal(
      <ModalChangeUserRole
        user={user}
        newRoleKey="admin"
        onClose={closeModal}
      />,
    );
  };

  return (
    <>
      <EuiPopover
        button={
          <EuiButtonIcon
            aria-label="Modify user"
            color="text"
            iconType="boxesVertical"
            onClick={togglePopover}
            disabled={user.id === currentUser.id}
          />
        }
        isOpen={isPopoverOpen}
        closePopover={closePopover}
        panelPaddingSize="none"
        anchorPosition="rightCenter"
        repositionOnScroll
      >
        <EuiFlexGroup
          alignItems="stretch"
          direction="column"
          gutterSize="none"
          justifyContent="center"
        >
          {user.role.key === "member" && (
            <EuiFlexItem>
              <EuiButtonEmpty color="text" onClick={handleSetRoleAdmin}>
                Change role to admin
              </EuiButtonEmpty>
            </EuiFlexItem>
          )}
          {user.role.key === "admin" && (
            <EuiFlexItem>
              <EuiButtonEmpty
                color="text"
                onClick={() => void handleSetRoleMember()}
              >
                Change role to member
              </EuiButtonEmpty>
            </EuiFlexItem>
          )}
          <EuiFlexItem>
            <EuiButtonEmpty
              color="danger"
              onClick={() => {
                handleRemove();
                closePopover();
              }}
            >
              Remove
            </EuiButtonEmpty>
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiPopover>
      {modal}
    </>
  );
};
