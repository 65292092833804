import { useCallback } from "react";
// eslint-disable-next-line no-restricted-imports
import { useSearchParams } from "react-router-dom-v5-compat";

const PARAMS = {
  PAGE: "page",
  PROJECT_VIEW: "projectView",
  SEARCH: "search",
  OPEN_MODAL: "openModal",
  SORT_FIELD: "sortField",
  SORT_ORDER: "sortOrder",
} as const;

type Value = {
  OPEN_MODAL: "shareProject";
  SEARCH: string;
  PAGE: string;
  SORT_FIELD: string;
  SORT_ORDER: "asc" | "desc";
  PROJECT_VIEW: "cards" | "grid";
};

type Param = keyof typeof PARAMS;

export const useIdeasSearchParams = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  /**
   * Set a search param in the route
   */

  const setParam = useCallback(
    <T extends Param>(paramKey: T, value: Value[T]) => {
      if (paramKey !== "PAGE") {
        /**
         * Delete the page param if we set a different search parameter
         * If a user is on page 2, then searches for projects with "S",
         * we want that new search to start on page 1 look a google search
         */
        setSearchParams(
          (params) => {
            params.delete(PARAMS.PAGE);
            return params;
          },
          { replace: true },
        );
      }

      setSearchParams(
        (params) => {
          if (value === "") {
            /**
             * Clear a param from the route if the value is empty
             */
            params.delete(PARAMS[paramKey]);
          } else {
            params.set(PARAMS[paramKey], value);
          }
          return params;
        },
        { replace: true },
      );
    },
    [setSearchParams],
  );
  /**
   * Retrieve a search param from the route
   */
  const getParam = useCallback(
    <T extends Param>(paramKey: T): Value[T] | null => {
      return searchParams.get(PARAMS[paramKey]) as Value[T];
    },
    [searchParams],
  );

  const deleteParam = useCallback(
    (paramKey: Param) =>
      setSearchParams(
        (params) => {
          params.delete(PARAMS[paramKey]);
          return params;
        },
        { replace: true },
      ),
    [setSearchParams],
  );

  const appendParamToPath = useCallback(
    <T extends Param>(paramKey: T, value: Value[T], path: string) => {
      const url = new URL(path, window.location.origin);
      url.searchParams.append(PARAMS[paramKey], value);
      return url.pathname + url.search;
    },
    [],
  );

  return { setParam, getParam, deleteParam, appendParamToPath };
};
