import {
  DatasetRecordingsTableColumn,
  File as DrsFile,
  FileRecordingGroup,
} from "graphql/_Types";
import { useTenantContext } from "providers/TenantProvider/TenantProvider";
import { useDatasetDataContext } from "pages/project/dataset/DatasetDataProvider";
import { useCallback } from "react";
import { uuid } from "utils/uuid";
import { cloneDeep } from "lodash";
import { useRecordingGroupDrsFileCreateDjango } from "hooks/useCreateRecordingGroupDrsFileDjango";
import { updateCacheFragment, writeCacheFragment } from "utils/cache-fragments";
import { useUserContext } from "providers/UserProvider/UserProvider";
import { useRecordingIdentifierContext } from "providers/RecordingIdentifierProvider/RecordingIdentifierProvider";
import { useProjectPermissionStore } from "pages/project/ProjectPermissionProvider";

type AssignFileParams = {
  columnId: DatasetRecordingsTableColumn["id"];
  drsFileId: DrsFile["id"];
  recordingId: FileRecordingGroup["id"];
};

export const useActionAssignFile = () => {
  const currentUser = useUserContext((s) => s.currentUser);
  const currentTenant = useTenantContext((s) => s.currentTenant);
  const { datasetMode, dataset, project } = useDatasetDataContext();
  const { recordingGroupDrsFileCreateDjango } =
    useRecordingGroupDrsFileCreateDjango();
  const hasEditPermission = useProjectPermissionStore(
    (s) => s.permissions["edit"],
  );
  const refetchAllIdentifiers = useRecordingIdentifierContext(
    (s) => s.refetchAllIdentifiers,
  );

  const getActionDef = useCallback(
    (params: AssignFileParams) => {
      const assignment = {
        id: uuid(),
        fileId: params.drsFileId,
        fileRecordingGroupId: params.recordingId,
        datasetRecordingsTableColumnId: params.columnId,
        dateCreated: new Date().toISOString(),
        dateAssignmentEnded: null,
        tenantId: currentTenant.id,
        userId: currentUser.id,
      };

      return {
        onEnqueue: () => {
          writeCacheFragment({
            __typename: "RecordingGroupFile",
            id: assignment.id,
            data: {
              __typename: "RecordingGroupFile",
              ...assignment,
              dateAssignmentEnded: null,
              tenant: {
                __typename: "Tenant",
                id: assignment.tenantId,
              },
              user: {
                __typename: "ApplicationUser",
                id: assignment.userId,
              },
              recording: {
                __typename: "FileRecordingGroup",
                id: assignment.fileRecordingGroupId,
              },
              file: {
                __typename: "File",
                id: assignment.fileId,
              },
              column: {
                __typename: "DatasetRecordingsTableColumn",
                id: assignment.datasetRecordingsTableColumnId,
              },
              datasetRecordingsTableColumnId:
                assignment.datasetRecordingsTableColumnId,
              project: {
                __typename: "Project",
                id: project.id,
              },
              projectId: project.id,
            },
          });
          // Update the cached active assignment for the file object
          updateCacheFragment({
            __typename: "File",
            id: assignment.fileId,
            update: (data) => {
              const newData = cloneDeep(data);
              newData.assignment = {
                __typename: "RecordingGroupFile",
                id: assignment.id,
              };
              newData.datasetId = dataset.id;
              newData.dataset = dataset;

              return newData;
            },
          });

          updateCacheFragment({
            __typename: "Project",
            id: project.id,
            update: (data) => {
              const newData = { ...data };
              newData.dateLastActive = assignment.dateCreated;
              return newData;
            },
          });
        },

        onDequeue: async () => {
          const {
            id,
            datasetRecordingsTableColumnId,
            fileId,
            fileRecordingGroupId,
          } = assignment;
          const result = await recordingGroupDrsFileCreateDjango({
            id,
            dataset_recordings_table_column: datasetRecordingsTableColumnId,
            file: fileId,
            file_recording_group: fileRecordingGroupId,
          });
          // Refetch recording identifiers
          // TODO: This will be removed when identifiers are calculated on the backend
          refetchAllIdentifiers();

          return result;
        },
      };
    },
    [
      currentTenant.id,
      currentUser.id,
      dataset,
      project.id,
      recordingGroupDrsFileCreateDjango,
      refetchAllIdentifiers,
    ],
  );

  const isDisabled = datasetMode.type !== "current" || !hasEditPermission;

  return {
    getActionDef,
    isDisabled,
  };
};
