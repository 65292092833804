import { useDataTableContext } from "./DataTableProvider";
import { memo, useEffect } from "react";
import { isTerminalTaskStatus } from "utils/isTerminalTaskStatus";

/**
 * Component that refetches analysis table rows that are currently executing.
 *
 * I chose to use a null component over a hook to minimize rerenders. Using a
 * hook would require the component calling it to rerender every time new
 * analyses are executed.
 */
export const DataTableAnalysisPoller = memo(function DataTableAnalysisPoller() {
  const refetchRows = useDataTableContext((s) => s.refetchAnalysisTableRows);

  // Determine whether any analysis table rows are executing
  const isPendingRows = useDataTableContext((s) => {
    for (const table of s.tables) {
      for (const row of table.rows) {
        if (row.task !== null && !isTerminalTaskStatus(row.task.status)) {
          return true;
        }
      }
    }
    return false;
  });

  // Poll for updates to pending analysis table rows
  useEffect(() => {
    let timer: NodeJS.Timer;

    if (isPendingRows) {
      timer = setInterval(() => {
        void refetchRows();
      }, 5000);
    }

    return () => clearInterval(timer);
  }, [isPendingRows, refetchRows]);

  return null;
});
