import { EuiText } from "@inscopix/ideas-eui";
import { fileBadgeColors } from "components/FileBadge/FileBadge";
import { useProcessFileFailedTaskError } from "hooks/useProcessFileFailedTaskError";
import { useGetDrsFileProcessingStatus } from "hooks/useGetDrsFileProcessingStatus";
import { FileStatus } from "types/constants";
import { FileBrowserFile } from "./FileBrowser";

interface FileBrowserCellRendererFileTypeTooltipContentProps {
  type: string;
  file: Pick<FileBrowserFile, "id" | "status">;
}
export const FileBrowserCellRendererFileTypeTooltipContent = ({
  file,
  type,
}: FileBrowserCellRendererFileTypeTooltipContentProps) => {
  const { processingStatus } = useGetDrsFileProcessingStatus(file.id);
  const isUploading = file.status === FileStatus["CREATED"];

  const { loading, errorDescription } = useProcessFileFailedTaskError(file.id);

  const tooltipMessage = (() => {
    if (isUploading) {
      return "Uploading";
    }
    if (processingStatus === "pending") {
      return "Extracting metadata and generating preview...";
    }
    if (processingStatus === "error") {
      if (loading) {
        return "Retrieving error information";
      }
      return errorDescription;
    }
  })();

  return (
    <div>
      <EuiText size="s">{type}</EuiText>
      {tooltipMessage !== undefined && (
        <EuiText color={fileBadgeColors.processing} size="s">
          {tooltipMessage}
        </EuiText>
      )}
    </div>
  );
};
