import {
  EuiFlyout,
  EuiFlyoutHeader,
  EuiTitle,
  EuiFlyoutBody,
  EuiFlexGroup,
  EuiFlexItem,
  EuiFilePicker,
  EuiForm,
  EuiFormRow,
  EuiSpacer,
  EuiText,
  EuiTextArea,
  EuiButton,
  htmlIdGenerator,
  EuiRadio,
  EuiFieldText,
  EuiCallOut,
} from "@inscopix/ideas-eui";
import { getEnvVar } from "ideas.env";
import { useFlyoutContext } from "providers/FlyoutProvider/FlyoutProvider";
import { useUserContext } from "providers/UserProvider/UserProvider";
import { useCallback, useState } from "react";
import { getRequestHeaders } from "utils/getRequestHeaders";
import axios from "axios";
import { addUtilityToastFailure } from "utils/addUtilityToastFailure";
import { useLocation } from "react-router-dom-v5-compat";
import { captureException } from "@sentry/react";

const ISSUE_TYPES = {
  question: "Question/request for assistance",
  analysis: "Analysis issue/failed task",
  feedback: "Feedback/feature request",
  bug: "Bug report",
  other: "Other",
};

// restrict attachment size, only intended for a screenshot
const MAX_FILE_SIZE_MB = 5;
const MAX_FILE_SIZE_BYTES = MAX_FILE_SIZE_MB * 1024 * 1024;

type Fields = {
  issue_type: {
    value: keyof typeof ISSUE_TYPES | null;
    isValid: boolean;
  };
  task_ids: {
    value: string;
    isValid: boolean;
  };
  message: {
    value: string;
    isValid: boolean;
  };
  file: {
    value: File | null;
    isValid: boolean;
  };
};

const INITIAL_FIELDS: Fields = {
  issue_type: {
    value: null,
    isValid: true,
  },
  task_ids: {
    value: "",
    isValid: true,
  },
  message: {
    value: "",
    isValid: true,
  },
  file: {
    value: null,
    isValid: true,
  },
};

export const ContactSupport = () => {
  const { flyout, closeFlyout } = useFlyoutContext();
  const isCurrentFlyout = flyout === "contactSupport";

  const currentUser = useUserContext((s) => s.currentUser);
  const BASE_URL = window.location.origin;
  const location = useLocation();

  const [isLoading, setIsLoading] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [formId] = useState(htmlIdGenerator()());
  const [fields, setFields] = useState<Fields>(INITIAL_FIELDS);

  const handleFieldChange = useCallback(
    <T extends keyof Fields>(fieldName: T, field: Fields[T]) => {
      setFields((prevFields) => ({
        ...prevFields,
        [fieldName]: field,
      }));
    },
    [],
  );

  const handleSubmit = async () => {
    let isValid = true;
    if (fields.issue_type.value === null) {
      handleFieldChange("issue_type", {
        value: null,
        isValid: false,
      });
      isValid = false;
    }
    if (fields.message.value.trim().length === 0) {
      handleFieldChange("message", {
        value: fields.message.value,
        isValid: false,
      });
      isValid = false;
    }
    if (isValid) {
      setIsLoading(true);
      const headers = await getRequestHeaders();
      const url = getEnvVar("URL_CONTACT_SUPPORT");

      const formData = new FormData();
      formData.append("issue_type", fields.issue_type.value ?? "");
      formData.append("task_ids", fields.task_ids.value);
      formData.append("message", fields.message.value);
      formData.append("path", `${BASE_URL}${location.pathname}`);
      if (fields.file.value) {
        formData.append("file", fields.file.value);
      }

      try {
        const response = await axios.post(url, formData, {
          headers: {
            ...headers,
            "Content-Type": "multipart/form-data",
          },
        });

        if (response.status !== 200) {
          throw new Error("Network response was not ok");
        }
        setIsSuccess(true);
        setFields(INITIAL_FIELDS);
      } catch (error) {
        captureException(error);
        addUtilityToastFailure("Failed to send support request");
      } finally {
        setIsLoading(false);
      }
    }
  };

  const close = () => {
    closeFlyout();
    setIsSuccess(false);
  };

  return isCurrentFlyout ? (
    <EuiFlyout
      type={"push"}
      size="420px"
      onClose={() => close()}
      hideCloseButton={false}
      pushMinBreakpoint="xxl"
    >
      <EuiFlyoutHeader hasBorder>
        <EuiTitle size="s">
          <EuiFlexGroup alignItems="center">
            <EuiFlexItem>
              <EuiTitle size="xs">
                <h2>Contact Support</h2>
              </EuiTitle>
            </EuiFlexItem>
          </EuiFlexGroup>
        </EuiTitle>
      </EuiFlyoutHeader>

      <EuiFlyoutBody>
        {isSuccess ? (
          <EuiCallOut title="Message sent" color="success">
            <p>
              Your request has been sent to support - responses will be sent to
              the email address associated with your account.
            </p>
            <EuiButton
              onClick={close}
              color="text"
              size="s"
              fullWidth
              iconType={"cross"}
            >
              Close
            </EuiButton>
          </EuiCallOut>
        ) : (
          <>
            <EuiForm
              id={formId}
              component="form"
              role="form"
              onSubmit={(e) => {
                e.preventDefault();
                void handleSubmit();
              }}
            >
              <EuiFormRow
                label="From"
                helpText="Support will respond to the email address associated with your account shown above."
              >
                <EuiText>
                  {currentUser.firstName} {currentUser.lastName} (
                  {currentUser.email})
                </EuiText>
              </EuiFormRow>
              <EuiSpacer />
              <EuiFormRow
                label="Issue type"
                isInvalid={!fields.issue_type.isValid}
                error={
                  fields.issue_type.isValid
                    ? undefined
                    : "Please select an issue type"
                }
              >
                <>
                  {Object.keys(ISSUE_TYPES).map((key) => (
                    <EuiRadio
                      key={key}
                      id={key}
                      label={ISSUE_TYPES[key as keyof typeof ISSUE_TYPES]}
                      checked={fields.issue_type.value === key}
                      onChange={(e) => {
                        handleFieldChange("issue_type", {
                          value: e.target.id as keyof typeof ISSUE_TYPES,
                          isValid: true,
                        });
                        // clear task_ids if not analysis
                        if (e.target.id !== "analysis") {
                          handleFieldChange("task_ids", {
                            value: "",
                            isValid: true,
                          });
                        }
                      }}
                    />
                  ))}
                </>
              </EuiFormRow>
              <EuiSpacer />
              {fields.issue_type.value === "analysis" && (
                <>
                  <EuiFormRow
                    label="Task ID(s) (optional)"
                    helpText="Enter any task ID(s) associated with the issue"
                  >
                    <EuiFieldText
                      name="task_ids"
                      placeholder="Enter task ID(s) separated by commas"
                      value={fields.task_ids.value}
                      onChange={(e) =>
                        handleFieldChange("task_ids", {
                          value: e.target.value,
                          isValid: true,
                        })
                      }
                    />
                  </EuiFormRow>
                  <EuiSpacer />
                </>
              )}
              <EuiFormRow
                label="Message"
                isInvalid={!fields.message.isValid}
                error={
                  fields.message.isValid ? undefined : "Message is required"
                }
              >
                <EuiTextArea
                  name="message"
                  placeholder="Describe your question, feedback, or issue in detail"
                  value={fields.message.value}
                  onChange={(e) =>
                    handleFieldChange("message", {
                      value: e.target.value,
                      isValid: e.target.value.trim().length > 0,
                    })
                  }
                />
              </EuiFormRow>
              <EuiFormRow
                label="Screenshot (optional)"
                helpText={`Attach a screenshot or file (max ${MAX_FILE_SIZE_MB} MB) to help us understand the issue.`}
                isInvalid={!fields.file.isValid}
                error={
                  fields.file.isValid
                    ? undefined
                    : `File size exceeds ${MAX_FILE_SIZE_MB} MB`
                }
              >
                <EuiFilePicker
                  compressed
                  multiple={false}
                  onChange={(files) => {
                    const file = files && files.length > 0 ? files[0] : null;
                    handleFieldChange("file", {
                      value: file,
                      isValid: file ? file.size <= MAX_FILE_SIZE_BYTES : true,
                    });
                  }}
                />
              </EuiFormRow>
            </EuiForm>
            <EuiSpacer />
            <EuiButton
              type="submit"
              form={formId}
              isLoading={isLoading}
              fill
              disabled={Object.values(fields).some((field) => !field.isValid)}
            >
              Send
            </EuiButton>
          </>
        )}
      </EuiFlyoutBody>
    </EuiFlyout>
  ) : null;
};
