import {
  CellValue,
  Group,
  IdeasFile,
  RoiFrame,
} from "@inscopix/ideas-hyperformula";
import { ModalToolRoiFrameParamWrapper } from "components/ToolParamsGrid/ModalToolRoiFrameParam/ModalToolRoiFrameParamWrapper";
import { DataTableColumnDefinition } from "../store/DataTableProvider.types";
import { ShapeJson } from "types/ToolRoiFrameParamValue/ToolRoiFrameParamValue";
import { ModalToolRoiFrameParamBase } from "components/ToolParamsGrid/ModalToolRoiFrameParam/ModalToolRoiFrameParamBase";
import { useDataTableContext } from "../store/DataTableProvider";
import { EuiCallOut, EuiLink, EuiSpacer, EuiText } from "@inscopix/ideas-eui";
import { GridApi } from "ag-grid-community";
import { isNonNullish } from "utils/isNonNullish";
import assert from "assert";
import { captureException } from "@sentry/react";
import { sleep } from "utils/sleep";
import { CallOutError } from "components/CallOutError/CallOutError";

interface CellEditorRoiFrameProps {
  gridApi: GridApi;
  value: CellValue;
  rowId: string;
  columnName: string;
  columnDefinition: Extract<DataTableColumnDefinition, { kind: "roi_frame" }>;
  onChangeFormula: (newFormula: string) => void;
  stopEditing: (cancel?: boolean) => void;
}

/** Cell editor for cells in ROI frame columns */
export const CellEditorRoiFrame = ({
  gridApi,
  value,
  rowId,
  columnName,
  columnDefinition,
  onChangeFormula,
  stopEditing,
}: CellEditorRoiFrameProps) => {
  // Parse column containing source movie or image file
  const { fileColumn, sourceFileIds } = useDataTableContext((s) => {
    const table = s.tables.find(({ id }) => id === s.selectedTableId);
    const columnId = columnDefinition.file_column.id;
    const columnIdx = table?.columns.findIndex(({ id }) => id === columnId);
    const row = table?.rows.find(({ id }) => id === rowId);

    if (table === undefined || columnIdx === undefined || row === undefined) {
      return {};
    }

    const fileColumn = table.columns[columnIdx];
    const cell = row.cells[columnIdx];
    const sourceFileIds = (() => {
      if (cell.value instanceof IdeasFile) {
        return [cell.value.attrs.id];
      } else if (cell.value instanceof Group) {
        return cell.value.cellValues
          .filter((value) => value instanceof IdeasFile)
          .map((file) => (file as IdeasFile).attrs.id);
      } else {
        return [];
      }
    })();

    return { fileColumn, sourceFileIds };
  });

  const onAccept = (shapes: ShapeJson[]) => {
    const newParamValue = JSON.stringify(shapes).replace(/"/g, '\\"');
    onChangeFormula(`=ROI_FRAME("${newParamValue}")`);
    setTimeout(stopEditing, 100);
  };

  if (fileColumn === undefined) {
    return (
      <ModalToolRoiFrameParamWrapper
        paramName={columnName}
        onClose={() => stopEditing()}
      >
        <CallOutError />
      </ModalToolRoiFrameParamWrapper>
    );
  }

  return (
    <ModalToolRoiFrameParamBase
      fileIds={sourceFileIds}
      paramName={columnName}
      onSave={onAccept}
      initialShapes={value instanceof RoiFrame ? value.shapes : undefined}
      onClose={stopEditing}
      isReadOnly={false}
      sourceFile={{
        key: columnDefinition.file_column.id,
        data: columnDefinition.source_file_data,
      }}
      roiSettings={{
        overlap: columnDefinition.overlap,
        bounding_box: columnDefinition.bounding_box ?? undefined,
        groups: columnDefinition.groups ?? undefined,
      }}
      NoSourceFiles={() => (
        <ModalToolRoiFrameParamWrapper
          paramName={columnName}
          onClose={() => stopEditing()}
          maxWidth="600px"
          readOnly={false}
        >
          <EuiCallOut>
            <EuiText>
              <p>
                To view/edit ROIs you must first select an input file of the
                appropriate type in the{" "}
                <EuiLink
                  onClick={() =>
                    void (async () => {
                      try {
                        stopEditing();
                        gridApi?.ensureColumnVisible(fileColumn.id, "start");
                        // Wait a tick for the scroll to complete
                        await sleep(0);
                        const rowNode = gridApi?.getRowNode(rowId);
                        assert(isNonNullish(rowNode));
                        gridApi?.flashCells({
                          rowNodes: [rowNode],
                          columns: [fileColumn.id],
                        });
                      } catch (err) {
                        // TODO: investigate the cause of this error
                        // https://github.com/ag-grid/ag-grid/issues/5085
                        captureException(err);
                      }
                    })()
                  }
                >
                  {fileColumn.name}
                </EuiLink>{" "}
                column.
              </p>
            </EuiText>
          </EuiCallOut>
          <EuiSpacer />
        </ModalToolRoiFrameParamWrapper>
      )}
    />
  );
};
