import { getAdminProjectDjango } from "django/libraryProjectDjango";
import {
  ModalProjectSharing,
  ModalProjectSharingProps,
} from "./ModalProjectSharing";
import { permissions } from "./permissions";

export type ModalProjectSharingAdminManageProps = Omit<
  ModalProjectSharingProps,
  | "getProject"
  | "updateInternalDefaultAccessLevel"
  | "sendExternalProjectAccessInvitation"
  | "resendExternalProjectAccessInvitation"
  | "updateExternalProjectSharingInvitation"
  | "revokeExternalProjectAccessInvitation"
  | "getInternalUsersWithProjectAccess"
  | "createInternalUserAccess"
  | "revokeInternalUserAccess"
  | "getProjectSharingExternalInvitations"
  | "updateInternalUserAccessLevel"
>;

export const ModalProjectSharingAdminManage = (
  props: ModalProjectSharingAdminManageProps,
) => {
  return (
    <ModalProjectSharing
      {...props}
      getProject={getAdminProjectDjango}
      updateInternalDefaultAccessLevel={
        permissions.adminFunctions.internalAccess
          .updateInternalDefaultAccessLevel
      }
      sendExternalProjectAccessInvitation={
        permissions.adminFunctions.externalAccess
          .sendExternalProjectAccessInvitation
      }
      resendExternalProjectAccessInvitation={
        permissions.adminFunctions.externalAccess
          .resendExternalProjectAccessInvitation
      }
      updateExternalProjectSharingInvitation={
        permissions.adminFunctions.externalAccess
          .updateExternalProjectSharingInvitation
      }
      revokeExternalProjectAccessInvitation={
        permissions.adminFunctions.externalAccess
          .revokeExternalProjectAccessInvitation
      }
      getInternalUsersWithProjectAccess={
        permissions.adminFunctions.internalAccess
          .getInternalUsersWithProjectAccess
      }
      createInternalUserAccess={
        permissions.adminFunctions.internalAccess.createInternalUserAccess
      }
      revokeInternalUserAccess={
        permissions.adminFunctions.internalAccess.revokeInternalUserAccess
      }
      getProjectSharingExternalInvitations={
        permissions.adminFunctions.externalAccess
          .getProjectSharingExternalInvitations
      }
      updateInternalUserAccessLevel={
        permissions.adminFunctions.internalAccess.updateInternalUserAccessLevel
      }
    />
  );
};
