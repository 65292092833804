import { flatMap, keyBy, uniq } from "lodash";

const RESTRICTED = {
  id: 1,
  key: "RESTRICTED",
  name: "Restricted",
  permissions: [],
  icon: "lock",
} as const;

const VIEWER = {
  id: 2,
  key: "VIEWER",
  name: "Viewer",
  permissions: ["view"],
  icon: "eye",
} as const;

// The distinction between a viewer and a copier is one we only make internally.
// To users, copiers appear as viewers, hence the matching name and icon.
const COPIER = {
  id: 5,
  key: "COPIER",
  name: VIEWER.name,
  permissions: [...VIEWER.permissions, "download"],
  icon: VIEWER.icon,
} as const;

const EDITOR = {
  id: 3,
  key: "EDITOR",
  name: "Editor",
  permissions: [...COPIER.permissions, "edit", "execute", "upload"],
  icon: "pencil",
} as const;

const ADMIN = {
  id: 4,
  key: "ADMIN",
  name: "Admin",
  permissions: [...EDITOR.permissions, "grant_access"],
  icon: "console",
} as const;

export const USER_ACCESS_LEVELS = {
  RESTRICTED,
  VIEWER,
  COPIER,
  EDITOR,
  ADMIN,
} as const;

/***********
  BASE TYPE
 ***********/

export type UserAccessLevel =
  (typeof USER_ACCESS_LEVELS)[keyof typeof USER_ACCESS_LEVELS];

export type FrontendRoleKey = UserAccessLevel["key"] | "OWNER";

/*******************
  AGGREGATED EXPORTS
 *******************/
export const USER_ACCESS_LEVELS_BY_ID = keyBy(
  USER_ACCESS_LEVELS,
  (UserAccessLevel) => UserAccessLevel.id,
) as {
  [id in UserAccessLevel["id"]]: UserAccessLevel;
} & {
  [unknown: number]: UserAccessLevel | undefined;
};

export const USER_ACCESS_LEVELS_BY_KEY = keyBy(
  USER_ACCESS_LEVELS,
  (UserAccessLevel) => UserAccessLevel.key,
) as {
  [key in UserAccessLevel["key"]]: UserAccessLevel;
} & {
  [unknown: string]: UserAccessLevel | undefined;
} & {
  [unknown: number | symbol]: undefined;
};

export type UserPermission = UserAccessLevel["permissions"][number];

export const USER_PERMISSIONS: UserPermission[] = uniq(
  flatMap(USER_ACCESS_LEVELS, (accessLevel) => accessLevel.permissions),
);
