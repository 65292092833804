import {
  generatePath,
  // eslint-disable-next-line no-restricted-imports
  useHistory,
  matchPath,
  useLocation,
} from "react-router-dom";
import {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useState,
} from "react";
import {
  AnalysisTableGroup,
  Dataset,
  DatasetVersion,
  Project,
  Tenant,
} from "graphql/_Types";
import { useIdeasSearchParams } from "../../hooks/useIdeasSearchParams";
import { create, StoreApi, UseBoundStore, useStore } from "zustand";
import assert from "assert";
import { ContextOutOfBoundsError } from "providers/ContextOutOfBoundsError";
import { ExtractRouteParams } from "react-router";

type StaticRoute = {
  path: string;
  navigateTo: () => void;
};

type DynamicRoute<
  Path extends string,
  Params extends ExtractRouteParams<Path>,
  Options = unknown,
> = {
  parameterizedPath: Path;
  dynamicPath: (params: Params, options?: Options) => StaticRoute;
};

interface RouteMapPrivateStoreState {
  history: ReturnType<typeof useHistory>;
  routeMap: {
    PROJECT: DynamicRoute<
      "/:tenantKey/project/:projectKey",
      { tenantKey: Tenant["key"]; projectKey: Project["key"] },
      { openShareModal?: boolean }
    >;
    DATASET: DynamicRoute<
      "/:tenantKey/project/:projectKey/dataset/:datasetId",
      {
        datasetId: Dataset["id"];
        projectKey: Project["key"];
        tenantKey: Tenant["key"];
      }
    >;
    CHANGELOG: StaticRoute;
    DASHBOARD: StaticRoute;
    HELP: StaticRoute;
    ROOT: StaticRoute;
    SETTINGS: StaticRoute;
    ORGANIZATION: DynamicRoute<"/:tenantKey", { tenantKey: Tenant["key"] }>;
    ORGANIZATION_USAGE: DynamicRoute<
      "/:tenantKey/usage",
      { tenantKey: Tenant["key"] }
    >;
    ORGANIZATION_PEOPLE: DynamicRoute<
      "/:tenantKey/people",
      { tenantKey: Tenant["key"] }
    >;
    PROJECT_ANALYSIS_TABLE_GROUP: DynamicRoute<
      "/:tenantKey/project/:projectKey/analysis/:analysisTableGroupId",
      {
        tenantKey: Tenant["key"];
        projectKey: Project["key"];
        analysisTableGroupId: AnalysisTableGroup["id"];
      }
    >;
    DATASET_HISTORY: DynamicRoute<
      "/:tenantKey/project/:projectKey/dataset/:datasetId/history/:cutoffTime",
      {
        tenantKey: Tenant["key"];
        projectKey: Project["key"];
        cutoffTime: number;
        datasetId: Dataset["id"];
      }
    >;
    DATASET_VERSION: DynamicRoute<
      "/:tenantKey/project/:projectKey/dataset/:datasetId/version/:versionId",
      {
        tenantKey: Tenant["key"];
        projectKey: Project["key"];
        datasetId: Dataset["id"];
        versionId: DatasetVersion["id"];
      }
    >;
    PROJECT_TASKS: DynamicRoute<
      "/:tenantKey/project/:projectKey/tasks",
      { projectKey: Project["key"]; tenantKey: Tenant["key"] }
    >;
  };
  isRouteMatch: (path: string) => boolean;
}

export type RouteMapPublicStoreState = Omit<
  RouteMapPrivateStoreState,
  "history" | "navigateTo" | "appendParamToPath"
>;

const RouteMapContext = createContext<
  UseBoundStore<StoreApi<RouteMapPublicStoreState>> | undefined
>(undefined);

export const RouteMapProvider = ({ children }: { children: ReactNode }) => {
  const { appendParamToPath } = useIdeasSearchParams();
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const location = useLocation();

  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const history = useHistory();
  const [store] = useState(() =>
    create<RouteMapPrivateStoreState>((set, get) => {
      const navigateTo = (path: string) => {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
        get().history.push(path);
      };

      const generateStaticRoute = (path: string) => {
        return {
          path,
          navigateTo: () => navigateTo(path),
        };
      };

      const generateDynamicRoute = <T extends string>(parameterizedPath: T) => {
        return {
          parameterizedPath,
          dynamicPath: (params: ExtractRouteParams<T>) => {
            const path = generatePath(parameterizedPath, params);
            return generateStaticRoute(path);
          },
        };
      };

      return {
        isRouteMatch: (path) =>
          // eslint-disable-next-line @typescript-eslint/no-unsafe-argument, @typescript-eslint/no-unsafe-member-access
          matchPath(location.pathname, { path })?.isExact ?? false,
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        history,
        routeMap: {
          CHANGELOG: generateStaticRoute("/changelog"),
          DASHBOARD: generateStaticRoute("/dashboard"),
          HELP: generateStaticRoute("/help"),
          ROOT: generateStaticRoute("/"),
          SETTINGS: generateStaticRoute("/settings"),
          ORGANIZATION: generateDynamicRoute("/:tenantKey"),
          DATASET: generateDynamicRoute(
            "/:tenantKey/project/:projectKey/dataset/:datasetId",
          ),
          ORGANIZATION_USAGE: generateDynamicRoute("/:tenantKey/usage"),
          ORGANIZATION_PEOPLE: generateDynamicRoute("/:tenantKey/people"),
          PROJECT_ANALYSIS_TABLE_GROUP: generateDynamicRoute(
            "/:tenantKey/project/:projectKey/analysis/:analysisTableGroupId",
          ),
          DATASET_HISTORY: generateDynamicRoute(
            "/:tenantKey/project/:projectKey/dataset/:datasetId/history/:cutoffTime",
          ),
          DATASET_VERSION: generateDynamicRoute(
            "/:tenantKey/project/:projectKey/dataset/:datasetId/version/:versionId",
          ),
          PROJECT_TASKS: generateDynamicRoute(
            "/:tenantKey/project/:projectKey/tasks",
          ),
          PROJECT: {
            parameterizedPath: "/:tenantKey/project/:projectKey",
            dynamicPath: (params, options) => {
              const { parameterizedPath } = get().routeMap.PROJECT;
              const path = generatePath(parameterizedPath, params);
              return {
                path,
                navigateTo: () => {
                  const navigatePath = options?.openShareModal
                    ? appendParamToPath("OPEN_MODAL", "shareProject", path)
                    : path;
                  navigateTo(navigatePath);
                },
              };
            },
          },
        },
      };
    }),
  );

  /**
   * isRouteMatch should be reactive so update store when history changes
   * Components will only subscribe to their specific route match when you call this in a selector
   */
  useEffect(() => {
    store.setState({
      ...store.getState(),
      isRouteMatch: (path) =>
        // eslint-disable-next-line @typescript-eslint/no-unsafe-argument, @typescript-eslint/no-unsafe-member-access
        matchPath(location.pathname, { path })?.isExact ?? false,
    });
  }, [store, location]);

  /**
   * Keep history in store current
   */
  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    store.setState({ ...store.getState(), history });
  }, [history, store]);

  return (
    <RouteMapContext.Provider value={store}>
      {children}
    </RouteMapContext.Provider>
  );
};

export const useRouteMapContext = <S,>(
  selector: (state: RouteMapPublicStoreState) => S,
  comparator?: (a: S, b: S) => boolean,
): S => {
  const store = useContext(RouteMapContext);
  assert(store !== undefined, new ContextOutOfBoundsError("RouteMapContext"));
  return useStore(store, selector, comparator);
};
