import { gql, useFragment } from "@apollo/client";
import { isNullish } from "@apollo/client/cache/inmemory/helpers";
import {
  File as DrsFile,
  ProcessingStatusFragment,
  ProcessingStatusFragmentDoc,
  GetProcessingStatusQuery,
  GetProcessingStatusDocument,
  GetProcessingStatusQueryVariables,
} from "graphql/_Types";
import { throttle } from "lodash";
import { client } from "providers/ApolloProvider/ApolloProvider";
import { ProcessingStatus } from "types/constants";

gql`
  query GetProcessingStatus($drsFileIds: [UUID!]!) {
    files: allFiles(filter: { id: { in: $drsFileIds } }) {
      nodes {
        id
        processingStatus
      }
    }
  }
`;

export const PROCESSING_STATUS_FRAGMENT = gql`
  fragment ProcessingStatus on File {
    id
    processingStatus
  }
`;

const fileIds = new Set<DrsFile["id"]>();

const terminalStates = [
  ProcessingStatus["SKIPPED"],
  ProcessingStatus["COMPLETE"],
  ProcessingStatus["FAILED"],
  ProcessingStatus["PENDING"],
];

const getProcessingStatus = throttle(
  () => {
    const fileIdsToQuery = Array.from(fileIds);
    fileIds.clear();

    const repoll = (fileIds: DrsFile["id"][]) =>
      fileIds.forEach((id) => pollProcessingStatus(id));

    client
      .query<GetProcessingStatusQuery, GetProcessingStatusQueryVariables>({
        query: GetProcessingStatusDocument,
        variables: {
          drsFileIds: fileIdsToQuery,
        },
        fetchPolicy: "network-only",
      })
      .then(({ data }) => {
        const files = data.files?.nodes;
        if (isNullish(files)) {
          return repoll(fileIdsToQuery);
        }

        const idsToRepoll = files
          .filter((file) => !terminalStates.includes(file.processingStatus))
          .map(({ id }) => id);
        if (idsToRepoll.length > 0) {
          repoll(idsToRepoll);
        }
      })
      .catch(() => {
        repoll(fileIdsToQuery);
      });
  },
  5000,
  { trailing: true, leading: false },
);

const pollProcessingStatus = (fileId: DrsFile["id"]) => {
  if (fileIds.has(fileId)) {
    // we're already polling this file
    return;
  }

  fileIds.add(fileId);
  void getProcessingStatus();
};

export const useGetDrsFileProcessingStatus = (fileId: DrsFile["id"]) => {
  //   const cacheResult = readCacheFragment({ __typename: "File", id: fileId });

  const { data: cacheResult } = useFragment<ProcessingStatusFragment>({
    fragment: ProcessingStatusFragmentDoc,
    fragmentName: "ProcessingStatus",
    from: {
      __typename: "File",
      id: fileId,
    },
  });

  const status: ProcessingStatus | undefined = cacheResult.processingStatus;

  switch (status) {
    case ProcessingStatus["COMPLETE"]:
    case ProcessingStatus["SKIPPED"]:
      return { processingStatus: "complete" as const };

    case ProcessingStatus["UNPROCESSED"]:
    case ProcessingStatus["PENDING"]:
    case ProcessingStatus["PROCESSING"]:
    case undefined:
      pollProcessingStatus(fileId);
      return { processingStatus: "pending" as const };

    case ProcessingStatus["FAILED"]:
      return { processingStatus: "error" as const };
  }
};
