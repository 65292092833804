/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { CellValue } from "@inscopix/ideas-hyperformula";
import { memo } from "react";
import { CellValueRendererBase } from "../cell-value-renderers/CellValueRendererBase";
import { Tooltip } from "components/Tooltip/Tooltip";
import { getValidationErrors } from "./CellRendererBase.helpers";
import { DataTableColumnDefinition } from "../store/DataTableProvider.types";
import { EuiIcon } from "@inscopix/ideas-eui";
import { CellRendererFileEmpty } from "./CellRendererFileEmpty";

const styles = {
  root: css`
    align-items: center;
    justify-content: space-between;
    gap: 10px;
    display: flex;
    height: 100%;
    width: 100%;
    padding: 0px 11px;
    background-color: inherit;
    // Allow flex items to shrink
    & > * {
      min-width: 0;
    }
  `,
  error: css`
    background-color: #fff9e8;
  `,
  warningIcon: css`
    cursor: pointer;
  `,
};

interface CellRendererBaseProps {
  eParentOfValue: HTMLElement;
  tableId: string;
  tableKind: "data" | "analysis";
  columnId: string;
  columnDefinition: DataTableColumnDefinition;
  isColumnRequired: boolean;
  rowId: string;
  formula: string;
  value: CellValue;
}

/**
 * Cell renderer that renders any cell value
 */
export const CellRendererBase = ({
  eParentOfValue,
  tableId,
  tableKind,
  columnId,
  columnDefinition,
  isColumnRequired,
  rowId,
  formula,
  value,
}: CellRendererBaseProps) => {
  const validationErrors = getValidationErrors({
    columnDefinition,
    isColumnRequired,
    value,
  });

  // If there are multiple validation errors, render them in an unordered list
  const tooltipContent = (() => {
    if (validationErrors.length === 0) {
      return null;
    } else if (validationErrors.length === 1) {
      return validationErrors[0];
    } else {
      return (
        <ul>
          {validationErrors.map((error) => (
            <li key={error}>• {error}</li>
          ))}
        </ul>
      );
    }
  })();

  // Render empty cells in file columns
  if (
    columnDefinition.kind === "file" &&
    tableKind === "data" &&
    formula.trim() === ""
  ) {
    return (
      <CellRendererFileEmpty
        eParentOfValue={eParentOfValue}
        tableId={tableId}
        columnId={columnId}
        rowId={rowId}
      />
    );
  }

  return (
    <span
      css={[
        styles.root,
        ...(validationErrors.length > 0 ? [styles.error] : []),
      ]}
    >
      <CellValueRendererBase value={value} />
      {validationErrors.length > 0 && (
        <Tooltip content={tooltipContent}>
          <EuiIcon css={styles.warningIcon} color="warning" type="warning" />
        </Tooltip>
      )}
    </span>
  );
};

export const CellRendererBaseMemo = memo(CellRendererBase);
