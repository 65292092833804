import {
  EuiButtonGroup,
  EuiFlexGroup,
  EuiFlexItem,
  EuiSkeletonText,
  EuiSpacer,
  EuiTitle,
} from "@inscopix/ideas-eui";
import { ButtonCreateProject } from "../ButtonCreateProject/ButtonCreateProject";
import { Tenant } from "graphql/_Types";

import { useMemo, useState } from "react";
import { CallOutError } from "components/CallOutError/CallOutError";
import { useGetProjects } from "hooks/useGetProjects";
import { ProjectCards } from "components/ProjectsCards/ProjectCards";
import { useIdeasSearchParams } from "hooks/useIdeasSearchParams";

interface PanelProjectsProps {
  panelTitle?: string;
  tenantId?: Tenant["id"];
  showCreateProject?: boolean;
}

export const PanelProjects = ({
  panelTitle = "Projects",
  tenantId,
  showCreateProject = true,
}: PanelProjectsProps) => {
  const { projects, loading } = useGetProjects({ tenantId });
  const { getParam, setParam } = useIdeasSearchParams();
  const [view, setView] = useState<"GRID" | "CARDS">(() => {
    const viewParam = getParam("PROJECT_VIEW");
    if (viewParam === "grid") {
      return "GRID";
    }
    return "CARDS";
  });

  const projectsBody = useMemo(() => {
    if (loading) {
      return <EuiSkeletonText lines={5} />;
    }

    if (projects === undefined) {
      return <CallOutError />;
    }
    return <ProjectCards projects={projects} tenantId={tenantId} view={view} />;
  }, [loading, projects, tenantId, view]);

  const viewOptions = [
    {
      id: "CARDS",
      label: "Cards",
      iconType: "apmTrace",
      value: "CARDS",
    } as const,
    {
      id: "GRID",
      label: "Grid",
      iconType: "tableDensityCompact",
      value: "GRID",
    } as const,
  ];
  return (
    <EuiFlexGroup
      direction="column"
      gutterSize="none"
      style={{ height: "100%" }}
    >
      <EuiFlexGroup
        responsive={false}
        gutterSize="none"
        style={{ flexGrow: 0 }}
        alignItems="baseline"
      >
        <EuiFlexItem>
          <EuiTitle>
            <h4>{panelTitle}</h4>
          </EuiTitle>
        </EuiFlexItem>
        <EuiFlexItem>
          <EuiButtonGroup
            type="single"
            legend="View"
            options={viewOptions}
            idSelected={view}
            onChange={(id) => {
              const paramKey =
                (id as (typeof viewOptions)[number]["id"]) === "CARDS"
                  ? "cards"
                  : "grid";
              setParam("PROJECT_VIEW", paramKey);
              setView(id as (typeof viewOptions)[number]["id"]);
            }}
          />
        </EuiFlexItem>
        {showCreateProject && (
          <EuiFlexItem grow={false}>
            <ButtonCreateProject tenantId={tenantId} />
          </EuiFlexItem>
        )}
      </EuiFlexGroup>
      <EuiSpacer size="s" />
      {projectsBody}
    </EuiFlexGroup>
  );
};
