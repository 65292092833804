import assert from "assert";
import axios from "axios";
import { File as DrsFile, Project } from "graphql/_Types";
import { getEnvVar } from "ideas.env";
import { getRequestHeaders } from "utils/getRequestHeaders";
import { isDefined } from "utils/isDefined";
import {
  CellAddress,
  DataTableColumnDefinition,
} from "./DataTableProvider.types";
import { chain } from "lodash";
import { FileStatus, ProcessingStatus, TaskStatus } from "types/constants";
import { FileType } from "types/FileTypes";
import { Except, JsonValue } from "type-fest";
import pDebounce from "p-debounce";
import { AnalysisParamValue, SerializedSideEffects } from "./SideEffects";

/**
 * Represents the options passed to {@link getProject}
 */
type GetProjectOptions = {
  projectKey: Project["key"];
};

type GetProjectResponse = {
  results: {
    id: string;
    name: string;
    key: string;
  }[];
};

/**
 * Gets the project with a specified key within the current tenant.
 * @param options
 * @returns The project.
 */
export const getProject = async ({ projectKey }: GetProjectOptions) => {
  const url = getEnvVar("URL_LIBRARY_PROJECT")();
  const headers = await getRequestHeaders();
  const params = { key: projectKey };
  const { data } = await axios.get<GetProjectResponse>(url, {
    headers,
    params,
  });
  const project = data.results[0];
  assert(isDefined(project));
  return project;
};

/**
 * Represents the options passed to {@link getTableData}
 */
type GetTableDataOptions = {
  projectId: string;
};

/**
 * Represents the response from the server when reading data for data tables
 * and analysis tables within a project.
 */
export type GetTableDataResponse = [
  {
    id: string;
    kind: "data";
    key: string;
    name: string;
    column_groups: {
      id: string;
      name: string;
    }[];
    columns: {
      id: string;
      name: string;
      pinned: boolean;
      order: number;
      width: number;
      editable: boolean;
      deletable: boolean;
      identifier_position: number | null;
      default_formula: string | null;
      required: boolean;
      help_text: string | null;
      is_result: boolean;
      definition: DataTableColumnDefinition;
      group: string | null;
    }[];
    rows: {
      id: string;
      index: number;
      editable: boolean;
      task: {
        id: string;
        status: TaskStatus;
        created: string;
        credits: number | null;
        duration: string;
        user: string;
      } | null;
      cells: {
        is_custom_formula: boolean;
        formula: string | null;
      }[];
    }[];
  },
];

/**
 * Get the table data for every data table cell within a project.
 * @param options
 * @returns The table data organized by tables, columns and rows.
 */
export const getTableData = async ({ projectId }: GetTableDataOptions) => {
  const baseUrl = getEnvVar("URL_LIBRARY_PROJECT")();
  const url = `${baseUrl}formulas/${projectId}/`;
  const headers = await getRequestHeaders();
  const { data } = await axios.get<GetTableDataResponse>(url, { headers });
  return data;
};

/**
 * Represents the options passed to {@link createDataTable}
 */
type CreateDataTableOptions = {
  name: string;
  key: string;
  projectId: string;
  sideEffects: SerializedSideEffects;
};

/**
 * Represents the response returned by the server when creating a data table
 */
type CreateDataTableResponse = {
  id: string;
  column_groups: GetTableDataResponse[number]["column_groups"];
  columns: GetTableDataResponse[number]["columns"];
  rows: Except<GetTableDataResponse[number]["rows"][number], "cells">[];
};

/**
 * Creates a new data table within a project.
 * @param options
 * @returns The table's ID.
 */
export const createDataTable = async ({
  name,
  key,
  projectId,
  sideEffects,
}: CreateDataTableOptions) => {
  const url = getEnvVar("URL_LIBRARY_DATA_TABLE");
  const body = {
    name,
    key,
    project: projectId,
    side_effects: sideEffects,
  };
  const headers = await getRequestHeaders();
  const { data } = await axios.post<CreateDataTableResponse>(url, body, {
    headers,
  });
  return data;
};

/**
 * Represents the options passed to {@link createAnalysisTable}
 */
type CreateAnalysisTableOptions = {
  toolVersionId: number;
  name: string;
  key: string;
  projectId: string;
  sideEffects: SerializedSideEffects;
};

/**
 * Represents the response returned by the server when creating an analysis table
 */
type CreateAnalysisTableResponse = {
  id: string;
  column_groups: GetTableDataResponse[number]["column_groups"];
  columns: GetTableDataResponse[number]["columns"];
  rows: Except<GetTableDataResponse[number]["rows"][number], "cells">[];
};

/**
 * Creates a new analysis table within a project.
 * @param options
 * @returns The table's ID.
 */
export const createAnalysisTable = async ({
  toolVersionId,
  name,
  key,
  projectId,
  sideEffects,
}: CreateAnalysisTableOptions) => {
  const url = getEnvVar("URL_LIBRARY_GDT_ANALYSIS_TABLE");
  const body = {
    tool_version: toolVersionId,
    name,
    key,
    project: projectId,
    side_effects: sideEffects,
  };
  const headers = await getRequestHeaders();
  const { data } = await axios.post<CreateAnalysisTableResponse>(url, body, {
    headers,
  });
  return data;
};

/**
 * Represents the options passed to {@link updateDataTable}
 */
type UpdateDataTableOptions = {
  tableId: string;
  name?: string;
  key?: string;
  sideEffects: SerializedSideEffects;
};

/**
 * Represents the response returned by the server when updating a data table
 */
type UpdateDataTableResponse = {
  id: string;
};

/**
 * Updated a data table.
 * @param options
 * @returns The table's ID.
 */
export const updateDataTable = async ({
  tableId,
  name,
  key,
  sideEffects,
}: UpdateDataTableOptions) => {
  const baseUrl = getEnvVar("URL_LIBRARY_DATA_TABLE");
  const url = `${baseUrl}${tableId}/`;
  const body = {
    ...(isDefined(name) ? { name } : {}),
    ...(isDefined(key) ? { key } : {}),
    side_effects: sideEffects,
  };
  const headers = await getRequestHeaders();
  const { data } = await axios.patch<UpdateDataTableResponse>(url, body, {
    headers,
  });
  return data.id;
};

/**
 * Represents the options passed to {@link updateAnalysisTable}
 */
type UpdateAnalysisTableOptions = {
  tableId: string;
  name?: string;
  key?: string;
  sideEffects: SerializedSideEffects;
};

/**
 * Represents the response returned by the server when updating an analysis table
 */
type UpdateAnalysisTableResponse = {
  id: string;
};

/**
 * Updated an analysis table.
 * @param options
 * @returns The table's ID.
 */
export const updateAnalysisTable = async ({
  tableId,
  name,
  key,
  sideEffects,
}: UpdateAnalysisTableOptions) => {
  const baseUrl = getEnvVar("URL_LIBRARY_GDT_ANALYSIS_TABLE");
  const url = `${baseUrl}${tableId}/`;
  const body = {
    ...(isDefined(name) ? { name } : {}),
    ...(isDefined(key) ? { key } : {}),
    side_effects: sideEffects,
  };
  const headers = await getRequestHeaders();
  const { data } = await axios.patch<UpdateAnalysisTableResponse>(url, body, {
    headers,
  });
  return data.id;
};

/**
 * Represents the options passed to {@link updateColumn}
 */
type UpdateColumnOptions = {
  tableKind: "data" | "analysis";
  columnId: string;
  name?: string;
  width?: number;
  sideEffects: SerializedSideEffects;
};

/**
 * Represents the response returned by the server when updating a column
 */
type UpdateColumnResponse = {
  id: string;
};

/**
 * Updates a column.
 * @param options
 * @returns The column's ID.
 */
export const updateColumn = async ({
  tableKind,
  columnId,
  name,
  width,
  sideEffects,
}: UpdateColumnOptions) => {
  const baseUrl =
    tableKind === "data"
      ? getEnvVar("URL_LIBRARY_DATA_TABLE_COLUMN")
      : getEnvVar("URL_LIBRARY_GDT_ANALYSIS_TABLE_COLUMN");
  const url = `${baseUrl}${columnId}/`;
  const body = {
    ...(isDefined(name) ? { name } : {}),
    ...(isDefined(width) ? { width } : {}),
    side_effects: sideEffects,
  };
  const headers = await getRequestHeaders();
  const { data } = await axios.patch<UpdateColumnResponse>(url, body, {
    headers,
  });
  return data.id;
};

/**
 * Represents the options passed to {@link createDataTableColumn}
 */
type CreateColumnOptions = {
  tableId: string;
  name: string;
  editable: boolean;
  defaultFormula: string | undefined;
  definition: DataTableColumnDefinition;
  sideEffects: SerializedSideEffects;
};

/**
 * Represents the response returned by the server when creating a data table
 * column
 */
type CreateColumnResponse = {
  id: string;
  definition: DataTableColumnDefinition;
  name: string;
  date_created: string;
  date_deleted: null;
  pinned: boolean;
  order: number;
  width: number;
  editable: boolean;
  help_text: string | null;
  deletable: boolean;
  identifier_position: number | null;
  default_formula: string | null;
  user: string;
  table: string;
  group: null;
  required: boolean;
  is_result: boolean;
};

/**
 * Creates a new data table column.
 * @param options
 * @returns The new column.
 */
export const createDataTableColumn = async ({
  tableId,
  name,
  editable,
  defaultFormula,
  definition,
  sideEffects,
}: CreateColumnOptions) => {
  const url = getEnvVar("URL_LIBRARY_DATA_TABLE_COLUMN");
  const body = {
    table: tableId,
    name,
    default_formula: defaultFormula,
    definition,
    editable,
    side_effects: sideEffects,
  };
  const headers = await getRequestHeaders();
  const res = await axios.post<CreateColumnResponse>(url, body, { headers });
  return res.data;
};

/**
 * Represents the options passed to {@link createRowsBulk}
 */
type CreateRowsBulkOptions = {
  tableId: string;
  numRows: number;
  tableKind: "data" | "analysis";
  sideEffects: SerializedSideEffects;
};

/**
 * Represents the response returned by the server when creating table rows
 */
type CreateRowsBulkResponse = {
  id: string;
  index: number;
  editable: boolean;
  date_created: string;
  date_deleted: null;
  user: string;
  table: string;
  task: null;
}[];

/**
 * Creates multiple rows in a specified table
 * @param options
 * @returns The new rows
 */
export const createRowsBulk = async ({
  tableId,
  numRows,
  tableKind,
  sideEffects,
}: CreateRowsBulkOptions) => {
  const baseUrl =
    tableKind === "data"
      ? getEnvVar("URL_LIBRARY_DATA_TABLE_ROW")
      : getEnvVar("URL_LIBRARY_GDT_ANALYSIS_TABLE_ROW");
  const url = `${baseUrl}bulk/`;
  const body = new Array(numRows).fill({
    table: tableId,
    side_effects: sideEffects,
  });
  const headers = await getRequestHeaders();
  const res = await axios.post<CreateRowsBulkResponse>(url, body, { headers });
  return res.data;
};

/**
 * Represents the options passed to {@link updateCellFormula}
 */
type UpdateCellFormulaOptions = {
  address: CellAddress;
  formula: string;
  tableKind: "data" | "analysis";
  sideEffects: SerializedSideEffects;
};

/**
 * Represents the response returned by the server when updating a cell formula
 */
type UpdateCellFormulaResponse = {
  id: string;
  formula: string;
  tenant: number;
  column: string;
  row: string;
};

/**
 * Get the formulas for every data table cell within a project.
 * @param options
 * @returns The cell formulas organized by columns and rows.
 */
export const updateCellFormula = async ({
  address,
  formula,
  tableKind,
  sideEffects,
}: UpdateCellFormulaOptions) => {
  const baseUrl =
    tableKind === "data"
      ? getEnvVar("URL_LIBRARY_DATA_TABLE_CELL")
      : getEnvVar("URL_LIBRARY_GDT_ANALYSIS_TABLE_CELL");
  const url = `${baseUrl}set_formula/`;
  const body = {
    table: address.tableId,
    column: address.columnId,
    row: address.rowId,
    formula,
    side_effects: sideEffects,
  };
  const headers = await getRequestHeaders();
  const { data } = await axios.post<UpdateCellFormulaResponse>(url, body, {
    headers,
  });
  return data.formula;
};

/**
 * Represents the options passed to {@link getProjectFile}
 */
type GetProjectFileOptions = {
  fileId: string;
};

/**
 * Represents the response returned by the server when fetching files
 */
export type GetProjectFileResponse = {
  results: {
    id: string;
    name: string;
    processing_status: ProcessingStatus;
    file_type: FileType["id"];
    status: FileStatus;
    is_series: boolean;
    series_parent_id: string | null;
  }[];
};

/**
 * Gets all files stored in the project.
 * @param options
 * @returns The project files.
 */
export const getProjectFile = (() => {
  // Accumulator that stores all arguments passed during the debounce interval
  const allArgs: GetProjectFileOptions[] = [];

  // Debounced function that fetches all files requested in an interval
  const callback = pDebounce(async () => {
    // Copy the accumulator and reset it. Since this happens in one step, there
    // is no risk of concurrency issues when pushing to the array.
    const args = allArgs.splice(0);

    const url = getEnvVar("URL_DRS_FILE_CREATE");
    const params = {
      id__in: chain(args)
        .map((arg) => arg.fileId)
        .uniq()
        .join(",")
        .value(),
    };
    const headers = await getRequestHeaders();
    const res = await axios.get<GetProjectFileResponse>(url, {
      headers,
      params,
    });

    return res.data.results;
  }, 10);

  return async (options: GetProjectFileOptions) => {
    // Add arguments to the accumulator
    allArgs.push(options);

    // Call the debounced callback. This will resolve after the debounce
    // interval has elapsed.
    const files = await callback();

    // Return the file matching the provided arguments
    const file = files.find(
      (result) =>
        result.id === options.fileId &&
        ![
          FileStatus.DATA_DELETED,
          FileStatus.SCHEDULE_DATA_DELETE,
          FileStatus.SCHEDULE_DELETE,
        ].includes(result.status),
    );
    assert(isDefined(file), "File not found");
    return file;
  };
})();

/**
 * Represents the options passed to {@link getFileMetadatumValue}
 */
type GetFileMetadatumValueOptions = {
  fileId: string;
  metadatumKey: string;
};

/**
 * Represents the response returned by the server when fetching file metadatum
 * values
 */
type GetFileMetadatumValueResponse = {
  results: {
    date_created: string;
    file: string;
    id: string;
    key: string;
    tenant: number;
    values: [
      {
        date_created: string;
        value: JsonValue;
      },
    ];
  }[];
};

/**
 * Gets the current value of a specified file metadatum.
 * @param options
 * @returns The metadatum value.
 */
export const getFileMetadatumValue = (() => {
  // Accumulator that stores all arguments passed during the debounce interval
  const allArgs: GetFileMetadatumValueOptions[] = [];

  // Debounced function that fetches all file metadata requested in an interval
  const callback = pDebounce(async () => {
    // Copy the accumulator and reset it. Since this happens in one step, there
    // is no risk of concurrency issues when pushing to the array.
    const args = allArgs.splice(0);

    const url = getEnvVar("URL_METADATA_FILE_METADATA");
    const params = {
      file__in: chain(args)
        .map((arg) => arg.fileId)
        .uniq()
        .join(",")
        .value(),
      metadata__key__in: chain(args)
        .map((arg) => arg.metadatumKey)
        .uniq()
        .join(",")
        .value(),
    };
    const headers = await getRequestHeaders();
    const res = await axios.get<GetFileMetadatumValueResponse>(url, {
      headers,
      params,
    });

    return res.data.results;
  }, 10);

  return async (options: GetFileMetadatumValueOptions) => {
    // Add arguments to the accumulator
    allArgs.push(options);

    // Call the debounced callback. This will resolve after the debounce
    // interval has elapsed.
    const results = await callback();

    // Find the metadatum matching the provided arguments
    const metadatum = results.find(
      (result) =>
        result.file === options.fileId && result.key === options.metadatumKey,
    );

    if (metadatum === undefined) {
      return null;
    }

    // Return the latest value for the metadatum
    return chain(metadatum.values)
      .sortBy((value) => value.date_created)
      .last()
      .value().value;
  };
})();

/**
 * Represents the options passed to {@link deleteRows}
 */
type DeleteTableOptions = {
  tableKind: "data" | "analysis";
  tableId: string;
  sideEffects: SerializedSideEffects;
};

/**
 * Represents the response returned by the server when deleting tables
 */
type DeleteTableResponse = {
  id: string;
};

/**
 * Deletes a specified table
 * @param options
 * @returns The ID of the deleted table.
 */
export const deleteTable = async ({
  tableKind,
  tableId,
  sideEffects,
}: DeleteTableOptions) => {
  const baseUrl =
    tableKind === "data"
      ? getEnvVar("URL_LIBRARY_DATA_TABLE")
      : getEnvVar("URL_LIBRARY_GDT_ANALYSIS_TABLE");

  const url = `${baseUrl}delete/${tableId}/`;
  const headers = await getRequestHeaders();
  const body = { side_effects: sideEffects };
  const res = await axios.post<DeleteTableResponse>(url, body, { headers });
  return res.data.id;
};

/**
 * Represents the options passed to {@link deleteDataTableColumn}
 */
type DeleteColumnOptions = {
  columnId: string;
  sideEffects: SerializedSideEffects;
};

/**
 * Represents the response returned by the server when deleting data table columns
 */
type DeleteColumnResponse = {
  id: string;
};

/**
 * Deletes a data table column.
 * @param options
 * @returns The ID of the deleted column.
 */
export const deleteDataTableColumn = async ({
  columnId,
  sideEffects,
}: DeleteColumnOptions) => {
  const baseUrl = getEnvVar("URL_LIBRARY_DATA_TABLE_COLUMN");
  const url = `${baseUrl}delete/${columnId}/`;
  const body = { side_effects: sideEffects };
  const headers = await getRequestHeaders();
  const res = await axios.post<DeleteColumnResponse>(url, body, { headers });
  return res.data.id;
};

/**
 * Represents the options passed to {@link deleteRows}
 */
type DeleteRowsOptions = {
  rows: {
    id: string;
    sideEffects: SerializedSideEffects;
  }[];
  tableKind: "data" | "analysis";
};

/**
 * Represents the response returned by the server when deleting table rows
 */
type DeleteRowsResponse = {
  id: string;
  index: number;
  editable: boolean;
  date_created: string;
  date_deleted: null;
  user: string;
  table: string;
  task: null;
}[];

/**
 * Deletes multiple rows.
 * @param options
 * @returns The IDs of the deleted rows.
 */
export const deleteRows = async ({ rows, tableKind }: DeleteRowsOptions) => {
  const baseUrl =
    tableKind === "data"
      ? getEnvVar("URL_LIBRARY_DATA_TABLE_ROW")
      : getEnvVar("URL_LIBRARY_GDT_ANALYSIS_TABLE_ROW");

  const promises = rows.map(async (row) => {
    const url = `${baseUrl}delete/${row.id}/`;
    const body = { side_effects: row.sideEffects };
    const headers = await getRequestHeaders();
    const res = await axios.post<DeleteRowsResponse>(url, body, { headers });
    return res.data;
  });

  await Promise.all(promises);
  return rows.map((row) => row.id);
};

/**
 * Represents the options passed to {@link deleteFile}
 */
type DeleteFileOptions = {
  fileId: string;
  dateDeleted: string;
  mode: "data_delete" | "delete";
  sideEffects: SerializedSideEffects;
};

/**
 * Represents the response returned by the server when deleting files
 */
type DeleteFileResponse = {
  id: string;
};

/**
 * Deletes a file.
 * @param options
 * @returns The ID of the deleted file.
 */
export const deleteFile = async ({
  fileId,
  dateDeleted,
  mode,
  sideEffects,
}: DeleteFileOptions) => {
  const baseUrl =
    mode === "data_delete"
      ? getEnvVar("URL_DRS_FILE_SCHEDULE_DATA_DELETE")
      : getEnvVar("URL_DRS_FILE_SCHEDULE_DELETE");
  const url = `${baseUrl}${fileId}/`;
  const body = {
    date_data_deleted: mode === "data_delete" ? dateDeleted : undefined,
    date_deleted: mode === "delete" ? dateDeleted : undefined,
    side_effects: sideEffects,
  };
  const headers = await getRequestHeaders();
  const res = await axios.patch<DeleteFileResponse>(url, body, { headers });
  return res.data.id;
};

/**
 * Represents the options passed to {@link reorderColumns}
 */
type ReorderColumnsOptions = {
  tableId: string;
  tableKind: "data" | "analysis";
  columnIds: string[];
  sideEffects: SerializedSideEffects;
};

/**
 * Represents the response returned by the server when reordering columns
 */
type ReorderColumnsResponse = {
  id: string;
};

/**
 * Reorders column withing a data table or analysis table.
 * @param options
 * @returns The IDs of the columns in their new order.
 */
export const reorderColumns = async ({
  tableId,
  tableKind,
  columnIds,
  sideEffects,
}: ReorderColumnsOptions) => {
  const baseUrl =
    tableKind === "data"
      ? getEnvVar("URL_LIBRARY_DATA_TABLE")
      : getEnvVar("URL_LIBRARY_GDT_ANALYSIS_TABLE");
  const url = `${baseUrl}reorder_columns/${tableId}/`;
  const body = {
    columns: columnIds,
    side_effects: sideEffects,
  };
  const headers = await getRequestHeaders();
  await axios.patch<ReorderColumnsResponse>(url, body, { headers });
  return columnIds;
};

/**
 * Represents the options passed to {@link pinColumn}
 */
type PinColumnOptions = {
  tableKind: "data" | "analysis";
  columnId: string;
  sideEffects: SerializedSideEffects;
};

/**
 * Represents the response returned by the server when pinning columns
 */
type PinColumnResponse = {
  id: string;
};

/**
 * Pins a column withing a data table or analysis table.
 * @param options
 * @returns The IDs of the pinned columns.
 */
export const pinColumn = async ({
  tableKind,
  columnId,
  sideEffects,
}: PinColumnOptions) => {
  const baseUrl =
    tableKind === "data"
      ? getEnvVar("URL_LIBRARY_DATA_TABLE_COLUMN")
      : getEnvVar("URL_LIBRARY_GDT_ANALYSIS_TABLE_COLUMN");
  const url = `${baseUrl}pin/${columnId}/`;
  const body = { side_effects: sideEffects };
  const headers = await getRequestHeaders();
  await axios.patch<PinColumnResponse>(url, body, { headers });
  return columnId;
};

/**
 * Represents the options passed to {@link unpinColumn}
 */
type UnpinColumnOptions = {
  tableKind: "data" | "analysis";
  columnId: string;
  sideEffects: SerializedSideEffects;
};

/**
 * Represents the response returned by the server when unpinning columns
 */
type UnpinColumnResponse = {
  id: string;
};

/**
 * Unpins a column withing a data table or analysis table.
 * @param options
 * @returns The IDs of the unpinned columns.
 */
export const unpinColumn = async ({
  tableKind,
  columnId,
  sideEffects,
}: UnpinColumnOptions) => {
  const baseUrl =
    tableKind === "data"
      ? getEnvVar("URL_LIBRARY_DATA_TABLE_COLUMN")
      : getEnvVar("URL_LIBRARY_GDT_ANALYSIS_TABLE_COLUMN");
  const url = `${baseUrl}unpin/${columnId}/`;
  const body = { side_effects: sideEffects };
  const headers = await getRequestHeaders();
  await axios.patch<UnpinColumnResponse>(url, body, { headers });
  return columnId;
};

type CreateFileInput = Pick<
  DrsFile,
  | "projectId"
  | "name"
  | "tenantId"
  | "processingStatus"
  | "fileType"
  | "fileFormat"
> & {
  size: number;
};

type CreateFileBody = {
  name: DrsFile["name"];
  part_size: DrsFile["partSize"];
  size: DrsFile["size"];
  date_created: DrsFile["dateCreated"];
  project: DrsFile["projectId"];
  uploaded: true;
  tenant: number;
  processing_status: ProcessingStatus;
  file_type: DrsFile["fileType"];
  file_format: DrsFile["fileFormat"];
};

type CreateFileResponse = {
  date_created: DrsFile["dateCreated"];
  id: DrsFile["id"];
  name: DrsFile["name"];
  project: DrsFile["projectId"];
  part_size: NonNullable<DrsFile["partSize"]>;
  size: DrsFile["size"];
  status: FileStatus;
  user: DrsFile["userId"];
  tenant: DrsFile["tenantId"];
  processing_status: ProcessingStatus;
  file_type: FileType["id"];
  file_format: DrsFile["fileFormat"];
  is_series: boolean;
  series_parent_id: string | null;
};

/**
 * Calculates a multipart upload part size for a minimum part size of 100 MB
 * (AWS guideline) and maximum 10,000 parts (AWS limit). Part sizes will only
 * increase from the minimum for extremely large (1TB+) files.
 * @param fileSize file size in bytes
 * @returns A multipart upload part size in bytes
 */
const getPartSize = (fileSize: number) => {
  const minPartSize = 52_428_800; // 50 MB
  const maxNumParts = 10_000;
  const factor = Math.ceil(fileSize / (minPartSize * maxNumParts));
  return factor * minPartSize;
};

/**
 * Formats a filename to be compatible with the backend
 * @param filename
 * @returns The formatted filename
 */
const formatFilename = (filename: string) => {
  /*
      A valid filename may contain the following characters:
      - Letters (A-Za-z)
      - Numbers (\d)
      - Periods (.)
      - Hyphens (-)
      - Underscores (_)
    */
  const invalidCharRegExp = /[^A-Za-z\d.-_]/g;
  const replaceValue = "-";
  return filename.replace(invalidCharRegExp, replaceValue);
};

/**
 * Creates a file within a project.
 * @param options
 * @returns The new file.
 */
export const createFile = async (input: CreateFileInput) => {
  const url = getEnvVar("URL_DRS_FILE_CREATE");
  const body: CreateFileBody = {
    name: formatFilename(input.name),
    size: input.size.toString(),
    part_size: getPartSize(input.size),
    date_created: new Date().toISOString(),
    project: input.projectId,
    uploaded: true,
    tenant: input.tenantId,
    processing_status: input.processingStatus,
    file_format: input.fileFormat,
    file_type: input.fileType,
  };
  const headers = await getRequestHeaders();
  const { data } = await axios.post<CreateFileResponse>(url, body, { headers });
  return data;
};

/**
 * Represents the options passed to {@link executeAnalysisTableRows}
 */
type ExecuteAnalysisTableRowOptions = {
  rowId: string;
  paramValues: {
    column: string;
    value: AnalysisParamValue;
  }[];
  sideEffects: SerializedSideEffects;
}[];

/**
 * Represents the response returned by the server when executing analysis table
 * rows
 */
type ExecuteAnalysisTableRowResponse = {
  id: string;
  task: {
    id: string;
    status: TaskStatus;
    created: string;
    credits: number | null;
    duration: string;
    user: string;
  };
}[];

/**
 * Deletes multiple rows.
 * @param options
 * @returns The IDs of the deleted rows.
 */
export const executeAnalysisTableRows = async (
  inputs: ExecuteAnalysisTableRowOptions,
) => {
  const baseUrl = getEnvVar("URL_LIBRARY_GDT_ANALYSIS_TABLE_ROW");
  const url = `${baseUrl}bulk_execute/`;
  const body = inputs.map((input) => ({
    id: input.rowId,
    param_values: input.paramValues,
    side_effects: input.sideEffects,
  }));
  const headers = await getRequestHeaders();
  const res = await axios.post<ExecuteAnalysisTableRowResponse>(url, body, {
    headers,
  });
  return res.data;
};

/**
 * Represents the options passed to {@link cancelTask}
 */
type CancelTaskOptions = {
  taskId: string;
};

/**
 * Represents the response returned by the server when canceling tasks
 */
type CancelTaskResponse = {
  status: string;
};

/**
 * Cancels a running task.
 * @param options
 * @returns The ID of the canceled task.
 */
export const cancelTask = async ({ taskId }: CancelTaskOptions) => {
  const baseUrl = getEnvVar("URL_TES_TASK_CANCEL");
  const url = `${baseUrl}${taskId}/`;
  const body = undefined;
  const headers = await getRequestHeaders();
  await axios.patch<CancelTaskResponse>(url, body, { headers });
};
