/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import {
  EuiButtonEmpty,
  EuiCallOut,
  EuiFlexGroup,
  EuiFlexItem,
  EuiForm,
  EuiFormRow,
  EuiModal,
  EuiModalBody,
  EuiModalFooter,
  EuiModalHeader,
  EuiModalHeaderTitle,
  EuiPanel,
  EuiSpacer,
  EuiSuperSelectOption,
  EuiTab,
  EuiTabs,
  EuiText,
} from "@inscopix/ideas-eui";
import { ModalError } from "components/ModalError/ModalError";
import { ModalLoading } from "components/ModalLoading/ModalLoading";
import { UserWithAccessProps } from "components/ModalProjectSharing/UserWithAccess";
import { ApplicationUser, PageProjectDocument, Project } from "graphql/_Types";
import { useUserContext } from "providers/UserProvider/UserProvider";
import { useTenantContext } from "providers/TenantProvider/TenantProvider";
import { useState, Fragment, useCallback } from "react";
import {
  USER_ACCESS_LEVELS_BY_KEY,
  UserAccessLevel,
} from "types/UserAccessLevels";
import { addUtilityToastFailure } from "utils/addUtilityToastFailure";
import { addUtilityToastSuccess } from "utils/addUtilityToastSuccess";
import {
  UserWithAccessExternal,
  UserWithAccessExternalProps,
} from "./UserWithAccessExternal";
import useFindUser from "hooks/useFindUser";
import { addUtilityToastPointing } from "utils/addUtilityToastPointing";
import { ideasFeatures } from "ideas.features";
import { useIdeasSearchParams } from "hooks/useIdeasSearchParams";
import {
  AdminProjectDjango,
  getProjectDjango,
} from "django/libraryProjectDjango";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { queryKeys } from "django/queryKeys";
import { permissions, UserProjectAccessDjango } from "./permissions";
import { ModalProjectSharingDefaultAccessLevel } from "./ModalProjectSharingDefaultAccessLevel";
import { ModalProjectSharingInternalUserAccess } from "./ModalProjectSharingInternalUserAccess";
import { ButtonPermissionedBase } from "components/ButtonPermissioned/ButtonPermissioned";
import { FieldTextPermissionedBase } from "components/FieldTextPermissioned/FieldTextPermissioned";
import { client } from "providers/ApolloProvider/ApolloProvider";

export interface ModalProjectSharingProps {
  projectId: Project["id"];
  onClose: () => void;
  /**
   * Optional override for project query when accessing sharing modal for restricted projects
   * from org admin table
   */
  getProject?: (projectId: Project["id"]) => Promise<AdminProjectDjango>;
  /**
   * Optional override for default access level update when accessing sharing modal for restricted projects
   * from org admin table
   */
  updateInternalDefaultAccessLevel?: typeof permissions.userFunctions.internalAccess.updateInternalDefaultAccessLevel;
  createInternalUserAccess?: typeof permissions.userFunctions.internalAccess.createInternalUserAccess;
  revokeInternalUserAccess?: typeof permissions.userFunctions.internalAccess.revokeInternalUserAccess;
  updateInternalUserAccessLevel?: UserWithAccessProps["updateInternalUserAccessLevel"];
  revokeExternalProjectAccessInvitation?: UserWithAccessExternalProps["revokeExternalProjectAccessInvitation"];
  sendExternalProjectAccessInvitation?: UserWithAccessExternalProps["sendExternalProjectAccessInvitation"];
  resendExternalProjectAccessInvitation?: UserWithAccessExternalProps["resendExternalProjectAccessInvitation"];
  updateExternalProjectSharingInvitation?: UserWithAccessExternalProps["updateExternalProjectSharingInvitation"];
  getInternalUsersWithProjectAccess?: typeof permissions.userFunctions.internalAccess.getInternalUsersWithProjectAccess;
  getProjectSharingExternalInvitations?: typeof permissions.userFunctions.externalAccess.getProjectSharingExternalInvitations;
}

export type TUserWithAccessExternal = {
  id: UserProjectAccessDjango["id"];
  email: string;
  invitee: ApplicationUser["id"] | null;
  status: number;
  project_access_level: UserAccessLevel["id"];
};

type TProjectSharingTab = "organization" | "external";

const getPermissionData = async (
  projectId: Project["id"],
  getProject: NonNullable<ModalProjectSharingProps["getProject"]>,
  getInternalUsersWithProjectAccess: NonNullable<
    ModalProjectSharingProps["getInternalUsersWithProjectAccess"]
  >,
  getProjectSharingExternalInvitations: NonNullable<
    ModalProjectSharingProps["getProjectSharingExternalInvitations"]
  >,
) => {
  const usersWithProjectAccess = await getInternalUsersWithProjectAccess({
    projectId,
  });
  const project = await getProject(projectId);
  const externalUsersWithAccess = await getProjectSharingExternalInvitations({
    projectId,
  });

  return { project, usersWithProjectAccess, externalUsersWithAccess };
};

export const useInvalidateModalProjectSharingQuery = () => {
  const queryClient = useQueryClient();

  const invalidateModalProjectSharingQuery = useCallback(
    async (projectId: Project["id"]) => {
      await queryClient.invalidateQueries({
        queryKey: queryKeys.modalProjectShare(projectId),
      });

      await queryClient.invalidateQueries({
        queryKey: queryKeys.permissionsProvider(projectId),
        exact: true,
      });

      await client.refetchQueries({ include: [PageProjectDocument] });

      await queryClient.invalidateQueries({
        queryKey: queryKeys.getAdminProjects(),
      });
    },
    [queryClient],
  );
  return { invalidateModalProjectSharingQuery };
};

const accessLevelOptions: EuiSuperSelectOption<`${UserAccessLevel["id"]}`>[] = [
  // All internal users with "Viewer" permission are invited as copiers.
  // Only external users with have the true "Viewer" role.
  {
    value: `${USER_ACCESS_LEVELS_BY_KEY["COPIER"].id}`,
    inputDisplay: <EuiText size="s">Viewer</EuiText>,
    dropdownDisplay: (
      <>
        <strong>Viewer</strong>
        <EuiText size="s" color="subdued">
          <p>View project and files only</p>
        </EuiText>
      </>
    ),
  },
  {
    value: `${USER_ACCESS_LEVELS_BY_KEY["EDITOR"].id}`,
    inputDisplay: <EuiText size="s">Editor</EuiText>,
    dropdownDisplay: (
      <>
        <strong>Editor</strong>
        <EuiText size="s" color="subdued">
          <p>Create/edit tables, upload files, and run analyses</p>
        </EuiText>
      </>
    ),
  },
  {
    value: `${USER_ACCESS_LEVELS_BY_KEY["ADMIN"].id}`,
    inputDisplay: <EuiText size="s">Admin</EuiText>,
    dropdownDisplay: (
      <>
        <strong>Admin</strong>
        <EuiText size="s" color="subdued">
          <p>Manage access, delete project</p>
        </EuiText>
      </>
    ),
  },
];

export const ModalProjectSharing = ({
  projectId,
  getProject = getProjectDjango,
  updateInternalDefaultAccessLevel = permissions.userFunctions.internalAccess
    .updateInternalDefaultAccessLevel,
  createInternalUserAccess = permissions.userFunctions.internalAccess
    .createInternalUserAccess,
  revokeInternalUserAccess = permissions.userFunctions.internalAccess
    .revokeInternalUserAccess,
  updateInternalUserAccessLevel = permissions.userFunctions.internalAccess
    .updateInternalUserAccessLevel,
  sendExternalProjectAccessInvitation = permissions.userFunctions.externalAccess
    .sendExternalProjectAccessInvitation,
  resendExternalProjectAccessInvitation = permissions.userFunctions
    .externalAccess.resendExternalProjectAccessInvitation,
  revokeExternalProjectAccessInvitation = permissions.userFunctions
    .externalAccess.revokeExternalProjectAccessInvitation,
  updateExternalProjectSharingInvitation = permissions.userFunctions
    .externalAccess.updateExternalProjectSharingInvitation,
  getInternalUsersWithProjectAccess = permissions.userFunctions.internalAccess
    .getInternalUsersWithProjectAccess,
  getProjectSharingExternalInvitations = permissions.userFunctions
    .externalAccess.getProjectSharingExternalInvitations,
  onClose: onCloseFromProps,
}: ModalProjectSharingProps) => {
  const { invalidateModalProjectSharingQuery } =
    useInvalidateModalProjectSharingQuery();

  const { data, isLoading, isRefetchError, isLoadingError } = useQuery({
    queryFn: () =>
      getPermissionData(
        projectId,
        getProject,
        getInternalUsersWithProjectAccess,
        getProjectSharingExternalInvitations,
      ),
    queryKey: queryKeys.modalProjectShare(projectId),
    refetchOnMount: "always",
  });

  const { getParam, deleteParam } = useIdeasSearchParams();
  const onClose = useCallback(() => {
    const isShareRouteParamSet = getParam("OPEN_MODAL") === "shareProject";
    if (isShareRouteParamSet) {
      deleteParam("OPEN_MODAL");
    }
    onCloseFromProps();
  }, [deleteParam, getParam, onCloseFromProps]);

  const currentUser = useUserContext((s) => s.currentUser);
  const tenantUsers = useTenantContext((s) => s.tenantUsers);

  const [selectedTab, setSelectedTab] =
    useState<TProjectSharingTab>("organization");

  const [emailToInvite, setEmailToInvite] = useState<string>("");
  const [sendingInvite, setSendingInvite] = useState<boolean>(false);

  const { findUser } = useFindUser();

  const handleInviteUser = async () => {
    if (emailToInvite === currentUser.email) {
      ideasFeatures.devOnly
        ? addUtilityToastPointing("You cannot invite yourself")
        : addUtilityToastFailure("You cannot invite yourself");
      return;
    }
    if (tenantUsers.some((user) => user.email === emailToInvite)) {
      addUtilityToastFailure(
        "User already exists in the organization - use the organization access tab to share this project with them",
      );
      return;
    }
    setSendingInvite(true);

    try {
      // Check if the user exists
      await findUser(emailToInvite);
    } catch (error) {
      addUtilityToastFailure(
        `No current IDEAS user found with this email address: ${emailToInvite}. Inviting new users to IDEAS will be possible in the next release.`,
      );
      setSendingInvite(false);
      return;
    }

    try {
      await sendExternalProjectAccessInvitation({
        projectId,
        email: emailToInvite,
      });

      setEmailToInvite("");

      addUtilityToastSuccess("User invited");
    } catch (error) {
      addUtilityToastFailure("Failed to invite user");
    }

    await invalidateModalProjectSharingQuery(projectId);
    setSendingInvite(false);
  };

  if (isLoading) {
    return <ModalLoading onClose={onClose} />;
  }

  if (data === undefined || isRefetchError || isLoadingError) {
    return <ModalError onClose={onClose} />;
  }

  const userHasGrantAccessPermission = data.project.permissions.grant_access;

  const organizationTab = (
    <Fragment>
      <EuiPanel hasShadow={false} hasBorder={true}>
        <EuiForm>
          <ModalProjectSharingDefaultAccessLevel
            userHasGrantAccessPermission={userHasGrantAccessPermission}
            accessLevelOptions={accessLevelOptions}
            updateInternalDefaultAccessLevel={updateInternalDefaultAccessLevel}
            projectId={projectId}
            defaultAccessLevel={data.project.default_user_access_level}
          />

          <EuiSpacer size="l" />

          <ModalProjectSharingInternalUserAccess
            userHasGrantAccessPermission={userHasGrantAccessPermission}
            accessLevelOptions={accessLevelOptions}
            project={data.project}
            revokeInternalUserAccess={revokeInternalUserAccess}
            updateInternalUserAccessLevel={updateInternalUserAccessLevel}
            createInternalUserAccess={createInternalUserAccess}
            usersWithAccess={data.usersWithProjectAccess}
          />
        </EuiForm>
      </EuiPanel>
    </Fragment>
  );
  const externalTab = (
    <Fragment>
      <EuiPanel hasShadow={false} hasBorder={true}>
        <EuiText size="xs">
          <h4>External users with access</h4>
        </EuiText>
        <EuiSpacer size="s" />

        <EuiPanel hasShadow={false} hasBorder={true}>
          {data.externalUsersWithAccess.length === 0 && (
            <EuiCallOut>
              No external users have been invited to access this project
            </EuiCallOut>
          )}
          {data.externalUsersWithAccess.map((usersWithAccess, idx) => {
            return (
              <Fragment key={usersWithAccess.id}>
                <UserWithAccessExternal
                  userHasGrantAccessPermission={userHasGrantAccessPermission}
                  projectId={projectId}
                  key={usersWithAccess.id}
                  invite={usersWithAccess}
                  sendExternalProjectAccessInvitation={
                    sendExternalProjectAccessInvitation
                  }
                  resendExternalProjectAccessInvitation={
                    resendExternalProjectAccessInvitation
                  }
                  revokeExternalProjectAccessInvitation={
                    revokeExternalProjectAccessInvitation
                  }
                  updateExternalProjectSharingInvitation={
                    updateExternalProjectSharingInvitation
                  }
                />
                {/* Put space between items except for the last child */}
                {idx + 1 < data.externalUsersWithAccess.length && (
                  <EuiSpacer size="m" />
                )}
              </Fragment>
            );
          })}
        </EuiPanel>

        <EuiSpacer size="l" />
        <EuiFormRow label="Invite external users" fullWidth>
          <EuiFlexGroup gutterSize="s">
            <EuiFlexItem>
              <FieldTextPermissionedBase
                type="email"
                placeholder="Enter email address"
                value={emailToInvite}
                onChange={(e) => {
                  setEmailToInvite(e.target.value);
                }}
                isPermitted={userHasGrantAccessPermission}
              />
            </EuiFlexItem>
            <EuiFlexItem grow={false}>
              <ButtonPermissionedBase
                isPermitted={userHasGrantAccessPermission}
                isLoading={sendingInvite}
                size="m"
                onClick={() => void handleInviteUser()}
              >
                Invite user
              </ButtonPermissionedBase>
            </EuiFlexItem>
          </EuiFlexGroup>
        </EuiFormRow>
        <EuiSpacer size="m" />
        <EuiCallOut
          size="s"
          color="warning"
          title="External users can only have 'View' access"
          iconType="iInCircle"
        />
      </EuiPanel>
    </Fragment>
  );

  return (
    <EuiModal
      onClose={onClose}
      css={css`
        min-width: 540px;
        // matches the height to the max-block-size of EuiModals so it stays constant
        height: 75vh;
      `}
    >
      <EuiModalHeader>
        <EuiModalHeaderTitle>Share Project</EuiModalHeaderTitle>
      </EuiModalHeader>

      <EuiModalBody>
        <EuiTabs>
          <EuiTab
            onClick={() => setSelectedTab("organization")}
            isSelected={selectedTab === "organization"}
          >
            Organization Access
          </EuiTab>
          <EuiTab
            onClick={() => setSelectedTab("external")}
            isSelected={selectedTab === "external"}
          >
            External access
          </EuiTab>
        </EuiTabs>
        {selectedTab === "organization" ? organizationTab : externalTab}
      </EuiModalBody>
      <EuiModalFooter>
        <EuiButtonEmpty onClick={onClose}>Close</EuiButtonEmpty>
      </EuiModalFooter>
    </EuiModal>
  );
};
