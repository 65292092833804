import { EuiFieldNumber } from "@inscopix/ideas-eui";
import { withUserPermission } from "hocs/withUserPermission";
import { withUserPermissionBase } from "hocs/withUserPermissionBase";

/**
 * Enhanced {@link EuiFieldNumber} that is disabled when the user lacks a
 * required permission.
 *
 * Context independent.
 */
export const FieldNumberPermissionedBase =
  withUserPermissionBase(EuiFieldNumber);

/**
 * Enhanced {@link EuiFieldNumber} that is disabled when the user lacks a
 * required permission.
 *
 * Reads permissions from project permissions context.
 */
export const FieldNumberPermissioned = withUserPermission(
  FieldNumberPermissionedBase,
);
