import { useState } from "react";
import {
  EuiFlexGroup,
  EuiFlexItem,
  EuiHeaderSectionItem,
  EuiHeaderSectionItemButton,
  EuiLink,
  EuiPopover,
  EuiSpacer,
  EuiText,
  htmlIdGenerator,
} from "@inscopix/ideas-eui";
import { UserAvatar } from "../UserAvatar/UserAvatar";
import { logOut } from "../../utils/logOut";
import { useUserContext } from "providers/UserProvider/UserProvider";
import { useRouteMapContext } from "providers/RouteMapProvider/RouteMapProvider";

/**
 * User menu popover to access account controls.
 */
function UserMenu() {
  const routeMap = useRouteMapContext((s) => s.routeMap);
  const currentUser = useUserContext((s) => s.currentUser);
  const [isOpen, setIsOpen] = useState(false);

  const togglePopover = () => {
    setIsOpen((isOpen) => !isOpen);
  };

  const closePopover = () => {
    setIsOpen(false);
  };

  const handleLogOut = (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault();
    void logOut();
  };

  const button = (
    <EuiHeaderSectionItemButton
      aria-controls={htmlIdGenerator()()}
      aria-expanded={isOpen}
      aria-haspopup="true"
      aria-label="Button to open user menu"
      onClick={togglePopover}
    >
      <UserAvatar userId={currentUser.id} size="s" />
    </EuiHeaderSectionItemButton>
  );

  return (
    <EuiHeaderSectionItem>
      <EuiPopover
        id={htmlIdGenerator()()}
        ownFocus
        button={button}
        isOpen={isOpen}
        anchorPosition="downRight"
        closePopover={closePopover}
      >
        <div style={{ width: 375 }} aria-label="user menu">
          <EuiFlexGroup className="euiHeaderProfile" responsive={false}>
            <EuiFlexItem grow={false}>
              <UserAvatar userId={currentUser.id} size="xl" />
            </EuiFlexItem>
            <EuiFlexItem>
              <EuiFlexItem>
                <EuiText
                  title={`${currentUser.firstName} ${currentUser.lastName}`}
                >
                  <h4>
                    {currentUser.firstName} {currentUser.lastName}
                  </h4>
                </EuiText>
              </EuiFlexItem>
              <EuiFlexItem>
                <EuiText
                  color="subdued"
                  size="xs"
                  title={currentUser.email}
                  style={{
                    maxWidth: "180px",
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                  }}
                >
                  {currentUser.email}
                </EuiText>
              </EuiFlexItem>
              <EuiSpacer size="m" />
              <EuiFlexGroup justifyContent="spaceBetween">
                <EuiFlexItem grow={false}>
                  <EuiLink
                    role="button"
                    to={routeMap["SETTINGS"].path}
                    onClick={closePopover}
                  >
                    Profile
                  </EuiLink>
                </EuiFlexItem>
                <EuiFlexItem>
                  <EuiLink onClick={handleLogOut}>Log out</EuiLink>
                </EuiFlexItem>
              </EuiFlexGroup>
            </EuiFlexItem>
          </EuiFlexGroup>
        </div>
      </EuiPopover>
    </EuiHeaderSectionItem>
  );
}

export default UserMenu;
