import { OpenContract } from "hooks/useFetchAndSetHasActiveContract";
import { create } from "zustand";

interface HasActiveContract {
  activeContract: OpenContract | undefined;
  upcomingContract: OpenContract | undefined;
  setActiveContract: (contract: OpenContract | undefined) => void;
  setUpcomingContract: (contract: OpenContract | undefined) => void;
}

/**
 * A hook for for managing the hasActiveContract state
 * @returns The hasActiveContract state and setter
 */

export const useHasActiveContract = create<HasActiveContract>()((set) => {
  return {
    activeContract: undefined,
    upcomingContract: undefined,
    setActiveContract: (contract) => {
      set({ activeContract: contract });
    },
    setUpcomingContract: (contract) => {
      set({ upcomingContract: contract });
    },
  };
});
