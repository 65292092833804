import { Fragment } from "react";
import { EuiToken, isColorDark, IconType, hexToRgb } from "@inscopix/ideas-eui";
import { useEuiTheme } from "@inscopix/ideas-eui";
import { Project } from "../../graphql/_Types";
import { useRouteMapContext } from "providers/RouteMapProvider/RouteMapProvider";
import { TRegion } from "providers/RegionsProvider";
import { isUndefined } from "lodash";

// The ProjectIcon component displays a linked image as an icon
// If no image is provided, it creates an icon using the first two letters of the provided icon_text
// If no icon_text is provided, it creates an icon using the first two letters of the project name

// Props:
//  A size string
//  A project object expected to optionally contain name, icon, icon_text, icon_color properties

export interface ProjectIconProps {
  size: "s" | "m" | "l" | "xs";
  project: Pick<
    Project,
    "id" | "icon" | "iconColor" | "iconText" | "name" | "key"
  > & {
    region?: TRegion;
  };
  tenantKey: string;
}

function ProjectIcon({ size, project, tenantKey }: ProjectIconProps) {
  const routeMap = useRouteMapContext((s) => s.routeMap);
  const { euiTheme } = useEuiTheme();
  let type: IconType;
  let backgroundColor: string;
  let text: string;
  let fill: "none" | "dark" | "light" | undefined;

  const name = project.name;
  // Finds the project iconColor in the Eui colors, otherwise uses euiColorGhost

  const iconColor = project.iconColor;
  backgroundColor = iconColor; // works fine!

  fill = backgroundColor === euiTheme.colors.ghost ? "light" : "dark";
  const [r, g, b] = hexToRgb(backgroundColor);
  const isBackgroundDark = isColorDark(r, g, b);

  // If the background color is dark, use euiColorGhost for the text
  // Otherwise, use euiColorInk
  const textColor = isBackgroundDark
    ? euiTheme.colors.ghost
    : euiTheme.colors.ink;

  // If icon exists displays the linked image
  if (project.icon !== null) {
    type = project.icon;
    // If the icon is from Eui and the text is darker than the background
    // The color prop needs to be set to the darker color to get the expected behaviour
    if (typeof type === "string" && !isBackgroundDark) {
      fill = "none";
      backgroundColor = project.name !== "Select Project" ? "light" : iconColor;
    }

    // If icon is not defined, should instead display the icon_text (truncated to 2 letters)
  } else if (project.iconText !== null) {
    text = truncateTwoLetters(project.iconText);
    type = generateSVG(text, size);

    // If icon_text is not present, it should display the first two letters of the project name
  } else {
    text = truncateTwoLetters(name);
    type = generateSVG(text, size);
  }

  const onClick = (() => {
    if (isUndefined(project.region)) {
      return () =>
        routeMap["PROJECT"]
          .dynamicPath({
            tenantKey: tenantKey,
            projectKey: project.key,
          })
          .navigateTo();
    } else {
      return () =>
        window.open(
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          `${project.region!.url}${
            routeMap["PROJECT"].dynamicPath({
              tenantKey: tenantKey,
              projectKey: project.key,
            }).path
          }`,
          "_blank",
        );
    }
  })();

  // Get the first two letters of a string
  function truncateTwoLetters(text: string) {
    return text.substring(0, 2);
  }

  // Creates an SVG object based on an input string and size
  function generateSVG(text: string | undefined, size: "s" | "m" | "l" | "xs") {
    const sizeArray = {
      s: [11, euiTheme.size.base],
      m: [14, euiTheme.size.l],
      l: [19, euiTheme.size.xl],
      xs: [8, euiTheme.size.m],
    };
    const [fontSize, numericSize] = sizeArray[size];
    const numericSizeInt =
      typeof numericSize === "string"
        ? numericSize.replace(/\D/g, "")
        : numericSize;
    const viewBox = `0 -4 ${numericSizeInt} ${numericSizeInt}`;
    const typeComponent = () => (
      <svg viewBox={viewBox} width={numericSize} height={numericSize}>
        <text
          x="50%"
          y="50%"
          dominantBaseline="central"
          textAnchor="middle"
          fontSize={fontSize}
          fill={textColor}
          fontWeight="bold"
        >
          {text}
        </text>
      </svg>
    );
    return typeComponent;
  }

  return (
    <Fragment>
      <EuiToken
        aria-label={name}
        role="icon"
        iconType={type}
        size={size}
        color={backgroundColor}
        style={{ cursor: "pointer" }}
        shape="square"
        fill={fill}
        onClick={onClick}
      />
    </Fragment>
  );
}

export default ProjectIcon;
