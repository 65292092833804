import { EuiCheckbox, htmlIdGenerator, shade } from "@inscopix/ideas-eui";
import { CSSProperties, ChangeEvent, memo } from "react";
import { useValueValidator } from "components/ToolParamsGrid/ToolParamsGridValueValidatorContext";
import { ColorsByTaskStatus } from "types/ColorsByTaskStatus";
import { css } from "@emotion/react";
import { useStartTaskToolTipContent } from "./hooks/useStartTaskToolTipContent";
import { Tooltip } from "components/Tooltip/Tooltip";
import { isEqual, isUndefined } from "lodash";
import { TaskStatus } from "types/constants";

interface GridRendererSelectRowProps {
  rowId: string;
  taskStatus: TaskStatus | undefined;
  value: boolean;
}

/**
 * A component for rendering a checkbox for selecting a row
 */
export const GridRendererSelectRow = memo(function GridRendererSelectRow({
  rowId,
  value,
  taskStatus,
}: GridRendererSelectRowProps) {
  const rowErrorsByParamKey = useValueValidator(
    (s) => s.errorsByRowId?.[rowId] ?? {},
    isEqual,
  );
  const isRowValid = Object.values(rowErrorsByParamKey).every(isUndefined);
  const toolTipContent = useStartTaskToolTipContent(rowId);

  const style: CSSProperties = {
    alignItems: "center",
    display: "flex",
    height: "100%",
    justifyContent: "center",
    width: "100%",
  };

  let checkBoxCss = css``;
  if (taskStatus !== undefined) {
    const checkColor = ColorsByTaskStatus[taskStatus];
    checkBoxCss = css`
      .euiCheckbox__input:indeterminate + .euiCheckbox__square {
        background-color: ${checkColor} !important;
        border-color: ${shade(checkColor, 0.35)} !important;
      }
    `;
  }

  return (
    <div style={style}>
      <Tooltip content={toolTipContent} place="right">
        <EuiCheckbox
          css={checkBoxCss}
          checked={value}
          id={htmlIdGenerator()()}
          indeterminate={value && (!isRowValid || taskStatus !== undefined)}
          onChange={(e: ChangeEvent<HTMLInputElement>) => {
            e.preventDefault(); // Prevent the checkbox from being checked on click before the state is updated
            return;
          }}
        />
      </Tooltip>
    </div>
  );
});
