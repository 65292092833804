import { useState } from "react";
import WorkflowSelectionModal from "../WorkflowSelectionModal/WorkflowSelectionModal";
import { uniq } from "lodash";
import {
  AnalysisTableRowFieldsFragment,
  FileRecordingGroup,
  useCreateAnalysisTableGroupMutation,
  useCreateAnalysisTableMutation,
  PageProjectDocument,
} from "graphql/_Types";
import { addUtilityToastFailure } from "utils/addUtilityToastFailure";
import { ApolloError } from "@apollo/client";
import { isDefined } from "utils/isDefined";
import { addUtilityToastSuccess } from "utils/addUtilityToastSuccess";
import { useTenantContext } from "providers/TenantProvider/TenantProvider";
import {
  getDefaultRowDatum,
  isFileFilterMatch,
} from "components/ToolParamsGrid/ToolParamsGridProvider.helpers";
import { isToolPathParam } from "components/ToolParamsGrid/ToolParamsGrid.helpers";
import { useRouteMapContext } from "providers/RouteMapProvider/RouteMapProvider";
import { ToolSpecAndToolVersionId } from "providers/ToolSpecProvider/ToolSpecProvider";
import { useProjectDataContext } from "pages/project/ProjectDataProvider";
import { captureException } from "@sentry/react";
import { useProjectFilesStore } from "stores/project-files/ProjectFilesManager";
import { useUserContext } from "providers/UserProvider/UserProvider";
import { isNullish } from "utils/isNullish";
import { client } from "providers/ApolloProvider/ApolloProvider";
import { libraryAnalysisTableRowDjango } from "django/libraryAnalysisTableRowDjango";

export type ModalDatasetRunAnalysisProps = {
  onClose: () => void;
  selectedRecordings?: Pick<FileRecordingGroup, "id">[];
  selectedToolSpecKey?: string;
};

/**
 * A modal to create an analysis table from a dataset recordings table
 */

function ModalDatasetRunAnalysis({
  onClose,
  selectedRecordings = [],
  selectedToolSpecKey,
}: ModalDatasetRunAnalysisProps) {
  const routeMap = useRouteMapContext((s) => s.routeMap);
  const files = useProjectFilesStore((s) => s.files);
  const { project } = useProjectDataContext();
  const currentUser = useUserContext((s) => s.currentUser);
  const currentTenant = useTenantContext((s) => s.currentTenant);
  const [isLoading, setIsLoading] = useState(false);
  const currentUserId = currentUser.id;
  const tenantId = currentTenant.id;
  const projectId = project.id;

  const [createAnalysisTableGroup] = useCreateAnalysisTableGroupMutation();
  const [createAnalysisTable] = useCreateAnalysisTableMutation();

  const handleSelectWorkflow = async (
    toolSpec: ToolSpecAndToolVersionId | undefined,
    tableName: string,
  ) => {
    setIsLoading(true);
    if (isDefined(toolSpec)) {
      try {
        const failedError = new ApolloError({
          errorMessage: "Failed to create analysis table",
        });

        const { data: groupCreatedData } = await createAnalysisTableGroup({
          variables: {
            input: {
              analysisTableGroup: {
                name: tableName,
                description: "",
                userId: currentUserId,
                tenantId: tenantId,
                toolId: toolSpec.toolId,
                projectId: projectId,
              },
            },
          },
        });

        const groupCreated =
          groupCreatedData?.createAnalysisTableGroup?.analysisTableGroup;
        if (isNullish(groupCreated)) {
          throw failedError;
        }

        const { data: tableCreatedData } = await createAnalysisTable({
          variables: {
            input: {
              analysisTable: {
                userId: currentUserId,
                tenantId: tenantId,
                toolVersionId: toolSpec.toolVersionId,
                projectId: projectId,
                groupId: groupCreated.id,
              },
            },
          },
        });

        const tableCreated =
          tableCreatedData?.createAnalysisTable?.analysisTable;
        const toolVersion = tableCreated?.toolVersion;
        if (isNullish(tableCreated) || isNullish(toolVersion)) {
          throw failedError;
        }

        const rows = selectedRecordings.map((recording) => {
          const rowDatum = getDefaultRowDatum(toolSpec.spec, toolVersion);

          // auto-fill tool path params if only one valid file exists
          const toolPathParams = toolSpec.spec.params
            .filter(isToolPathParam)
            // don't auto-fill optional params
            .filter(({ required }) => required);

          toolPathParams.forEach((param) => {
            const fileFilters = param.type.file_filters;

            // Skip auto-population for params with no file filters
            if (fileFilters === undefined) {
              return;
            }

            const matchingDrsFiles = files
              // Remove files belonging to other recordings
              .filter((file) => {
                const { recordings } = file;
                return recordings.some(({ id }) => id === recording.id);
              })
              /* Remove files that aren't visible in a column. Analysis results
                 assigned back to datasets will belong to recordings even if no
                 analysis result columns display them. This presents a confusing
                 UX where auto-population does not work because there are multiple
                 files assigned to a recording that match the file filters even
                 though the user can't see them in the recordings table. */
              .filter((file) => file.columns.length > 0)
              // Remove files that don't match any of the file filters
              .filter((file) =>
                fileFilters.some((filter) => isFileFilterMatch(file, filter)),
              );

            if (matchingDrsFiles.length === 1) {
              const matchingFile = matchingDrsFiles[0];

              rowDatum.params[param.key] = matchingFile.isSeries
                ? matchingFile.seriesFiles.map(({ id }) => id)
                : [matchingFile.id];

              const datasetIds = matchingFile.datasets.map(({ id }) => id);
              rowDatum["datasets"] = uniq(datasetIds);

              const recordingIds = matchingFile.recordings.map(({ id }) => id);
              rowDatum["recordings"] = uniq(recordingIds);
            }
          });

          return rowDatum;
        });

        const rowCreationErrors: Error[] = [];
        const createdRows: AnalysisTableRowFieldsFragment[] = [];

        for (const row of rows) {
          try {
            const { data: rowCreatedData } =
              await libraryAnalysisTableRowDjango.post({
                id: row.id,
                table: tableCreated.id,
                data: row.params,
                tenant: currentTenant.id,
                attachments: {
                  datasets: row.datasets ?? [],
                  recordings: row.recordings ?? [],
                },
                project: project.id,
                selections: null,
                metadatum_references: row.metadatumReferences,
                tool_version: row.toolVersion.id,
              });
            createdRows.push(rowCreatedData);
          } catch (err) {
            captureException(err);
            rowCreationErrors.push(new Error("Failed to create row"));
          }
        }
        if (rowCreationErrors.length > 0) {
          // TODO Find graceful way to notify the user that the table was created
          // but some rows failed to be created in the table
        }

        // Update cache
        await client.refetchQueries({ include: [PageProjectDocument] });

        addUtilityToastSuccess("Successfully created analysis table");

        routeMap["PROJECT_ANALYSIS_TABLE_GROUP"]
          .dynamicPath({
            tenantKey: currentTenant.key,
            projectKey: project.key,
            analysisTableGroupId: groupCreated.id,
          })
          .navigateTo();
      } catch (err) {
        captureException(err);
        addUtilityToastFailure("Failed to create analysis table");
      }
    }
    setIsLoading(false);
    onClose();
  };

  return (
    <WorkflowSelectionModal
      isLoading={isLoading}
      onClose={onClose}
      onSubmit={handleSelectWorkflow}
      selectedToolSpecKey={selectedToolSpecKey}
    />
  );
}

export default ModalDatasetRunAnalysis;
