/** @jsxImportSource @emotion/react */
import {
  EuiBreadcrumbs,
  EuiButton,
  EuiButtonIcon,
  EuiCheckbox,
  EuiFlexGroup,
  EuiFlexItem,
  EuiIcon,
  EuiPopover,
  EuiPopoverFooter,
  EuiPopoverTitle,
  EuiEmptyPrompt,
  EuiLoadingLogo,
  EuiButtonEmpty,
  EuiSelectableOption,
  EuiSelectable,
  EuiHighlight,
} from "@inscopix/ideas-eui";
import { ICellEditorParams } from "ag-grid-community";
import { FileNotFoundBadge } from "components/FileBadge/FileNotFoundBadge";
import { DataSelectorQuery, Dataset, File as DrsFile } from "graphql/_Types";
import {
  forwardRef,
  ReactNode,
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from "react";
import "./GridEditorToolPathParam.scss";
import {
  AnalysisTableIdentifiers,
  SelectorPathByDrsFileId,
  ToolParamsGridRowDatum,
  ToolPathParam,
  ToolSpec,
} from "./ToolParamsGrid.types";
import assert from "assert";
import { cloneDeep, find, get, isEqual, isUndefined, remove } from "lodash";
import {
  ProjectFile,
  useProjectFilesStore,
} from "stores/project-files/ProjectFilesManager";
import { RecordingsGridColDef } from "../RecordingsGrid/RecordingsGrid.types";
import { isFileFilterMatch } from "./ToolParamsGridProvider.helpers";
import {
  isAnalysisResultColumn,
  isDrsFileColumn,
} from "../RecordingsGrid/RecordingsGrid.helpers";
import { useDataSelectorStyles } from "./DataSelector/useDataSelectorStyles";
import { NoMatchWarning } from "./DataSelector/NoMatchWarning";
import {
  isSelectionDrsFile,
  SelectorPathWithLabel,
  TAnySelectionChildren,
  TAnySelectionItem,
  TColumnSelectionItems,
  TSelectionAnalysisTable,
  TSelectionAnalysisTableTaskResultColumn,
  TSelectionAnalysisTableTaskRow,
  TSelectionDataColumn,
  TSelectionDrsFile,
  TSelectionDataset,
  TSelectionRecording,
  TSelectionAllFiles,
} from "./DataSelector/DataSelector.types";
import { isDefined } from "utils/isDefined";
import { TaskStatus } from "../../types/constants";
import {
  AnalysisTableRowIdentifier,
  AnalysisTableRowIdentifierProps,
  parseAnalysisTableIdentifiers,
} from "../AnalysisTableRowIdentifier/AnalysisTableRowIdentifier";
import { RecordingIdentifierBadge } from "components/RecordingIdentifierBadge/RecordingIdentifierBadge";
import { FileBadge } from "components/FileBadge/FileBadge";
import { FILE_TYPES_BY_ID } from "types/FileTypes";
import { predictColumnSelections } from "./GridEditorToolPathParam.helpers";
import {
  DataSelectorData,
  useDataSelectorData,
} from "./DataSelector/useDataSelectorData";
import { DataSelectorDrsFile } from "./DataSelector/DataSelectorDrsFile";
import { formatDate } from "utils/formatDate";
import { captureException } from "@sentry/react";
import { isNonNull } from "utils/isNonNull";
import { CallOutError } from "components/CallOutError/CallOutError";
import { useGetProjectFilesByFileIds } from "./hooks/useGetProjectFilesByFileIds";
import { useToolParamsGridRowDataContext } from "./ToolParamsGridRowDataProvider";
import { coalesceToStringArray } from "utils/coalesceToStringArray";

interface GridEditorToolPathParamProps extends ICellEditorParams {
  data: ToolParamsGridRowDatum;
  toolParam: ToolPathParam;
}

type TSelection = {
  drsFiles: (TSelectionDrsFile & {
    selectors: SelectorPathWithLabel[];
  })[];
  fileIdsNotFound: string[];
};

type OptionData =
  | { isGroupLabel: true }
  | {
      selection: {
        id: string;
        searchText: string;
        hasMatch: boolean;
        level: number;
        label: ReactNode | string;
        colType: TAnySelectionItem["colType"];
        projectFile?: ProjectFile;
      };
    };

/**
 * A component for editing any `ToolChoiceParam` grid cells
 */
export const GridEditorToolPathParam = forwardRef(
  function GridEditorToolPathParam(
    {
      data,
      stopEditing,
      toolParam,
      column,
      value,
    }: GridEditorToolPathParamProps,
    ref,
  ) {
    const files = useProjectFilesStore((s) => s.files);
    const fileFilters = toolParam.type.file_filters;
    const styles = useDataSelectorStyles();
    const { getProjectFilesByFileIds } = useGetProjectFilesByFileIds();
    const updateRowDatum = useToolParamsGridRowDataContext(
      (s) => s.updateRowDatum,
    );
    const isMultiselectEnabled = toolParam.type.multiple ?? false;

    /**
     * This component queries for its own data when opened so that it
     * always has an up-to-date list of analysis tables rows and dataset columns
     * and for loading the current recording and task row identifiers.
     */

    const { data: queryData, error } = useDataSelectorData();

    /**
     * BUILDING THE COLUMN SELECTION ITEMS
     * This constructs a nested object containing all selection items for the data selector
     */
    const columnSelectionItems: TColumnSelectionItems = useMemo(() => {
      const datasets = queryData?.project?.datasets.nodes ?? [];
      const analysisTables = queryData?.project?.analysisTables.nodes ?? [];

      /**
       * Builds a list of data column selectors and their DrsFile children for a given recording and set of recording table columns
       * @param columns a generic set of columns for a given recordings table - these are reused for each recording row in the table
       * @param recordingId the specific recording ID we want to build the columns for
       * @returns {children, hasMatch} a list of data column selectors with DrsFile children, as well as a boolean to indicate if any match the file filters
       */
      const getDataColumnsWithFiles = (
        columns: Omit<TSelectionDataColumn, "children" | "hasMatch">[],
        recordingId: string,
      ): { children: TSelectionDataColumn[]; hasMatch: boolean } => {
        // we want to track if any of the data columns contain a file that matches the filters
        let anyColumnContainsMatchingFile = false;
        return {
          // to create the DrsFile children for the given recording and table columns, we map over the provided template columns
          children: columns.map((column) => {
            // find matching files by filtering on column and recording
            const matchingFiles = files
              .filter(
                (file) =>
                  file.columns?.some(({ id }) => id === column.id) &&
                  file.recordings?.some(({ id }) => id === recordingId),
              )
              // check if any match the provided file filters
              .map((file) => {
                const hasMatch =
                  fileFilters !== undefined &&
                  fileFilters.some((filter) => isFileFilterMatch(file, filter));

                // if they do, we want to indicate this on the parent columns
                if (hasMatch) {
                  anyColumnContainsMatchingFile = true;
                }

                // return the file column
                return {
                  id: file.id,
                  label: <FileBadge drsFile={file} />,
                  searchText: file.name,
                  colType: "DrsFile",
                  hasMatch,
                  projectFile: file,
                };
              });
            // now that we have our file children, we can add them to the column template and return the selector
            return {
              ...column,
              children: matchingFiles as TSelectionDrsFile[],
              hasMatch: matchingFiles.some(({ hasMatch }) => hasMatch),
            };
          }) as TSelectionDataColumn[],
          hasMatch: anyColumnContainsMatchingFile,
        };
      };

      /**
       * Builds a list of recording group selectors for a given recordings table
       * @param recordingsTable a recordings table query result with recording groups
       * @returns {children, hasMatch} a list of recording column selectors with children, as well as a boolean to indicate if any files match the file filters
       */
      const getRecordingGroupsFromDataset = (
        recordingsTable: NonNullable<
          DataSelectorData["project"]
        >["datasets"]["nodes"][number]["recordingsTable"],
        prefix: Dataset["prefix"],
      ): { children: TSelectionRecording[]; hasMatch: boolean } => {
        if (recordingsTable === null) return { children: [], hasMatch: false };
        else {
          // get all data columns (file columns and result columns) from the table to build a template for the recordings
          const dataColumns: Omit<
            TSelectionDataColumn,
            "children" | "hasMatch"
          >[] = recordingsTable.activeColumns.nodes
            .filter((column) => {
              // formatting this so the type guard is happy - could use cleanup
              const formattedColumn = {
                ...column,
                colDef: column.activeColDef as RecordingsGridColDef,
                colDefMetadatumId: column.colDefId,
              };
              // we don't want to include any metadata columns, just data columns
              return (
                isAnalysisResultColumn(formattedColumn) ||
                isDrsFileColumn(formattedColumn)
              );
            })
            .map((column) => {
              const order = column.orders.nodes[0]?.value;
              const colDef = column.activeColDef as RecordingsGridColDef;
              const type = colDef.type;
              const fileType = type === "drsFile" ? colDef.fileType : undefined;
              // build the label for the column, with icon if applicable
              const label = (
                <div>
                  {"fileType" in colDef && (
                    <span css={styles.iconWrapper}>
                      <EuiIcon
                        size={"s"}
                        type={FILE_TYPES_BY_ID[colDef.fileType].icon}
                      />
                    </span>
                  )}
                  {type === "analysisResult"
                    ? colDef.resultKey
                    : colDef.headerName}
                </div>
              );
              // the columns returned here are incomplete, missing children and hasMatch
              // we'll pass them to the getDataColumnsWithFiles to fill that in for each recording
              return {
                colType: "DataColumn",
                id: column.id,
                searchText:
                  type === "analysisResult"
                    ? colDef.resultKey
                    : colDef.headerName,
                label,
                order,
                fileType,
              } as Omit<TSelectionDataColumn, "children" | "hasMatch">;
            })
            .sort((a, b) => a.order - b.order);

          // we want to track if any of the downstream columns contain a file that matches the filters
          let anyColumnContainsMatchingFile = false;
          return {
            // mapping over all recording nodes, we build a selector for each
            children: recordingsTable.recordingGroups.nodes.map(
              (recordingGroup) => {
                const { children, hasMatch } = getDataColumnsWithFiles(
                  dataColumns,
                  recordingGroup.id,
                );
                if (hasMatch) anyColumnContainsMatchingFile = true;

                return {
                  colType: "RecordingGroup",
                  searchText: recordingGroup.identifier ?? "",
                  label: (
                    <RecordingIdentifierBadge recordingId={recordingGroup.id} />
                  ),
                  ...recordingGroup,
                  children,
                  hasMatch,
                };
              },
            ),
            hasMatch: anyColumnContainsMatchingFile,
          };
        }
      };

      /**
       * Builds a list of dataset selectors
       */
      const datasetSelectionItems = datasets.map(
        ({ id, name, prefix, recordingsTable }) => {
          const { children, hasMatch } = getRecordingGroupsFromDataset(
            recordingsTable,
            prefix,
          );
          return {
            colType: "Dataset",
            id,
            label: name,
            searchText: name,
            children,
            hasMatch,
          };
        },
      ) as TSelectionDataset[];

      /**
       * Builds a list of analysis result column selectors and their file children for a given recording and set of recording table columns
       * @param columns a generic set of columns for a given analysis table - these are reused for each task row in the table
       * @param taskId the specific task ID we want to build the columns for
       * @returns {children, hasMatch} a list of analysis result column selectors with children, as well as a boolean to indicate if any match the file filters
       */
      const getAnalysisResultColumnsWithFiles = (
        columns: Omit<
          TSelectionAnalysisTableTaskResultColumn,
          "children" | "hasMatch"
        >[],
        taskId: string,
      ): {
        children: TSelectionAnalysisTableTaskResultColumn[];
        hasMatch: boolean;
      } => {
        // we want to track if any of the downstream columns contain a file that matches the filters
        let anyColumnContainsMatchingFile = false;
        return {
          children: columns.map((column) => {
            // filter files by task ID and output key
            const matchingFiles = files
              .filter(
                (file) =>
                  file.outputGroup?.task?.id === taskId &&
                  file.key === column.id,
              )
              .map((file) => {
                const hasMatch =
                  fileFilters !== undefined &&
                  fileFilters.some((filter) => isFileFilterMatch(file, filter));

                if (hasMatch) {
                  anyColumnContainsMatchingFile = true;
                }

                return {
                  id: file.id,
                  searchText: file.name,
                  label: <FileBadge drsFile={file} />,
                  colType: "DrsFile",
                  hasMatch,
                  projectFile: file,
                };
              });
            return {
              ...column,
              children: matchingFiles as TSelectionDrsFile[],
              hasMatch: matchingFiles.some(({ hasMatch }) => hasMatch),
            };
          }) as TSelectionAnalysisTableTaskResultColumn[],
          hasMatch: anyColumnContainsMatchingFile,
        };
      };

      /**
       * Builds a list of analysis table row column selectors and their children for a given analysis table
       * @param analysisTable a query result for an analysis table with tool info
       * @returns {children, hasMatch} a list of analysis table row column selectors with children, as well as a boolean to indicate if any match the file filters
       */
      const getTaskRowsFromAnalysisTable = (
        analysisTable: NonNullable<
          DataSelectorQuery["project"]
        >["analysisTables"]["nodes"][number],
      ): { children: TSelectionAnalysisTableTaskRow[]; hasMatch: boolean } => {
        if (analysisTable === null) return { children: [], hasMatch: false };
        else {
          // get all output columns from the spec to build a template for the task row children for this table
          const toolSpec = (analysisTable.toolVersion?.toolSpec ??
            undefined) as ToolSpec | undefined;

          const outputCols: Omit<
            TSelectionAnalysisTableTaskResultColumn,
            "children" | "hasMatch"
          >[] = [];
          if (
            toolSpec &&
            toolSpec.results.length > 0 &&
            isDefined(toolSpec.results[0].files)
          ) {
            // assume only one group result
            const outputGroupResult = toolSpec.results[0];
            // add all object result columns from the group
            for (const fileResult of outputGroupResult.files) {
              outputCols.push({
                colType: "TaskResultColumn",
                id: fileResult.result_key,
                label: fileResult.result_name,
                searchText: fileResult.result_name,
              });
            }
          }
          // we want to track if any of the downstream columns contain a file that matches the filters
          let anyColumnContainsMatchingFile = false;
          return {
            children: analysisTable.rows.nodes
              // only include rows with completed tasks, others won't have files
              .filter(
                ({ taskId, task }) =>
                  taskId !== null &&
                  task &&
                  task.status === TaskStatus.COMPLETE,
              )
              .map((analysisTableRow) => {
                const { children, hasMatch } =
                  getAnalysisResultColumnsWithFiles(
                    outputCols,
                    analysisTableRow.taskId as string,
                  );
                if (hasMatch) anyColumnContainsMatchingFile = true;
                assert(isDefined(toolSpec));
                assert(isNonNull(analysisTableRow.taskId));
                const params =
                  analysisTableRow.data as AnalysisTableRowIdentifierProps["params"];
                const identifierValues = parseAnalysisTableIdentifiers(
                  analysisTable.identifiers as AnalysisTableIdentifiers,
                )
                  .map((key) => {
                    if (key === "task_id") {
                      return undefined;
                    }
                    if (key === "task_date_created") {
                      return formatDate(analysisTableRow.dateCreated);
                    }
                    const toolParam = toolSpec.params.find(
                      (param) => param.key === key,
                    );
                    if (toolParam) {
                      const paramValue = params[key];
                      const displayValue = Array.isArray(paramValue)
                        ? paramValue.join()
                        : String(paramValue);
                      return displayValue;
                    } else {
                      captureException("Unhandled analysis table identifier");
                      return undefined;
                    }
                  })
                  .filter(isDefined);
                return {
                  id: analysisTableRow.taskId,
                  searchText: [
                    analysisTableRow.taskId.substring(0, 5),
                    ...identifierValues,
                  ].join(" "),
                  colType: "Task",
                  label: isNonNull(analysisTableRow.task) && (
                    <AnalysisTableRowIdentifier
                      table={analysisTable}
                      task={analysisTableRow.task}
                      params={params}
                      toolSpec={toolSpec}
                    />
                  ),
                  children,
                  hasMatch,
                };
              }),
            hasMatch: anyColumnContainsMatchingFile,
          };
        }
      };

      /**
       * Builds a list of analysis table selectors
       */
      const analysisTableSelectionItems = analysisTables.map(
        (analysisTable) => {
          const { children, hasMatch } =
            getTaskRowsFromAnalysisTable(analysisTable);
          const analysisTableName = `${analysisTable.group?.name ?? ""} (${
            analysisTable.name ?? ""
          })`;
          return {
            colType: "AnalysisTable",
            id: analysisTable.id,
            label: analysisTableName,
            searchText: analysisTableName,
            children: children,
            hasMatch: hasMatch,
          };
        },
      ) as TSelectionAnalysisTable[];

      const allFilesSelectionItem = (() => {
        const allFiles = files.map((file) => {
          const hasMatch =
            fileFilters !== undefined &&
            fileFilters.some((filter) => isFileFilterMatch(file, filter));
          return {
            id: file.id,
            searchText: file.name,
            label: <FileBadge drsFile={file} />,
            colType: "DrsFile",
            hasMatch,
            projectFile: file,
          };
        });
        return {
          colType: "AllFiles",
          id: "allFiles",
          label: "All Files",
          searchText: "All Files",
          children: allFiles,
          hasMatch: allFiles.some(({ hasMatch }) => hasMatch),
        };
      })() as TSelectionAllFiles;

      return [
        allFilesSelectionItem,
        ...datasetSelectionItems,
        ...analysisTableSelectionItems,
      ];
    }, [queryData, files, fileFilters, styles.iconWrapper]);

    // selections are stored on the row data by param key
    // check to see if any already exist so we can show the full selection path
    const initialSelections: SelectorPathByDrsFileId = (() => {
      const { selections: rowInitialSelections } = data;
      if (rowInitialSelections && toolParam.key in rowInitialSelections) {
        return rowInitialSelections[toolParam.key];
      } else return {};
    })();

    /**
     * Attempts to construct a selection path from stored selection stores for a given file
     * @param id a file ID to fetch a selector path for
     * @returns a selection path array, or an empty array is none wa found
     */
    const getSelectionPathByFileId = (id: string) => {
      // try to find stored selections for this file ID if they exist
      const storedSelections = get(initialSelections, id, []);
      // if there are no stored paths, return the empty array
      if (storedSelections.length === 0) return [];
      else {
        const selectorPath: SelectorPathWithLabel[] = [];
        let items: TAnySelectionChildren = columnSelectionItems;
        // try to find each ID in the path, replacing items with each found parent item's children
        for (let i = 0; i < storedSelections.length; i++) {
          const selectorItem = items.find(
            ({ id }) => id === storedSelections[i].id,
          );
          if (selectorItem !== undefined && !isSelectionDrsFile(selectorItem)) {
            items = selectorItem.children;
            selectorPath.push({
              id: selectorItem.id,
              colType: selectorItem.colType,
              label: selectorItem.label,
            });
          }
          // if we can't find the ID in our items, it may have been moved or deleted since save
          // we break here and return whatever we were able to match (if anything)
          else break;
        }
        return selectorPath;
      }
    };

    /* Initialize the list of selected files.  */

    const initialValue = useRef(() => {
      const { drsFilesFound, fileIdsNotFound } = getProjectFilesByFileIds(
        coalesceToStringArray(value),
      );

      if (drsFilesFound.length > 0 || fileIdsNotFound.length > 0) {
        const drsFileSelectors: (TSelectionDrsFile & {
          selectors: SelectorPathWithLabel[];
        })[] = drsFilesFound.map((file) => ({
          id: file.id,
          colType: "DrsFile",
          searchText: file.name,
          hasMatch:
            fileFilters !== undefined &&
            fileFilters.some((filter) => isFileFilterMatch(file, filter)),
          label: <FileBadge drsFile={file} />,
          selectors: getSelectionPathByFileId(file.id),
          projectFile: file,
        }));

        return {
          drsFiles: drsFileSelectors,
          fileIdsNotFound,
        };
      } else {
        return undefined;
      }
    });

    // store the current state of selections with their selection paths
    const [selections, setSelections] = useState<TSelection | undefined>(
      initialValue.current,
    );

    const [hideNonMatches, setHideNonMatches] = useState<boolean>(true);

    useImperativeHandle(ref, () => ({
      getValue: () => {
        if (selections === undefined) {
          return;
        }

        // Flatten series files into the IDs of their member files
        const fileIdsFound = selections.drsFiles.flatMap((file) =>
          file.projectFile.isSeries
            ? file.projectFile.seriesFiles.map(({ id }) => id)
            : file.id,
        );
        const fileIdsNotFound = selections.fileIdsNotFound;
        return [...fileIdsFound, ...fileIdsNotFound];
      },
    }));

    /**
     * Adds or removes a file to the selection state
     * @param drsFile
     */
    const toggleFileSelection = (drsFile: TSelectionDrsFile) => {
      setSelections((prevSelections) => {
        if (prevSelections === undefined || !isMultiselectEnabled) {
          const selectedFile = find(selections?.drsFiles, {
            id: drsFile.id,
          });
          // if the toggled file was already selected and multiselect is off
          // return undefined so the cell validation logic sees it as unpopulated
          if (selectedFile) return undefined;
          else {
            return {
              drsFiles: [
                {
                  ...drsFile,
                  selectors: getCurrentSelectors(),
                },
              ],
              fileIdsNotFound: [],
            };
          }
        } else {
          const newSelectedFiles = selections ? [...selections.drsFiles] : [];
          const selectedFile = find(selections?.drsFiles, {
            id: drsFile.id,
          });
          if (selectedFile) {
            remove(newSelectedFiles, { id: drsFile.id });
            // if we removed the only selected object, return undefined so the
            // cell validation logic will see this as empty
            if (
              newSelectedFiles.length === 0 &&
              prevSelections.fileIdsNotFound.length === 0
            ) {
              return undefined;
            }
          } else {
            newSelectedFiles.push({
              ...drsFile,
              selectors: getCurrentSelectors(),
            });
          }
          return {
            ...prevSelections,
            drsFiles: newSelectedFiles,
          };
        }
      });
    };

    /**
     * Determines whether a DrsFile is currently selected
     * @param drsFile
     * @returns A `boolean` representing whether the DrsFile is selected
     */
    const isDrsFileSelected = useCallback(
      (drsFile: Pick<DrsFile, "id">) => {
        return (
          selections?.drsFiles.some(({ id }) => id === drsFile.id) ?? false
        );
      },
      [selections?.drsFiles],
    );

    /**
     * Determines whether a selector is currently selected
     * @param level the column level (starting from 0) of the selector
     * @param id the id of the selector to check
     * @returns A `boolean` representing whether the item is selected
     */
    const isItemSelected = (level: number, id: string) => {
      if (level > columnSelections.length || columnSelections.length === 0) {
        return false;
      } else return columnSelections[level] === id;
    };

    // ref so we can control scrolling of the column container
    const columnContainerRef = useRef<null | HTMLDivElement>(null);
    // ref so we can control scrolling of the selected file container
    const selectedFileContainerRef = useRef<null | HTMLDivElement>(null);

    /**
     * Tracking the state of the columns currently viewed
     */
    const [columnSelections, setColumnSelections] = useState<string[]>([]);

    /**
     * Sets the current end column
     * @param level the column index we want to set (starts at 0 but you can't hide the first column)
     * @param id the id of the selector we want visible at that level
     */
    const selectLastColumn = (level: number, id: string) => {
      const currentSelectedCols = [...columnSelections].slice(0, level);
      currentSelectedCols.push(id);
      setColumnSelections(currentSelectedCols);
    };

    /**
     * Sets all columns given a set of selectors
     * @param level the column index we want to set (starts at 0 but you can't hide the first column)
     * @param selectors a list of selectors with ids and labels
     */
    const selectColumnFromSelector = (
      selectors: SelectorPathWithLabel[],
      level: number,
    ) => {
      const newColumnSelections = [];
      for (let i = 0; i <= level; i++) {
        newColumnSelections.push(selectors[i].id);
      }
      setColumnSelections(newColumnSelections);
    };

    /**
     * Helper function to return the current selector path that is visible
     */
    const getCurrentSelectors = () => {
      const currentSelectorPath: SelectorPathWithLabel[] = [];
      let items: TAnySelectionChildren = columnSelectionItems;
      for (let i = 0; i < columnSelections.length; i++) {
        const selectorItem = items.find(({ id }) => id === columnSelections[i]);
        if (selectorItem !== undefined && !isSelectionDrsFile(selectorItem)) {
          items = selectorItem.children;
          currentSelectorPath.push({
            id: selectorItem.id,
            colType: selectorItem.colType,
            label: selectorItem.label,
          });
        }
      }
      return currentSelectorPath;
    };

    /**
     * Helper function to fetch all visible items for a given level
     * @param level the column index to fetch visible items at
     * @returns a list of items currently visible at the provided level
     */
    const getVisibleItemsAtLevel = (level: number) => {
      if (columnSelections.length === 0) return [];

      let items: TAnySelectionChildren = columnSelectionItems;
      for (let i = 0; i <= columnSelections.length; i++) {
        if (i === level) {
          return items;
        } else {
          const item: TAnySelectionItem | undefined = items.find(
            ({ id }) => id === columnSelections[i],
          );
          if (item !== undefined && !isSelectionDrsFile(item)) {
            items = item.children;
          } else return [];
        }
      }
    };

    /**
     * Attempts to keep the rightmost column visible as selections change
     */
    useEffect(() => {
      columnContainerRef.current?.scroll({ left: 100000 });
    }, [columnSelections]);

    /**
     * When adding selected items, will try to scroll to keep the last one visible so
     * that users can observe the change. Does not scroll if the user is removing items.
     */
    const prevSelectionsDrsFilesLengthRef = useRef(0);
    const prevSelectionsMissingFilesLengthRef = useRef(0);
    useEffect(() => {
      if (
        selections &&
        (selections.drsFiles.length > prevSelectionsDrsFilesLengthRef.current ||
          selections?.fileIdsNotFound.length >
            prevSelectionsMissingFilesLengthRef.current)
      ) {
        selectedFileContainerRef.current?.scroll({ top: 100000 });
      }

      prevSelectionsDrsFilesLengthRef.current =
        selections?.drsFiles.length ?? 0;
      prevSelectionsMissingFilesLengthRef.current =
        selections?.fileIdsNotFound.length ?? 0;
    }, [selections]);

    /**
     * Updates the row datum to preserve selection paths
     */
    useEffect(() => {
      const newSavedSelections = data.selections
        ? cloneDeep(data.selections)
        : {};
      if (!(toolParam.key in newSavedSelections)) {
        newSavedSelections[toolParam.key] = {};
      }
      if (isDefined(selections)) {
        for (const selection of selections.drsFiles) {
          newSavedSelections[toolParam.key][selection.id] =
            selection.selectors.map(({ id, colType }) => ({ id, colType }));
        }
      }
      updateRowDatum(data.id, {
        selections: newSavedSelections,
      });
    }, [selections, updateRowDatum, toolParam.key, data.id, data.selections]);

    // for any files not found, we will show these in selections so they can be deselected
    const fileNotFoundDeselectors = selections?.fileIdsNotFound?.map(
      (fileId) => (
        <div key={fileId}>
          <FileNotFoundBadge />
          <EuiButtonIcon
            aria-label="Deselect file"
            iconType={"cross"}
            onClick={() =>
              setSelections((prevSelections) => {
                assert(
                  prevSelections !== undefined,
                  "Expected selections to be undefined",
                );
                return {
                  ...prevSelections,
                  fileIdsNotFound: prevSelections?.fileIdsNotFound.filter(
                    (id) => id !== fileId,
                  ),
                };
              })
            }
          />
        </div>
      ),
    );

    const popoverButton = useMemo(() => {
      const isCompact =
        (selections?.drsFiles?.length ?? 0) +
          (selections?.fileIdsNotFound?.length ?? 0) >
        1;
      return (
        <div
          className="noHover"
          style={{
            width: column.getActualWidth(),
            backgroundColor: "inherit",
            fontWeight: "inherit",
            color: "inherit",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            padding: "0 17px",
            height: "40px",
            textAlign: "center",
            gap: "2px",
          }}
        >
          {selections?.drsFiles.map((selection) => {
            return (
              <FileBadge
                key={selection.id}
                drsFile={selection.projectFile}
                compact={isCompact}
              />
            );
          })}
          {selections?.fileIdsNotFound.map((fileNotFoundId) => (
            <FileNotFoundBadge key={fileNotFoundId} isCompact={isCompact} />
          ))}
        </div>
      );
    }, [column, selections]);

    // Recursively auto-selects columns if there is only one set of valid options
    useEffect(() => {
      const predictedColumnSelections = predictColumnSelections(
        columnSelections,
        columnSelectionItems,
      );

      // Is the prediction different than current set of selected columns?
      const isPredictionNew = !isEqual(
        predictedColumnSelections,
        columnSelections,
      );

      if (isPredictionNew) {
        setColumnSelections(predictedColumnSelections);
      }
    }, [columnSelectionItems, columnSelections, isDrsFileSelected]);

    /**
     * FORMAT DATASET AND ANALSYS TABLE ITEMS AS SELECTABLE OPTIONS
     */
    const tableOptions: Array<EuiSelectableOption<OptionData>> = [
      ...columnSelectionItems
        .filter(({ colType }) => colType === "AllFiles")
        .map(
          (item): EuiSelectableOption<OptionData> => ({
            label: item.searchText,
            searchableLabel: item.searchText,
            selection: {
              id: item.id,
              level: 0,
              searchText: item.searchText,
              colType: item.colType,
              hasMatch: item.hasMatch,
              label: item.label,
            },
            className: isItemSelected(0, item.id) ? "selected" : undefined,
          }),
        ),
      {
        label: "Datasets",
        isGroupLabel: true,
      },
      ...columnSelectionItems
        .filter(({ colType }) => colType === "Dataset")
        .filter(
          ({ id, hasMatch }) =>
            !hideNonMatches || hasMatch || isItemSelected(0, id),
        )
        .map(
          (item): EuiSelectableOption<OptionData> => ({
            label: item.searchText,
            searchableLabel: item.searchText,
            selection: {
              id: item.id,
              level: 0,
              searchText: item.searchText,
              colType: item.colType,
              hasMatch: item.hasMatch,
              label: item.label,
            },
            className: isItemSelected(0, item.id) ? "selected" : undefined,
          }),
        ),
      {
        label: "Analysis Tables",
        isGroupLabel: true,
      },
      ...columnSelectionItems
        .filter(({ colType }) => colType === "AnalysisTable")
        .filter(
          ({ id, hasMatch }) =>
            !hideNonMatches || hasMatch || isItemSelected(0, id),
        )
        .map(
          (item): EuiSelectableOption<OptionData> => ({
            label: item.searchText,
            searchableLabel: item.searchText,
            selection: {
              level: 0,
              searchText: item.searchText,
              colType: item.colType,
              id: item.id,
              hasMatch: item.hasMatch,
              label: item.label,
            },
            className: isItemSelected(0, item.id) ? "selected" : undefined,
          }),
        ),
    ];

    // custom renderer for EuiSelectable
    const renderOption = (
      option: EuiSelectableOption<OptionData>,
      searchValue: string,
    ) => {
      switch (option.selection?.colType) {
        case "RecordingGroup":
        case "Task":
        case "TaskResultColumn":
          return option.selection.label;
        case "DrsFile":
          assert(isDefined(option.selection.projectFile));
          return (
            <DataSelectorDrsFile
              item={option.selection as TSelectionDrsFile}
              isFileSelected={isDrsFileSelected(option.selection.projectFile)}
              toggleFileSelection={toggleFileSelection}
              isMultiselectEnabled={isMultiselectEnabled}
            />
          );
        case "Dataset":
        case "AnalysisTable":
        default:
          return (
            <EuiHighlight search={searchValue}>{option.label}</EuiHighlight>
          );
      }
    };

    return (
      <EuiPopover
        anchorPosition="downCenter"
        button={popoverButton}
        className="Popover PopoverAnchor"
        closePopover={stopEditing}
        display="block"
        isOpen
        panelPaddingSize="xs"
        repositionOnScroll={false}
      >
        <EuiPopoverTitle>
          <EuiFlexGroup>
            <EuiFlexItem grow={false} css={styles.selectedFileTitle}>
              Selected:
            </EuiFlexItem>
            <div css={styles.selectedFiles} ref={selectedFileContainerRef}>
              {selections?.drsFiles.map((selectedFile) => (
                <div
                  key={`selected-${selectedFile.id}`}
                  css={styles.selectedFile}
                >
                  {!selectedFile.hasMatch && <NoMatchWarning />}
                  <EuiBreadcrumbs
                    breadcrumbs={[
                      ...selectedFile.selectors.map((selectorPath, level) => ({
                        text: selectorPath.label,
                        onClick: () =>
                          selectColumnFromSelector(
                            selectedFile.selectors,
                            level,
                          ),
                      })),
                      {
                        text: <FileBadge drsFile={selectedFile.projectFile} />,
                        onClick: () =>
                          selectColumnFromSelector(
                            selectedFile.selectors,
                            selectedFile.selectors.length - 1,
                          ),
                      },
                    ]}
                  />
                  <EuiButtonIcon
                    aria-label="TODO"
                    iconType={"cross"}
                    onClick={() => toggleFileSelection(selectedFile)}
                  />
                </div>
              ))}
              {fileNotFoundDeselectors}
            </div>
            <EuiFlexItem grow={false} css={styles.clearSelections}>
              <EuiButton
                color={"text"}
                size="s"
                onClick={() => {
                  setSelections(undefined);
                }}
                disabled={isUndefined(selections)}
              >
                Clear Selections
              </EuiButton>
            </EuiFlexItem>
          </EuiFlexGroup>
        </EuiPopoverTitle>
        <div ref={columnContainerRef} css={styles.columnSelector}>
          {/* FIRST COLUMN - DATASETS AND ANALYSIS TABLES */}
          {error !== undefined ? (
            <EuiFlexGroup alignItems="center" justifyContent="center">
              <EuiFlexItem grow={false}>
                <CallOutError />
              </EuiFlexItem>
            </EuiFlexGroup>
          ) : queryData === undefined && columnSelectionItems.length === 0 ? (
            <EuiEmptyPrompt
              icon={<EuiLoadingLogo logo="tableDensityNormal" size="xl" />}
              title={<h2>Loading Options</h2>}
            />
          ) : (
            <div css={styles.column} key="level-0">
              <EuiSelectable<OptionData>
                searchable
                singleSelection={true}
                options={tableOptions}
                onChange={(options) => {
                  if (isDefined(options)) {
                    const selectedItem = options.find(
                      (option) => option.checked === "on",
                    );
                    if (selectedItem && isDefined(selectedItem?.selection)) {
                      selectLastColumn(0, selectedItem.selection.id);
                    }
                  }
                }}
                height={"full"}
                searchProps={{
                  placeholder: "",
                  compressed: true,
                  isClearable: true,
                }}
                listProps={{
                  showIcons: false,
                }}
                renderOption={renderOption}
              >
                {(list, search) => (
                  <>
                    {search}
                    {list}
                  </>
                )}
              </EuiSelectable>
            </div>
          )}
          {/* ALL OTHER COLUMNS */}
          {columnSelections.map((parentId, index) => {
            const level = index + 1;
            const items =
              getVisibleItemsAtLevel(level)?.filter(
                ({ id, colType, hasMatch }) =>
                  !hideNonMatches ||
                  hasMatch ||
                  (colType === "DrsFile"
                    ? isDrsFileSelected({ id: id })
                    : isItemSelected(level, id)),
              ) ?? [];

            const isFileColumn =
              items.length > 0 && items[0].colType === "DrsFile";

            // format items as selectable options
            const options = items.map(
              (item): EuiSelectableOption<OptionData> => ({
                label: item.searchText,
                searchableLabel: item.searchText,
                selection: {
                  level,
                  colType: item.colType,
                  searchText: item.searchText,
                  id: item.id,
                  hasMatch: item.hasMatch,
                  label: item.label,
                  projectFile:
                    item.colType === "DrsFile" ? item.projectFile : undefined,
                },
                className: `${
                  isItemSelected(level, item.id) ? "selected" : ""
                } ${item.colType === "DrsFile" ? "fileColumn" : "otherColumn"}`,
              }),
            );
            return (
              <div css={styles.column} key={`level-${level}`}>
                <EuiSelectable<OptionData>
                  searchable
                  options={options}
                  onChange={(options) => {
                    if (isDefined(options)) {
                      const selectedItem = options.find(
                        (option) => option.checked === "on",
                      );
                      if (isDefined(selectedItem)) {
                        assert(isDefined(selectedItem?.selection));
                        if (selectedItem.selection?.colType !== "DrsFile") {
                          assert(level !== undefined);
                          selectLastColumn(
                            selectedItem.selection.level,
                            selectedItem.selection.id,
                          );
                        } else if (
                          selectedItem?.selection.colType === "DrsFile"
                        ) {
                          const selectedFile = {
                            ...selectedItem.selection.projectFile,
                            ...selectedItem.selection,
                            label: selectedItem.label,
                            colType: "DrsFile",
                          } as TSelectionDrsFile;
                          toggleFileSelection(selectedFile);
                        }
                      }
                    }
                  }}
                  height={"full"}
                  searchProps={{
                    placeholder: "",
                    compressed: true,
                    isClearable: true,
                  }}
                  listProps={{
                    showIcons: false,
                    rowHeight: isFileColumn ? 40 : 32,
                  }}
                  renderOption={renderOption}
                >
                  {(list, search) => (
                    <>
                      {search}
                      {list}
                    </>
                  )}
                </EuiSelectable>
              </div>
            );
          })}
        </div>
        <EuiPopoverFooter>
          <EuiFlexGroup alignItems={"center"}>
            <EuiFlexItem>
              <EuiCheckbox
                id={"allVisibleToggle"}
                label="Hide options that do not contain a matching file"
                checked={hideNonMatches}
                onChange={(e) => setHideNonMatches(e.target.checked)}
              />
            </EuiFlexItem>
            <EuiFlexItem grow={true} css={styles.lastButtonContainer}>
              {/* extra div keeps EUI from making the button expand to fill the flex container */}
              <div>
                <EuiButtonEmpty
                  color={"text"}
                  iconType={"cross"}
                  size="s"
                  onClick={() => stopEditing()}
                >
                  Close
                </EuiButtonEmpty>
              </div>
            </EuiFlexItem>
          </EuiFlexGroup>
        </EuiPopoverFooter>
      </EuiPopover>
    );
  },
);
