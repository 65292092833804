import { useUpdatePageDatasetCache } from "components/Dataset/hooks/useUpdatePageDatasetCache";
import { useDatasetDataContext } from "pages/project/dataset/DatasetDataProvider";
import { useCallback } from "react";
import { cloneDeep } from "lodash";
import { DatasetRecordingsTableColumn } from "graphql/_Types";
import assert from "assert";
import { useDatasetRecordingsTableColumnResizeDjango } from "hooks/useDatasetRecordingsTableColumnResizeDjango";
import { useProjectPermissionStore } from "pages/project/ProjectPermissionProvider";

type ResizeColumnParams = {
  column: Pick<DatasetRecordingsTableColumn, "id" | "width">;
};

export const useActionResizeColumn = () => {
  const { dataset, datasetMode } = useDatasetDataContext();
  const { updateCache } = useUpdatePageDatasetCache(dataset.id);
  const { resizeColumn } = useDatasetRecordingsTableColumnResizeDjango();
  const hasEditPermission = useProjectPermissionStore(
    (s) => s.permissions["edit"],
  );

  const getActionDef = useCallback(
    (params: ResizeColumnParams) => {
      return {
        onEnqueue: () => {
          updateCache((prevData) => {
            const newData = cloneDeep(prevData);
            const table = newData.dataset.datasetRecordingsTable;
            const column = table.datasetRecordingsTableColumns.nodes.find(
              ({ id }) => id === params.column.id,
            );
            assert(
              column !== undefined,
              "expected column to be defined in useActionResizeColumn",
            );
            column.width = params.column.width;
            return newData;
          });
        },

        onDequeue: async () => {
          // read only resize on locked dataset
          if (hasEditPermission && datasetMode.type === "current") {
            return await resizeColumn(params.column.id, {
              width: params.column.width,
            });
          }
        },
      };
    },
    [datasetMode.type, hasEditPermission, resizeColumn, updateCache],
  );

  return {
    getActionDef,
    isDisabled: false,
  };
};
