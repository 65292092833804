import axios, { AxiosResponse } from "axios";
import { Notification } from "graphql/_Types";
import { getRequestHeaders } from "utils/getRequestHeaders";
import { getEnvVar } from "ideas.env";
import { TRegion } from "providers/RegionsProvider";
import { groupBy } from "lodash";

export type UpdateNotificationInput = {
  id: Notification["id"];
  has_seen?: boolean;
  region?: TRegion;
};

type UpdateNotificationDjangoResponse = {
  data: {
    id: Notification["id"];
    has_seen?: boolean;
  };
};

type UseUpdateNotificationDjangoResult = {
  __typename: Notification["__typename"];
  id: Notification["id"];
  hasSeen?: Notification["hasSeen"];
};

export const updateNotificationDjango = async (
  input: UpdateNotificationInput,
): Promise<UseUpdateNotificationDjangoResult> => {
  const baseUrl = getEnvVar("URL_NOTIFICATION_NOTIFICATION")(
    input.region?.urlDjango,
  );
  const url = `${baseUrl}${input.id}/`;
  const headers = await getRequestHeaders();
  const response = await axios.patch<
    UpdateNotificationInput,
    UpdateNotificationDjangoResponse
  >(url, input, { headers });

  return {
    __typename: "Notification",
    id: response.data.id,
    hasSeen: response.data.has_seen ?? false,
  };
};

type BulkMarkAllNotificationsAsReadDjangoResponse = {
  id: string;
  has_seen: boolean;
}[];

export type BulkMarkAllNotificationsAsReadDjangoInput = Pick<
  UpdateNotificationInput,
  "id" | "region"
>[];
export const markAllNotificationsAsReadDjango = async (
  input: BulkMarkAllNotificationsAsReadDjangoInput,
) => {
  const notificationsByBaseUrl = groupBy(
    input,
    (notification) => notification.region?.urlDjango ?? getEnvVar("URL_DJANGO"),
  );
  const requests: Promise<
    AxiosResponse<BulkMarkAllNotificationsAsReadDjangoResponse>
  >[] = [];
  const headers = await getRequestHeaders();

  for (const baseUrl in notificationsByBaseUrl) {
    const notifications = notificationsByBaseUrl[baseUrl];
    const url = getEnvVar("URL_NOTIFICATION_BULK_MARK_READ")(baseUrl);
    requests.push(
      axios.patch<
        typeof input,
        AxiosResponse<BulkMarkAllNotificationsAsReadDjangoResponse>
      >(url, notifications, { headers }),
    );
  }

  const res = await Promise.all(requests);
  return res;
};
