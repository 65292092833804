import { EuiLink } from "@inscopix/ideas-eui";
import assert from "assert";
import { NotificationsFeedBodyQuery } from "graphql/_Types";
import { useRouteMapContext } from "providers/RouteMapProvider/RouteMapProvider";
import { isNonNull } from "utils/isNonNull";
import { NotificationAlert } from "./NotificationAlert";
import { TRegion } from "providers/RegionsProvider";
import { isUndefined } from "lodash";
import { RegionBadge } from "components/RegionBadge/RegionBadge";
import { useFlyoutContext } from "providers/FlyoutProvider/FlyoutProvider";

interface NotificationAlertProjectClonedSucceededProps {
  data: NonNullable<
    NotificationsFeedBodyQuery["notifications"]
  >["nodes"][number] & { region?: TRegion };
  onNotificationRead: () => void;
}

/** Component that renders a notification that a project has finished cloning */
export const NotificationAlertProjectClonedSucceeded = ({
  data,
  onNotificationRead,
}: NotificationAlertProjectClonedSucceededProps) => {
  const routeMap = useRouteMapContext((s) => s.routeMap);
  const { closeFlyout } = useFlyoutContext();

  const { dateCreated, hasSeen } = data;
  const attrs = data.projectClonedNotification;

  assert(isNonNull(attrs));
  const { project } = attrs;
  assert(isNonNull(project));
  const projectKey = project.key;
  const tenantKey = project.tenant?.key;
  assert(tenantKey !== undefined);

  const projectPath = routeMap["PROJECT"].dynamicPath({
    projectKey,
    tenantKey: tenantKey,
  }).path;

  const action = (() => {
    const handleClick = () => {
      closeFlyout();
      onNotificationRead();
    };

    return isUndefined(data.region) ? (
      <EuiLink to={projectPath} onClick={() => void handleClick()}>
        Go to project
      </EuiLink>
    ) : (
      <EuiLink
        href={`${data.region.url}${projectPath}`}
        target="_blank"
        onClick={() => void handleClick()}
      >
        Go to project <RegionBadge regionKey={data.region.key} />
      </EuiLink>
    );
  })();

  return (
    <NotificationAlert
      dateCreated={dateCreated}
      hasSeen={hasSeen}
      title="Project copied"
      onNotificationRead={onNotificationRead}
      text={
        <span>
          <strong>{project.name}</strong> has finished copying.
        </span>
      }
      action={action}
    />
  );
};
