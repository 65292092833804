import {
  EuiBadge,
  EuiFlexGroup,
  EuiFlexItem,
  EuiLoadingSpinner,
} from "@inscopix/ideas-eui";
import { useProjectPermissionStore } from "pages/project/ProjectPermissionProvider";
import { useActionQueueStore } from "stores/ActionsQueue/ActionsQueueManager";

const cyTestID = "dataset-actions-queue-status";

export const DatasetActionsQueueStatusIndicator = () => {
  const { status } = useActionQueueStore((s) => s);

  const hasEditPermission = useProjectPermissionStore(
    (s) => s.permissions["edit"],
  );

  if (!hasEditPermission) {
    return (
      <EuiBadge
        data-test-subj={cyTestID}
        color="hollow"
        iconType="lock"
        iconSide="left"
      >
        Read only
      </EuiBadge>
    );
  }

  switch (status) {
    case "error":
      return (
        <EuiBadge
          data-test-subj={cyTestID}
          color="danger"
          iconType="warning"
          iconSide="left"
        >
          Error syncing changes
        </EuiBadge>
      );

    case "synced":
      return (
        <EuiBadge
          data-test-subj={cyTestID}
          color="hollow"
          iconType="check"
          iconSide="left"
        >
          Changes synced
        </EuiBadge>
      );
    case "syncing":
      return (
        <EuiBadge data-test-subj={cyTestID} color="hollow">
          <EuiFlexGroup gutterSize="xs" alignItems="center">
            <EuiFlexItem grow={false}>
              <EuiLoadingSpinner size="s" />
            </EuiFlexItem>
            <EuiFlexItem grow={false}>Syncing changes</EuiFlexItem>
          </EuiFlexGroup>
        </EuiBadge>
      );
  }
};
