import { Dataset, FileRecordingGroup, Tenant } from "graphql/_Types";
import { useDatasetAction } from "hooks/useDatasetAction/useDatasetAction";
import { useCallback } from "react";
import { uuid } from "utils/uuid";

export const useUploadAndAssignFile = ({
  columnId,
  datasetId,
  recordingId,
  tenantId,
}: {
  columnId: string;
  datasetId: Dataset["id"];
  recordingId: FileRecordingGroup["id"];
  tenantId: Tenant["id"];
}) => {
  const uploadFileAction = useDatasetAction("uploadFile");
  const assignFileAction = useDatasetAction("assignFile");

  const uploadAndAssignFile = useCallback(
    (file: File) => {
      const drsFileId = uuid();

      void uploadFileAction.enqueue({
        blob: file,
        id: drsFileId,
        name: file.name,
        datasetId: datasetId,
        tenantId,
        size: String(file.size),
      });

      void assignFileAction.enqueue({
        drsFileId,
        recordingId,
        columnId,
      });
    },
    [
      assignFileAction,
      columnId,
      datasetId,
      recordingId,
      tenantId,
      uploadFileAction,
    ],
  );
  return { uploadAndAssignFile };
};
