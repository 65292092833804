import { EuiButtonIcon } from "@inscopix/ideas-eui";
import { Tooltip } from "components/Tooltip/Tooltip";
import { useDataTableContext } from "pages/gdt/store/DataTableProvider";
import { useState } from "react";
import { addUtilityToastFailure } from "utils/addUtilityToastFailure";
import { addUtilityToastSuccess } from "utils/addUtilityToastSuccess";

interface QuickActionCancelTaskProps {
  tableId: string;
  rowId: string;
}

export const QuickActionCancelTask = ({
  tableId,
  rowId,
}: QuickActionCancelTaskProps) => {
  const cancelTask = useDataTableContext((s) => s.cancelTask);
  const [isLoading, setIsLoading] = useState(false);

  const handleClick = async () => {
    setIsLoading(true);
    const { error } = await cancelTask({ tableId, rowId });
    setIsLoading(false);

    if (error === undefined) {
      addUtilityToastSuccess("Task canceled");
    } else {
      addUtilityToastFailure("Failed to cancel task");
    }
  };

  return (
    <Tooltip content="Cancel task">
      <EuiButtonIcon
        aria-label="Cancel task"
        size="xs"
        iconType="cross"
        color="warning"
        onClick={() => void handleClick()}
        isLoading={isLoading}
      />
    </Tooltip>
  );
};
