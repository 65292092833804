/** @jsxImportSource @emotion/react */
import { EuiImage } from "@inscopix/ideas-eui";
import { MenuVegaMenuClone } from "components/MenuVegaMenuClone/MenuVegaMenuClone";
import { downloadFile } from "utils/downloadFile";
import { FilePreviewComponentProps } from "./FilePreview.types";
import { css } from "@emotion/react";

const styles = {
  wrapper: css`
    // Improve legibility of SVGs with transparent backgrounds
    background-color: white;
  `,
};

/** Component that renders a file preview formatted as an SVG file */
export const FilePreviewSvg = ({ preview }: FilePreviewComponentProps) => {
  return (
    <div style={{ position: "relative" }}>
      <div style={{ position: "absolute", top: 10, right: 10 }}>
        <MenuVegaMenuClone
          menuItems={[
            {
              text: "Save as SVG",
              action: () => {
                const filename = `${preview.name}.svg`;
                void downloadFile(preview.url, filename);
              },
            },
          ]}
        />
      </div>
      <EuiImage
        src={preview.url}
        alt="Preview"
        allowFullScreen
        size="fullWidth"
        style={{
          objectFit: "contain",
          cursor: "zoom-in",
        }}
        wrapperProps={{ css: styles.wrapper }}
      />
    </div>
  );
};
