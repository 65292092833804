/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { EuiFlexItem } from "@inscopix/ideas-eui";
import assert from "assert";
import { FileBadge } from "components/FileBadge/FileBadge";
import {
  FileBrowser,
  FileBrowserFile,
  FileBrowserProps,
} from "components/FileBrowser/FileBrowser";
import { Flyout, FlyoutProps } from "components/Flyout/Flyout";
import { useGetFileBrowserActions } from "components/FlyoutFiles/useGetFileBrowserActions";
import { FileSource } from "graphql/_Types";
import { useDragState } from "hooks/useDragState";
import { useProjectDataContext } from "pages/project/ProjectDataProvider";
import { memo, useCallback, useMemo } from "react";
import { useProjectFilesStore } from "stores/project-files/ProjectFilesManager";
import { isNonNull } from "utils/isNonNull";
import { ProjectDropZone } from "./ProjectDropZone";
import { useProjectLayoutContext } from "./ProjectLayoutProvider";

const fileBrowserZIndex = 100;
const styles = {
  mainFlexContainer: css`
    height: 100%;
    min-height: 0px;
    flex: 4;
    position: relative;
  `,
  dropZone: css`
    display: flex;
    position: absolute;
    bottom: 0;
    z-index: ${fileBrowserZIndex * 2};
    border: 1px dashed lightgray;
    height: 100%;
    width: 100%;
    background-color: white;
    border-radius: 5px;
    align-items: center;
    justify-content: center;
  `,
  fileBrowser: css`
    z-index: ${fileBrowserZIndex};
  `,
};

export type FlyoutProjectFilesProps = Omit<FlyoutProps, "children" | "title">;

export const FlyoutProjectFiles = memo(function FlyoutProjectFiles(
  props: FlyoutProjectFilesProps,
) {
  const { datasets } = useProjectDataContext();
  const files = useProjectFilesStore((s) => s.files);

  const { openModal, openFlyout } = useProjectLayoutContext();

  const { actions } = useGetFileBrowserActions(openModal);

  const { isDraggingFiles } = useDragState();

  const fileBrowserFiles: FileBrowserFile[] = useMemo(
    () =>
      files.map((projectFile) => {
        const source: FileBrowserFile["source"] = (() => {
          if (projectFile.source === FileSource.Uploaded) {
            const formattedAssignments:
              | FileBrowserFile["source"]["assignments"]
              | undefined =
              // only one recording possible for uploaded files
              projectFile.recordings?.[0] !== undefined &&
              projectFile.columns?.[0] !== undefined
                ? [
                    {
                      dataset: projectFile.recordings[0].dataset,
                      columnId: projectFile.columns[0].id,
                      recording: projectFile.recordings[0],
                    },
                  ]
                : undefined;
            return {
              ...projectFile,
              type: FileSource.Uploaded,
              dataset: projectFile.datasets[0],
              assignments: formattedAssignments,
            };
          }

          return {
            ...projectFile,
            type: FileSource.AnalysisResult,
            assignments: projectFile.recordings.map(
              ({ id, number, dataset }) => ({
                dataset,
                recording: { id, number },
              }),
            ),
          };
        })();

        assert(isNonNull(projectFile.user));

        return {
          ...projectFile,
          source,
        };
      }),
    [files],
  );

  const selection = useMemo(() => ({ actions }), [actions]);

  const fileRenderer: NonNullable<FileBrowserProps["fileRenderer"]> =
    useCallback(
      (props) => {
        return (
          <FileBadge
            drsFile={{ ...props.file, source: props.file.source.type }}
            onClick={() => {
              openFlyout({
                type: "fileInfo",
                props: {
                  drsFile: { ...props.file, source: props.file.source.type },
                },
              });
            }}
          />
        );
      },
      [openFlyout],
    );

  return (
    <Flyout {...props} title="File Browser" titleSpacerSize="s">
      <EuiFlexItem css={styles.mainFlexContainer}>
        <div
          css={[
            styles.dropZone,
            !isDraggingFiles &&
              fileBrowserFiles.length > 0 &&
              css`
                display: none !important;
              `,
          ]}
        >
          <ProjectDropZone isClickable={fileBrowserFiles.length === 0} />
        </div>
        {fileBrowserFiles.length > 0 && (
          <FileBrowser
            css={styles.fileBrowser}
            selection={selection}
            defaultSource="UPLOADED"
            fileRenderer={fileRenderer}
            datasetFilter={[
              {
                onFilter: () => true,
                value: "ALL_FILES",
                default: true,
                inputDisplay: "All Files",
              },
              ...datasets.map<FileBrowserProps["datasetFilter"][number]>(
                (dataset) => ({
                  onFilter: (file) =>
                    file.datasets.some(({ id }) => id === dataset.id),
                  value: `DATASET_${dataset.id}`,
                  inputDisplay: dataset.name,
                }),
              ),
              {
                value: "NO_DATASET",
                inputDisplay: "No Dataset Assigned",
                onFilter: (file) => file.datasets.length === 0,
              },
            ]}
            files={fileBrowserFiles}
          />
        )}
      </EuiFlexItem>
    </Flyout>
  );
});
