import {
  EuiButtonEmpty,
  EuiFlexGroup,
  EuiFlexItem,
  EuiLink,
  EuiPanel,
  EuiTable,
  EuiTableBody,
  EuiTableHeader,
  EuiTableHeaderCell,
  EuiTableRow,
  EuiTableRowCell,
  EuiText,
} from "@inscopix/ideas-eui";
import { useProjectDataContext } from "pages/project/ProjectDataProvider";
import { useMemo, useState } from "react";
import ModalDatasetRunAnalysis from "../../ModalDatasetRunAnalysis/ModalDatasetRunAnalysis";
import { useRouteMapContext } from "providers/RouteMapProvider/RouteMapProvider";
import { ButtonToolDocs } from "components/ButtonToolDocs/ButtonToolDocs";
import { AnalysisTableActions } from "components/AnalysisTableActions/AnalysisTableActions";
import { orderBy } from "lodash";
import { isNonNull } from "utils/isNonNull";
import { useShowHiddenAnalysisTables } from "hooks/useShowHiddenAnalysisTables";
import { EmptyTablePrompt } from "components/EmptyTablePrompt/EmptyTablePrompt";
import { ButtonPermissioned } from "components/ButtonPermissioned/ButtonPermissioned";
import { useTenantContext } from "providers/TenantProvider/TenantProvider";
import { AnalysisTableGroupTaskCounts } from "components/AnalysisTableTaskCounts/AnalysisTableGroupTaskCounts";
import { roundToSignificant } from "utils/roundToSignificant";
import { IconComputeCredits } from "components/IconComputeCredits/IconComputeCredits";

export const ProjectPanelAnalysisTable = () => {
  const routeMap = useRouteMapContext((s) => s.routeMap);
  const { analysisTableGroups, project } = useProjectDataContext();
  const [showWorkflowSelectionModal, setShowWorkflowSelectionModal] =
    useState(false);
  const { showHiddenTables, showHiddenTableSwitch } =
    useShowHiddenAnalysisTables(false);
  const currentTenant = useTenantContext((s) => s.currentTenant);

  const sortedGroups = useMemo(
    () =>
      orderBy(
        [...analysisTableGroups],
        ({ dateCreated }) => dateCreated,
        "desc",
      ),
    [analysisTableGroups],
  );

  return (
    <EuiPanel hasBorder={true} paddingSize={"l"}>
      <EuiFlexGroup direction={"column"} gutterSize={"s"}>
        <EuiFlexGroup alignItems={"center"}>
          <EuiFlexItem>
            <EuiText>
              <h3>Analyze</h3>
            </EuiText>
          </EuiFlexItem>
          <EuiFlexItem grow={false}>
            <EuiButtonEmpty
              onClick={() =>
                routeMap.PROJECT_TASKS.dynamicPath({
                  tenantKey: currentTenant.key,
                  projectKey: project.key,
                }).navigateTo()
              }
            >
              View All Tasks
            </EuiButtonEmpty>
          </EuiFlexItem>
          <EuiFlexItem grow={false}>
            <ButtonPermissioned
              fullWidth
              iconType={"plus"}
              onClick={() => {
                setShowWorkflowSelectionModal(true);
              }}
              requiredPermission="edit"
            >
              Analysis Table
            </ButtonPermissioned>
          </EuiFlexItem>
        </EuiFlexGroup>
        <EuiFlexItem>
          <EuiText>
            <p>
              Select from available workflows to transform and analyze your
              data. Analysis tables can run multiple tasks simultaneously.
            </p>
          </EuiText>
        </EuiFlexItem>
        <EuiFlexItem>
          {analysisTableGroups.length > 0 ? (
            <EuiTable tableLayout={"auto"}>
              <EuiTableHeader>
                <EuiTableHeaderCell>Name</EuiTableHeaderCell>
                <EuiTableHeaderCell align="right">Tasks</EuiTableHeaderCell>
                <EuiTableHeaderCell align="right">
                  Compute Credits
                </EuiTableHeaderCell>
                <EuiTableHeaderCell align="right">
                  {showHiddenTableSwitch}
                </EuiTableHeaderCell>
              </EuiTableHeader>

              <EuiTableBody>
                {sortedGroups
                  .filter(
                    (analysisTableGroup) =>
                      showHiddenTables || !analysisTableGroup.hidden,
                  )
                  .map((analysisTableGroup) => {
                    return (
                      <EuiTableRow
                        style={{
                          opacity: analysisTableGroup.hidden ? 0.45 : 1,
                        }}
                        key={`row_${analysisTableGroup.id}`}
                      >
                        <EuiTableRowCell truncateText={false}>
                          <EuiLink
                            to={
                              routeMap[
                                "PROJECT_ANALYSIS_TABLE_GROUP"
                              ].dynamicPath({
                                tenantKey: currentTenant.key,
                                analysisTableGroupId: analysisTableGroup.id,
                                projectKey: project.key,
                              }).path
                            }
                            data-test-subj="analysisTableLink"
                          >
                            {analysisTableGroup.name}
                          </EuiLink>
                        </EuiTableRowCell>
                        <EuiTableRowCell align="right">
                          <AnalysisTableGroupTaskCounts
                            analysisTableGroup={analysisTableGroup}
                          />
                        </EuiTableRowCell>
                        <EuiTableRowCell align="right">
                          <EuiFlexGroup
                            alignItems="center"
                            justifyContent="flexEnd"
                            gutterSize="s"
                          >
                            <EuiText css={{ fontWeight: "normal" }}>
                              {roundToSignificant(
                                analysisTableGroup.usedCredits ?? 0,
                              )}
                            </EuiText>
                            <IconComputeCredits />
                          </EuiFlexGroup>
                        </EuiTableRowCell>
                        <EuiTableRowCell align="right">
                          <EuiFlexGroup
                            gutterSize="s"
                            alignItems="center"
                            responsive={false}
                          >
                            {analysisTableGroup.tool !== null && (
                              <EuiFlexItem grow={false}>
                                <ButtonToolDocs
                                  tool={{
                                    key: analysisTableGroup.tool.key,
                                  }}
                                />
                              </EuiFlexItem>
                            )}
                            <EuiFlexItem>
                              <AnalysisTableActions
                                isHidden={analysisTableGroup.hidden === true} //Currently, "hidden" is nullable on the DB, so this is a workaround
                                analysisTableGroupId={analysisTableGroup.id}
                                analysisTableGroupName={analysisTableGroup.name}
                                hasTasks={
                                  // determine if there are any tasks in the group
                                  analysisTableGroup.analysisTables
                                    .flatMap((table) => table.rows.nodes)
                                    .map((row) => row.task)
                                    .filter(isNonNull).length > 0
                                }
                              />
                            </EuiFlexItem>
                          </EuiFlexGroup>
                        </EuiTableRowCell>
                      </EuiTableRow>
                    );
                  })}
              </EuiTableBody>
            </EuiTable>
          ) : (
            <EmptyTablePrompt message="No analysis tables found" />
          )}
        </EuiFlexItem>
      </EuiFlexGroup>
      {showWorkflowSelectionModal && (
        <ModalDatasetRunAnalysis
          onClose={() => setShowWorkflowSelectionModal(false)}
        />
      )}
    </EuiPanel>
  );
};
