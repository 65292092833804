/** @jsxImportSource @emotion/react */
import { Fragment, memo, useState } from "react";
import { ToolParamsGridRowDatum, ToolSpec } from "./ToolParamsGrid.types";
import { FileBadge } from "components/FileBadge/FileBadge";
import { useResizeDetector } from "react-resize-detector";
import { isNonNull } from "utils/isNonNull";
import { EuiBadge, EuiPopover, EuiSpacer } from "@inscopix/ideas-eui";
import { FlyoutDrsFileInfoProps } from "components/FlyoutDrsFileInfo/FlyoutDrsFileInfo";
import { useFileGridRendererStyles } from "styles/useFileGridRendererStyles/useFileGridRendererStyles";
import { getNumBadgesFit } from "styles/useFileGridRendererStyles/getNumBadgesFit";
import { File as DrsFile } from "graphql/_Types";
import { useProjectFilesStore } from "stores/project-files/ProjectFilesManager";
import { isDefined } from "utils/isDefined";

interface GridRendererSelectRowProps {
  data: Pick<ToolParamsGridRowDatum, "output_group_files">;
  result: ToolSpec["results"][number]["files"][number];
  onClickFile: (
    file: FlyoutDrsFileInfoProps["drsFile"] &
      Pick<
        DrsFile,
        "source" | "projectId" | "seriesParentId" | "processingStatus"
      >,
  ) => void;
}

/**
 * A component for rendering a result from an output group
 */
export const GridRendererAnalysisResult = memo(
  function GridRendererAnalysisResult({
    data,
    result,
    onClickFile,
  }: GridRendererSelectRowProps) {
    const fileGridRendererStyles = useFileGridRendererStyles();
    const { width, ref } = useResizeDetector();
    const [isPopoverVisible, setIsPopoverVisible] = useState(false);
    const togglePopover = () => setIsPopoverVisible((isOpen) => !isOpen);
    const closePopover = () => setIsPopoverVisible(false);

    const files = useProjectFilesStore((s) => s.files);

    const drsFiles = (
      data.output_group_files
        ?.filter(isNonNull)
        .filter(({ key }) => key === result.result_key) ?? []
    )
      .map((file) => files.find((projectFile) => projectFile.id === file.id))
      .filter(isDefined);

    if (drsFiles.length === 1) {
      const handleClick = () => {
        onClickFile(drsFiles[0]);
      };

      return (
        <div css={fileGridRendererStyles.root}>
          <div>
            <FileBadge drsFile={drsFiles[0]} onClick={handleClick} />
          </div>
        </div>
      );
    }

    // Calculate the number of badges that fit in the cell
    const numBadgesFit = getNumBadgesFit(width);

    const isOverflow = numBadgesFit < drsFiles.length;
    const visibleFiles = isOverflow
      ? drsFiles.slice(0, numBadgesFit - 1)
      : drsFiles;

    const button = (
      <div
        onClick={togglePopover}
        css={fileGridRendererStyles.button}
        ref={ref}
      >
        {visibleFiles.map((drsFile) => {
          return <FileBadge key={drsFile.id} drsFile={drsFile} compact />;
        })}
        {isOverflow && (
          <EuiBadge color="hollow">
            +{drsFiles.length - visibleFiles.length}
          </EuiBadge>
        )}
      </div>
    );

    return (
      <EuiPopover
        display="block"
        anchorPosition="downCenter"
        button={button}
        closePopover={closePopover}
        isOpen={isPopoverVisible}
      >
        {drsFiles.map((drsFile, idx, arr) => {
          const isLast = idx === arr.length - 1;

          const handleClick = () => {
            onClickFile(drsFile);
          };

          return (
            <Fragment key={drsFile.id}>
              <FileBadge drsFile={drsFile} onClick={handleClick} />
              {!isLast && <EuiSpacer size="s" />}
            </Fragment>
          );
        })}
      </EuiPopover>
    );
  },
);
