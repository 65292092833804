import { EuiLink } from "@inscopix/ideas-eui";
import assert from "assert";
import { RegionBadge } from "components/RegionBadge/RegionBadge";
import { NotificationsFeedBodyQuery } from "graphql/_Types";
import { useRouteMapContext } from "providers/RouteMapProvider/RouteMapProvider";
import { isUndefined } from "lodash";
import { TRegion } from "providers/RegionsProvider";
import { useCallback } from "react";
import { TENANT_MEMBERSHIP_ROLES_BY_ID } from "types/TenantMembershipRole";
import { isNonNull } from "utils/isNonNull";
import { NotificationAlert } from "./NotificationAlert";
import { useFlyoutContext } from "providers/FlyoutProvider/FlyoutProvider";

export interface NotificationAlertRoleChangeProps {
  data: NonNullable<
    NotificationsFeedBodyQuery["notifications"]
  >["nodes"][number] & { region?: TRegion };
  onNotificationRead: (
    notificationId: string,
    region?: TRegion,
    refetchNotifications?: () => void,
  ) => void;
  refetchNotifications: () => void;
}

/** Component that renders a notification that a user's role has been changed in an organization */
export const NotificationAlertRoleChange = ({
  data,
  onNotificationRead,
  refetchNotifications,
}: NotificationAlertRoleChangeProps) => {
  const routeMap = useRouteMapContext((s) => s.routeMap);
  const { closeFlyout } = useFlyoutContext();
  const { dateCreated, hasSeen, id: notificationId } = data;
  const attrs = data.roleChangeNotification;
  assert(isNonNull(attrs));
  const { role, tenant } = attrs;
  assert(isNonNull(role));
  assert(isNonNull(tenant));
  const tenantKey = tenant.key;
  const tenantName = tenant.name;
  const roleName = TENANT_MEMBERSHIP_ROLES_BY_ID[role]?.name;
  assert(isNonNull(roleName));

  const onNotificationReadMemoized = useCallback(() => {
    if (!hasSeen) {
      onNotificationRead(notificationId, data.region, refetchNotifications);
    }
  }, [
    hasSeen,
    notificationId,
    onNotificationRead,
    refetchNotifications,
    data.region,
  ]);

  const action = (() => {
    const handleClick = () => {
      closeFlyout();
      onNotificationReadMemoized();
    };

    const path = routeMap["ORGANIZATION"].dynamicPath({
      tenantKey,
    }).path;

    return isUndefined(data.region) ? (
      <EuiLink to={path} onClick={handleClick}>
        Go to organization
      </EuiLink>
    ) : (
      <EuiLink
        href={`${data.region.url}${path}`}
        target="_blank"
        onClick={onNotificationReadMemoized}
      >
        Go to organization
        <RegionBadge regionKey={data.region.key} />
      </EuiLink>
    );
  })();

  return (
    <NotificationAlert
      dateCreated={dateCreated}
      hasSeen={hasSeen}
      title="Organization access changed"
      onNotificationRead={onNotificationReadMemoized}
      text={
        <span>
          Your role in the organization <strong>{tenantName}</strong> has been
          changed to <strong>{roleName}</strong>.
        </span>
      }
      action={action}
    />
  );
};
