import { htmlIdGenerator } from "@inscopix/ideas-eui";
import { useEffect, useState } from "react";
import * as fabric from "fabric";
import {
  Circle,
  getPointFromMouseEvent,
  Rect,
  Shape,
  Ellipse,
  Polygon,
  isPointInsideCircle,
  Polyline,
  Line,
  BoundingBox,
  defaultShapeProps,
  RoiShape,
  Contour,
} from "components/RoiEditor/RoiEditor.helpers";
import { Point, ShapeType } from "components/RoiEditor/RoiEditor";
import { isDefined } from "utils/isDefined";
import { captureException } from "@sentry/react";
import { ShapeJson } from "types/ToolRoiFrameParamValue/ToolRoiFrameParamValue";
import assert from "assert";

const CURSORS = {
  DEFAULT: "auto" as const,
  MOVE: "move" as const,
  HOVER: "move" as const,
  DRAWING: "crosshair" as const,
} as const;

/**
 * Helper used for drawing shapes with mouse
 * @param origin The mouse point where the drawing event was first triggered on the canvas
 * @param mousePoint The current mouse point
 * @param groupKey the group key for the shape
 * @returns A fabric object instance
 */

const renderRect = (origin: Point, mousePoint: Point, groupKey: string) => {
  return new Rect(
    { groupKey },
    {
      left: Math.min(origin.x, mousePoint.x),
      top: Math.min(origin.y, mousePoint.y),
      height: Math.abs(mousePoint.y - origin.y),
      width: Math.abs(mousePoint.x - origin.x),
      groupKey,
    },
  );
};

/**
 * Helper used for drawing shapes with mouse
 * @param origin The mouse point where the drawing event was first triggered on the canvas
 * @param mousePoint The current mouse point
 * @param groupKey the group key for the shape
 * @returns A fabric object instance
 */

const renderCircle = (origin: Point, mousePoint: Point, groupKey: string) => {
  const a = origin.x - mousePoint.x;
  const b = origin.y - mousePoint.y;
  // every kid in elementary shool: "I'll never use the pythagorean theorem in real life"
  const c = Math.sqrt(a * a + b * b);
  const radius = c / 2;

  const distanceFromCenterToCornerOfBoundingBox = Math.sqrt(
    radius * radius + radius * radius,
  );
  const distanceFromCircleEdgeToCornerOfBoundingBox =
    distanceFromCenterToCornerOfBoundingBox - radius;
  return new Circle(
    { groupKey },
    {
      left: origin.x - distanceFromCircleEdgeToCornerOfBoundingBox / 2,
      top: origin.y - distanceFromCircleEdgeToCornerOfBoundingBox / 2,
      radius,
    },
  );
};

/**
 * Helper used for drawing shapes with mouse
 * @param origin The mouse point where the drawing event was first triggered on the canvas
 * @param mousePoint The current mouse point
 * @param groupKey the group key for the shape
 * @returns A fabric object instance
 */

const renderEllipse = (origin: Point, mousePoint: Point, groupKey: string) => {
  return new Ellipse(
    { groupKey },
    {
      originX: "left",
      originY: "top",
      top: Math.min(origin.y, mousePoint.y),
      left: Math.min(origin.x, mousePoint.x),
      rx: Math.abs((origin.x - mousePoint.x) / 2),
      ry: Math.abs((origin.y - mousePoint.y) / 2),
    },
  );
};

/**
 * Custom fabric Canvas class
 */
export class Canvas extends fabric.Canvas {
  /**
   * Set and used internally to determine whether a bounding box is present or not
   */
  boundingBox: BoundingBox | undefined;
  /**
   * Optional callback which allows watching shapes for property changes
   */
  onObjectPropertyChanged?: <T extends Shape>(
    object: T,
    property: keyof T,
    value: (typeof object)[typeof property],
  ) => void;

  /**
   * Takes a json object describing a shape and draws it on the canvas
   * @param shape JSON formatted shape to render on canvas
   */
  drawShapeFromJson(shape: ShapeJson) {
    const addRect = (shape: Extract<ShapeJson, { type: "rectangle" }>) => {
      const { groupKey, name, rotation, ...fabricProps } = shape;
      const newShape = new Rect(
        { groupKey, name },
        {
          ...fabricProps,
          angle: rotation,
        },
      );
      // adjust for background image offset due to bounding box
      newShape.top += this.backgroundImage?.top ?? 0;
      newShape.left += this.backgroundImage?.left ?? 0;

      this.add(newShape);
      if (this.onEndDrawing !== undefined) {
        this.onEndDrawing(newShape);
      }
    };

    const addBoundingBox = (
      shape: Extract<ShapeJson, { type: "boundingBox" }>,
    ) => {
      const { groupKey, name, ...fabricProps } = shape;
      this.drawBoundingBox({ groupKey, name }, { ...fabricProps });
    };

    const addCircle = (shape: Extract<ShapeJson, { type: "circle" }>) => {
      const { groupKey, name, center, ...fabricProps } = shape;
      const newShape = new Circle(
        { groupKey, name },
        {
          ...fabricProps,
          /**
           * It would be nice if fabric allowed us to just specify center, rx, and ry
           * which should be sufficient to describe the shape, but without a top and left,
           * fabric will draw it in the top corner.
           */
          // adjust for background image offset due to bounding box
          left: center.x + (this.backgroundImage?.left ?? 0),
          originX: "center",

          // adjust for background image offset due to bounding box
          top: center.y + (this.backgroundImage?.top ?? 0),
          originY: "center",
        },
      );
      this.add(newShape);
      if (this.onEndDrawing !== undefined) {
        this.onEndDrawing(newShape);
      }
    };

    const addEllipse = (shape: Extract<ShapeJson, { type: "ellipse" }>) => {
      const { groupKey, name, center, rotation, ...fabricProps } = shape;
      const newShape = new Ellipse(
        { groupKey, name },
        {
          ...fabricProps,
          angle: rotation,

          /**
           * It would be nice if fabric allowed us to just specify center, rx, and ry
           * which should be sufficient to describe the shape, but without a top and left,
           * fabric will draw it in the top corner.
           */
          // adjust for background image offset due to bounding box
          left: center.x + (this.backgroundImage?.left ?? 0),
          originX: "center",

          // adjust for background image offset due to bounding box
          top: center.y + (this.backgroundImage?.top ?? 0),
          originY: "center",
        },
      );
      this.add(newShape);
      if (this.onEndDrawing !== undefined) {
        this.onEndDrawing(newShape);
      }
    };

    const addPolygon = (shape: Extract<ShapeJson, { type: "polygon" }>) => {
      const { groupKey, name, points, ...fabricProps } = shape;
      const newShape = new Polygon({ groupKey, name }, points, {
        ...fabricProps,
      });

      // adjust for background image offset due to bounding box
      newShape.set({
        left:
          newShape.left +
          (this.backgroundImage?.left ?? 0) -
          newShape.strokeWidth,
        top:
          newShape.top +
          (this.backgroundImage?.top ?? 0) -
          newShape.strokeWidth,
      });

      this.add(newShape);
      if (this.onEndDrawing !== undefined) {
        this.onEndDrawing(newShape);
      }
    };

    const addLine = (shape: Extract<ShapeJson, { type: "line" }>) => {
      const { groupKey, name, points, ...fabricProps } = shape;
      const newShape = new Line({ groupKey, name }, [points[0], points[1]], {
        ...fabricProps,
      });

      // adjust for background image offset due to bounding box
      newShape.set({
        left:
          newShape.left +
          (this.backgroundImage?.left ?? 0) -
          newShape.strokeWidth,
        top:
          newShape.top +
          (this.backgroundImage?.top ?? 0) -
          newShape.strokeWidth,
      });

      this.add(newShape);
      if (this.onEndDrawing !== undefined) {
        this.onEndDrawing(newShape);
      }
    };

    const addContour = (shape: Extract<ShapeJson, { type: "contour" }>) => {
      const { groupKey, name, points, ...fabricProps } = shape;
      const newShape = new Contour({ groupKey, name }, points, {
        ...fabricProps,
      });

      // adjust for background image offset due to bounding box
      newShape.set({
        left:
          newShape.left +
          (this.backgroundImage?.left ?? 0) -
          newShape.strokeWidth,
        top:
          newShape.top +
          (this.backgroundImage?.top ?? 0) -
          newShape.strokeWidth,
      });

      this.add(newShape);
      if (this.onEndDrawing !== undefined) {
        this.onEndDrawing(newShape);
      }
    };

    switch (shape.type) {
      case "rectangle":
        return addRect(shape);
      case "circle":
        return addCircle(shape);
      case "polygon":
        return addPolygon(shape);
      case "ellipse":
        return addEllipse(shape);
      case "boundingBox":
        return addBoundingBox(shape);
      case "contour":
        return addContour(shape);
      case "line":
        return addLine(shape);
    }
  }

  /**
   * Internal method used to manage things like disabling selection and firing appropriate callbacks
   * when the user begins a manual drawing action (drawing a shape on the canvas with a mouse)
   */
  private beginDrawing() {
    this.getObjects().forEach((obj) => (obj.selectable = false));
    this.discardActiveObject();
    this.getActiveObjects().forEach((obj) => obj.onDeselect());
    this.defaultCursor = CURSORS.DRAWING;
    this.hoverCursor = CURSORS.DRAWING;
    this.moveCursor = CURSORS.DRAWING;
    this.selection = false;

    if (this.onBeginDrawing !== undefined) {
      this.onBeginDrawing();
    }

    this.renderAll();
  }

  /**
   * Override add method to handle bounding box and restrict shapes to our custom classes
   * Functionality should be identical for every other shape type
   */
  add(object: Shape): number {
    if (object instanceof BoundingBox) {
      if (
        object.canvas?.getObjects().find((obj) => obj instanceof BoundingBox)
      ) {
        captureException(
          "Attempted to add more than one bounding box to canvas",
        );
        return 0;
      }
      this.boundingBox = object;
    }
    return super.add(object);
  }

  /**
   * Override add method to handle bounding boz
   * Functionality should be identical for every other shape type
   */
  remove(...props: Parameters<fabric.Canvas["remove"]>) {
    props.forEach((object) => {
      if (object instanceof BoundingBox) {
        this.boundingBox = undefined;

        const backgroundImage = this.backgroundImage;
        if (backgroundImage !== undefined) {
          backgroundImage.top = 0;
          backgroundImage.left = 0;

          this.setDimensions({
            height: this.height - 2 * object.strokeWidth * this.getZoom(),
            width: this.width - 2 * object.strokeWidth * this.getZoom(),
          });
        }
      }
    });
    return super.remove(...props);
  }

  setZoom(zoom: number) {
    const canvasDimensions = {
      height: (this.backgroundImage?.height ?? 0) * zoom,
      width: (this.backgroundImage?.width ?? 0) * zoom,
    };

    if (this.boundingBox !== undefined) {
      canvasDimensions.height += 2 * this.boundingBox.strokeWidth * zoom; //(2 * BoundingBox.DEFAULT_STROKE_WIDTH) / zoom; //2 * this.boundingBox.strokeWidth;
      canvasDimensions.width += 2 * this.boundingBox.strokeWidth * zoom; //(2 * BoundingBox.DEFAULT_STROKE_WIDTH) / zoom; // 2 * this.boundingBox.strokeWidth;
    }

    this.setDimensions(canvasDimensions);
    super.setZoom(zoom);
  }

  /**
   * Internal method used to manage things like re-enabling selection and firing appropriate callbacks
   * when the user ends a manual drawing action (drawing a shape on the canvas with a mouse)
   */

  private endDrawing(shape: RoiShape) {
    this.defaultCursor = CURSORS.DEFAULT;
    this.hoverCursor = CURSORS.HOVER;
    this.moveCursor = CURSORS.MOVE;
    this.getObjects().forEach((obj) => (obj.selectable = true));
    this.selection = true;

    this.add(shape);

    if (this.onEndDrawing !== undefined) {
      this.onEndDrawing(shape);
    }

    this.renderAll();
  }

  /**
   * Method on canvas to trigger the manual drawing state for the canvas
   * @param shape type of shape to draw
   * @param groupKey group key of shape
   */
  dragToDraw(
    shape: Extract<ShapeType, "rectangle" | "circle" | "ellipse">,
    groupKey: string,
  ) {
    let shapeDrawn: Rect | Circle | Ellipse;
    let origin: Point;
    let renderShape: (
      origin: Point,
      currentMousePoint: Point,
      groupKey: string,
    ) => Rect | Circle | Ellipse;

    switch (shape) {
      case "circle":
        renderShape = renderCircle;
        break;
      case "ellipse":
        renderShape = renderEllipse;
        break;
      case "rectangle":
        renderShape = renderRect;
        break;
    }

    this.beginDrawing();

    const handleMouseMove = (
      e: fabric.TPointerEventInfo<fabric.TPointerEvent>,
    ) => {
      const currentMousePoint = getPointFromMouseEvent(e);

      if (shapeDrawn !== undefined) {
        this.remove(shapeDrawn);
      }

      shapeDrawn = renderShape(origin, currentMousePoint, groupKey);

      shapeDrawn.set({ selectable: false, isCurrentlyBeingDrawn: true });
      this.add(shapeDrawn);
      this.renderAll();
    };

    const handleMousedown = (
      e: fabric.TPointerEventInfo<fabric.TPointerEvent>,
    ) => {
      origin = getPointFromMouseEvent(e);

      this.on("mouse:move", handleMouseMove);
      this.on("mouse:up", handleMouseUp);
    };
    this.on("mouse:down", handleMousedown);

    const handleMouseUp = (
      e: fabric.TPointerEventInfo<fabric.TPointerEvent>,
    ) => {
      const currentMousePoint = getPointFromMouseEvent(e);

      this.off("mouse:move", handleMouseMove);
      this.off("mouse:up", handleMouseUp);
      this.off("mouse:down", handleMousedown);
      this.remove(shapeDrawn);
      const completeShape = renderShape(origin, currentMousePoint, groupKey);
      this.endDrawing(completeShape);
    };
  }

  drawContour(groupKey: string) {
    this.beginDrawing();
    const pencilBrush = new fabric.PencilBrush(this);
    pencilBrush.width = defaultShapeProps.strokeWidth;
    pencilBrush.color = defaultShapeProps.stroke;
    this.freeDrawingBrush = pencilBrush;
    this.isDrawingMode = true;

    const handlePathCreated = (e: { path: fabric.Path }) => {
      const { path } = e;
      this.remove(path);
      const pathInfo = fabric.util.getPathSegmentsInfo(path.path);
      const contour = new Contour({ groupKey }, pathInfo);
      this.off("path:created", handlePathCreated);
      this.freeDrawingBrush = undefined;
      this.isDrawingMode = false;
      this.endDrawing(contour);
    };

    this.on("path:created", handlePathCreated);
  }

  drawPolygon(groupKey: string) {
    const objects: Shape[] = [];
    let handleMouseMove: (
      e: fabric.TPointerEventInfo<fabric.TPointerEvent>,
    ) => void;
    const points: Point[] = [];
    let originCircle: Circle | undefined;

    const handleMouseMoveForOrigin = (
      e: fabric.TPointerEventInfo<fabric.TPointerEvent>,
    ) => {
      if (originCircle !== undefined) {
        const mousePoint = getPointFromMouseEvent(e);

        const isInsideOriginCircle = isPointInsideCircle(
          mousePoint,
          originCircle,
        );

        if (isInsideOriginCircle) {
          originCircle.set({ fill: "red" });
          this.renderAll();
        } else {
          // avoid re rendering canvas on every mouse move event outside the circle
          if (originCircle.fill === "red") {
            originCircle.set({ fill: "transparent" });
            this.renderAll();
          }
        }
      }
    };

    this.beginDrawing();

    const refreshCanvas = (newObjects: Shape[]) => {
      // remove old objects
      objects.forEach((obj) => this.remove(obj));
      // reset objects to new objects
      objects.splice(0, objects.length);
      objects.push(...newObjects);
      // add new objects to canvas and render
      newObjects.forEach((obj) => this.add(obj));
      this.renderAll();
    };

    const finishDrawing = () => {
      this.off("mouse:down", handleClick);
      this.off("mouse:dblclick", handleDoubleClick);
      this.off("mouse:move", handleMouseMove);
      this.off("mouse:down", handleMousedown);
      this.off("mouse:move", handleMouseMoveForOrigin);

      const polygon = new Polygon(
        { groupKey },
        points.slice(
          0,
          // leave the last point behind because double click will add 2
          points.length - 1,
        ),
      );

      refreshCanvas([]);

      this.endDrawing(polygon);
    };

    // event handlers for objects were not firing as expected
    // so using a roundabout way to detect clicks on circle
    const handleClick = (e: fabric.TPointerEventInfo<fabric.TPointerEvent>) => {
      if (originCircle !== undefined) {
        const point = getPointFromMouseEvent(e);
        const isPointInsideOriginCircle = isPointInsideCircle(
          point,
          originCircle,
        );
        if (isPointInsideOriginCircle) {
          finishDrawing();
        }
      }
    };

    const handleMousedown = (
      e: fabric.TPointerEventInfo<fabric.TPointerEvent>,
    ) => {
      if (handleMouseMove !== undefined) {
        this.off("mouse:move", handleMouseMove);
      }

      const point = getPointFromMouseEvent(e);
      points.push(point);
      if (originCircle === undefined) {
        originCircle = new Circle(
          {},
          {
            strokeWidth: 1,
            top: point.y,
            left: point.x,
            originX: "center",
            originY: "center",
            radius: 6 / this.getZoom(),
          },
        );

        this.on("mouse:down", handleClick);
      }

      const polyline = new Polyline({}, points, {
        selectable: false,
      });

      refreshCanvas([originCircle, polyline]);

      handleMouseMove = (e) => {
        const lastPoint = points[points.length - 1];
        const mousePoint = getPointFromMouseEvent(e);
        const line = new Line({}, [lastPoint, mousePoint], {
          selectable: false,
        });
        refreshCanvas([...[originCircle].filter(isDefined), polyline, line]);
      };
      this.on("mouse:move", handleMouseMove);
    };

    const handleDoubleClick = () => {
      finishDrawing();
    };

    this.on("mouse:move", handleMouseMoveForOrigin);
    this.on("mouse:dblclick", handleDoubleClick);
    this.on("mouse:down", handleMousedown);
  }

  drawLine(groupKey: string) {
    this.beginDrawing();
    const objects: Shape[] = [];
    let origin: Point | undefined;

    const refreshCanvas = (newObjects: Shape[]) => {
      // remove old objects
      objects.forEach((obj) => this.remove(obj));
      // reset objects to new objects
      objects.splice(0, objects.length);
      objects.push(...newObjects);
      // add new objects to canvas and render
      newObjects.forEach((obj) => this.add(obj));
      this.renderAll();
    };

    const finishDrawing = (endPoint: Point) => {
      this.off("mouse:down", handleClick);
      this.off("mouse:move", handleMouseMove);

      assert(
        origin !== undefined,
        "Expected origin to be defined when closing line",
      );
      const line = new Line({ groupKey }, [origin, endPoint]);

      refreshCanvas([]);

      this.endDrawing(line);
    };

    const handleClick = (e: fabric.TPointerEventInfo<fabric.TPointerEvent>) => {
      if (origin === undefined) {
        origin = getPointFromMouseEvent(e);
      } else {
        finishDrawing(getPointFromMouseEvent(e));
      }
    };

    const handleMouseMove = (
      e: fabric.TPointerEventInfo<fabric.TPointerEvent>,
    ) => {
      // do don't draw a placeholder line until origin has been selected
      if (origin === undefined) {
        return;
      }
      const mousePoint = getPointFromMouseEvent(e);
      const line = new Line({}, [origin, mousePoint], {
        selectable: false,
      });
      refreshCanvas([line]);
    };
    this.on("mouse:down", handleClick);
    this.on("mouse:move", handleMouseMove);
  }

  onBeginDrawing: (() => void) | undefined = undefined;
  onEndDrawing: ((shape: RoiShape) => void) | undefined = undefined;

  /**
   * Draws a bounding box on the canvas. Updates existing instance if exists
   * or creates new bounding box if one does not exist on canvas
   * @param properties optional dimensions of bounding box. Assumes full size of image if omitted
   */
  drawBoundingBox(
    { groupKey, name }: { groupKey: string; name: string },
    properties?: {
      top?: number;
      left?: number;
      height?: number;
      width?: number;
    },
  ) {
    const scale = this.getZoom();

    // bounding box is made larger so the stroke doesn't cover the image
    // this adjusts the size of the box compared to what we display to the user

    const existingBoundBox = this.boundingBox;

    if (existingBoundBox !== undefined) {
      // bounding box already exists
      if (properties === undefined) {
        // no properties specified, so reset bounding box
        existingBoundBox.set({
          top: 0,
          left: 0,
          height:
            (this.backgroundImage?.height ?? 0) + existingBoundBox.strokeWidth,
          width:
            (this.backgroundImage?.width ?? 0) + existingBoundBox.strokeWidth,
        });
      } else {
        existingBoundBox.set({
          ...properties,
          height:
            properties.height !== undefined
              ? properties.height + existingBoundBox.strokeWidth
              : undefined,
          width:
            properties.width !== undefined
              ? properties.width + existingBoundBox.strokeWidth
              : undefined,
        });
      }
    } else {
      const defaultProps = {
        top: 0,
        left: 0,
        height:
          (this.backgroundImage?.height ?? 0) +
          BoundingBox.DEFAULT_STROKE_WIDTH / scale,
        width:
          (this.backgroundImage?.width ?? 0) +
          BoundingBox.DEFAULT_STROKE_WIDTH / scale,
      };

      // bounding box does not exist, create default and attach to canvas
      const newBox = new BoundingBox(
        { name, groupKey },
        properties !== undefined
          ? {
              ...properties,
              height:
                properties.height !== undefined
                  ? properties.height + BoundingBox.DEFAULT_STROKE_WIDTH / scale
                  : undefined,
              width:
                properties.width !== undefined
                  ? properties.width + BoundingBox.DEFAULT_STROKE_WIDTH / scale
                  : undefined,
            }
          : { ...defaultProps },
      );

      const backgroundImage = this.backgroundImage;
      if (backgroundImage !== undefined) {
        backgroundImage.top += newBox.strokeWidth / scale;
        backgroundImage.left += newBox.strokeWidth / scale;

        this.setDimensions({
          height: this.height + 2 * newBox.strokeWidth,
          width: this.width + 2 * newBox.strokeWidth,
        });
      }

      this.add(newBox);
      if (this.onEndDrawing !== undefined) {
        this.onEndDrawing(newBox);
      }
    }
    this.renderAll();
  }
}

export interface RoiEditorCanvasProps {
  backgroundImage: fabric.Image;
  onCanvasReady?: (canvas: Canvas) => void;
}

export const RoiEditorCanvas = ({
  backgroundImage,
  onCanvasReady,
}: RoiEditorCanvasProps) => {
  const [canvasId] = useState(htmlIdGenerator("canvas"));

  const [isReady, setIsReady] = useState(false);

  useEffect(() => {
    if (!isReady) {
      const newCanvas = new Canvas(canvasId, {
        height: backgroundImage.height,
        width: backgroundImage.width,
        backgroundImage,
        preserveObjectStacking: true,
        uniformScaling: false,
      });

      if (onCanvasReady !== undefined) {
        onCanvasReady(newCanvas);
      }

      setIsReady(true);
    }
  }, [backgroundImage, canvasId, isReady, onCanvasReady]);

  return <canvas id={canvasId} />;
};
