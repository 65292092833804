/** @jsxImportSource @emotion/react */

import { css } from "@emotion/react";
import { CroppingCoordinates } from "components/CroppingFrameSelector/CroppingFrameSelector";
import { parseCroppingFrameParamData } from "components/CroppingFrameSelector/CroppingFrameSelector.helpers";
import { isNonNullish } from "utils/isNonNullish";
import { ModalToolCroppingFrameParamProps } from "../ModalToolCroppingFrameParam/ModalToolCroppingFrameParam";
import {
  ToolParamsGridRowDatum,
  ToolParamValue,
} from "../ToolParamsGrid.types";
import { ButtonToolCroppingFrameParam } from "./ButtonToolCroppingFrameParam";
import { useValueValidator } from "../ToolParamsGridValueValidatorContext";
import { isDefined } from "utils/isDefined";
import { isEqual } from "lodash";

export interface GridRendererToolCroppingFrameParamProps
  extends Pick<ModalToolCroppingFrameParamProps, "toolParam"> {
  rowId: ToolParamsGridRowDatum["id"];
  value: ToolParamValue;
}

export const GridRendererToolCroppingFrameParam = ({
  toolParam,
  rowId,
  value,
}: GridRendererToolCroppingFrameParamProps) => {
  const validationError = useValueValidator(
    (s) => s.errorsByRowId[rowId]?.[toolParam.key],
    isEqual,
  );

  let isInvalidCoordinates = false;
  let coordinates: CroppingCoordinates | undefined;

  if (isNonNullish(value)) {
    try {
      coordinates = parseCroppingFrameParamData(value);
    } catch (err) {
      isInvalidCoordinates = true;
    }
  }

  return (
    <div
      css={css`
        background-color: ${isDefined(validationError)
          ? "rgba(255, 0, 0, 0.05)"
          : "inherit"};
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 100%;
      `}
    >
      <span
        css={css`
          overflow: hidden;
          text-overflow: ellipsis;
          margin-right: 5px;
        `}
      >
        {(value ?? "").toString()}
      </span>

      <ButtonToolCroppingFrameParam
        initialCroppingCoordinates={coordinates}
        isInvalidInitialCoordinates={isInvalidCoordinates}
        rowId={rowId}
        toolParam={toolParam}
      />
    </div>
  );
};
