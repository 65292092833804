import {
  DatasetRecordingsTableColumn,
  File as DrsFile,
  FileRecordingGroup,
} from "graphql/_Types";
import { useDatasetDataContext } from "pages/project/dataset/DatasetDataProvider";
import { useCallback } from "react";
import { cloneDeep } from "lodash";
import assert from "assert";
import { useRecordingGroupDrsFilesEndAssignmentDjango } from "hooks/useRecordingGroupDrsFilesEndAssignmentDjango";
import { updateCacheFragment } from "utils/cache-fragments";
import { useProjectFilesStore } from "stores/project-files/ProjectFilesManager";
import { useProjectPermissionStore } from "pages/project/ProjectPermissionProvider";

type UnassignFileParams = {
  columnId: DatasetRecordingsTableColumn["id"];
  drsFileId: DrsFile["id"];
  recordingId: FileRecordingGroup["id"];
};

export const useActionUnassignFile = () => {
  const { datasetMode, project } = useDatasetDataContext();
  const { recordingGroupDrsFileEndAssignment } =
    useRecordingGroupDrsFilesEndAssignmentDjango();

  const projectFiles = useProjectFilesStore(({ files }) => files);
  const hasEditPermission = useProjectPermissionStore(
    (s) => s.permissions["edit"],
  );

  const getActionDef = useCallback(
    (params: UnassignFileParams) => {
      const assignmentId = projectFiles.find(
        (projectFile) => projectFile.id === params.drsFileId,
      )?.activeAssignment?.id;
      const dateAssignmentEnded = new Date().toISOString();

      return {
        onEnqueue: () => {
          updateCacheFragment({
            __typename: "File",
            id: params.drsFileId,
            update: (data) => {
              const newData = cloneDeep(data);
              newData.assignment = null;
              return newData;
            },
          });

          updateCacheFragment({
            __typename: "Project",
            id: project.id,
            update: (data) => {
              const newData = { ...data };
              newData.dateLastActive = dateAssignmentEnded;
              return newData;
            },
          });
        },

        onDequeue: async () => {
          assert(
            assignmentId !== undefined,
            "Expected existing assignment to exist in project file",
          );

          return await recordingGroupDrsFileEndAssignment(assignmentId, {
            date_assignment_ended: dateAssignmentEnded,
          });
        },
      };
    },
    [project.id, projectFiles, recordingGroupDrsFileEndAssignment],
  );

  const isDisabled = datasetMode.type !== "current" || !hasEditPermission;

  return {
    getActionDef,
    isDisabled,
  };
};
