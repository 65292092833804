import {
  CellStyle,
  Column,
  ICellRendererParams,
  ITooltipParams,
  ValueFormatterParams,
  ValueGetterParams,
} from "ag-grid-community";
import { get, groupBy, intersection, sortBy } from "lodash";
import { isDefined } from "utils/isDefined";
import { useToolParamsGridContext } from "../ToolParamsGridProvider";
import { useCallback, useMemo } from "react";
import {
  ToolBooleanParam,
  ToolChoiceParam,
  ToolCroppingFrameParam,
  ToolFloatRangeParam,
  ToolIntRangeParam,
  ToolParam,
  ToolParamsGridCellEditRequestNewValue,
  ToolParamsGridColDef as ColDef,
  ToolParamsGridColGroupDef as ColGroupDef,
  ToolParamsGridRowDatum as RowDatum,
  ToolParamsGridRowDatum,
  ToolParamValue,
  ToolPathParam,
  ToolRoiFrameParam,
  ToolStringParam,
  ToolCellStatusParam,
  ResourceTierColDef,
  RESOURCE_TIER_COL,
  TASK_INFO_COLS,
  ToolSpec,
} from "../ToolParamsGrid.types";
import {
  isToolBooleanParam,
  isToolCellStatusParam,
  isToolChoiceParam,
  isToolCroppingFrameParam,
  isToolFloatRangeParam,
  isToolIntRangeParam,
  isToolPathParam,
  isToolRoiFrameParam,
  isToolStringParam,
} from "../ToolParamsGrid.helpers";
import { GridRendererToolParam } from "../GridRendererToolParam/GridRendererToolParam";
import { GridEditorToolChoiceParam } from "../GridEditorToolChoiceParam";
import {
  GridRendererToolPathParam,
  GridRendererToolPathParamProps,
} from "../GridRendererToolPathParam";
import { useValueValidator } from "components/ToolParamsGrid/ToolParamsGridValueValidatorContext";
import { useEuiTheme } from "@inscopix/ideas-eui";
import { GridRendererSelectRow } from "../GridRendererSelectRow";
import { GridRendererRowControls } from "../GridRendererRowControls";
import { GridEditorToolPathParam } from "../GridEditorToolPathParam";
import { SetNonNullable, SetRequired } from "type-fest";
import { AgGridReactProps } from "ag-grid-react";
import { GridEditorToolBooleanParam } from "../GridEditorToolBooleanParam";
import assert from "assert";
import { GridRendererAnalysisResult } from "../GridRendererAnalysisResult";
import { ButtonViewTaskLogs } from "components/ButtonViewTaskLogs/ButtonViewTaskLogs";
import { GridRendererToolChoiceParam } from "../GridRendererToolChoiceParam";
import { GridRendererAttachResults } from "../GridRendererAttachResults";
import { GridRendererTaskShortId } from "../GridRendererTaskShortId";
import { GridRendererRowIdentifier } from "../GridRendererRowIdentifier";
import { ToolParamsGridIdentifierHeader } from "../ToolParamsGridIdentifierHeader";
import { Interval } from "graphql/_Types";
import { roundToSignificant } from "utils/roundToSignificant";
import { GridRendererToolCroppingFrameParam } from "../GridRendererToolCroppingFrameParam/GridRendererToolCroppingFrameParam";
import { isNonNullish } from "utils/isNonNullish";
import { formatInterval } from "utils/formatInterval";
import { GridRendererRecording } from "../GridRendererRecording";
import { useAnalysisTableLayoutContext } from "pages/project/analysis/AnalysisTableLayoutProvider";
import { HeaderComponentSelectRow } from "../HeaderComponentSelectRow";
import { COL_ID_CHECKBOX } from "../AnalysisTableConstants";
import { GridRendererToolRoiFrameParam } from "../GridRendererToolRoiFrameParam/GridRendererToolRoiFrameParam";
import { GridRendererToolBooleanParam } from "../GridRendererToolBooleanParam";
import { useProjectFilesStore } from "stores/project-files/ProjectFilesManager";
import { GridEditorToolVersion } from "../GridEditorToolVersion";
import { useToolParamsGridRowDataContext } from "../ToolParamsGridRowDataProvider";
import { formatDate } from "utils/formatDate";
import { EuiBadgeMemo } from "../EuiBadgeMemo";
import { GridRendererToolCellStatusParam } from "../GridRendererToolCellStatusParam/GridRendererToolCellStatusParam";
import { GridRendererTaskUser } from "../GridRendererTaskUser";
import {
  formatToolResources,
  GridEditorTierResourceChoiceParam,
} from "../GridEditorResourceTierChoice";
import { ResourceTier, RESOURCE_TIERS } from "types/ResourceTiers";
import { useProjectPermissionStore } from "pages/project/ProjectPermissionProvider";
import { captureException } from "@sentry/react";
import { coalesceToStringArray } from "utils/coalesceToStringArray";

export type ToolParamsGridCellRendererParams = SetNonNullable<
  ICellRendererParams<ToolParamsGridRowDatum>,
  "data"
> & { column: Column<ToolParamsGridRowDatum> };

/**
 * A hook for accessing the tool param grid column defs
 */
export const useColumnDefs = () => {
  const { toolSpec, toolVersions } = useToolParamsGridContext();
  const updateRowDatum = useToolParamsGridRowDataContext(
    (s) => s.updateRowDatum,
  );

  const { openFlyout } = useAnalysisTableLayoutContext();
  const getRowDatumErrors = useValueValidator((s) => s.getRowDatumErrors);
  const { euiTheme } = useEuiTheme();
  const hasEditPermission = useProjectPermissionStore(
    (s) => s.permissions["edit"],
  );
  const projectFiles = useProjectFilesStore((s) => s.files);

  const openFileFlyoutMemoized: GridRendererToolPathParamProps["onClickFileBadge"] =
    useCallback(
      (drsFile) => openFlyout({ type: "fileInfo", props: { drsFile } }),
      [openFlyout],
    );

  const createToolParamColDef = useCallback(
    (toolParam: ToolParam) => {
      /**
       * Persists new cell values to the provider state
       * @param newValue
       * @param rowDatum
       * @returns The new value sent to the provider state
       */
      const handleCellChange = (
        newValue: ToolParamValue | null,
        rowDatum: RowDatum,
      ) => {
        // coerce null values from keyboard delete back to undefined
        const parsedValue = newValue ?? undefined;
        updateRowDatum(rowDatum.id, {
          params: {
            [toolParam.key]: parsedValue,
          },
          // Remove metadatum reference when value changes outside the
          // metadatum reference selector
          metadatumReferences: {
            [toolParam.key]: undefined,
          },
        });
        return parsedValue;
      };

      const colDef: SetNonNullable<
        SetRequired<ColDef, "onCellEdit" | "onCellFill" | "onCellPaste">,
        "onCellFill"
      > = {
        cellRenderer: (props: ToolParamsGridCellRendererParams) => {
          return (
            <GridRendererToolParam
              {...props}
              rowId={props.data.id}
              toolParam={toolParam}
              defaultValue={toolParam.default}
            />
          );
        },
        cellStyle: { padding: 5 },
        colId: toolParam.key,
        editable: ({ data }) => {
          const isUnexecuted = data?.task_status === undefined;
          return isUnexecuted && hasEditPermission;
        },
        valueGetterField: `params.${toolParam.key}`,
        headerName: toolParam.name,
        headerTooltip: toolParam.help,
        onCellEdit: handleCellChange,
        onCellFill: handleCellChange,
        onCellPaste: handleCellChange,
        suppressPaste: ({ data }) => data?.task_status !== undefined,
        suppressFillHandle: false,
        suppressNavigable: false,
        tooltipValueGetter: ({ data }) => {
          assert(
            data !== undefined,
            "Expected row datum to be defined it toolParam tooltipValueGetter",
          );
          const errorsByParamKey = getRowDatumErrors(data.id);
          return errorsByParamKey?.[toolParam.key]?.message;
        },
      };

      return colDef;
    },
    [getRowDatumErrors, hasEditPermission, updateRowDatum],
  );

  const createToolBooleanParamColDef = useCallback(
    (toolParam: ToolBooleanParam) => {
      const baseColDef = createToolParamColDef(toolParam);

      /**
       * Converts a value to a boolean
       * @param newValue
       * @param oldValue
       * If the value parses to a valid boolean, returns the value as a boolean.
       * Otherwise, returns the old value.
       */
      const convertValueToBoolean = (
        newValue: ToolParamsGridCellEditRequestNewValue,
        oldValue: ToolParamValue,
      ) => {
        const valueString = (newValue ?? "").toString().toLowerCase().trim();
        switch (valueString) {
          case "true":
            return true;
          case "false":
            return false;
          default:
            return oldValue;
        }
      };

      const colDef: ColDef = {
        ...baseColDef,
        cellRenderer: (props: ToolParamsGridCellRendererParams) => {
          return (
            <GridRendererToolBooleanParam
              rowId={props.data.id}
              {...props}
              toolParam={toolParam}
            />
          );
        },
        cellEditor: GridEditorToolBooleanParam,
        cellEditorParams: { toolParam },
        cellEditorPopup: true,
        onCellEdit: baseColDef.onCellEdit,
        onCellFill: (newValue, rowDatum) => {
          const oldValue = rowDatum.params[toolParam.key];
          const formattedValue = convertValueToBoolean(newValue, oldValue);
          return baseColDef.onCellFill(formattedValue, rowDatum);
        },
        onCellPaste: (newValue, rowDatum) => {
          const oldValue = rowDatum.params[toolParam.key];
          const formattedValue = convertValueToBoolean(newValue, oldValue);
          return baseColDef.onCellPaste(formattedValue, rowDatum);
        },
      };

      return colDef;
    },
    [createToolParamColDef],
  );

  const createToolChoiceParamColDef = useCallback(
    (toolParam: ToolChoiceParam) => {
      const baseColDef = createToolParamColDef(toolParam);

      /**
       * Checks if a value is one of the choices in the tool param
       * @param newValue
       * @param oldValue
       * @returns
       * If the value is a valid tool param choice, returns the new value.
       * Otherwise, returns the old value.
       */
      const checkValueIsChoice = (
        newValue: ToolParamsGridCellEditRequestNewValue,
        oldValue: ToolParamValue,
      ) => {
        const valueString = (newValue ?? "").toString();
        const valueFloat = parseFloat(valueString);
        const valueInt = parseInt(valueString);

        switch (toolParam.type.param_datatype) {
          case "float":
            if (toolParam.type.choices.includes(valueFloat)) {
              return valueFloat;
            }
            break;
          case "integer":
            if (toolParam.type.choices.includes(valueInt)) {
              return valueInt;
            }
            break;
          case "string":
            if (toolParam.type.choices.includes(valueString)) {
              return valueString;
            }
            break;
        }

        return oldValue;
      };

      const colDef: ColDef = {
        ...baseColDef,
        cellEditor: GridEditorToolChoiceParam,
        cellEditorParams: { toolParam },
        cellEditorPopup: true,
        cellRenderer: (props: ToolParamsGridCellRendererParams) => {
          return (
            <GridRendererToolChoiceParam
              rowId={props.data.id}
              {...props}
              toolParam={toolParam}
            />
          );
        },
        onCellEdit: baseColDef.onCellEdit,
        onCellFill: (newValue, rowDatum) => {
          const oldValue = rowDatum.params[toolParam.key];
          const formattedValue = checkValueIsChoice(newValue, oldValue);
          return baseColDef.onCellFill(formattedValue, rowDatum);
        },
        onCellPaste: (newValue, rowDatum) => {
          const oldValue = rowDatum.params[toolParam.key];
          const formattedValue = checkValueIsChoice(newValue, oldValue);
          return baseColDef.onCellPaste(formattedValue, rowDatum);
        },
      };

      return colDef;
    },
    [createToolParamColDef],
  );

  const createToolFloatRangeParamColDef = useCallback(
    (toolParam: ToolFloatRangeParam) => {
      const baseColDef = createToolParamColDef(toolParam);

      /**
       * Converts a value to a float
       * @param value
       * @returns
       * If the value parses to a valid float, returns the value as a float.
       * Otherwise, returns the value as a string.
       */
      const convertValueToFloat = (
        value: ToolParamsGridCellEditRequestNewValue,
      ) => {
        const valueString = (value ?? "").toString();
        if (valueString === "") {
          return undefined;
        }
        const valueFloat = parseFloat(Number(valueString).toString());

        if (isFinite(valueFloat)) {
          return valueFloat;
        } else {
          return valueString;
        }
      };

      const colDef: ColDef = {
        ...baseColDef,
        onCellEdit: (newValue, rowDatum) => {
          const formattedValue = convertValueToFloat(newValue);
          return baseColDef.onCellEdit(formattedValue, rowDatum);
        },
        onCellFill: (newValue, rowDatum) => {
          const formattedValue = convertValueToFloat(newValue);
          return baseColDef.onCellFill(formattedValue, rowDatum);
        },
        onCellPaste: (newValue, rowDatum) => {
          const formattedValue = convertValueToFloat(newValue);
          return baseColDef.onCellPaste(formattedValue, rowDatum);
        },
      };

      return colDef;
    },
    [createToolParamColDef],
  );

  const createToolIntRangeParamColDef = useCallback(
    (toolParam: ToolIntRangeParam) => {
      const baseColDef = createToolParamColDef(toolParam);

      /**
       * Converts a value to an integer
       * @param value
       * @returns
       * If the value parses to a valid integer, returns the value as a integer.
       * Otherwise, returns the value as a string.
       */
      const convertValueToInt = (
        value: ToolParamsGridCellEditRequestNewValue,
      ) => {
        const valueString = (value ?? "").toString();
        if (valueString === "") {
          return undefined;
        }
        const valueInt = parseInt(Number(valueString).toString());
        if (isFinite(valueInt)) {
          return valueInt;
        } else {
          return valueString;
        }
      };

      const colDef: ColDef = {
        ...baseColDef,
        onCellEdit: (newValue, rowDatum) => {
          const formattedValue = convertValueToInt(newValue);
          return baseColDef.onCellEdit(formattedValue, rowDatum);
        },
        onCellFill: (newValue, rowDatum) => {
          const formattedValue = convertValueToInt(newValue);
          return baseColDef.onCellFill(formattedValue, rowDatum);
        },
        onCellPaste: (newValue, rowDatum) => {
          const formattedValue = convertValueToInt(newValue);
          return baseColDef.onCellPaste(formattedValue, rowDatum);
        },
      };

      return colDef;
    },
    [createToolParamColDef],
  );

  const createToolCroppingFrameParamColDef = useCallback(
    (toolParam: ToolCroppingFrameParam) => {
      const baseColDef = createToolParamColDef(toolParam);
      /**
       * Converts a value to a string
       * @param value
       * @returns The value as a string or `undefined` if it parses to an empty string
       */
      const convertValueToString = (
        value: ToolParamsGridCellEditRequestNewValue,
      ) => {
        const valueString = (value ?? "").toString();
        return valueString !== "" ? valueString : undefined;
      };

      const colDef: ColDef = {
        ...baseColDef,
        cellRenderer: ({
          data,
          value,
        }: ICellRendererParams<ToolParamsGridRowDatum>) => {
          assert(data !== undefined, "Expected row datum to be defined");

          return (
            <GridRendererToolCroppingFrameParam
              /**
               * The cell renderer reads row data to get cell values from different cells
               * because re renders are only triggered by the grid when the value for that cell changes,
               * we pass the row id and select the row from the context in the renderer if it needs to subscribe
               * to all row data changes
               */
              value={value as ToolParamValue}
              rowId={data.id}
              toolParam={toolParam}
            />
          );
        },
        onCellEdit: (newValue, rowDatum) => {
          const formattedValue = convertValueToString(newValue);
          return baseColDef.onCellEdit(formattedValue, rowDatum);
        },
        onCellFill: (newValue, rowDatum) => {
          const formattedValue = convertValueToString(newValue);
          return baseColDef.onCellFill(formattedValue, rowDatum);
        },
        onCellPaste: (newValue, rowDatum) => {
          const formattedValue = convertValueToString(newValue);
          return baseColDef.onCellPaste(formattedValue, rowDatum);
        },
      };

      return colDef;
    },
    [createToolParamColDef],
  );

  const createToolRoiFrameParamColDef = useCallback(
    (toolParam: ToolRoiFrameParam) => {
      const baseColDef = createToolParamColDef(toolParam);
      /**
       * Converts a value to a string
       * @param value
       * @returns The value as a string or `undefined` if it parses to an empty string
       */
      const convertValueToString = (
        value: ToolParamsGridCellEditRequestNewValue,
      ) => {
        const valueString = (value ?? "").toString();
        return valueString !== "" ? valueString : undefined;
      };

      const colDef: ColDef = {
        ...baseColDef,
        cellRenderer: ({
          data,
          value,
        }: ICellRendererParams<ToolParamsGridRowDatum>) => {
          assert(data !== undefined, "Expected row datum to be defined");

          return (
            <GridRendererToolRoiFrameParam
              /**
               * The cell renderer reads row data to get cell values from different cells
               * because re renders are only triggered by the grid when the value for that cell changes,
               * we pass the row id and select the row from the context in the renderer if it needs to subscribe
               * to all row data changes
               */
              value={value as ToolParamValue}
              rowId={data.id}
              toolParam={toolParam}
            />
          );
        },

        onCellEdit: (newValue, rowDatum) =>
          baseColDef.onCellEdit(convertValueToString(newValue), rowDatum),
        onCellFill: (newValue, rowDatum) =>
          baseColDef.onCellFill(convertValueToString(newValue), rowDatum),
        onCellPaste: (newValue, rowDatum) =>
          baseColDef.onCellPaste(convertValueToString(newValue), rowDatum),
      };

      return colDef;
    },
    [createToolParamColDef],
  );

  const createToolPathParamColDef = useCallback(
    (toolParam: ToolPathParam) => {
      const baseColDef = createToolParamColDef(toolParam);

      /**
       * Converts a value to a list of file ids
       * @param value
       * @returns The list of data file with data classes allowed by the tool param
       */
      const convertValueToFileIds = (
        value: ToolParamsGridCellEditRequestNewValue,
      ) => {
        if (value === undefined || value === null) {
          return undefined;
        }
        const incomingValueFileIds = value.toString().split(",");

        const matchedDataObjectIds = intersection(
          projectFiles.map(({ id }) => id),
          incomingValueFileIds,
        );

        return matchedDataObjectIds.length > 0
          ? matchedDataObjectIds
          : undefined;
      };

      const colDef: ColDef = {
        ...baseColDef,
        cellEditor: GridEditorToolPathParam,
        cellEditorParams: { toolParam },
        cellStyle: { padding: 5 },
        cellRenderer: ({ data, column }: ToolParamsGridCellRendererParams) => {
          // TODO ID-4230 remove when we figure out how the empty string case occurs
          const fileIds =
            data.params[column.getColId()] !== ""
              ? data.params[column.getColId()]
              : [];
          return (
            <GridRendererToolPathParam
              fileIds={coalesceToStringArray(fileIds)}
              onClickFileBadge={openFileFlyoutMemoized}
            />
          );
        },
        cellEditorPopup: true,
        onCellEdit: baseColDef.onCellEdit,
        onCellFill: (newValue, rowDatum) => {
          const formattedValue = convertValueToFileIds(newValue);
          return baseColDef.onCellFill(formattedValue, rowDatum);
        },
        onCellPaste: (newValue, rowDatum) => {
          const formattedValue = convertValueToFileIds(newValue);
          return baseColDef.onCellPaste(formattedValue, rowDatum);
        },
      };

      return colDef;
    },
    [createToolParamColDef, openFileFlyoutMemoized, projectFiles],
  );

  const createToolStringParamColDef = useCallback(
    (toolParam: ToolStringParam) => {
      const baseColDef = createToolParamColDef(toolParam);

      /**
       * Converts a value to a string
       * @param value
       * @returns The value as a string or `undefined` if it parses to an empty string
       */
      const convertValueToString = (
        value: ToolParamsGridCellEditRequestNewValue,
      ) => {
        const valueString = (value ?? "").toString();
        return valueString !== "" ? valueString : undefined;
      };

      const colDef: SetRequired<ColDef, "onCellFill"> = {
        ...baseColDef,
        onCellEdit: (newValue, rowDatum) => {
          const formattedValue = convertValueToString(newValue);
          return baseColDef.onCellEdit(formattedValue, rowDatum);
        },
        onCellFill: (newValue, rowDatum) => {
          const formattedValue = convertValueToString(newValue);
          return baseColDef.onCellFill(formattedValue, rowDatum);
        },
        onCellPaste: (newValue, rowDatum) => {
          const formattedValue = convertValueToString(newValue);
          return baseColDef.onCellPaste(formattedValue, rowDatum);
        },
      };
      return colDef;
    },
    [createToolParamColDef],
  );

  const createToolCellStatusParamColDef = useCallback(
    (toolParam: ToolCellStatusParam) => {
      const baseColDef = createToolParamColDef(toolParam);
      /**
       * Converts a value to a string
       * @param value
       * @returns The value as a string or `undefined` if it parses to an empty string
       */
      const convertValueToString = (
        value: ToolParamsGridCellEditRequestNewValue,
      ) => {
        const valueString = (value ?? "").toString();
        return valueString !== "" ? valueString : undefined;
      };

      const colDef: ColDef = {
        ...baseColDef,
        cellRenderer: ({
          data,
          value,
        }: ICellRendererParams<ToolParamsGridRowDatum>) => {
          assert(data !== undefined, "Expected row datum to be defined");

          return (
            <GridRendererToolCellStatusParam
              /**
               * The cell renderer reads row data to get cell values from different cells
               * because re renders are only triggered by the grid when the value for that cell changes,
               * we pass the row id and select the row from the context in the renderer if it needs to subscribe
               * to all row data changes
               */
              rowId={data.id}
              value={value as ToolParamValue}
              toolParam={toolParam}
            />
          );
        },
        onCellEdit: (newValue, rowDatum) => {
          const formattedValue = convertValueToString(newValue);
          return baseColDef.onCellEdit(formattedValue, rowDatum);
        },
        onCellFill: (newValue, rowDatum) => {
          const formattedValue = convertValueToString(newValue);
          return baseColDef.onCellFill(formattedValue, rowDatum);
        },
        onCellPaste: (newValue, rowDatum) => {
          const formattedValue = convertValueToString(newValue);
          return baseColDef.onCellPaste(formattedValue, rowDatum);
        },
      };

      return colDef;
    },
    [createToolParamColDef],
  );

  /**
   * Parses a column definition from a tool spec param
   * @param param
   * @returns The parsed column definition
   */
  const parseParam = useCallback(
    (toolParam: ToolParam) => {
      if (isToolBooleanParam(toolParam)) {
        return createToolBooleanParamColDef(toolParam);
      }

      if (isToolChoiceParam(toolParam)) {
        return createToolChoiceParamColDef(toolParam);
      }

      if (isToolFloatRangeParam(toolParam)) {
        return createToolFloatRangeParamColDef(toolParam);
      }

      if (isToolIntRangeParam(toolParam)) {
        return createToolIntRangeParamColDef(toolParam);
      }

      if (isToolPathParam(toolParam)) {
        return createToolPathParamColDef(toolParam);
      }

      if (isToolCroppingFrameParam(toolParam)) {
        return createToolCroppingFrameParamColDef(toolParam);
      }

      if (isToolRoiFrameParam(toolParam)) {
        return createToolRoiFrameParamColDef(toolParam);
      }

      if (isToolStringParam(toolParam)) {
        return createToolStringParamColDef(toolParam);
      }

      if (isToolCellStatusParam(toolParam)) {
        return createToolCellStatusParamColDef(toolParam);
      }

      return createToolParamColDef(toolParam);
    },
    [
      createToolBooleanParamColDef,
      createToolChoiceParamColDef,
      createToolCroppingFrameParamColDef,
      createToolFloatRangeParamColDef,
      createToolIntRangeParamColDef,
      createToolParamColDef,
      createToolPathParamColDef,
      createToolRoiFrameParamColDef,
      createToolStringParamColDef,
      createToolCellStatusParamColDef,
    ],
  );

  /**
   * The column definitions for the tool params
   */
  const colDefsParams = useMemo(() => {
    const paramOrder: Record<string, number> = toolSpec.params.reduce(
      (acc, { key }, idx) => ({
        ...acc,
        [key]: idx,
      }),
      {},
    );

    const colDefs = toolSpec.params
      .flatMap((param) => {
        const paramColDef = { ...parseParam(param), paramKey: param.key };
        const colDefs = [paramColDef];
        return colDefs.map((colDef) => ({
          colDef,
          display: param.type.display,
          paramKey: param.key,
        }));
      })
      .map((param) => ({
        ...param,
        order: paramOrder[param.paramKey],
      }));

    const colDefsAndColGroupDefs: (ColDef | ColGroupDef)[] = [];

    // Get column definitions without a display group
    const ungroupedColDefs = colDefs.filter(({ display }) => {
      const hasDisplayGroup = isDefined(display);
      return !hasDisplayGroup;
    });

    ungroupedColDefs.forEach(({ colDef, order }) => {
      colDefsAndColGroupDefs[order] = colDef;
    });

    // Get column definitions with a display group
    const groupedColDefs = colDefs.filter(({ display }) => {
      const hasDisplayGroup = isDefined(display);
      return hasDisplayGroup;
    });

    const colDefsByDisplayGroupName = groupBy(
      groupedColDefs,
      ({ display }) => display?.group,
    );

    Object.entries(colDefsByDisplayGroupName).forEach(
      ([groupName, children]) => {
        const sortedChildren = sortBy(
          children,
          ({ display }) => display?.group_order,
        );
        const colGroupDef: ColGroupDef = {
          children: sortedChildren.map(({ colDef }) => colDef),
          headerName: groupName,
        };
        const order = Math.min(...children.map(({ order }) => order));
        colDefsAndColGroupDefs[order] = colGroupDef;
      },
    );

    // Remove any empty slots caused by grouping
    return colDefsAndColGroupDefs.filter(isDefined);
  }, [parseParam, toolSpec]);

  /**
   * Column definition for resource tier choice
   */

  const colDefResourceTierChoice: ResourceTierColDef = useMemo(() => {
    const validTiers = [
      ...Object.values(RESOURCE_TIERS).map(({ id }) => id),
      // null is a valid value to specify tool default
      null,
    ];

    /**
     * Parses incoming value for resource tier changes
     * @param value incoming unknown value
     * @param rowDatum row datum
     * @returns new value if valid resource tier, old value if not
     */
    const parseTier = (value: unknown, rowDatum: RowDatum) => {
      try {
        const tier = value !== null && value !== "" ? Number(value) : null;

        assert(validTiers.some((key) => key === tier));
        return tier as ResourceTier["id"] | null;
      } catch (err) {
        return rowDatum.resource_tier ?? null;
      }
    };

    const handleCellChange = (
      newValue: ToolParamValue | null,
      rowDatum: RowDatum,
    ) => {
      const parsedValue = parseTier(newValue, rowDatum);
      updateRowDatum(rowDatum.id, {
        // checked above
        resource_tier: parsedValue,
      });

      return parsedValue;
    };

    return {
      colId: RESOURCE_TIER_COL.id,
      headerName: RESOURCE_TIER_COL.label,
      width: 250,
      columnGroupShow: "open",
      valueGetter: ({ data }) => data?.resource_tier ?? null,
      valueFormatter: ({ data }) => {
        const toolVersion = toolVersions.find(
          ({ id }) => id === data?.toolVersion.id,
        );
        if (toolVersion === undefined) {
          captureException("Unable to find tool version", {
            extra: { toolVersionId: data?.toolVersion.id },
          });
          return "Unspecified";
        }
        const toolSpec = toolVersion.toolSpec;

        return formatToolResources(
          { ...(toolSpec as ToolSpec), credits: toolVersion.credits ?? 0 },
          data?.resource_tier ?? null,
        );
      },
      cellStyle: ({ data }) => {
        const tier = data?.resource_tier ?? null;
        if (tier === null) {
          // format cloned task compute credit
          return { color: "grey", fontStyle: "italic", padding: 5 };
        }
        return { color: "black", fontStyle: "normal", padding: 5 };
      },
      cellEditor: GridEditorTierResourceChoiceParam,
      cellEditorPopup: true,
      editable: (params) => params.data?.task_id === undefined,
      onCellEdit: handleCellChange,
      onCellFill: handleCellChange,
      onCellPaste: handleCellChange,
      suppressFillHandle: false,
      suppressPaste: false,
    };
  }, [toolVersions, updateRowDatum]);

  /**
   * The column definition for the recording column
   */
  const colDefRecording: ColDef = useMemo(() => {
    return {
      cellRenderer: (params: ToolParamsGridCellRendererParams) => (
        <GridRendererRecording data={params.data} />
      ),
      colId: "recording",
      headerName: "Recording Session IDs",
      onCellFill: null,
      /**
       * Value getters are used to determine when to refresh a cell.
       * We want to trigger a re render of controls when row data changes
       * https://www.ag-grid.com/javascript-data-grid/change-detection/#comparing-values
       */
      valueGetter: (params) => params.data?.recordings,
    };
  }, []);

  /**
   * The column definition for the row checkbox/selection
   */
  const colDefSelectRow: ColDef = useMemo(() => {
    const euiCheckboxSize = parseInt(euiTheme.size.base.replace("px", "")) + 1; // +1 for the border, prevents the header checkbox from being cut off
    const cellPadding = 12;

    const colDef: ColDef = {
      cellRenderer: (params: ToolParamsGridCellRendererParams) => (
        <GridRendererSelectRow
          rowId={params.data.id}
          taskStatus={params.data.task_status}
          value={params.value as boolean}
        />
      ),
      colId: COL_ID_CHECKBOX,
      valueGetterField: COL_ID_CHECKBOX,
      headerComponent: HeaderComponentSelectRow,
      pinned: "left",
      resizable: false,
      width: euiCheckboxSize + cellPadding * 2,
      onCellFill: null,
    };

    return colDef;
  }, [euiTheme.size.base]);

  /**
   * The column definition for the task status of executed params
   */
  const colGroupDefTool: ColGroupDef = useMemo(() => {
    const handleCellChange = (
      newValue: ToolParamValue | null,
      rowDatum: RowDatum,
    ) => {
      const newToolVersion = toolVersions.find(
        (toolVersion) => toolVersion.version === newValue,
      );
      const oldToolVersion = rowDatum.toolVersion;

      if (isDefined(newToolVersion)) {
        updateRowDatum(rowDatum.id, {
          toolVersion: newToolVersion,
        });
        return newToolVersion.version;
      }

      return oldToolVersion.version;
    };
    // necessary to define separately to keep component props from changing
    const rendererStyle = { fontFamily: "monospace" };
    const colDefToolVersion: ColDef = {
      cellRenderer: ({ data }: ToolParamsGridCellRendererParams) => (
        <EuiBadgeMemo color="hollow" style={rendererStyle}>
          {data.toolVersion.version}
        </EuiBadgeMemo>
      ),
      colId: "tool_version",
      headerName: "Version",
      width: 100,
      valueGetterField: "toolVersion.version",
      onCellEdit: handleCellChange,
      onCellPaste: handleCellChange,
      onCellFill: handleCellChange,
      editable: ({ data }) => {
        const isUnexecuted = data?.task_status === undefined;
        return isUnexecuted && hasEditPermission;
      },
      cellEditor: GridEditorToolVersion,
      suppressFillHandle: false,
      suppressPaste: false,
    };

    return {
      groupId: "tool",
      headerName: "Tool",
      children: [colDefToolVersion],
    };
  }, [hasEditPermission, toolVersions, updateRowDatum]);

  /**
   * The column definition for the task short ID
   */
  const colDefTaskId: ColDef = useMemo(() => {
    const colDef: ColDef = {
      cellRenderer: (params: ToolParamsGridCellRendererParams) => (
        <GridRendererTaskShortId taskId={params.data.task_id} />
      ),
      colId: TASK_INFO_COLS.ID.id,
      valueGetterField: "task_id",
      headerName: TASK_INFO_COLS.ID.label,
      width: 100,
      onCellFill: null,
    };

    return colDef;
  }, []);

  /**
   * The column definition for the task logs of executed params
   */
  const colDefTaskLogs: ColDef = useMemo(() => {
    const euiButtonIconSize = parseInt(euiTheme.size.l.replace("px", ""));
    const cellPadding = 20;

    const colDef: ColDef = {
      cellRenderer: (params: { data: ToolParamsGridRowDatum }) => {
        const taskId = params.data.task_id;
        const taskStatus = params.data.task_status;
        const taskCreated = params.data.task_date_created;
        if (
          taskId === undefined ||
          taskStatus === undefined ||
          taskCreated === undefined
        ) {
          return null;
        }

        return (
          <ButtonViewTaskLogs
            taskId={taskId}
            taskStatus={taskStatus}
            taskCreated={taskCreated}
          />
        );
      },
      colId: TASK_INFO_COLS.LOGS.id,
      headerName: TASK_INFO_COLS.LOGS.label,
      width: euiButtonIconSize + cellPadding * 2,
      onCellFill: null,
      /**
       * Value getters are used to determine when to refresh a cell.
       * We want to trigger a re-render of the log when the task status changes.
       * https://www.ag-grid.com/javascript-data-grid/change-detection/#comparing-values
       */
      valueGetter: (params) => params.data?.task_status,
    };

    return colDef;
  }, [euiTheme.size.l]);

  /**
   * The column definition for the date the task was submitted
   */
  const colDefTaskDateStarted: ColDef = useMemo(() => {
    const colDef: ColDef = {
      colId: TASK_INFO_COLS.DATE_STARTED.id,
      valueGetterField: "task_date_created",
      valueFormatter: (params) => {
        if (params.data?.task_date_created !== undefined) {
          return formatDate(params.data.task_date_created);
        }
        return "";
      },
      headerName: TASK_INFO_COLS.DATE_STARTED.label,
      width: 170,
      onCellFill: null,
    };

    return colDef;
  }, []);

  /**
   * The column definition for the task Compute Credit
   */
  const colDefTaskComputeCredit: ColDef = useMemo(() => {
    const colDef: ColDef = {
      colId: TASK_INFO_COLS.COMPUTE_CREDITS.id,
      valueGetterField: "task_compute_credit",
      valueFormatter: (params: ValueFormatterParams<RowDatum, number>) => {
        if (isNonNullish(params?.value)) {
          const credits = roundToSignificant(params.value).toString();
          const cloned = params.data?.task_cloned;
          return cloned ? `[${credits}]` : credits;
        }
        return "";
      },
      headerName: TASK_INFO_COLS.COMPUTE_CREDITS.label,
      width: 130,
      onCellFill: null,
      cellStyle: (params): CellStyle => {
        if (params.data?.task_cloned) {
          // format cloned task compute credit
          return { color: "grey", fontStyle: "italic", padding: 5 };
        }
        return { padding: 5 };
      },
      tooltipValueGetter: (params: ITooltipParams<RowDatum, number>) =>
        params.data?.task_cloned
          ? "Copied tasks do not count towards usage"
          : "",
    };

    return colDef;
  }, []);

  /**
   * The column definition for the task vCPU duration
   */
  const colDefTaskDuration: ColDef = useMemo(() => {
    const colDef: ColDef = {
      colId: TASK_INFO_COLS.DURATION.id,
      valueGetterField: "task_duration",
      headerName: TASK_INFO_COLS.DURATION.label,
      width: 90,
      valueFormatter: (params: ValueFormatterParams<RowDatum, Interval>) => {
        const duration = params?.value;

        if (isNonNullish(duration)) {
          return formatInterval(duration);
        }
        return "";
      },
      onCellFill: null,
    };

    return colDef;
  }, []);

  /**
   * The column definition for the task user
   */
  const colDefTaskUser: ColDef = useMemo(() => {
    return {
      colId: TASK_INFO_COLS.USER.id,
      headerName: TASK_INFO_COLS.USER.label,
      width: 64,
      onCellFill: null,
      cellRenderer: (params: ToolParamsGridCellRendererParams) => (
        <GridRendererTaskUser userId={params.data.task_user_id} />
      ),
      valueGetter: ({ data }) => data?.task_user_id,
    };
  }, []);

  /**
   * The group definition for task-related pinned columns
   */
  const colDefTaskInfo: ColGroupDef = useMemo(() => {
    const colDef: ColGroupDef = {
      groupId: "task_info_group",
      headerName: "Task",
      children: [
        colDefTaskId,
        colDefTaskLogs,
        colDefTaskUser,
        colDefTaskDateStarted,
        colDefTaskDuration,
        colDefTaskComputeCredit,
      ],
    };

    return colDef;
  }, [
    colDefTaskId,
    colDefTaskLogs,
    colDefTaskUser,
    colDefTaskDateStarted,
    colDefTaskDuration,
    colDefTaskComputeCredit,
  ]);

  /**
   * The column definition for the result assignments toggle
   */
  const colDefAssignments: ColDef = useMemo(() => {
    const euiButtonIconSize = parseInt(euiTheme.size.l.replace("px", ""));
    const cellPadding = 29;

    const colDef: ColDef = {
      cellRenderer: (params: ToolParamsGridCellRendererParams) => (
        <GridRendererAttachResults data={params.data} />
      ),
      colId: "task_attach",
      headerName: "Attach results",
      pinned: "right",
      wrapHeaderText: true,
      width: euiButtonIconSize + cellPadding * 2,
      onCellFill: null,
    };

    return colDef;
  }, [euiTheme.size.l]);

  /**
   * The column definition for the row controls
   */
  const colDefRowControls: ColDef = useMemo(() => {
    const euiButtonIconSize = parseInt(euiTheme.size.l.replace("px", ""));
    const numButtons = 3;
    const cellPadding = 25;

    const colDef: ColDef = {
      cellRenderer: (params: ToolParamsGridCellRendererParams) => (
        <GridRendererRowControls data={params.data} />
      ),
      colId: "row_controls",
      pinned: "right",
      /**
       * Value getters are used to determine when to refresh a cell.
       * We want to trigger a re render of controls when row data changes
       * https://www.ag-grid.com/javascript-data-grid/change-detection/#comparing-values
       */
      valueGetter: ({ data }) => data,
      resizable: false,
      width: euiButtonIconSize * numButtons + cellPadding * 2,
      onCellFill: null,
    };

    return colDef;
  }, [euiTheme.size.l]);

  const colDefOutputGroup = useMemo(() => {
    const outputCols: ColDef[] = [];
    if (toolSpec.results.length > 0 && isDefined(toolSpec.results[0].files)) {
      // assume only one group result
      const outputGroupResult = toolSpec.results[0];
      // add all object result columns from the group
      for (const fileResult of outputGroupResult.files) {
        outputCols.push({
          colId: fileResult.result_key,
          headerName: fileResult.result_name,
          cellStyle: { padding: 5 },
          cellRenderer: (params: ToolParamsGridCellRendererParams) => (
            <GridRendererAnalysisResult
              data={params.data}
              result={fileResult}
              onClickFile={openFileFlyoutMemoized}
            />
          ),
          valueGetter: ({ data }) => {
            // Returning an array of drsFiles to reuse the existing serializer in the processCellCallback
            return data?.output_group_files?.filter(
              (outputGroupFile) =>
                outputGroupFile?.key === fileResult.result_key,
            );
          },
          onCellFill: null,
        });
      }
    }

    return {
      colId: "output_group_header",
      headerName: "Analysis Results",
      children: outputCols,
    };
  }, [openFileFlyoutMemoized, toolSpec.results]);

  /**
   * The column definition for the task identifier
   */
  const colDefTaskIdentifier: ColDef = useMemo(() => {
    return {
      colId: "task_identifier",
      headerName: "Task ID",
      valueGetterField: "task_id",
      cellRenderer: (params: ToolParamsGridCellRendererParams) => (
        <GridRendererRowIdentifier data={params.data} />
      ),
      headerComponent: () => <ToolParamsGridIdentifierHeader />,
      onCellFill: null,
    };
  }, []);

  /**
   * Disable editing, fill, paste, and navigation by default
   */
  const defaultColDef: AgGridReactProps["defaultColDef"] = useMemo(
    () => ({
      useValueFormatterForExport: false,
      editable: false,
      sortable: false,
      resizable: true,
      suppressFillHandle: true,
      suppressHeaderMenuButton: true,
      suppressMovable: true,
      suppressNavigable: true,
      suppressPaste: true,
      valueGetter: ({
        colDef,
        data,
      }: ValueGetterParams<ToolParamsGridRowDatum>) => {
        const { valueGetterField } = colDef as ColDef;
        if (isDefined(valueGetterField) && isDefined(data)) {
          // eslint-disable-next-line @typescript-eslint/no-unsafe-return
          return get(data, valueGetterField);
        }
      },
      // setting to false to avoid unexpected changes to existing grids when bumping from 28.x to 30.x
      // if using this grid as a template for new grids, consider taking advantage of this new feature
      // https://www.ag-grid.com/archive/30.0.0/react-data-grid/cell-data-types/
      cellDataType: false,
      cellStyle: { padding: 5 },
    }),
    [],
  );

  const colDefs = useMemo(
    () => ({
      columnDefs: [
        colDefSelectRow,
        colDefTaskIdentifier,
        colGroupDefTool,
        colDefRecording,
        ...colDefsParams,
        colDefResourceTierChoice,
        colDefOutputGroup,
        colDefTaskInfo,
        colDefAssignments,
        colDefRowControls,
      ],
      defaultColDef,
    }),
    [
      colDefAssignments,
      colDefOutputGroup,
      colDefRecording,
      colDefResourceTierChoice,
      colDefRowControls,
      colDefSelectRow,
      colDefTaskIdentifier,
      colDefTaskInfo,
      colDefsParams,
      colGroupDefTool,
      defaultColDef,
    ],
  );

  return colDefs;
};
