/** @jsxImportSource @emotion/react */

import { css, Global } from "@emotion/react";
import {
  EuiComboBoxOptionOption,
  EuiFlexGroup,
  EuiFlexItem,
  EuiFormRow,
  EuiPanel,
  EuiSpacer,
  EuiSuperSelectOption,
  EuiText,
} from "@inscopix/ideas-eui";
import { captureException } from "@sentry/react";
import { ButtonPermissionedBase } from "components/ButtonPermissioned/ButtonPermissioned";
import { ComboBoxPermissionedBase } from "components/ComboBoxPermissioned/ComboBoxPermissioned";
import { SuperSelectPermissionedBase } from "components/SuperSelectPermissioned/SuperSelectPermissioned";
import { UserAvatar } from "components/UserAvatar/UserAvatar";
import { ApplicationUser, Project } from "graphql/_Types";
import { useTenantContext } from "providers/TenantProvider/TenantProvider";
import { useMemo, useState } from "react";
import { SetRequired } from "type-fest";
import {
  UserAccessLevel,
  USER_ACCESS_LEVELS_BY_KEY,
} from "types/UserAccessLevels";
import { addUtilityToastFailure } from "utils/addUtilityToastFailure";
import { addUtilityToastSuccess } from "utils/addUtilityToastSuccess";
import {
  ModalProjectSharingProps,
  useInvalidateModalProjectSharingQuery,
} from "./ModalProjectSharing";
import { UserProjectAccessDjango } from "./permissions";
import { UserWithAccess } from "./UserWithAccess";

export interface ModalProjectSharingInternalUserAccessProps {
  revokeInternalUserAccess: NonNullable<
    ModalProjectSharingProps["revokeInternalUserAccess"]
  >;
  updateInternalUserAccessLevel: NonNullable<
    ModalProjectSharingProps["updateInternalUserAccessLevel"]
  >;

  createInternalUserAccess: NonNullable<
    ModalProjectSharingProps["createInternalUserAccess"]
  >;
  project: Pick<Project, "id"> & { user: Pick<ApplicationUser, "id"> };
  accessLevelOptions: EuiSuperSelectOption<`${UserAccessLevel["id"]}`>[];
  usersWithAccess: Pick<
    UserProjectAccessDjango,
    "id" | "user" | "access_level"
  >[];
  userHasGrantAccessPermission: boolean;
}

export const ModalProjectSharingInternalUserAccess = ({
  accessLevelOptions,
  project,
  revokeInternalUserAccess,
  updateInternalUserAccessLevel,
  createInternalUserAccess,
  usersWithAccess,
  userHasGrantAccessPermission,
}: ModalProjectSharingInternalUserAccessProps) => {
  const [loading, setLoading] = useState(false);
  const [userAccessLevel, setUserAccessLevel] = useState<UserAccessLevel["id"]>(
    USER_ACCESS_LEVELS_BY_KEY["COPIER"].id,
  );
  const [selectedUserIds, setSelectedUserIds] =
    useState<SetRequired<EuiComboBoxOptionOption<string>, "value">[]>();

  const { invalidateModalProjectSharingQuery } =
    useInvalidateModalProjectSharingQuery();
  const handleAddUsers = async () => {
    if (selectedUserIds && selectedUserIds.length > 0) {
      setLoading(true);

      //
      // Add new users to the bottom of the list
      //

      try {
        await createInternalUserAccess({
          projectId: project.id,
          users: selectedUserIds.map((user) => ({
            userId: user.value,
            userAccessLevel: userAccessLevel,
          })),
        });

        await invalidateModalProjectSharingQuery(project.id);
        addUtilityToastSuccess("Successfully added users");
        setSelectedUserIds([]);
      } catch (error) {
        await invalidateModalProjectSharingQuery(project.id);

        addUtilityToastFailure("Failed to add users");
        captureException(error);
      } finally {
        setLoading(false);
      }
    }
  };

  const tenantUsers = useTenantContext((s) => s.tenantUsers);

  const optionsTenantUsers = useMemo(
    () =>
      tenantUsers
        .filter(({ internal }) => !internal)
        // Remove project owner from the list
        .filter(({ id }) => id !== project.user.id)
        // remove existing users from the list
        .filter(
          ({ id }) =>
            !usersWithAccess.some(
              (userWithAccess) => userWithAccess.user === id,
            ),
        )
        .map((user) => ({
          label: user.firstName + " " + user.lastName,
          value: user.id,
          prepend: <UserAvatar userId={user.id} size="s" />,
          append: (
            <EuiText size="xs" color="subdued">
              {user.email}
            </EuiText>
          ),
          css: {
            height: "31px !important",
          },
        }))
        .sort((a, b) => a.label.localeCompare(b.label)), // Sorting by name

    [project.user.id, tenantUsers, usersWithAccess],
  );

  return (
    <EuiFormRow label="Organization users with access" fullWidth>
      <>
        <EuiSpacer size="xs" />
        <EuiPanel hasShadow={false} hasBorder={true}>
          {usersWithAccess.map((userProjectAccess) => {
            return (
              <UserWithAccess
                userHasGrantAccessPermission={userHasGrantAccessPermission}
                projectId={project.id}
                key={userProjectAccess.user}
                userProjectAccess={userProjectAccess}
                updateInternalUserAccessLevel={updateInternalUserAccessLevel}
                revokeInternalUserAccess={revokeInternalUserAccess}
              />
            );
          })}

          {usersWithAccess.length !== 0 && <EuiSpacer size="l" />}

          <EuiText size="xs">
            <h4>Add users from organization</h4>
          </EuiText>
          <EuiSpacer size="s" />

          {/* Eui does not have a good style for appending avatar. A custom style is needed to format properly */}
          <Global
            styles={css`
              .euiComboBox.euiComboBox--withAvatar {
                .euiComboBoxPill {
                  height: 30px !important;
                  line-height: 26px;
                }
                .euiComboBoxOption__prepend {
                  .euiAvatar {
                    height: 12px;
                    width: 12px;
                  }
                }
              }
            `}
          />
          <EuiFlexGroup gutterSize="xs">
            <EuiFlexItem grow={true}>
              <EuiFormRow fullWidth>
                <ComboBoxPermissionedBase
                  fullWidth
                  style={{ maxWidth: "418px" }}
                  className="euiComboBox--withAvatar"
                  data-test-subj="add-user-access-combobox"
                  placeholder="Select users"
                  options={optionsTenantUsers}
                  selectedOptions={selectedUserIds}
                  onChange={(selectedOptions) => {
                    if (
                      selectedOptions.every(
                        (
                          option,
                        ): option is NonNullable<
                          typeof selectedUserIds
                        >[number] => option.value !== undefined,
                      )
                    ) {
                      setSelectedUserIds(selectedOptions);
                    }
                  }}
                  isClearable={true}
                  isPermitted={userHasGrantAccessPermission}
                />
              </EuiFormRow>
            </EuiFlexItem>
          </EuiFlexGroup>

          <EuiSpacer size="s" />
          <SuperSelectPermissionedBase
            fullWidth
            compressed
            options={accessLevelOptions}
            valueOfSelected={`${userAccessLevel}`}
            placeholder="Select an option"
            onChange={(value) =>
              setUserAccessLevel(Number(value) as UserAccessLevel["id"])
            }
            itemLayoutAlign="top"
            hasDividers
            isPermitted={userHasGrantAccessPermission}
          />
          <EuiSpacer size="s" />
          <ButtonPermissionedBase
            isLoading={loading}
            size="s"
            fullWidth
            onClick={() => void handleAddUsers()}
            isPermitted={userHasGrantAccessPermission}
          >
            Add users
          </ButtonPermissionedBase>
        </EuiPanel>
      </>
    </EuiFormRow>
  );
};
