import {
  EuiFlexGroup,
  EuiFlexItem,
  EuiHorizontalRule,
  EuiPageTemplate,
} from "@inscopix/ideas-eui";
import { useParams } from "react-router-dom";
import { DataTableContent } from "./layout/DataTableContent";
import { DataTableHeader } from "./layout/DataTableHeader";
import { DataTableMenuBar } from "./layout/DataTableMenuBar";
import { DataTableTabs } from "./layout/DataTableTabs";
import { DataTableProvider } from "./store/DataTableProvider";
import { DataTableQuickActionBar } from "./layout/DataTableQuickActionBar/DataTableQuickActionBar";
import { DataTableBreadcrumbs } from "./layout/DataTableBreadcrumbs";
import { ModalProvider } from "providers/ModalProvider/ModalProvider";

export const PageGenericDataTables = () => {
  const projectKey = useParams<{ projectKey: string }>().projectKey;

  return (
    <EuiPageTemplate style={{ height: "100%" }}>
      <ModalProvider>
        <DataTableProvider projectKey={projectKey}>
          <EuiFlexGroup
            direction="column"
            gutterSize="none"
            style={{ height: "100%" }}
          >
            <EuiFlexItem
              grow={false}
              style={{ paddingLeft: 10, paddingRight: 10, paddingTop: 10 }}
            >
              <DataTableBreadcrumbs />
            </EuiFlexItem>

            <EuiFlexItem
              grow={false}
              style={{ paddingLeft: 10, paddingRight: 10 }}
            >
              <DataTableHeader />
            </EuiFlexItem>

            <EuiFlexItem
              grow={false}
              style={{ paddingLeft: 5, paddingRight: 5 }}
            >
              <DataTableMenuBar />
            </EuiFlexItem>

            <EuiFlexItem
              grow={false}
              style={{ paddingLeft: 5, paddingRight: 5 }}
            >
              <EuiHorizontalRule margin="none" />
              <DataTableQuickActionBar />
            </EuiFlexItem>

            <EuiFlexItem style={{ minHeight: 0 }}>
              <DataTableContent />
            </EuiFlexItem>

            <EuiFlexItem grow={false}>
              <DataTableTabs />
            </EuiFlexItem>
          </EuiFlexGroup>
        </DataTableProvider>
      </ModalProvider>
    </EuiPageTemplate>
  );
};
