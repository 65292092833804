/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { useState } from "react";
import { CellValue, RowIdentifier } from "@inscopix/ideas-hyperformula";
import {
  EuiListGroup,
  EuiListGroupItem,
  EuiPopover,
} from "@inscopix/ideas-eui";
import { CellValueRendererBase } from "../cell-value-renderers/CellValueRendererBase";
import { useDataTableContext } from "../store/DataTableProvider";
import { DataTableColumnDefinition } from "../store/DataTableProvider.types";

const styles = {
  popover: css`
    & {
      height: 100%;
      width: 100%;
      display: flex;
      align-items: center;
    }
  `,
  panel: css`
    max-height: max(200px, 50vh);
    overflow-y: scroll;
  `,
};

interface CellEditorIdentifierProps {
  columnDefinition: Extract<DataTableColumnDefinition, { kind: "identifier" }>;
  value: CellValue;
  onChangeFormula: (newFormula: string) => void;
  stopEditing: (cancel?: boolean) => void;
}

/** Cell editor for cells in identifier columns */
export const CellEditorIdentifier = ({
  columnDefinition,
  value,
  onChangeFormula,
  stopEditing,
}: CellEditorIdentifierProps) => {
  const table = useDataTableContext((s) => {
    return s.tables.find((table) => table.id === columnDefinition.table.id);
  });
  const [newValue, setNewValue] = useState(value);

  return (
    <EuiPopover
      anchorPosition="downCenter"
      button={<CellValueRendererBase value={newValue} />}
      isOpen
      panelPaddingSize="none"
      css={styles.popover}
      closePopover={stopEditing}
      onKeyDown={(e) => {
        if (e.key === "Escape") {
          e.preventDefault();
          stopEditing(true);
        }
      }}
      display="block"
      repositionOnScroll={true}
    >
      <EuiListGroup css={styles.panel} maxWidth="100%">
        {table?.rows.map((row) => {
          const isSelected =
            newValue instanceof RowIdentifier &&
            newValue.tableKey === table.key &&
            newValue.rowIndex === row.index;
          const value = new RowIdentifier(table.key, row.index);

          return (
            <EuiListGroupItem
              id={row.id}
              key={row.id}
              iconType={isSelected ? "check" : "empty"}
              label={<CellValueRendererBase value={value} />}
              onClick={() => {
                if (isSelected) {
                  onChangeFormula("");
                  setNewValue(null);
                } else {
                  onChangeFormula(
                    `=ROW_IDENTIFIER("${table.key}", ${row.index})`,
                  );
                  setNewValue(value);
                }
              }}
            />
          );
        })}
      </EuiListGroup>
    </EuiPopover>
  );
};
