import { captureException } from "@sentry/react";
import assert from "assert";
import { isToolPathParam } from "components/ToolParamsGrid/ToolParamsGrid.helpers";
import {
  getDefaultRowDatum,
  isFileFilterMatch,
} from "components/ToolParamsGrid/ToolParamsGridProvider.helpers";
import {
  Project,
  FileRecordingGroup,
  useGetToolboxSpecByAnalysisTableIdLazyQuery,
  AnalysisTable,
} from "graphql/_Types";

import { useRouteMapContext } from "providers/RouteMapProvider/RouteMapProvider";
import { useTenantContext } from "providers/TenantProvider/TenantProvider";
import { uniq } from "lodash";
import { useState } from "react";
import { addUtilityToastFailure } from "utils/addUtilityToastFailure";
import { useProjectFilesStore } from "stores/project-files/ProjectFilesManager";
import { ButtonIconPermissioned } from "components/ButtonIconPermissioned/ButtonIconPermissioned";
import { ToolSpec } from "components/ToolParamsGrid/ToolParamsGrid.types";
import { isNonNullish } from "utils/isNonNullish";
import {
  EuiButtonEmpty,
  EuiFlexGroup,
  EuiFlexItem,
  EuiPopover,
  htmlIdGenerator,
} from "@inscopix/ideas-eui";
import { libraryAnalysisTableRowDjango } from "django/libraryAnalysisTableRowDjango";

interface ButtonAddRecordingsToTableProps {
  selectedRecordingIds: FileRecordingGroup["id"][];
  tables: Pick<AnalysisTable, "id" | "name" | "groupId">[];
  project: Pick<Project, "id" | "key">;
}
export const ButtonAddRecordingsToTable = ({
  selectedRecordingIds,
  tables,
  project,
}: ButtonAddRecordingsToTableProps) => {
  const routeMap = useRouteMapContext((s) => s.routeMap);
  const currentTenant = useTenantContext((s) => s.currentTenant);
  const files = useProjectFilesStore((s) => s.files);
  const [loading, setLoading] = useState(false);
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);

  const togglePopover = () => setIsPopoverOpen((isOpen) => !isOpen);
  const closePopover = () => setIsPopoverOpen(false);

  const [getToolSpec] = useGetToolboxSpecByAnalysisTableIdLazyQuery({
    onError: (err) => captureException(err),
  });

  const onSubmit = async (tableId: string, groupId: string) => {
    setLoading(true);
    try {
      const { data: analysisTableToolSpecData } = await getToolSpec({
        variables: {
          analysisTableId: tableId,
        },
      });

      const toolVersion = analysisTableToolSpecData?.analysisTable?.toolVersion;

      const toolSpec = (toolVersion?.toolSpec ?? undefined) as
        | ToolSpec
        | undefined;

      assert(
        isNonNullish(toolVersion) && toolSpec !== undefined,
        "Expected tool spec to be defined when creating an analysis table",
      );

      const rows = selectedRecordingIds.map((selectedRecordingId) => {
        const rowDatum = getDefaultRowDatum(toolSpec, toolVersion);

        // auto-fill tool path params if only one valid file exists
        const toolPathParams = toolSpec.params.filter(isToolPathParam);

        toolPathParams.forEach((param) => {
          const fileFilters = param.type.file_filters;

          // Skip auto-population for params with no file filters
          if (fileFilters === undefined) {
            return;
          }

          const matchingDrsFiles = files
            // Remove files belonging to other recordings
            .filter((file) => {
              const { recordings } = file;
              return recordings.some(({ id }) => id === selectedRecordingId);
            })
            /* Remove files that aren't visible in a column. Analysis results
               assigned back to datasets will belong to recordings even if no
               analysis result columns display them. This presents a confusing
               UX where auto-population does not work because there are multiple
               files assigned to a recording that match the file filters even
               though the user can't see them in the recordings table. */
            .filter((file) => file.columns.length > 0)
            // Remove files that don't match any of the file filters
            .filter((file) =>
              fileFilters.some((filter) => isFileFilterMatch(file, filter)),
            );

          if (matchingDrsFiles.length === 1) {
            const matchingFile = matchingDrsFiles[0];

            rowDatum.params[param.key] = matchingFile.isSeries
              ? matchingFile.seriesFiles.map(({ id }) => id)
              : [matchingFile.id];

            const datasetIds = matchingFile.datasets.map(({ id }) => id);
            rowDatum["datasets"] = uniq(datasetIds);

            const recordingIds = matchingFile.recordings.map(({ id }) => id);
            rowDatum["recordings"] = uniq(recordingIds);
          }
        });

        return rowDatum;
      });

      const rowCreationErrors: Error[] = [];

      for (const row of rows) {
        try {
          await libraryAnalysisTableRowDjango.post({
            id: row.id,
            table: tableId,
            data: row.params,
            tenant: currentTenant.id,
            attachments: {
              datasets: row.datasets ?? [],
              recordings: row.recordings ?? [],
            },
            project: project.id,
            selections: null,
            metadatum_references: row.metadatumReferences,
            tool_version: row.toolVersion.id,
          });
        } catch (err) {
          captureException(err);
          rowCreationErrors.push(new Error("Failed to create row"));
        }
      }
      if (rowCreationErrors.length > 0) {
        // TODO Find graceful way to notify the user that the table was created
        // but some rows failed to be created in the table
      }
    } catch (err) {
      captureException(err);
      addUtilityToastFailure("failed to create rows");
    }
    setLoading(false);
    routeMap["PROJECT_ANALYSIS_TABLE_GROUP"]
      .dynamicPath({
        tenantKey: currentTenant.key,
        projectKey: project.key,
        analysisTableGroupId: groupId,
      })
      .navigateTo();
  };

  return (
    <EuiPopover
      id={htmlIdGenerator()()}
      button={
        <ButtonIconPermissioned
          aria-label="Add row to table"
          isLoading={loading}
          iconType="plus"
          size="m"
          disabled={selectedRecordingIds.length === 0}
          onClick={(e: React.MouseEvent) => {
            e.stopPropagation();
            togglePopover();
          }}
          requiredPermission="edit"
          defaultTooltip={
            selectedRecordingIds.length === 0
              ? "Select at least one recording to add to table"
              : `Add ${selectedRecordingIds.length} selected recording${
                  selectedRecordingIds.length === 1 ? "" : "s"
                } to table`
          }
        />
      }
      isOpen={isPopoverOpen}
      closePopover={closePopover}
      anchorPosition="upCenter"
    >
      <EuiFlexGroup
        alignItems="stretch"
        direction="column"
        gutterSize="none"
        justifyContent="center"
      >
        {tables.map((table) => (
          <EuiFlexItem key={table.id}>
            <EuiButtonEmpty
              color="text"
              onClick={(e: React.MouseEvent) => {
                e.stopPropagation();
                void onSubmit(table.id, table.groupId);
              }}
            >
              {table.name}
            </EuiButtonEmpty>
          </EuiFlexItem>
        ))}
      </EuiFlexGroup>
    </EuiPopover>
  );
};
