/** @jsxImportSource @emotion/react */

import { css } from "@emotion/react";
import {
  EuiBreadcrumbs,
  EuiIcon,
  EuiPageHeader,
  EuiPageHeaderSection,
  EuiTitle,
} from "@inscopix/ideas-eui";
import { useRouteMapContext } from "providers/RouteMapProvider/RouteMapProvider";
import { useProjectDataContext } from "../ProjectDataProvider";
import { usePageProjectTasksStyles } from "./usePageProjectTasksStyles";
import { useTenantContext } from "providers/TenantProvider/TenantProvider";

export const pageProjectTasksHeaderHeight = "70px";

export const PageProjectTasksHeader = () => {
  const { header, headerPaddingSize } = usePageProjectTasksStyles();
  const { project } = useProjectDataContext();
  const routeMap = useRouteMapContext((s) => s.routeMap);
  const currentTenant = useTenantContext((s) => s.currentTenant);

  return (
    <EuiPageHeader
      paddingSize={headerPaddingSize}
      css={css`
        height: ${header};
      `}
    >
      <EuiPageHeaderSection>
        <EuiBreadcrumbs
          data-test-subj="project-tasks-breadcrumbs"
          truncate={false}
          aria-label="breadcrumbs"
          breadcrumbs={[
            {
              text: (
                <>
                  <EuiIcon size="s" type="arrowLeft" />
                  {project.name}
                </>
              ),
              "aria-current": false,
              href: routeMap["PROJECT"].dynamicPath({
                tenantKey: currentTenant.key,
                projectKey: project.key,
              }).path,
              onClick: (e) => {
                e.preventDefault();
                routeMap["PROJECT"]
                  .dynamicPath({
                    tenantKey: currentTenant.key,
                    projectKey: project.key,
                  })
                  .navigateTo();
              },
            },
          ]}
        />
        <EuiTitle size="m">
          <h1 data-test-subj="dataset-header-title">All Tasks</h1>
        </EuiTitle>
      </EuiPageHeaderSection>
    </EuiPageHeader>
  );
};
