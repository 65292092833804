import {
  EuiFlexGroup,
  EuiHeaderSectionItem,
  EuiHeaderSectionItemButton,
  EuiIcon,
  EuiPopover,
  htmlIdGenerator,
} from "@inscopix/ideas-eui";
import { SupportLinks } from "components/SupportLinks/SupportLinks";
import { useState } from "react";

export const HelpMenu = () => {
  const [isOpen, setIsOpen] = useState(false);
  const closePopover = () => setIsOpen(false);
  const button = (
    <EuiHeaderSectionItemButton
      aria-controls={htmlIdGenerator()()}
      aria-expanded={isOpen}
      aria-haspopup="true"
      aria-label="Button to open help menu"
      onClick={() => setIsOpen(!isOpen)}
    >
      <EuiIcon type="questionInCircle" size="l" />
    </EuiHeaderSectionItemButton>
  );

  return (
    <>
      <EuiHeaderSectionItem>
        <EuiPopover
          id={htmlIdGenerator()()}
          ownFocus
          button={button}
          isOpen={isOpen}
          anchorPosition="downRight"
          closePopover={closePopover}
        >
          <EuiFlexGroup direction="column" gutterSize="xl">
            <SupportLinks closePopover={closePopover} />
          </EuiFlexGroup>
        </EuiPopover>
      </EuiHeaderSectionItem>
    </>
  );
};
