import { EuiSelect } from "@inscopix/ideas-eui";
import { withUserPermission } from "hocs/withUserPermission";
import { withUserPermissionBase } from "hocs/withUserPermissionBase";

/**
 * Enhanced {@link EuiSelect} that is disabled when the user lacks a
 * required permission.
 *
 * Context independent.
 */
export const SelectPermissionedBase = withUserPermissionBase(EuiSelect);

/**
 * Enhanced {@link EuiSelect} that is disabled when the user lacks a
 * required permission.
 *
 * Reads permissions from project permissions context.
 */
export const SelectPermissioned = withUserPermission(SelectPermissionedBase);
