import { EuiBadge } from "@inscopix/ideas-eui";
import { DetailedCellError } from "@inscopix/ideas-hyperformula";

interface CellValueRendererErrorProps {
  error: DetailedCellError;
}

/** Component that renders a `DetailedCellError` cell value` */
export const CellValueRendererError = ({
  error,
}: CellValueRendererErrorProps) => {
  return (
    <EuiBadge color="warning" title="">
      ERROR
    </EuiBadge>
  );
};
