import { useProjectPermissionStore } from "pages/project/ProjectPermissionProvider";
import { ReactElement, ReactNode } from "react";
import { UserPermission } from "types/UserAccessLevels";

/** Props for the wrapped component (i.e. the component consumed by the HOC) */
type WrappedProps = {
  // EUI is inconsistent about using `disabled` and `isDisabled` props
  // It takes a pessimistic approach of if any of these two props are true, the component is disabled
  disabled?: boolean;
  isDisabled?: boolean;
  title?: string;
};

/** Props for the enhanced component (i.e. the component returned by the HOC) */
type EnhancedProps<P> = P & {
  // Permission necessary for the component to be enabled
  requiredPermission: UserPermission;
  // Optional tooltip to display when the user has sufficient permissions
  defaultTooltip?: ReactNode;

  // Suppress the default permission tooltip
  suppressPermissionTooltip?: boolean;
};

/**
 * HOC that wraps a project component in a tooltip and disables it if the user
 * does not have sufficient permissions within the current project.
 * @param WrappedComponent The project component to enhance.
 * @returns The enhanced component.
 */
export const withUserPermission = <P extends WrappedProps>(
  /* Wrapped components must be function components to allow for generic type
     arguments to be propagated. TypeScript does not support this for classes. */
  WrappedComponent: (props: P) => ReactElement | null,
) => {
  const EnhancedComponent = (props: EnhancedProps<P>) => {
    const isPermitted = useProjectPermissionStore(
      (s) => s.permissions[props.requiredPermission],
    );

    return <WrappedComponent {...props} isPermitted={isPermitted} />;
  };
  return EnhancedComponent;
};
