/** @jsxImportSource @emotion/react */
import { memo, useState } from "react";
import { ICellRendererParams } from "ag-grid-community";
import {
  ToolParam,
  ToolParamValue,
  ToolParamsGridRowDatum,
} from "../ToolParamsGrid.types";
import { isNonNullish } from "utils/isNonNullish";
import { MetadatumReferenceSelector } from "./MetadatumReferenceSelector";
import { css } from "@emotion/react";
import { EuiFlexGroup, EuiFlexItem } from "@inscopix/ideas-eui";
import { useMouseOver } from "hooks/useMouseOver";
import { useValueValidator } from "../ToolParamsGridValueValidatorContext";
import { isEqual } from "lodash";
import { useToolParamsGridRowDataContext } from "../ToolParamsGridRowDataProvider";

const styles = {
  value: (isDefaultValue: boolean) => css`
    color: ${isDefaultValue ? "grey" : undefined};
    font-style: ${isDefaultValue ? "italic" : undefined};
  `,
};

export interface GridRendererToolParamProps
  extends Pick<ICellRendererParams, "eParentOfValue"> {
  rowId: ToolParamsGridRowDatum["id"];
  value: ToolParamValue;
  defaultValue: string | number | boolean | undefined;
  toolParam: ToolParam;
  suppressMetadatumSelector?: boolean;
}

/**
 * A component for rendering any `ToolParam` grid cells
 */
export const GridRendererToolParam = memo(function GridRendererToolParam({
  rowId,
  value,
  defaultValue,
  toolParam,
  eParentOfValue,
  suppressMetadatumSelector,
}: GridRendererToolParamProps) {
  const { isMouseOver: isMouseOverCell } = useMouseOver(eParentOfValue);
  const [isMetadatumSelectorOpen, setIsMetadatumSelectorOpen] = useState(false);

  /**
   * We only want param cells to re render when their value changes,
   * when a row is executed, when the rows validation state changes,
   * or when the existence of a metadatum reference.
   * The value getter only listens to param value changes so we need these selectors
   * to avoid stale state from row data
   */
  const isRowExecuted = useToolParamsGridRowDataContext(
    (s) => s.rowData.find((row) => row.id === rowId)?.task_id !== undefined,
  );

  const errorsByParamKey = useValueValidator(
    (s) => s.errorsByRowId[rowId] ?? {},
    isEqual,
  );

  const hasMetadatumReference = useToolParamsGridRowDataContext(
    (s) =>
      s.rowData.find((row) => row.id === rowId)?.metadatumReferences[
        toolParam.key
      ] !== undefined,
  );

  const isValid = errorsByParamKey[toolParam.key] === undefined;
  const style = {
    backgroundColor: !isValid ? "rgba(255, 0, 0, 0.05)" : "inherit",
    height: "100%",
    width: "100%",
    padding: "calc(var(--ag-cell-horizontal-padding) - 1px)",
    alignItems: "center",
  };
  return (
    <EuiFlexGroup justifyContent="spaceBetween" style={style}>
      <EuiFlexItem>
        <span
          css={styles.value(value?.toString() === defaultValue?.toString())}
        >
          {isNonNullish(value) ? value.toString() : ""}
        </span>
      </EuiFlexItem>
      {!suppressMetadatumSelector &&
        ((!isRowExecuted && isMouseOverCell) ||
          isMetadatumSelectorOpen ||
          hasMetadatumReference) && (
          <EuiFlexItem grow={false}>
            <MetadatumReferenceSelector
              key={toolParam.key}
              rowId={rowId}
              toolParam={toolParam}
              onPopoverOpen={() => setIsMetadatumSelectorOpen(true)}
              onPopoverClose={() => setIsMetadatumSelectorOpen(false)}
              currentValue={value}
              isReadonly={isRowExecuted}
            />
          </EuiFlexItem>
        )}
    </EuiFlexGroup>
  );
});
