import { Dataset, FileSource, ProjectFilesManagerQuery } from "graphql/_Types";
import { ProjectFile, ProjectFileColumn } from "./ProjectFilesManager";
import { RecordingsGridColDef } from "components/RecordingsGrid/RecordingsGrid.types";
import { get, orderBy, set } from "lodash";
import assert from "assert";
import { isNonNullish } from "utils/isNonNullish";
import { isAnalysisTableRowAttachments } from "../../components/ToolParamsGrid/ToolParamsGrid.helpers";
import { isDefined } from "../../utils/isDefined";
import { captureException } from "@sentry/react";
import { isNonNull } from "utils/isNonNull";
import { SERIES_TIMING_KEYS } from "types/MetadataMap";

/**
 * Formats the project files manager query response data
 * @param project The project returned from the query response
 * @returns The formatted data as project files
 */
export const formatData = (
  project: NonNullable<ProjectFilesManagerQuery["project"]>,
) => {
  /* Create an analysis result recordings table column lookup map.
     Analysis table ID → DRS object key → Dataset ID → Recordings table column */
  const analysisResultColumnMap = (() => {
    const map: Record<
      string,
      Record<string, Record<string, ProjectFileColumn>>
    > = {};

    project.datasets.nodes.forEach((dataset) => {
      dataset.recordingsTable?.columns.nodes.forEach((column) => {
        const colDef = column.colDef as RecordingsGridColDef;
        if (colDef.type === "analysisResult") {
          const { analysisTableId, resultKey } = colDef;
          set(map, [analysisTableId, resultKey, dataset.id], column);
        }
      });
    });

    return map;
  })();

  const files: ProjectFile[] = project.activeFiles.nodes
    .map((file) => {
      const source = file.source ?? FileSource.Unknown;
      if (source === FileSource.Unknown) {
        captureException(
          "Unexpected unkown file source in ProjectFilesManager",
        );
        return null;
      }
      const { dataset, user } = file;
      assert(isNonNullish(user), "Failed to fetch user for DRS object");

      /* If the file is the result of an analysis, get the analysis table row
       that generated the file. A DRS object can only belong to one output
       group, hence index 0. */
      const task = file.outputGroupFiles.nodes[0]?.outputGroup?.task;
      const analysisTableRow = task?.analysisTableRow;

      const outputGroup = (() => {
        if (!file.outputGroupFiles.nodes[0]?.outputGroup) return;
        else {
          assert(task, "No task found on output group");
          return {
            id: file.outputGroupFiles.nodes[0].outputGroup.id,
            task: { id: task.id },
          };
        }
      })();

      const recordings = (() => {
        const recordingNumbersAndDatasetsById = (() => {
          const recordings = project.datasets.nodes
            .flatMap(({ recordingsTable, id, prefix }) => {
              if (recordingsTable === null) {
                return null;
              }
              return recordingsTable.recordings.nodes.map((recording) => ({
                dataset: {
                  id,
                  prefix,
                },
                recording,
              }));
            })
            .filter(isNonNull);

          return new Map<
            string,
            {
              number: string;
              dataset: Pick<Dataset, "id" | "prefix">;
            }
          >(
            recordings.map(({ dataset, recording }) => {
              assert(
                isNonNullish(recording.number),
                'Expected "number" to exist for recording',
              );

              return [recording.id, { number: recording.number, dataset }];
            }),
          );
        })();

        switch (source) {
          case FileSource.Uploaded:
            if (file.assignment?.recording) {
              assert(
                file.assignment.recording.number !== null,
                'Expected "number" to exist for recording',
              );
              assert(
                file.dataset !== null,
                'Expected "dataset" to exist for recording',
              );
              // only one recording for uploaded files
              return [
                {
                  id: file.assignment.recording.id,
                  number: file.assignment.recording.number,
                  dataset: file.dataset,
                },
              ];
            } else {
              return [];
            }
          case FileSource.AnalysisResult:
            // analysis results can attach to multiple recordings
            if (
              analysisTableRow &&
              // if the row has attach results toggled off, don't assign anything
              analysisTableRow.activeAttachResults &&
              isAnalysisTableRowAttachments(analysisTableRow?.attachments) &&
              analysisTableRow.attachments.recordings.length > 0
            ) {
              // read the attachments from the row
              return analysisTableRow.attachments.recordings
                .map((recordingId) => {
                  const recording =
                    recordingNumbersAndDatasetsById.get(recordingId);
                  if (recording === undefined) {
                    captureException(
                      "Failed to get recording info for analysis result",
                    );
                    return null;
                  }

                  return {
                    id: recordingId,
                    ...recording,
                  };
                })
                .filter(isNonNull);
            } else return [];
        }
      })();

      const datasets = (() => {
        switch (source) {
          case FileSource.Uploaded:
            // only one dataset for uploaded files
            return dataset ? [dataset] : [];
          case FileSource.AnalysisResult:
            // analysis results can attach to multiple datasets
            if (
              analysisTableRow &&
              // if the row has attach results toggled off, don't assign anything
              analysisTableRow.activeAttachResults &&
              isAnalysisTableRowAttachments(analysisTableRow.attachments) &&
              analysisTableRow.attachments.datasets.length > 0
            ) {
              // read the attachments from the row
              return analysisTableRow.attachments.datasets.map((datasetId) => ({
                id: datasetId,
                name:
                  project.datasets.nodes.find(
                    (dataset) => dataset.id === datasetId,
                  )?.name ?? "",
              }));
            } else return [];
        }
      })();

      const columns = (() => {
        switch (source) {
          case FileSource.Uploaded:
            // only one column for uploaded files
            return file.assignment && file.assignment.column !== null
              ? [
                  {
                    ...file.assignment.column,
                    colDef: file.assignment.column
                      .colDef as RecordingsGridColDef,
                  },
                ]
              : [];
          case FileSource.AnalysisResult:
            // analysis results can appear in multiple datasets
            if (
              isNonNullish(analysisTableRow) &&
              file.key !== null &&
              datasets !== null
            ) {
              // return a column for every dataset with a matching result column
              return datasets
                ?.map(({ id }) =>
                  get(analysisResultColumnMap, [
                    analysisTableRow.analysisTableId,
                    file.key as string,
                    id,
                  ]),
                )
                .filter(isDefined);
            } else return [];
        }
      })();

      const seriesInfo = (() => {
        if (file.isSeries) {
          let seriesFiles = file.seriesFiles.nodes.map((seriesMember) => {
            return {
              ...seriesMember,
              size: seriesMember.size ?? "0",
            };
          });

          // sort by series_order if present
          if (seriesFiles.every((file) => file.seriesOrder !== null)) {
            seriesFiles = orderBy(
              seriesFiles,
              [(file) => file.seriesOrder],
              ["asc"],
            );
          } else {
            // Determine if metadata exists to order by start time
            let canOrderByStartTime = true;
            const seriesFilesWithStartTime = seriesFiles.map((file) => {
              const startTimeNum = file.metadata.nodes.find(
                (node) => node.metadatum?.key === SERIES_TIMING_KEYS.num,
              )?.metadatum?.activeValue;
              const startTimeDem = file.metadata.nodes.find(
                (node) => node.metadatum?.key === SERIES_TIMING_KEYS.dem,
              )?.metadatum?.activeValue;

              let startTime: number | null = null;

              if (
                typeof startTimeNum === "number" &&
                typeof startTimeDem === "number"
              ) {
                startTime = startTimeNum / startTimeDem;
              } else {
                canOrderByStartTime = false;
              }

              return { ...file, startTime };
            });

            if (canOrderByStartTime) {
              seriesFiles = orderBy(
                seriesFilesWithStartTime,
                [(file) => file.startTime],
                ["asc"],
              );
            } else {
              // fallback order by name when no series_order or start time metadata exists
              seriesFiles = orderBy(
                seriesFiles,
                [(file) => file.name],
                ["asc"],
              );
            }
          }

          return {
            isSeries: file.isSeries,
            seriesFiles,
          };
        }
        return {
          isSeries: file.isSeries,
          seriesFiles: null,
        };
      })();

      return {
        ...file,
        source,
        user,
        datasets,
        recordings,
        activeAssignment: file.assignment ? file.assignment : undefined,
        columns,
        outputGroup,
        ...seriesInfo,
        size: file.size ?? "0",
      };
    })
    .filter(isNonNull);

  return files;
};
