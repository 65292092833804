import { createStore } from "zustand";
import { FileStatus, FileUploadStatus } from "types/constants";
import { cloneDeep, omit, throttle } from "lodash";
import { updateCacheFragment } from "utils/cache-fragments";
import { File as DrsFile, Project, Tenant } from "graphql/_Types";
import { isDefined } from "utils/isDefined";

export type QueuedFile = {
  drsFile: {
    id: DrsFile["id"];
    partSize: NonNullable<DrsFile["partSize"]>;
  };
  project: Pick<Project, "id" | "key" | "name" | "tenantId">;
  blob: Blob;
  tenantId: Tenant["id"];
};

export type FileUploadStoreState = {
  filesById: Record<
    DrsFile["id"],
    | {
        fileSize: number;
        progress: number | undefined;
        isUploaded: boolean;
        error: Error | undefined;
        project: Pick<Project, "id" | "key" | "name" | "tenantId">;
      }
    | undefined
  >;
  queue: QueuedFile[];
  initTime: string;
  enqueueFile: (file: QueuedFile) => void;
  dequeueFile: () => QueuedFile;
  cancelFileUploads: (fileIds: string[]) => void;
  onUploadProgress: (drsFileId: DrsFile["id"], progress: number) => void;
  onUploadError: (drsFileId: DrsFile["id"], error: Error) => void;
  onUploadComplete: (drsFileId: DrsFile["id"]) => void;
};

type CreateFileUploadStoreOptions = {
  cancelFileUpload: (fileId: string) => void;
};

/**
 * Create a new Zustand store for storing data for uploading files and actions
 * for managing the upload queue.
 * @param options
 * @returns The Zustand store.
 */
export const createFileUploadStore = ({
  cancelFileUpload,
}: CreateFileUploadStoreOptions) =>
  createStore<FileUploadStoreState>((set, get) => ({
    filesById: {},
    queue: [],
    /* Represents the time the store was created. This is necessary for filtering
     out failed uploads from other sessions. */
    initTime: new Date().toISOString(),
    cancelFileUploads: (fileIds) => {
      // Cancel queued uploads
      set((state) => ({
        ...state,
        filesById: omit(state.filesById, fileIds),
        queue: state.queue.filter((file) => !fileIds.includes(file.drsFile.id)),
      }));

      // Cancel current upload
      fileIds.forEach((id) => {
        cancelFileUpload(id);
      });
    },
    enqueueFile: (file) => {
      set((state) => ({
        ...state,
        filesById: {
          ...state.filesById,
          [file.drsFile.id]: {
            fileSize: file.blob.size,
            progress: undefined,
            isUploaded: false,
            error: undefined,
            project: file.project,
          },
        },
        queue: [...state.queue, file],
      }));
    },
    dequeueFile: () => {
      const file = get().queue[0];
      set((state) => ({
        ...state,
        queue: state.queue.slice(1),
      }));
      return file;
    },
    onUploadProgress: throttle((drsFileId: DrsFile["id"], progress: number) => {
      const file = get().filesById[drsFileId];
      if (isDefined(file)) {
        set((state) => ({
          ...state,
          filesById: {
            ...state.filesById,
            [drsFileId]: {
              ...file,
              progress,
              isUploaded: progress === 100,
            },
          },
        }));
      }
    }, 500),
    onUploadError: (drsFileId, error) => {
      const file = get().filesById[drsFileId];
      if (isDefined(file)) {
        set((state) => ({
          ...state,
          filesById: {
            ...state.filesById,
            [drsFileId]: {
              ...file,
              error,
            },
          },
        }));
      }
    },
    onUploadComplete: (drsFileId) => {
      const file = get().filesById[drsFileId];
      if (isDefined(file)) {
        set((state) => ({
          ...state,
          filesById: {
            ...state.filesById,
            [drsFileId]: {
              ...file,
              isUploaded: true,
            },
          },
        }));

        let fileCreated: string | undefined;

        updateCacheFragment({
          __typename: "File",
          id: drsFileId,
          update: (data) => {
            const newData = cloneDeep(data);
            newData.uploadStatus = FileUploadStatus.COMPLETE;
            newData.status = FileStatus["AVAILABLE"];
            fileCreated = newData.dateCreated;
            return newData;
          },
        });
        updateCacheFragment({
          __typename: "Project",
          id: file.project.id,
          update: (data) => {
            const newData = cloneDeep(data);
            const oldSize = Number(newData.activeStorageSize ?? "0");
            const newSize = oldSize + file.fileSize;
            newData.activeStorageSize = newSize.toString();
            newData.dateLastActive = fileCreated;
            return newData;
          },
        });
      }
    },
  }));
