/** @jsxImportSource @emotion/react */

import {
  EuiCheckbox,
  EuiDataGrid,
  EuiDataGridColumn,
  EuiDataGridControlColumn,
  EuiDataGridSchemaDetector,
  EuiFlexGroup,
  EuiIcon,
  EuiPanel,
  useEuiTheme,
} from "@inscopix/ideas-eui";
import { FileBadge } from "components/FileBadge/FileBadge";
import { useSelectionState } from "components/FlyoutFiles/useSelectionState";
import { UserAvatarRenderer } from "components/UserAvatarRenderer/UserAvatarRenderer";
import { filesize } from "utils/filesize";
import {
  ApplicationUser,
  DatasetRecordingsTableColumn,
  Dataset,
  FileRecordingGroup,
  File as DrsFile,
  FileSource,
} from "graphql/_Types";
import { orderBy } from "lodash";
import { useCallback, useEffect, useMemo, useState } from "react";
import { FileStatus } from "types/constants";
import { isDefined } from "utils/isDefined";
import { FileBrowserActionControls } from "./FileBrowserActionControls";
import { css, Interpolation, Theme } from "@emotion/react";
import { htmlIdGenerator } from "@inscopix/ideas-eui";
import { RequireAllOrNone, SetNonNullable } from "type-fest";
import assert from "assert";
import { FileBrowserDetailRendererAnalysisResult } from "./FileBrowserDetailRendererAnalysisResult";
import {
  FileBrowserSearchAndFilter,
  FileBrowserSearchAndFilterProps,
  applyFileBrowserSearchAndFilter,
} from "components/FileBrowser/FileBrowserSearchAndFilter";
import { useLocalStorage } from "hooks/useLocalStorage";
import { FILE_TYPES_BY_ID } from "types/FileTypes";
import { RecordingIdentifierBadge } from "components/RecordingIdentifierBadge/RecordingIdentifierBadge";
import { formatDate } from "utils/formatDate";
import { FileBrowserCellRendererFileType } from "./FileBrowserCellRendererFileType";
import { ModalProvider } from "providers/ModalProvider/ModalProvider";

type TFile = Pick<
  DrsFile,
  | "id"
  | "name"
  | "fileType"
  | "fileFormat"
  | "fileStructure"
  | "status"
  | "dateCreated"
  | "datasetId"
  | "uploadStatus"
  | "projectId"
  | "size"
  | "seriesParentId"
  | "processingStatus"
  | "fileFormat"
  | "originalName"
>;

export type FileBrowserFile = TFile & {
  datasets: Pick<Dataset, "id" | "name">[];
  source:
    | {
        type: FileSource.Uploaded;
        assignments?: [
          {
            dataset: Pick<Dataset, "id" | "prefix">;
            recording: SetNonNullable<
              Pick<FileRecordingGroup, "id" | "number">,
              "number"
            >;
            columnId: DatasetRecordingsTableColumn["id"];
          },
        ];
      }
    | {
        type: FileSource.AnalysisResult;
        assignments: {
          dataset: Pick<Dataset, "id" | "prefix">;
          recording: SetNonNullable<
            Pick<FileRecordingGroup, "id" | "number">,
            "number"
          >;
        }[];
      };

  user: Pick<ApplicationUser, "id" | "firstName" | "lastName">;
} & (
    | { isSeries: true; seriesFiles: TFile[] }
    | { isSeries: false; seriesFiles: null }
  );

const schemaDetectors = [
  {
    type: "size" as const,
    comparator: () => 0 as const,
    detector: () => 0 as const,
    sortTextAsc: "Small-Large",
    sortTextDesc: "Large-Small",
    icon: "tokenFile",
  },
  {
    type: "text" as const,
    comparator: () => 0 as const,
    detector: () => 0 as const,
    sortTextAsc: "A-Z",
    sortTextDesc: "Z-A",
    icon: "tokenText",
  },
  {
    type: "source" as const,
    comparator: () => 0 as const,
    detector: () => 0 as const,
    sortTextAsc: "Analysis Result - Uploaded",
    sortTextDesc: "Uploaded - Analysis Result",
    icon: "tokenText",
  },
] satisfies EuiDataGridSchemaDetector[];

type AvailableSchema =
  | "datetime"
  | "boolean"
  | (typeof schemaDetectors)[number]["type"];

export type FileBrowserColumn = Omit<
  EuiDataGridColumn,
  | "id"
  // disable column level details specification in favor of custom column props
  // that allow us to dynamically determine and render detail views via callbacks
  | "isDetails"
> & {
  // note: if you add a new column id, you must decide if it should be searchable or not
  // if it's not searchable, disable it by adding it to unSearchableColumnIds
  id:
    | Extract<
        keyof FileBrowserFile,
        "name" | "dateCreated" | "status" | "fileType" | "isSeries"
      >
    | Extract<keyof FileBrowserFile, "user" | "size" | "source">
    | "assignment"
    | "dataset";
  schema: AvailableSchema;
  defaultVisibility?: "hidden";
};

export const fileBrowserValueGettersByColId: Record<
  FileBrowserColumn["id"],
  ColumnProps["sortValueGetter"]
> = {
  name: (file) => file.name,
  dateCreated: (file) => file.dateCreated,
  status: (file) => FileStatus[file.status],
  user: (file) => file.user.firstName + " " + file.user.lastName,
  fileType: (file) => {
    const fileType = file.fileType;
    return fileType === null
      ? "Unknown"
      : FILE_TYPES_BY_ID[fileType]?.name ?? "Unknown";
  },
  /**
   * In isolation sorting assignments would be done in steps (prefix then number).
   * However given the current API, string concatenation is the most straightforward way
   * to achieve the same result without reworking sorting entirely and potentially breaking something
   */
  assignment: (file) =>
    file.source.assignments
      ?.map(({ dataset, recording }) => {
        // pad number with leading zeroes to ensure proper sorting
        const paddedNumber = `${recording.number}`.padStart(12, "0");
        // use 0 as delimiter so shorter matching prefixes sort before longer matching prefixes (A before AA)
        return `${dataset.prefix}0${paddedNumber}`;
      })
      .sort()
      .join(", ") ??
    // sort assigned first
    "~~~~~~",
  size: (file) => BigInt(file.size ?? 0),
  isSeries: (file) => (file.isSeries ? 1 : 0),
  source: ({ uploadStatus }) =>
    uploadStatus !== null ? "Uploaded" : "Analysis Result",
  dataset: ({ datasets }) =>
    datasets.length > 0
      ? datasets
          .map(({ name }) => name)
          .sort()
          .join(", ")
      : // sort files with dataset first
        "~~~~~~",
} as const;

// some columns don't make sense to be searchable (e.g. boolean columns)
const unSearchableColumnIds: ReadonlySet<FileBrowserColumn["id"]> = new Set([
  "isSeries",
  "source",
  "size",
]);

// list of searchable columns
export const fileBrowserSearchableColumnIds: FileBrowserColumn["id"][] = (
  Object.keys(fileBrowserValueGettersByColId) as Array<
    keyof typeof fileBrowserValueGettersByColId
  >
).filter((colId) => !unSearchableColumnIds.has(colId));

// can't be stored on columns because may have dependencies
// that cause column reference to change and break sorting
type ColumnProps = RequireAllOrNone<
  {
    cellRenderer: (props: { file: FileBrowserFile }) => React.ReactNode;
    sortValueGetter: (file: FileBrowserFile) => string | number | bigint;
    isDetails: (file: FileBrowserFile) => boolean;
    detailRenderer: (file: FileBrowserFile) => NonNullable<React.ReactNode>;
  },
  "detailRenderer" | "isDetails"
>;

interface FileRendererProps {
  file: FileBrowserFile;
}

type TAction = {
  // Actions return a Promise that resolve with a list of successful files
  // when the action completes. This is necessary for the browser to manage
  // selection state and deselect files when actions complete
  onExecute: (files: FileBrowserFile[]) => Promise<{
    successfulFiles: Pick<FileBrowserFile, "id">[];
    errors: Error[];
  }>;
  isDisabled?: (files: FileBrowserFile[]) => boolean;
  disabledToolTip?: ((files: FileBrowserFile[]) => string | undefined) | string;
  suppressLoading?: boolean | ((files: FileBrowserFile[]) => boolean);
};

const styles = {
  toolbarText: css`
    font-size: 12px;
    font-weight: 600;
    padding: 0px 8px;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Oxygen",
      "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
      sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  `,
};

export type FileBrowserActionName =
  | "archive"
  | "delete"
  | "download"
  | "unassign"
  | "unarchive"
  | "createSeries"
  | "rename"
  | "revertSeries";

export type FileBrowserActions = { [key in FileBrowserActionName]?: TAction };

export interface FileBrowserProps {
  files: FileBrowserFile[];
  fileRenderer?: (props: FileRendererProps) => React.ReactNode;
  selection?: {
    actions: FileBrowserActions;
  };
  defaultSource: FileBrowserSearchAndFilterProps["selectedOptions"]["source"];
  datasetFilter: FileBrowserSearchAndFilterProps["datasetFilter"];
  css?: Interpolation<Theme>;
  defaultHiddenColumns?: FileBrowserColumn["id"][];
}

const defaultRenderer: NonNullable<FileBrowserProps["fileRenderer"]> = ({
  file,
}) => <FileBadge drsFile={{ ...file, source: file.source.type }} />;

/**
 * ********************************************************
 * IMPORTANT NOTE TO ANYONE WORKING ON THIS IN THE FUTURE
` * IF THIS NEEDS TO ACCESS INFORMATION IN DATASET, PROJECT
 * OR OTHER CONTEXTS, PLEASE PASS INFO IN AS PROPS SUCH THAT
 * THIS CAN ALWAYS DISPLAY A LIST OF FILES
 * ********************************************************
 */

export const FileBrowser = ({
  files,
  fileRenderer = defaultRenderer,
  selection,
  defaultSource,
  datasetFilter,
  css: cssFromProps,
  defaultHiddenColumns,
}: FileBrowserProps) => {
  const { euiTheme } = useEuiTheme();

  // Search and filter
  const [searchQuery, setSearchQuery] = useState("");
  const [selectedFilterOptions, setSelectedFilterOptions] = useState<
    FileBrowserSearchAndFilterProps["selectedOptions"]
  >({
    source: defaultSource,
    datasetFilter: datasetFilter.find((option) => option.default),
  });

  // This needs to be persisted as a reference between renders for column reordering
  // Intentionally made a useState vs useMemo because a useMemo with dependencies
  // would cause a reference change that would break column ordering
  const [columns] = useState<FileBrowserColumn[]>(() => {
    const defaultColDef: Pick<EuiDataGridColumn, "isExpandable"> = {
      isExpandable: false,
    };
    const columns: FileBrowserColumn[] = [
      {
        id: "name",
        displayAsText: "Name",
        schema: "text",
      },
      {
        id: "dateCreated",
        schema: "datetime",
        displayAsText: "Date",
        defaultSortDirection: "desc",
      },
      {
        id: "source",
        displayAsText: "Source",
        schema: "source",
      },
      {
        id: "status",
        displayAsText: "Status",
        schema: "text",
        defaultVisibility: "hidden",
      },
      {
        id: "user",
        displayAsText: "User",
        schema: "text",
        initialWidth: 60,
      },
      {
        id: "fileType",
        displayAsText: "Type",
        schema: "text",
      },
      { id: "dataset", displayAsText: "Dataset", schema: "text" },
      {
        id: "assignment",
        displayAsText: "Recording",
        schema: "text",
      },
      {
        id: "size",
        displayAsText: "Size",
        schema: "size",
      },
      {
        id: "isSeries",
        displayAsText: "Series",
        schema: "boolean",
        initialWidth: 75,
        defaultVisibility: "hidden",
      },
    ];
    return columns.map((col) => ({ ...defaultColDef, ...col }));
  });

  // see above note about columns array reference needing to be static
  // any dynamic column props need to go here to avoid breaking column ordering
  const columnPropsByColumnId: Record<FileBrowserColumn["id"], ColumnProps> =
    useMemo(
      () => ({
        name: {
          cellRenderer: ({ file }) => fileRenderer({ file }),
          sortValueGetter: fileBrowserValueGettersByColId["name"],
        },
        dateCreated: {
          cellRenderer: ({ file }) => formatDate(file.dateCreated),
          sortValueGetter: fileBrowserValueGettersByColId["dateCreated"],
        },
        status: {
          cellRenderer: ({ file }) => <div>{FileStatus[file.status]}</div>,
          sortValueGetter: fileBrowserValueGettersByColId["status"],
        },
        user: {
          cellRenderer: ({ file }) => (
            <UserAvatarRenderer size={"s"} userId={file.user.id} />
          ),
          sortValueGetter: fileBrowserValueGettersByColId["user"],
        },
        fileType: {
          cellRenderer: FileBrowserCellRendererFileType,
          sortValueGetter: fileBrowserValueGettersByColId["fileType"],
        },
        dataset: {
          cellRenderer: ({ file }) => {
            return file.datasets
              .map(({ name }) => name)
              .sort()
              .join(",");
          },
          sortValueGetter: fileBrowserValueGettersByColId["dataset"],
        },
        assignment: {
          cellRenderer: ({ file }) => (
            <div style={{ display: "flex" }}>
              {file.source.assignments?.map(({ recording }) => (
                <RecordingIdentifierBadge
                  key={recording.id}
                  recordingId={recording.id}
                  showShortIdOnly
                />
              ))}
            </div>
          ),
          sortValueGetter: fileBrowserValueGettersByColId["assignment"],
        },
        size: {
          cellRenderer: ({ file }) => filesize(file.size),
          sortValueGetter: fileBrowserValueGettersByColId["size"],
        },
        isSeries: {
          cellRenderer: ({ file }) => {
            const isChecked = file.isSeries;
            return isChecked ? (
              <EuiIcon aria-label="checkmark" type="check" />
            ) : null;
          },
          sortValueGetter: fileBrowserValueGettersByColId["isSeries"],
        },
        source: {
          cellRenderer: ({ file }) => {
            const { source } = file;
            if (source.type === "UPLOADED") {
              return "Uploaded";
            }
            if (source.type === "ANALYSIS_RESULT") {
              return "Analysis Result";
            }
            throw new Error("Unhandled source type in FileBrowser");
          },
          sortValueGetter: fileBrowserValueGettersByColId["source"],
          isDetails: (file) => file.source.type === "ANALYSIS_RESULT",
          detailRenderer: (file) => (
            <FileBrowserDetailRendererAnalysisResult drsFile={file} />
          ),
        },
      }),
      [fileRenderer],
    );

  /**
   * Pagination
   */
  const [paginationDefault, updatePaginationDefault] = useLocalStorage(
    "isx.fileBrowser.paginationSize",
    25,
  );

  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: paginationDefault,
  });

  const setPageIndex = useCallback(
    (pageIndex: number) => setPagination((prev) => ({ ...prev, pageIndex })),
    [setPagination],
  );
  const setPageSize = useCallback(
    (pageSize: number) => {
      updatePaginationDefault(pageSize);
      setPagination((prev) => ({ ...prev, pageSize, pageIndex: 0 }));
    },
    [updatePaginationDefault],
  );

  /**
   * Column visibility
   */

  const [visibleColumns, setVisibleColumns] = useState<
    FileBrowserColumn["id"][]
  >(
    columns
      .filter(({ defaultVisibility }) => defaultVisibility !== "hidden")
      .filter(({ id }) => !defaultHiddenColumns?.includes(id))
      .map(({ id }) => id),
  );

  /**
   * Column sorting
   */

  const [sortingColumns, setSortingColumns] = useState<
    { id: FileBrowserColumn["id"]; direction: "asc" | "desc" }[]
  >([]);

  const data = useMemo(() => {
    const { sortValueGetters, sortDirections } = sortingColumns.reduce<{
      sortValueGetters: ColumnProps["sortValueGetter"][];
      sortDirections: ("asc" | "desc")[];
    }>(
      (sortData, { id: columnId, direction }) => {
        const columnProps = columnPropsByColumnId[columnId];
        sortData.sortValueGetters.push(columnProps.sortValueGetter);
        sortData.sortDirections.push(direction);
        return sortData;
      },
      { sortValueGetters: [], sortDirections: [] },
    );
    const filteredFiles = applyFileBrowserSearchAndFilter(
      files,
      selectedFilterOptions,
      searchQuery,
    );

    const sortedData = orderBy(
      // pre order by date so we don't have a confusing default sort applied
      orderBy([...filteredFiles], [(file) => file.dateCreated], ["desc"]),
      sortValueGetters,
      sortDirections,
    );
    return sortedData;
  }, [
    columnPropsByColumnId,
    files,
    searchQuery,
    selectedFilterOptions,
    sortingColumns,
  ]);

  /**
   * Selection state
   */

  const [selectedFiles, selectedFileActions] = useSelectionState(data);

  const leadingControlColumns = useMemo(() => {
    const cols: EuiDataGridControlColumn[] = [];
    if (isDefined(selection) && Object.keys(selection).length > 0) {
      cols.push({
        id: "selection",
        width: 32,
        headerCellRender: () => {
          const isIndeterminate =
            selectedFiles.size > 0 && selectedFiles.size < data.length;
          return (
            <EuiCheckbox
              id="selection-toggle"
              aria-label="Select all rows"
              indeterminate={isIndeterminate}
              checked={selectedFiles.size > 0}
              onChange={(e) => {
                if (isIndeterminate) {
                  // clear selection
                  selectedFileActions.deselectAll();
                } else {
                  if (e.target.checked) {
                    selectedFileActions.selectAll();
                  } else {
                    selectedFileActions.deselectAll();
                  }
                }
              }}
            />
          );
        },
        rowCellRender: ({ rowIndex }) => {
          const file = data[rowIndex];
          const isChecked = selectedFiles.has(file.id);
          return (
            <div>
              <EuiCheckbox
                id={htmlIdGenerator()()}
                aria-label={`Select row ${rowIndex}, ${data[rowIndex].name}`}
                checked={isChecked}
                onChange={(e) => {
                  if (e.target.checked) {
                    selectedFileActions.selectItems([file]);
                  } else {
                    selectedFileActions.deselectItems([file]);
                  }
                }}
              />
            </div>
          );
        },
      });
      return cols;
    }
  }, [data, selectedFileActions, selectedFiles, selection]);

  return (
    <div
      css={[
        {
          display: "flex",
          minWidth: 0,
          minHeight: 0,
          flex: 1,
        },
        cssFromProps,
      ]}
    >
      <EuiFlexGroup
        direction="column"
        css={{ minWidth: 515, flex: 1, gap: euiTheme.size.s }}
      >
        <FileBrowserSearchAndFilter
          datasetFilter={datasetFilter}
          query={searchQuery}
          onChangeQuery={setSearchQuery}
          selectedOptions={selectedFilterOptions}
          onChangeSelectedOptions={setSelectedFilterOptions}
        />
        <EuiPanel
          hasBorder
          paddingSize="none"
          style={{
            width: "100%",
            overflow: "hidden",
            minWidth: 0,
            marginTop: 0,
          }}
        >
          <ModalProvider>
            <EuiDataGrid
              aria-label="File Browser"
              className="file-browser-data-grid"
              columns={columns}
              schemaDetectors={schemaDetectors}
              columnVisibility={{
                visibleColumns: visibleColumns,
                setVisibleColumns: (columnIds) =>
                  setVisibleColumns(
                    columnIds as (typeof columns)[number]["id"][],
                  ),
              }}
              rowCount={data.length}
              gridStyle={{
                border: "horizontal",
                header: "underline",
                cellPadding: "l",
              }}
              sorting={{
                columns: sortingColumns,
                onSort: (columns) =>
                  setSortingColumns(columns as typeof sortingColumns),
              }}
              renderCellValue={function RenderCellValue({
                rowIndex,
                columnId,
                setCellProps,
              }) {
                const colId: FileBrowserColumn["id"] =
                  columnId as FileBrowserColumn["id"];
                const file = data[rowIndex];
                const colProps = columnPropsByColumnId[colId];

                useEffect(() => {
                  if (
                    isDefined(colProps.isDetails) &&
                    colProps.isDetails(file)
                  ) {
                    setCellProps({ isExpandable: true });
                  } else {
                    setCellProps({ isExpandable: false });
                  }
                }, [columnId, setCellProps, colProps, file]);

                return colProps.cellRenderer({ file });
              }}
              renderCellPopover={({ rowIndex, columnId }) => {
                const colId: FileBrowserColumn["id"] =
                  columnId as FileBrowserColumn["id"];
                const file = data[rowIndex];
                const colProps = columnPropsByColumnId[colId];
                assert(
                  isDefined(colProps.detailRenderer),
                  "Expected detail renderer to be defined in col def",
                );
                return colProps.detailRenderer(file);
              }}
              pagination={{
                ...pagination,
                pageSizeOptions: [10, 25, 50],
                onChangeItemsPerPage: setPageSize,
                onChangePage: setPageIndex,
              }}
              toolbarVisibility={{
                showFullScreenSelector: false,
                showDisplaySelector: false,
                additionalControls: {
                  left:
                    selectedFiles.size > 0 ? (
                      <span key="selected file count" css={styles.toolbarText}>
                        {selectedFiles.size} file(s) selected
                      </span>
                    ) : undefined,
                  right: (
                    <>
                      {selection?.actions !== undefined && (
                        <FileBrowserActionControls
                          actions={selection?.actions}
                          selectedFiles={Array.from(selectedFiles.values())}
                          selectedFileActions={selectedFileActions}
                        />
                      )}
                    </>
                  ),
                },
              }}
              leadingControlColumns={leadingControlColumns}
            />
          </ModalProvider>
        </EuiPanel>
      </EuiFlexGroup>
    </div>
  );
};
