import { EuiButtonIcon } from "@inscopix/ideas-eui";
import { withUserPermission } from "hocs/withUserPermission";
import { withUserPermissionBase } from "hocs/withUserPermissionBase";

/**
 * Enhanced {@link EuiButtonIcon} that is disabled when the user lacks a
 * required permission.
 *
 * Context independent.
 */
export const ButtonIconPermissionedBase = withUserPermissionBase(EuiButtonIcon);

/**
 * Enhanced {@link EuiButtonIcon} that is disabled when the user lacks a
 * required permission.
 *
 * Reads permissions from project permissions context.
 */
export const ButtonIconPermissioned = withUserPermission(
  ButtonIconPermissionedBase,
);
