import {
  DragDropContextProps,
  EuiDragDropContext,
  euiDragDropReorder,
  EuiDraggable,
  EuiDroppable,
  EuiForm,
  EuiFormRow,
  EuiIcon,
  EuiPanel,
  EuiSpacer,
  EuiText,
} from "@inscopix/ideas-eui";
import { GridApi } from "ag-grid-community";
import assert from "assert";
import { RecordingsGridRowDatum } from "components/RecordingsGrid/RecordingsGrid.types";
import { DatasetRecordingsTableColumn, Dataset, Project } from "graphql/_Types";
import { isEqual, pull } from "lodash";
import { ChangeEvent, useState } from "react";
import { useUpdateRecordingIdentifierFormat } from "./useUpdateRecordingIdentifierFormat";
import { useValidateRecordingIdentifierFormat } from "./useValidateRecordingIdentifierFormat";
import { ButtonPermissioned } from "components/ButtonPermissioned/ButtonPermissioned";
import { ButtonIconPermissioned } from "components/ButtonIconPermissioned/ButtonIconPermissioned";
import { FieldTextPermissioned } from "components/FieldTextPermissioned/FieldTextPermissioned";
import { SelectPermissioned } from "components/SelectPermissioned/SelectPermissioned";
import { useDatasetAction } from "hooks/useDatasetAction/useDatasetAction";

interface RecordingIdentifierFormatFormProps {
  api: GridApi<RecordingsGridRowDatum>;
  columns: {
    id: DatasetRecordingsTableColumn["id"];
    identifierPosition: DatasetRecordingsTableColumn["identifierPosition"];
    name: string;
    order: number;
  }[];
  dataset: Pick<Dataset, "id" | "prefix">;
  projectId: Project["id"];
  recordingsTableId: DatasetRecordingsTableColumn["id"];
  onClose: () => void;
}

export const RecordingIdentifierFormatForm = ({
  api,
  columns,
  dataset,
  projectId,
  recordingsTableId,
  onClose,
}: RecordingIdentifierFormatFormProps) => {
  const initialIdentifierColumnIds = columns
    .filter((column) => column.identifierPosition !== null)
    .sort((a, b) => {
      assert(a.identifierPosition !== null);
      assert(b.identifierPosition !== null);
      return a.identifierPosition - b.identifierPosition;
    })
    .map((column) => column.id);
  const [prefix, setPrefix] = useState(dataset.prefix);
  const [identifierColumnIds, setIdentifierColumnIds] = useState(
    initialIdentifierColumnIds,
  );
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { validateFields, validationErrorMap } =
    useValidateRecordingIdentifierFormat(dataset.id, projectId);
  const { updateRecordingIdentifierFormat } =
    useUpdateRecordingIdentifierFormat(dataset.id, recordingsTableId);
  const setIdentifierColumnsAction = useDatasetAction("setIdentifierColumns");

  // Columns to display in the dropdown
  const unselectedColumns = columns
    .filter((column) => !identifierColumnIds.includes(column.id))
    .sort((a, b) => a.order - b.order);

  /**
   * Updates the prefix field state after change events
   * @param e
   */
  const handlePrefixChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;

    const formattedPrefix = value
      // Convert to uppercase
      .toUpperCase()
      // Remove illegal characters
      .replace(/[^A-Z0-9_]/g, "")
      // Enforce length limit
      .substring(0, 10);

    setPrefix(formattedPrefix);
  };

  /**
   * Reorders identifier columns after dragging has ended
   * @param result {@link DragDropContextProps}
   */
  const handleDragEnd: DragDropContextProps["onDragEnd"] = (result) => {
    const { source: src, destination: dst } = result;
    if (dst !== undefined && dst !== null) {
      setIdentifierColumnIds((prevIds) =>
        euiDragDropReorder(prevIds, src.index, dst.index),
      );
    }
  };

  /**
   * Adds a column as an identifier column
   * @param columnId
   */
  const selectIdentifierColumn = (columnId: string) => {
    setIdentifierColumnIds((prevIds) => [...prevIds, columnId]);
  };

  /**
   * Removes a column as an identifier column
   * @param columnId
   */
  const deselectIdentifierColumn = (columnId: string) => {
    setIdentifierColumnIds((prevIds) => pull([...prevIds], columnId));
  };

  /**
   * Determines whether a field has been modified after the form was opened.
   * This is used to avoid unnecessary network requests.
   * @returns A `boolean` representing whether a field has been modified.
   */
  const isModifiedField = () => {
    const isPrefixChange = prefix !== dataset.prefix;
    const isIdentifierColumnChange = !isEqual(
      identifierColumnIds,
      initialIdentifierColumnIds,
    );
    return isPrefixChange || isIdentifierColumnChange;
  };

  /**
   * Validates and submits the form
   */
  const handleSubmit = async () => {
    if (!isModifiedField()) {
      onClose();
    }

    setIsSubmitting(true);
    const isFormValid = await validateFields({ prefix });
    if (isFormValid) {
      updateRecordingIdentifierFormat({
        datasetPrefix: prefix,
        identifierColumnIds,
      });
      onClose();
    }
    setIsSubmitting(false);
  };

  return (
    <EuiForm component="form" onSubmit={(e) => e.preventDefault()}>
      <EuiFormRow
        isInvalid={validationErrorMap.prefix !== undefined}
        error={validationErrorMap.prefix}
        label="Prefix"
        helpText={
          <EuiText size="xs">
            <ul>
              <li>Maximum 10 characters.</li>
              <li>Uppercase letters, numbers, and underscores.</li>
              <li>Keys must be unique within this project.</li>
            </ul>
          </EuiText>
        }
      >
        <FieldTextPermissioned
          disabled={isSubmitting}
          onChange={handlePrefixChange}
          value={prefix}
          requiredPermission="edit"
        />
      </EuiFormRow>
      <EuiSpacer />
      <EuiFormRow
        label="Included columns"
        helpText={
          <EuiText size="xs">
            <p>
              Cell values in these columns will appear in the identifier. Drag
              the column names to change to order in which cell values will
              appear.
            </p>
          </EuiText>
        }
      >
        <EuiDragDropContext onDragEnd={handleDragEnd}>
          <SelectPermissioned
            requiredPermission="edit"
            disabled={isSubmitting}
            onChange={(e) => selectIdentifierColumn(e.target.value)}
            options={
              unselectedColumns.length === 0
                ? [
                    {
                      value: "PLACEHOLDER_TEXT",
                      text: "Select to add columns",
                      disabled: true,
                      hidden: true,
                    },
                    {
                      value: "NO_ITEMS",
                      text: "No items found",
                      disabled: true,
                    },
                  ]
                : [
                    {
                      value: "PLACEHOLDER_TEXT",
                      text: "Select to add columns",
                      disabled: true,
                      hidden: true,
                    },
                    ...unselectedColumns.map((column) => ({
                      value: column.id,
                      text: column.name,
                    })),
                  ]
            }
            /* https://stackoverflow.com/a/5859221
               This is the defacto standard for displaying placeholder text in
               select elements. Might be worth it to make a wrapper component
               in the future. */
            value="PLACEHOLDER_TEXT"
          />
          <EuiSpacer size="s" />
          <EuiDroppable droppableId="PLACEHOLDER">
            {identifierColumnIds.map((columnId, idx) => (
              <EuiDraggable
                key={columnId}
                index={idx}
                draggableId={columnId}
                hasInteractiveChildren
                isDragDisabled={
                  isSubmitting || setIdentifierColumnsAction.isDisabled
                }
              >
                {(provided) => (
                  <EuiPanel
                    {...provided.dragHandleProps}
                    color="transparent"
                    paddingSize="none"
                    style={{ width: "100%", display: "inline-flex" }}
                  >
                    <EuiIcon type="grab" style={{ marginRight: 5 }} />
                    <EuiText size="s">
                      {columns.find(({ id }) => id === columnId)?.name}
                    </EuiText>
                    <ButtonIconPermissioned
                      aria-label="Deselect column"
                      color="danger"
                      iconType="minusInCircle"
                      onClick={() => deselectIdentifierColumn(columnId)}
                      style={{ marginLeft: "auto" }}
                      requiredPermission="edit"
                    />
                  </EuiPanel>
                )}
              </EuiDraggable>
            ))}
          </EuiDroppable>
        </EuiDragDropContext>
      </EuiFormRow>
      <EuiSpacer />
      <ButtonPermissioned
        fill
        onClick={() => void handleSubmit()}
        isLoading={isSubmitting}
        requiredPermission="edit"
      >
        Update
      </ButtonPermissioned>
    </EuiForm>
  );
};
