import { useMemo, useState } from "react";

export const useSelectionState = <T extends { id: string | number }>(
  allItems: T[],
) => {
  const [selectedItemIds, setSelectedItemIds] = useState(new Set<T["id"]>());

  const actions = useMemo(
    () => ({
      selectItems: (items: T[]) =>
        setSelectedItemIds((previousItemIds) => {
          const newSelectedRows = new Set(previousItemIds);
          items.forEach((item) => newSelectedRows.add(item.id));
          return newSelectedRows;
        }),
      deselectItems: (items: Pick<T, "id">[]) =>
        setSelectedItemIds((previousItems) => {
          const newSelectedRows = new Set(previousItems);
          items.forEach((item) => newSelectedRows.delete(item.id));
          return newSelectedRows;
        }),
      deselectAll: () => setSelectedItemIds(new Set()),
      selectAll: () =>
        setSelectedItemIds(new Set(allItems.map((item) => item.id))),
    }),
    [allItems],
  );

  // Convert selected items into a lookup map. This isn't strictly necessary but
  // supports legacy code without the need for larger changes.
  const readOnlyItems = useMemo(() => {
    const readOnlyItems = new Map<T["id"], T>();
    const allItemsById = new Map(allItems.map((item) => [item.id, item]));
    selectedItemIds.forEach((itemId) => {
      const item = allItemsById.get(itemId);
      if (item !== undefined) {
        readOnlyItems.set(item.id, item);
      }
    });
    return readOnlyItems as ReadonlyMap<T["id"], T>;
  }, [allItems, selectedItemIds]);

  return [readOnlyItems, actions] as const;
};
