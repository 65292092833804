import {
  EuiHeader,
  EuiHeaderSection,
  EuiHeaderSectionItem,
} from "@inscopix/ideas-eui";
import "./AppHeader.scss";
import AppName from "../AppName/AppName";
import { PopoverFileUpload } from "components/PopoverFileUpload/PopoverFileUpload";
import UserMenu from "components/UserMenu/UserMenu";
import { Notifications } from "components/Notifications/Notifications";
import { HelpMenu } from "./HelpMenu";

/**
 * Top-level header for application views. Displays global components (eg. global search, user menu)
 */

function AppHeader() {
  return (
    <>
      <EuiHeader theme="dark" position="fixed">
        <EuiHeaderSection grow={false}>
          <AppName />
        </EuiHeaderSection>
        <EuiHeaderSection>
          <EuiHeaderSectionItem>
            <PopoverFileUpload />
          </EuiHeaderSectionItem>
          <EuiHeaderSectionItem>
            <Notifications />
          </EuiHeaderSectionItem>
          <EuiHeaderSectionItem>
            <HelpMenu />
          </EuiHeaderSectionItem>
          <div style={{ paddingRight: 8 }}></div>
          <UserMenu />
        </EuiHeaderSection>
      </EuiHeader>
    </>
  );
}

export default AppHeader;
