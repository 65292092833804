import { EuiResizableContainer } from "@inscopix/ideas-eui";
import { useResizablePanels } from "components/Project/useResizablePanels";
import { RecordingsGrid } from "components/RecordingsGrid/RecordingsGrid";
import { useDatasetLayoutContext } from "pages/project/dataset/DatasetLayoutProvider";

/**
 * Component that renders the main content of a dataset. This includes the
 * recordings table and all flyouts.
 */
export const DatasetContent = () => {
  const { bottomFlyout, rightFlyout } = useDatasetLayoutContext();
  const isBottomFlyoutOpen = bottomFlyout !== null;
  const isRightFlyoutOpen = rightFlyout !== null;
  const { panels, handlePanelWidthChange } = useResizablePanels({
    isBottomFlyoutOpen,
    isRightFlyoutOpen,
  });

  return (
    <EuiResizableContainer
      direction="horizontal"
      onPanelWidthChange={handlePanelWidthChange}
      style={{ width: "100%", height: "100%" }}
    >
      {(EuiResizablePanel, EuiResizableButton) => {
        const children = [
          <EuiResizablePanel
            key={panels.MAIN_AND_FLYOUT_BOTTOM.id}
            id={panels.MAIN_AND_FLYOUT_BOTTOM.id}
            panelRef={panels.MAIN_AND_FLYOUT_BOTTOM.ref}
            size={panels.MAIN_AND_FLYOUT_BOTTOM.size}
            paddingSize="none"
          >
            <EuiResizableContainer
              direction="vertical"
              onPanelWidthChange={handlePanelWidthChange}
              style={{ width: "100%", height: "100%" }}
            >
              {(EuiResizablePanel, EuiResizableButton) => {
                const children = [
                  <EuiResizablePanel
                    key={panels.MAIN.id}
                    id={panels.MAIN.id}
                    panelRef={panels.MAIN.ref}
                    size={panels.MAIN.size}
                    paddingSize="none"
                  >
                    <RecordingsGrid />
                  </EuiResizablePanel>,
                ];

                if (isBottomFlyoutOpen) {
                  children.push(
                    <EuiResizableButton
                      key="inner-resizer"
                      indicator="border"
                    />,
                    <EuiResizablePanel
                      key={panels.FLYOUT_BOTTOM.id}
                      id={panels.FLYOUT_BOTTOM.id}
                      panelRef={panels.FLYOUT_BOTTOM.ref}
                      size={panels.FLYOUT_BOTTOM.size}
                      paddingSize="none"
                      minSize="300px"
                    >
                      {bottomFlyout?.node}
                    </EuiResizablePanel>,
                  );
                }

                return <>{children}</>;
              }}
            </EuiResizableContainer>
          </EuiResizablePanel>,
        ];

        if (isRightFlyoutOpen) {
          children.push(
            <EuiResizableButton key="outer-resizer" indicator="border" />,
            <EuiResizablePanel
              key={panels.FLYOUT_RIGHT.id}
              id={panels.FLYOUT_RIGHT.id}
              panelRef={panels.FLYOUT_RIGHT.ref}
              size={panels.FLYOUT_RIGHT.size}
              paddingSize="none"
              minSize="300px"
            >
              {rightFlyout?.node}
            </EuiResizablePanel>,
          );
        }

        return <>{children}</>;
      }}
    </EuiResizableContainer>
  );
};
