import {
  euiPaletteColorBlind,
  euiPaletteGray,
  tint,
} from "@inscopix/ideas-eui";
import { CircularProgress } from "components/CircularProgress/CircularProgress";
import { File as DrsFile } from "graphql/_Types";
import { useMemo } from "react";
import { useFileUploadContext } from "stores/upload/FileUploadProvider";
import { FileStatus, ProcessingStatus } from "types/constants";
import {
  DrsFileModifyPermissions,
  getDrsFileModifyPermissionByDrsFileAndAction,
} from "types/DrsFileModifyPermissions";
import { getDrsFileIcon } from "./FileBadge.helpers";
import { FileBadgeBase, FileBadgeBaseProps } from "./FileBadgeBase";
import { Interpolation, Theme } from "@emotion/react";
import { useGetDrsFileProcessingStatus } from "hooks/useGetDrsFileProcessingStatus";
import { useSelectedDrsFileId } from "stores/useSelectedDrsFileId";
import { FileBadgeTooltipContent } from "./FileBadgeTooltipContent";

const colorPalette = euiPaletteColorBlind({
  rotations: 3,
});

export const fileBadgeColors = {
  misplaced: colorPalette[15],
  processing: colorPalette[14],
  archived: colorPalette[11],
  dataDeleted: colorPalette[19],
  unarchiving: "cornflowerblue",
  uploadFailed: "red",
  unreadable: "orange",
};

export interface FileBadgeProps extends Pick<FileBadgeBaseProps, "onClick"> {
  drsFile: Pick<
    DrsFile,
    | "id"
    | "name"
    | "status"
    | "fileType"
    | "isSeries"
    | "source"
    | "processingStatus"
    | "seriesParentId"
  >;
  // Whether the badge should be rendered as a shadow of an actively dragged badge
  // (https://tinyurl.com/3y933ft3)
  isDraggingActive?: boolean;
  isMisplaced?: boolean;
  isSelected?: boolean;
  compact?: boolean;
  onArchive?: () => void;
  onDelete?: () => void;
  onInspect?: () => void;
  onRename?: () => void;
  onUnarchive?: () => void;
  onUnassign?: () => void;
  onIdentify?: () => void;
  onCancelUpload?: () => void;
  suppressTooltip?: boolean;
  suppressContextMenu?: boolean;
  cssOverrides?: Interpolation<Theme>;
}

export const FileBadge = ({
  drsFile,
  isDraggingActive = false,
  isMisplaced = false,
  isSelected = false,
  compact = false,
  onArchive,
  onDelete,
  onInspect,
  onRename,
  onUnarchive,
  onUnassign,
  onIdentify,
  onCancelUpload,
  suppressTooltip = false,
  suppressContextMenu = false,
  ...passThroughProps
}: FileBadgeProps) => {
  // Getting the selectedDrsFileId from the store to determine if the badge is active
  const selectedDrsFileId = useSelectedDrsFileId(
    (state) => state.selectedDrsFileId,
  );

  const uploadProgress = useFileUploadContext((state) => {
    const { filesById } = state;
    return filesById[drsFile.id]?.progress;
  });
  const uploadError = useFileUploadContext((state) => {
    const { filesById } = state;
    return filesById[drsFile.id]?.error;
  });
  const { processingStatus } = useGetDrsFileProcessingStatus(drsFile.id);

  const contextMenuItemsMap: Readonly<
    Record<
      | Extract<
          DrsFileModifyPermissions,
          | "ARCHIVE"
          | "DELETE"
          | "UNASSIGN"
          | "RENAME"
          | "IDENTIFY"
          | "CANCEL_UPLOAD"
        >
      | "INSPECT",
      Readonly<{
        key: string;
        icon: string;
        onClick?: () => void;
        title: string;
      }>
    >
  > = useMemo(
    () =>
      ({
        INSPECT: {
          key: "inspect",
          icon: "eye",
          onClick: onInspect,
          title: "View info",
        } as const,
        UNASSIGN: {
          key: "unassign",
          icon: "unlink",
          onClick: onUnassign,
          title: "Unassign",
        } as const,
        ARCHIVE: {
          key: "archive",
          icon: "snowflake",
          onClick: onArchive,
          title: "Archive",
        } as const,
        DELETE: {
          key: "delete",
          icon: "trash",
          onClick: onDelete,
          title: "Delete",
        } as const,
        RENAME: {
          key: "rename",
          icon: "pencil",
          onClick: onRename,
          title: "Rename",
        } as const,
        UNARCHIVE: {
          key: "unarchive",
          icon: "tear",
          onClick: onUnarchive,
          title: "Unarchive",
        } as const,
        IDENTIFY: {
          key: "identify",
          icon: "documentEdit",
          onClick: onIdentify,
          title: "Change Type",
        } as const,
        CANCEL_UPLOAD: {
          key: "cancel_upload",
          icon: "cross",
          onClick: onCancelUpload,
          title: "Cancel Upload",
        } as const,
      }) as const,
    [
      onArchive,
      onCancelUpload,
      onDelete,
      onIdentify,
      onInspect,
      onRename,
      onUnarchive,
      onUnassign,
    ],
  );

  /* Determine the status of the file */

  const isActive = selectedDrsFileId === drsFile.id || isSelected;

  const isUploadFailed =
    uploadError !== undefined ||
    drsFile.status === FileStatus["UPLOAD_CANCELLED"];

  const isUploading =
    // This is the status of files waiting in the upload queue
    drsFile.status === FileStatus.CREATED ||
    drsFile.status === FileStatus.UPLOADING;

  const isProcessing =
    !isUploadFailed &&
    !isUploading &&
    (drsFile.processingStatus === ProcessingStatus["PROCESSING"] ||
      drsFile.processingStatus === ProcessingStatus["PENDING"]);

  const isUnreadable = !isUploading && processingStatus === "error";

  const isArchived = drsFile.status === FileStatus.ARCHIVED;

  const isUnarchiving = drsFile.status === FileStatus.UNARCHIVED;

  const isDataDeleted =
    drsFile.status === FileStatus.SCHEDULE_DATA_DELETE ||
    drsFile.status === FileStatus.DATA_DELETED;

  /* Determine the status specific props that should be passed to the base
     component. The order of the conditionals matter as statuses checked first
     will take precedence. */

  const baseProps: Required<
    Omit<FileBadgeBaseProps, "onClick" | "cssOverrides" | "tooltipContent">
  > &
    Pick<FileBadgeBaseProps, "tooltipContent"> = {
    backgroundColor: euiPaletteGray(20)[0],
    contextMenuItems: [contextMenuItemsMap.INSPECT],
    hasContextMenu: true,
    hasTooltip: true,
    icon: getDrsFileIcon(drsFile),
    border: "solid",
    isCompact: false,
    hasBoxShadow: false,
    name: drsFile.name,
    iconColor: "inherit",
    isActive: false,
  };

  if (isUploadFailed) {
    baseProps.backgroundColor = colorPalette[9];
    // an object that has failed to upload can only be deleted
    baseProps.contextMenuItems = [];
    baseProps.hasContextMenu = true;
    baseProps.icon = "error";
    baseProps.border = "transparent";
  } else if (isUploading) {
    const progress = uploadProgress ?? 0;
    baseProps.backgroundColor = colorPalette[10];
    // an object that's uploading can be renamed or unassigned
    baseProps.contextMenuItems = [];
    baseProps.icon = () => <CircularProgress value={progress} max={100} />;
    baseProps.border = "transparent";
  } else if (isUnreadable) {
    baseProps.backgroundColor = colorPalette[17];
    baseProps.border = "transparent";
    // a file that failed processing can be viewed, renamed, unassigned, or deleted
    // technically it could be archived/unarchived as well, but we need to implement
    // another badge state and figure out how to display this
    baseProps.contextMenuItems = [contextMenuItemsMap.INSPECT];
  } else if (isProcessing) {
    baseProps.border = "dashed";
    // an object that's processing can be renamed or unassigned
    /* NOTE: it cannot be archived or deleted yet because process file currently can generate
     * additional files after processing - if this happens post-request we can reach an invalid
     * state where some files are not marked for deletion or archive. */
    baseProps.contextMenuItems = [contextMenuItemsMap.UNASSIGN];
    baseProps.icon = getDrsFileIcon(drsFile);
    baseProps.iconColor = fileBadgeColors.processing;
  } else if (isMisplaced) {
    // a file is misplaced can be viewed, renamed, unassigned, or deleted
    // technically it could be archived/unarchived as well, but we need to implement
    // another badge state and figure out how to display this
    baseProps.contextMenuItems = [contextMenuItemsMap.INSPECT];
    baseProps.backgroundColor = fileBadgeColors.misplaced;
    baseProps.icon = "alert";
  } else if (isArchived) {
    baseProps.backgroundColor = fileBadgeColors.archived;
    // archived files display unarchive option instead of archive, all other actions ok
    baseProps.contextMenuItems = [contextMenuItemsMap.INSPECT];
    baseProps.icon = getDrsFileIcon(drsFile);
    baseProps.border = "transparent";
  } else if (isUnarchiving) {
    baseProps.backgroundColor = colorPalette[16];
    // files waiting to be unarchived can be viewed, unassigned, deleted, or renamed
    baseProps.contextMenuItems = [contextMenuItemsMap.INSPECT];
    baseProps.icon = "tear";
    baseProps.border = "dashed";
  } else if (isDataDeleted) {
    baseProps.backgroundColor = fileBadgeColors.dataDeleted;
    // files with data deleted can be viewed, deleted or renamed
    // cannot be unassigned because they should only exist historically
    // cannot be archived/unarchived as there is no data
    baseProps.contextMenuItems = [contextMenuItemsMap.INSPECT];
    baseProps.icon = getDrsFileIcon(drsFile);
    baseProps.border = "transparent";
  }

  if (isActive) {
    baseProps.isActive = true;
  }

  const actions: Readonly<
    Exclude<keyof typeof contextMenuItemsMap, "INSPECT">[]
  > = [
    "ARCHIVE",
    "DELETE",
    "RENAME",
    "UNASSIGN",
    "IDENTIFY",
    "CANCEL_UPLOAD",
  ] as const;

  actions.forEach((action) => {
    if (
      getDrsFileModifyPermissionByDrsFileAndAction(drsFile, action).isPermitted
    ) {
      baseProps.contextMenuItems.push(contextMenuItemsMap[action]);
    }
  });

  /* Override any status specific props. These will often be related to props
     passed to this component. */

  if (compact) {
    baseProps.hasContextMenu = false;
    baseProps.isCompact = true;
  }

  if (isDraggingActive) {
    baseProps.backgroundColor = tint(baseProps.backgroundColor, 0.5);
  }

  if (suppressTooltip) {
    baseProps.hasTooltip = false;
  }

  if (drsFile.isSeries) {
    baseProps.hasBoxShadow = true;
  }

  if (suppressContextMenu) {
    baseProps.hasContextMenu = false;
  }

  return (
    <FileBadgeBase
      {...baseProps}
      {...passThroughProps}
      tooltipContent={
        <FileBadgeTooltipContent
          fileId={drsFile.id}
          fileName={drsFile.name}
          isArchived={isArchived}
          isDataDeleted={isDataDeleted}
          isMisplaced={isMisplaced}
          isProcessing={isProcessing}
          isUnarchiving={isUnarchiving}
          isUnreadable={isUnreadable}
          isUploadFailed={isUploadFailed}
          isUploading={isUploading}
          uploadProgress={uploadProgress}
        />
      }
    />
  );
};
