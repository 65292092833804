import {
  EuiButtonIcon,
  EuiContextMenu,
  EuiContextMenuPanelDescriptor,
  EuiPopover,
} from "@inscopix/ideas-eui";
import { memo, useState } from "react";
import { css } from "@emotion/react";
import {
  ToolParam,
  ToolParamValue,
  ToolParamsGridRowDatum,
} from "../ToolParamsGrid.types";
import { useToolParamsGridContext } from "../ToolParamsGridProvider";
import {
  isFileMetadatumReference,
  isToolPathParam,
} from "../ToolParamsGrid.helpers";
import { chain } from "lodash";
import { isDefined } from "utils/isDefined";
import { MetadataTableRecording } from "./MetadataTableRecording";
import { MetadataTableFile } from "./MetadataTableFile";
import { Tooltip } from "components/Tooltip/Tooltip";
import { useGetProjectFilesByFileIds } from "../hooks/useGetProjectFilesByFileIds";
import { useToolParamsGridRowDataContext } from "../ToolParamsGridRowDataProvider";
import assert from "assert";
import { coalesceToStringArray } from "utils/coalesceToStringArray";

interface MetadatumReferenceSelectorProps {
  rowId: ToolParamsGridRowDatum["id"];
  toolParam: ToolParam;
  onPopoverOpen?: () => void;
  onPopoverClose?: () => void;
  currentValue: ToolParamValue | undefined;
  isReadonly: boolean;
}

/** Component for selecting file and recording metadata in analysis table rows */
export const MetadatumReferenceSelector = memo(
  function MetadatumReferenceSelector({
    rowId,
    toolParam,
    onPopoverOpen,
    onPopoverClose,
    currentValue,
    isReadonly,
  }: MetadatumReferenceSelectorProps) {
    const data = useToolParamsGridRowDataContext((s) =>
      s.rowData.find(({ id }) => id === rowId),
    );
    assert(
      data !== undefined,
      "Expected data to be defined in metadatum reference selector",
    );

    const currentReference = data?.metadatumReferences[toolParam.key];

    const [isPopoverOpen, setIsPopoverOpen] = useState(false);
    const { toolSpec } = useToolParamsGridContext();
    const updateRowDatum = useToolParamsGridRowDataContext(
      (s) => s.updateRowDatum,
    );

    const { getProjectFilesByFileIds } = useGetProjectFilesByFileIds();

    /** Opens the popover */
    const openPopover = () => {
      setIsPopoverOpen(true);
      if (isDefined(onPopoverOpen)) {
        onPopoverOpen();
      }
    };

    /** Closes the popover */
    const closePopover = () => {
      setIsPopoverOpen(false);
      if (isDefined(onPopoverClose)) {
        onPopoverClose();
      }
    };

    /** Opens the popover if it is closed, closes the popover if it is open */
    const togglePopover = isPopoverOpen ? closePopover : openPopover;

    /** Panel for selecting recording metadata */
    const recordingsPanel: EuiContextMenuPanelDescriptor = {
      id: "recording",
      title: "Recording Session IDs",
      content: (() => {
        const recordingsIds = chain(toolSpec.params)
          .filter(isToolPathParam)
          .flatMap((param) => {
            const fileIds = coalesceToStringArray(data?.params[param.key]);
            const files = getProjectFilesByFileIds(fileIds).drsFilesFound;
            const recordings = files?.flatMap((file) => file.recordings);
            return recordings?.map((recording) => recording.id);
          })
          .filter(isDefined)
          .uniq()
          .value();

        const handleSelect = (metadatumKey: string, value: ToolParamValue) => {
          if (!isReadonly) {
            updateRowDatum(rowId, {
              params: {
                [toolParam.key]: value,
              },
              metadatumReferences: {
                [toolParam.key]: {
                  type: "recording",
                  metadatum_key: metadatumKey,
                },
              },
            });
            closePopover();
          }
        };

        return (
          <MetadataTableRecording
            recordingIds={recordingsIds}
            onSelect={handleSelect}
            currentReference={currentReference}
            currentValue={currentValue}
          />
        );
      })(),
    };

    /** Panels for selecting file metadata */
    const toolPathParamPanels: EuiContextMenuPanelDescriptor[] = toolSpec.params
      .filter(isToolPathParam)
      .map((param) => ({
        id: param.key,
        title: param.name,
        content: (() => {
          const handleSelect = (
            metadatumKey: string,
            value: ToolParamValue,
          ) => {
            if (!isReadonly) {
              updateRowDatum(rowId, {
                params: {
                  [toolParam.key]: value,
                },
                metadatumReferences: {
                  [toolParam.key]: {
                    type: "file",
                    source: param.key,
                    metadatum_key: metadatumKey,
                  },
                },
              });
              closePopover();
            }
          };

          return (
            <MetadataTableFile
              fileIds={coalesceToStringArray(data.params[param.key])}
              onSelect={handleSelect}
              currentReference={currentReference}
              currentValue={currentValue}
            />
          );
        })(),
      }));

    /** Panel that is initially visible when the popover is opened */
    const rootPanel: EuiContextMenuPanelDescriptor = {
      id: "root",
      title: "Fill cell from metadata",
      items: [
        {
          panel: recordingsPanel.id,
          name: recordingsPanel.title,
          icon: "tag",
        },
        ...toolPathParamPanels.map((panel) => ({
          panel: panel.id,
          name: panel.title,
          icon: "documents",
        })),
      ],
    };

    return (
      <Tooltip content={rootPanel.title}>
        <EuiPopover
          button={
            <EuiButtonIcon
              aria-label="Select metadata"
              iconType="link"
              onClick={togglePopover}
            />
          }
          isOpen={isPopoverOpen}
          closePopover={closePopover}
          panelPaddingSize="none"
          anchorPosition="downCenter"
        >
          <EuiContextMenu
            initialPanelId={
              currentReference === undefined
                ? rootPanel.id
                : isFileMetadatumReference(currentReference)
                ? currentReference.source
                : recordingsPanel.id
            }
            panels={[rootPanel, recordingsPanel, ...toolPathParamPanels]}
            css={css`
              inline-size: 500px;
            `}
          />
        </EuiPopover>
      </Tooltip>
    );
  },
);
