import { EuiFlexGroup, EuiFlexItem, EuiIcon } from "@inscopix/ideas-eui";
import { ButtonIdentifyFile } from "components/ButtonIdentifyFile/ButtonIdentifyFile";
import { ButtonViewTaskLogsDrsFile } from "components/ButtonViewTaskLogs/ButtonViewTaskLogsForDrsFile";
import { CircularProgress } from "components/CircularProgress/CircularProgress";
import { Tooltip } from "components/Tooltip/Tooltip";
import { useGetDrsFileProcessingStatus } from "hooks/useGetDrsFileProcessingStatus";
import { getDrsFileModifyPermissionByDrsFileAndAction } from "types/DrsFileModifyPermissions";
import { FILE_TYPES_BY_ID } from "types/FileTypes";
import { FileBrowserFile } from "./FileBrowser";
import { FileBrowserCellRendererFileTypeTooltipContent } from "./FileBrowserCellRendererFileTypeTooltipContent";

export interface FileBrowserCellRendererFileTypeProps {
  file: FileBrowserFile;
}

export const FileBrowserCellRendererFileType = ({
  file,
}: FileBrowserCellRendererFileTypeProps) => {
  const { processingStatus } = useGetDrsFileProcessingStatus(file.id);

  let type = "";

  const fileType = file.fileType;
  if (fileType === null) {
    type = "Unknown";
  } else {
    type = FILE_TYPES_BY_ID[fileType]?.name ?? "Unknown";
  }

  return (
    <EuiFlexGroup
      alignItems="center"
      gutterSize="xs"
      justifyContent="flexStart"
    >
      <Tooltip
        content={
          <FileBrowserCellRendererFileTypeTooltipContent
            type={type}
            file={file}
          />
        }
      >
        {processingStatus === "pending" && (
          <EuiIcon
            type={() => (
              <CircularProgress size="xs" color="text" value={100} max={100} />
            )}
          />
        )}

        <span style={{ overflow: "hidden", textOverflow: "ellipsis" }}>
          {type}
        </span>
      </Tooltip>
      <EuiFlexItem />
      {(() => {
        if (processingStatus === "error") {
          return (
            <ButtonViewTaskLogsDrsFile drsFileId={file.id} color="warning" />
          );
        }
      })()}
      {getDrsFileModifyPermissionByDrsFileAndAction(
        {
          ...file,
          source: file.source.type,
        },
        "IDENTIFY",
      ).isPermitted && (
        <ButtonIdentifyFile file={{ ...file, source: file.source.type }} />
      )}
    </EuiFlexGroup>
  );
};
