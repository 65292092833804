/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { useState } from "react";
import { CellValue, Group, IdeasFile } from "@inscopix/ideas-hyperformula";
import {
  EuiButtonEmpty,
  EuiListGroup,
  EuiListGroupItem,
  EuiPopover,
} from "@inscopix/ideas-eui";
import { CellValueRendererBase } from "../cell-value-renderers/CellValueRendererBase";
import { selectSystemFiles } from "utils/selectSystemFiles";
import { addUtilityToastSuccess } from "utils/addUtilityToastSuccess";
import { addUtilityToastFailure } from "utils/addUtilityToastFailure";
import { useDataTableContext } from "../store/DataTableProvider";
import { CellValueRendererFile } from "../cell-value-renderers/CellValueRendererFile";

const styles = {
  popover: css`
    & {
      height: 100%;
      width: 100%;
      display: flex;
      align-items: center;
    }
  `,
  panel: css`
    padding: 10px;
    max-height: max(200px, 50vh);
    overflow-y: scroll;
  `,
  upload: css`
    margin: auto;
  `,
};

interface CellEditorFileUploadProps {
  tableId: string;
  columnId: string;
  rowId: string;
  value: CellValue;
  stopEditing: (cancel?: boolean) => void;
}

/** Cell editor for cells in file columns of data tables */
export const CellEditorFileUpload = ({
  tableId,
  columnId,
  rowId,
  value,
  stopEditing,
}: CellEditorFileUploadProps) => {
  const uploadFiles = useDataTableContext((s) => s.uploadFiles);
  const [isLoading, setIsLoading] = useState(false);

  const handleUploadFiles = async () => {
    try {
      setIsLoading(true);
      const blobs = (await selectSystemFiles(true)) ?? [];
      if (blobs.length > 0) {
        const address = { tableId, columnId, rowId };
        await uploadFiles({ blobs, address });
        setIsLoading(false);
        stopEditing(true);
        addUtilityToastSuccess("File upload initiated");
      }
    } catch (error) {
      setIsLoading(false);
      addUtilityToastFailure("Failed to initiate file upload");
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <EuiPopover
      anchorPosition="downCenter"
      button={<CellValueRendererBase value={value} />}
      isOpen
      panelPaddingSize="none"
      css={styles.popover}
      closePopover={() => stopEditing(true)}
      display="block"
      repositionOnScroll={true}
      panelProps={{ paddingSize: "none" }}
    >
      <EuiListGroup css={styles.panel} maxWidth="100%" gutterSize="none">
        {value instanceof Group
          ? value.cellValues
              .filter((value) => value instanceof IdeasFile)
              .map((file) => (
                <EuiListGroupItem
                  id={(file as IdeasFile).attrs.id}
                  key={(file as IdeasFile).attrs.id}
                  label={<CellValueRendererFile file={file as IdeasFile} />}
                />
              ))
          : []}
        <EuiListGroupItem
          id="upload"
          key="upload"
          css={styles.upload}
          label={
            <EuiButtonEmpty
              aria-label="Upload file"
              iconType="exportAction"
              onClick={() => void handleUploadFiles()}
              isLoading={isLoading}
              title=""
            >
              Upload files
            </EuiButtonEmpty>
          }
        ></EuiListGroupItem>
      </EuiListGroup>
    </EuiPopover>
  );
};
