import { EuiIcon } from "@inscopix/ideas-eui";
import { Tooltip } from "components/Tooltip/Tooltip";
import { ColorsByTaskStatus } from "types/ColorsByTaskStatus";
import { TaskStatus } from "types/constants";
import { useModalContext } from "providers/ModalProvider/ModalProvider";
import { Task } from "graphql/_Types";
import { ModalTaskLogs } from "components/ButtonViewTaskLogs/ModalTaskLogs";
import { includes } from "lodash";

interface TaskStatusAndLogIconProps {
  taskId: Task["id"];
  taskStatus: TaskStatus;
  taskCreated: Task["created"];
}

export const TaskStatusIcon = ({
  taskId,
  taskStatus,
  taskCreated,
}: TaskStatusAndLogIconProps) => {
  const color = ColorsByTaskStatus[taskStatus];
  const taskStatusName = TaskStatus[taskStatus];
  const showTaskLog = includes(
    [TaskStatus["COMPLETE"], TaskStatus["ERROR"], TaskStatus["FAILED"]],
    taskStatus,
  );
  const { openModal, closeModal } = useModalContext();

  const showModal = () =>
    openModal(
      <ModalTaskLogs
        task={{ id: taskId, status: taskStatus, created: taskCreated }}
        onClose={closeModal}
      />,
    );

  const tooltipContent = (
    <>
      <p style={{ color }}>{taskStatusName}</p>
      {showTaskLog && <p style={{ fontSize: "0.8em" }}>View task logs</p>}
    </>
  );

  return (
    <Tooltip content={tooltipContent}>
      <EuiIcon
        data-test-id={`task-status-icon-${TaskStatus[taskStatus]}`}
        aria-label="View task logs"
        type="stopFilled"
        size="l"
        color={color}
        onClick={() => showTaskLog && showModal()}
        style={{ cursor: showTaskLog ? "pointer" : "default" }}
      />
    </Tooltip>
  );
};
