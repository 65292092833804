import {
  EuiText,
  EuiContextMenuItem,
  EuiContextMenuPanel,
  EuiFlexGroup,
  EuiFlexItem,
  EuiPopover,
  htmlIdGenerator,
  EuiAvatar,
} from "@inscopix/ideas-eui";
import { useTenantContext } from "providers/TenantProvider/TenantProvider";
import { UserAvatar } from "components/UserAvatar/UserAvatar";
import { Fragment, useState } from "react";
import { useInvalidateModalProjectSharingQuery } from "./ModalProjectSharing";
import { ProjectSharingInviteStatus } from "types/constants";
import { addUtilityToastSuccess } from "utils/addUtilityToastSuccess";
import { captureException } from "@sentry/react";
import { addUtilityToastFailure } from "utils/addUtilityToastFailure";
import {
  USER_ACCESS_LEVELS_BY_KEY,
  UserAccessLevel,
} from "types/UserAccessLevels";
import { Project } from "graphql/_Types";

import { permissions, ProjectSharingInviteDjango } from "./permissions";
import { ButtonEmptyPermissionedBase } from "components/ButtonEmptyPermissioned/ButtonEmptyPermissioned";

export interface UserWithAccessExternalProps {
  invite: Pick<
    ProjectSharingInviteDjango,
    "invitee" | "id" | "project" | "email" | "project_access_level" | "status"
  >;
  projectId: Project["id"];
  updateExternalProjectSharingInvitation: typeof permissions.userFunctions.externalAccess.updateExternalProjectSharingInvitation;
  revokeExternalProjectAccessInvitation: typeof permissions.userFunctions.externalAccess.revokeExternalProjectAccessInvitation;
  sendExternalProjectAccessInvitation: typeof permissions.userFunctions.externalAccess.sendExternalProjectAccessInvitation;
  resendExternalProjectAccessInvitation: typeof permissions.userFunctions.externalAccess.resendExternalProjectAccessInvitation;
  userHasGrantAccessPermission: boolean;
}

const UserWithAccessExternal = ({
  projectId,
  invite,
  updateExternalProjectSharingInvitation,
  resendExternalProjectAccessInvitation,
  revokeExternalProjectAccessInvitation,
  userHasGrantAccessPermission,
}: UserWithAccessExternalProps) => {
  const { invalidateModalProjectSharingQuery } =
    useInvalidateModalProjectSharingQuery();
  const [isPopoverOpen, setPopover] = useState(false);

  const [isWorking, setWorking] = useState(false);
  const usersById = useTenantContext((s) => s.usersById);
  const user = usersById.get(invite.invitee ?? "");

  const closePopover = () => {
    setPopover(false);
  };

  const togglePopover = () => {
    setPopover((isOpen) => !isOpen);
  };

  /**
   * Updates the access level of a pending initiate.
   * @param projectAccessLevel The new access level.
   */
  const handleUpdateAccess = async (
    projectAccessLevel: UserAccessLevel["id"],
  ) => {
    setWorking(true);
    try {
      await updateExternalProjectSharingInvitation({
        invitationId: invite.id,
        projectAccessLevel,
        projectId,
      });
      await invalidateModalProjectSharingQuery(projectId);

      addUtilityToastSuccess("Invitation updated");
    } catch (e) {
      captureException(e as Error);
      addUtilityToastFailure("Failed to update invitation");
    }
    setWorking(false);
  };

  const handleRevoke = async () => {
    setWorking(true);
    try {
      await revokeExternalProjectAccessInvitation({
        invitationId: invite.id,
        projectId,
      });
      await invalidateModalProjectSharingQuery(projectId);

      addUtilityToastSuccess("Invitation revoked");
    } catch (e) {
      captureException(e as Error);
      addUtilityToastFailure("Failed to revoke invitation");
    } finally {
      setWorking(false);
    }
  };

  const handleResend = async () => {
    setWorking(true);
    try {
      await resendExternalProjectAccessInvitation({
        invitationId: invite.id,
        projectId,
      });
      await invalidateModalProjectSharingQuery(projectId);
      addUtilityToastSuccess("Invitation resent");
      setWorking(false);
    } catch (e) {
      captureException(e as Error);
      addUtilityToastFailure("Failed to resend invitation");
      setWorking(false);
    }
  };

  return (
    <Fragment key={invite.id}>
      <EuiFlexGroup gutterSize="xs" alignItems="center">
        <EuiFlexItem grow={false}>
          {user === undefined ? (
            <EuiAvatar size="m" name={invite.email} />
          ) : (
            <UserAvatar userId={user.id} size="m" />
          )}
        </EuiFlexItem>
        <EuiFlexItem grow={true}>
          <EuiText size="xs">
            {user !== undefined ? (
              <b>{`${user?.firstName ?? ""} ${user?.lastName ?? ""}`}</b>
            ) : (
              <b>{invite.email}</b>
            )}
          </EuiText>
          <EuiText size="xs" color="subdued">
            {invite.status === ProjectSharingInviteStatus.OPEN ? (
              <i>Pending Invite</i>
            ) : (
              invite.email
            )}
          </EuiText>
        </EuiFlexItem>
        <EuiFlexItem grow={false}>
          <EuiPopover
            id={htmlIdGenerator()()}
            button={
              <ButtonEmptyPermissionedBase
                isLoading={isWorking}
                size="xs"
                iconSide="right"
                iconType="arrowDown"
                onClick={() => togglePopover()}
                isPermitted={userHasGrantAccessPermission}
              >
                {invite.project_access_level ===
                USER_ACCESS_LEVELS_BY_KEY["COPIER"].id
                  ? "View, Download, & Copy"
                  : "View Only"}
              </ButtonEmptyPermissionedBase>
            }
            isOpen={isPopoverOpen}
            closePopover={() => setPopover(false)}
            panelPaddingSize="none"
            anchorPosition="downCenter"
          >
            <EuiContextMenuPanel
              size="s"
              items={[
                <EuiContextMenuItem
                  key="change-access"
                  onClick={() => {
                    const newAccessLevel =
                      invite.project_access_level ===
                      USER_ACCESS_LEVELS_BY_KEY["COPIER"].id
                        ? USER_ACCESS_LEVELS_BY_KEY["VIEWER"].id
                        : USER_ACCESS_LEVELS_BY_KEY["COPIER"].id;
                    void handleUpdateAccess(newAccessLevel);
                    closePopover();
                  }}
                >
                  <EuiText size="s" color="primary">
                    {invite.project_access_level ===
                    USER_ACCESS_LEVELS_BY_KEY["COPIER"].id ? (
                      <span>
                        Change to <strong>View Only</strong>
                      </span>
                    ) : (
                      <span>
                        Change to <strong>View, Download, & Copy</strong>
                      </span>
                    )}
                  </EuiText>
                </EuiContextMenuItem>,
                invite.status === ProjectSharingInviteStatus.OPEN && (
                  <EuiContextMenuItem
                    key="resend"
                    onClick={() => {
                      void handleResend();
                      closePopover();
                    }}
                  >
                    <EuiText size="s">Resend Invite</EuiText>
                  </EuiContextMenuItem>
                ),
                <EuiContextMenuItem
                  key="remove"
                  onClick={() => {
                    void handleRevoke();
                    closePopover();
                  }}
                >
                  <EuiText size="s" color="danger">
                    Revoke
                  </EuiText>
                </EuiContextMenuItem>,
              ]}
            />
          </EuiPopover>
        </EuiFlexItem>
      </EuiFlexGroup>
    </Fragment>
  );
};

export { UserWithAccessExternal };
