import { EuiButtonEmpty } from "@inscopix/ideas-eui";
import { useDataTableContext } from "pages/gdt/store/DataTableProvider";

export const QuickActionGoToResults = () => {
  const gridApi = useDataTableContext((s) => s.gridApi);
  const resultColumnIds = useDataTableContext((s) => {
    const table = s.tables.find(({ id }) => id === s.selectedTableId);
    const columns = table?.columns.filter((column) => column.is_result);
    return columns?.map((column) => column.id) ?? [];
  });

  const handleClick = () => {
    if (resultColumnIds.length > 0 && gridApi !== undefined) {
      gridApi.ensureColumnVisible(resultColumnIds[0], "start");
      // https://github.com/ag-grid/ag-grid/issues/5085
      setTimeout(() => {
        gridApi?.flashCells({ columns: resultColumnIds });
      }, 0);
    }
  };

  return (
    <EuiButtonEmpty
      size="xs"
      color="success"
      iconType="arrowEnd"
      iconSide="right"
      onClick={handleClick}
      disabled={resultColumnIds.length === 0}
    >
      Go to results
    </EuiButtonEmpty>
  );
};
