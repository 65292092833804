/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import {
  EuiBadge,
  EuiFlexGroup,
  EuiFlexItem,
  EuiLink,
  EuiLoadingSpinner,
  EuiStat,
  EuiText,
  EuiToolTip,
  useEuiTheme,
} from "@inscopix/ideas-eui";
import ProjectIcon from "components/ProjectIcon/ProjectIcon";
import { ProjectsCardActions } from "components/ProjectsCards/ProjectsCardActions";
import { ApplicationUser, Project, Tenant } from "graphql/_Types";
import { ProjectStatus } from "types/constants";
import { filesize } from "utils/filesize";
import { RoleBadge } from "components/RoleBadge/RoleBadge";
import { isUndefined } from "lodash";
import { TRegion } from "providers/RegionsProvider";
import { useRouteMapContext } from "providers/RouteMapProvider/RouteMapProvider";
import { RegionBadge } from "components/RegionBadge/RegionBadge";
import { isDefined } from "utils/isDefined";
import { roundToSignificant } from "utils/roundToSignificant";
import { IconComputeCredits } from "components/IconComputeCredits/IconComputeCredits";
import { ButtonProjectInfo } from "components/ButtonProjectInfo/ButtonProjectInfo";
import moment from "moment";
import { UserAccessLevel } from "types/UserAccessLevels";
import { ButtonProjectPin } from "./ButtonProjectPin";

const getStyles = (euiTheme: ReturnType<typeof useEuiTheme>["euiTheme"]) => ({
  card: css`
    border: 1px solid ${euiTheme.colors.lightShade};
    border-radius: 5px;
    padding: ${euiTheme.size.m};
  `,
  name: css`
    font-size: ${euiTheme.size.l};
  `,
  linkText: css`
    word-break: break-word;
  `,
});

export interface ProjectCardProps {
  project: Pick<
    Project,
    | "id"
    | "name"
    | "key"
    | "icon"
    | "iconColor"
    | "iconText"
    | "shortDescription"
    | "userId"
    | "activeStorageSize"
    | "archivedStorageSize"
    | "defaultUserAccessLevel"
    | "usedCredits"
    | "status"
    | "pinned"
    | "tenantId"
    | "dateCreated"
    | "dateLastActive"
  > & {
    user?: {
      email: ApplicationUser["email"];
      first_name: ApplicationUser["firstName"];
      last_name: ApplicationUser["lastName"];
    };
    tenantKey: Tenant["key"];
    tenantName: Tenant["name"];
    region?: TRegion;
    role: string;
    userPermissions: {
      role: UserAccessLevel["id"];
      role_display: string; //"Admin" | "Owner" | "Viewer" | "Editor";
      download: boolean;
      edit: boolean;
      execute: boolean;
      grant_access: boolean;
      upload: boolean;
      view: boolean;
    };
  };
}

export const ProjectCard = ({ project }: ProjectCardProps) => {
  const { euiTheme } = useEuiTheme();
  const routeMap = useRouteMapContext((s) => s.routeMap);
  const styles = getStyles(euiTheme);

  const {
    id,
    name,
    shortDescription,
    activeStorageSize,
    archivedStorageSize,
    tenantKey,
    tenantName,
    role,
    usedCredits,
  } = project;

  /* End get the user role*/

  const projectPath = routeMap["PROJECT"].dynamicPath({
    tenantKey: tenantKey,
    projectKey: project.key,
  }).path;

  const projectLink = () => {
    if (project.status === ProjectStatus.CLONING) {
      return (
        <EuiLink css={styles.name} disabled={true}>
          {name}
        </EuiLink>
      );
    }
    return isUndefined(project.region) ? (
      <EuiLink css={styles.name} to={projectPath}>
        {name}
      </EuiLink>
    ) : (
      <EuiLink
        css={styles.name}
        href={`${project.region.url}${projectPath}`}
        target="_blank"
      >
        {name}
      </EuiLink>
    );
  };

  return (
    <div css={[styles.card]}>
      <EuiFlexGroup direction="column">
        <EuiFlexGroup
          alignItems="center"
          justifyContent="flexStart"
          responsive={false}
          gutterSize="none"
        >
          <EuiFlexItem
            grow={false}
            css={{
              marginRight: "10px",
              alignSelf: "flex-start",
            }}
          >
            <ProjectIcon size="l" project={project} tenantKey={tenantKey} />
          </EuiFlexItem>
          <EuiFlexItem grow={false}>
            <EuiFlexGroup direction="column" gutterSize="none">
              <EuiFlexItem>
                <EuiText css={styles.linkText}>{projectLink()}</EuiText>
              </EuiFlexItem>
              <EuiFlexItem>
                <EuiText size="s" css={{ lineHeight: "15px" }} color="subdued">
                  updated {moment(project.dateLastActive).fromNow()}
                </EuiText>
              </EuiFlexItem>
            </EuiFlexGroup>
          </EuiFlexItem>
          {project.status === ProjectStatus.CLONING && (
            <EuiToolTip
              content="This project is being copied and will be available shortly"
              position="bottom"
            >
              <EuiBadge color="hollow" title="">
                <EuiFlexGroup gutterSize="xs" alignItems="center">
                  <EuiFlexItem grow={false}>
                    <EuiLoadingSpinner size="s" />
                  </EuiFlexItem>
                  <EuiFlexItem>COPYING</EuiFlexItem>
                </EuiFlexGroup>
              </EuiBadge>
            </EuiToolTip>
          )}

          {/* spacer on header */}
          <EuiFlexItem />

          <EuiFlexItem grow={false}>
            <EuiFlexGroup
              alignItems="center"
              responsive={false}
              gutterSize="xs"
            >
              <EuiFlexItem grow={false}>
                <RoleBadge role={role} />
              </EuiFlexItem>
              <EuiFlexItem grow={false}>
                <ButtonProjectPin
                  pinned={project.pinned ?? false}
                  projectId={project.id}
                />
              </EuiFlexItem>
              {isUndefined(project.region) && (
                <EuiFlexItem grow={false}>
                  <ProjectsCardActions
                    permissions={project.userPermissions}
                    projectId={id}
                    disabled={project.status === ProjectStatus.CLONING}
                  />
                </EuiFlexItem>
              )}
            </EuiFlexGroup>
          </EuiFlexItem>
        </EuiFlexGroup>
        <EuiFlexItem>
          <EuiText>{shortDescription}</EuiText>
        </EuiFlexItem>
        <EuiFlexGroup responsive={false} gutterSize="m">
          <EuiFlexItem>
            <EuiFlexGroup alignItems="flexStart" responsive={false}>
              <EuiFlexItem grow={false}>
                <EuiStat
                  titleSize="xs"
                  title={filesize(activeStorageSize)}
                  description="Active storage"
                  reverse
                />
              </EuiFlexItem>
              <EuiFlexItem grow={false}>
                <EuiStat
                  titleSize="xs"
                  title={filesize(archivedStorageSize)}
                  description="Archived storage"
                  reverse
                  titleColor="subdued"
                />
              </EuiFlexItem>
              <EuiFlexItem grow={false}>
                <EuiStat
                  titleSize="xs"
                  titleElement="div"
                  title={
                    <EuiFlexGroup alignItems="center" gutterSize="s">
                      {roundToSignificant(usedCredits ?? 0)}{" "}
                      <IconComputeCredits />
                    </EuiFlexGroup>
                  }
                  description="Compute credits"
                  reverse
                />
              </EuiFlexItem>
            </EuiFlexGroup>
          </EuiFlexItem>
          <EuiFlexItem grow={false}>
            <EuiFlexGroup
              direction="column"
              gutterSize="none"
              alignItems="flexEnd"
              justifyContent="center"
            >
              <EuiText>
                {isUndefined(project.region) ? (
                  <EuiLink
                    to={
                      routeMap["ORGANIZATION"].dynamicPath({
                        tenantKey: tenantKey,
                      }).path
                    }
                  >
                    {tenantName}
                  </EuiLink>
                ) : (
                  <EuiLink
                    href={`${project.region.url}${
                      routeMap["ORGANIZATION"].dynamicPath({
                        tenantKey: tenantKey,
                      }).path
                    }`}
                    target="_blank"
                  >
                    {tenantName}{" "}
                    {isDefined(project.region) && (
                      <RegionBadge regionKey={project.region.key} />
                    )}
                  </EuiLink>
                )}
              </EuiText>
            </EuiFlexGroup>
          </EuiFlexItem>
          <EuiFlexItem
            grow={false}
            css={css`
              justify-content: center;
            `}
          >
            <ButtonProjectInfo
              projectId={project.id}
              anchorPosition="leftDown"
              repositionOnScroll
            />
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFlexGroup>
    </div>
  );
};
