import {
  EuiFlexGroup,
  EuiFlexItem,
  EuiLink,
  EuiSpacer,
  EuiText,
} from "@inscopix/ideas-eui";
import { UserAvatar } from "components/UserAvatar/UserAvatar";
import { useTenantContext } from "providers/TenantProvider/TenantProvider";
import { PanelTenantUsageContent } from "components/PanelTenantUsage/PanelTenantUsageContent";
import { OrganizationActions } from "./OrganizationActions";
import { useRouteMapContext } from "providers/RouteMapProvider/RouteMapProvider";
import { useUserContext } from "providers/UserProvider/UserProvider";

const PageOrganizationSidebarOrg = () => {
  const currentTenant = useTenantContext((s) => s.currentTenant);
  const isOrgAdmin = useUserContext(
    (s) =>
      s.tenants.find((tenant) => tenant.id === currentTenant.id)?.role.key ===
      "admin",
  );

  return (
    <EuiFlexGroup alignItems="center" gutterSize="xs">
      <EuiFlexItem>
        <EuiFlexGroup gutterSize={"none"}>
          <EuiFlexItem>
            <EuiText title={`${currentTenant.name}`}>
              <h3>{currentTenant.name}</h3>
            </EuiText>
          </EuiFlexItem>
          <EuiSpacer size="xs" />
          <EuiFlexItem grow={false}>
            {isOrgAdmin && <OrganizationActions orgKey={currentTenant.key} />}
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFlexItem>
    </EuiFlexGroup>
  );
};

const PageOrganizationSidebarPeople = () => {
  const routeMap = useRouteMapContext((s) => s.routeMap);
  const currentTenant = useTenantContext((s) => s.currentTenant);

  const tenantUsers = useTenantContext((s) => s.tenantUsers);

  return (
    <EuiFlexGroup direction="column" gutterSize={"s"}>
      <EuiFlexItem>
        <EuiText>
          <h4>
            <EuiLink
              to={
                routeMap.ORGANIZATION_PEOPLE.dynamicPath({
                  tenantKey: currentTenant.key,
                }).path
              }
            >
              People
            </EuiLink>
          </h4>
        </EuiText>
      </EuiFlexItem>
      <EuiFlexItem>
        <EuiFlexGroup gutterSize={"s"} wrap={true} responsive={false}>
          {tenantUsers
            .filter(({ internal, isActive }) => !internal && isActive)
            .map((user) => (
              <EuiFlexItem key={user.id} grow={false}>
                <UserAvatar userId={user.id} size="l" />
              </EuiFlexItem>
            ))}
        </EuiFlexGroup>
      </EuiFlexItem>
    </EuiFlexGroup>
  );
};

const PageOrganizationSideBarUsage = () => {
  const routeMap = useRouteMapContext((s) => s.routeMap);
  const currentTenant = useTenantContext((s) => s.currentTenant);
  return (
    <EuiFlexGroup direction="column" gutterSize={"s"}>
      <EuiFlexItem>
        <EuiText>
          <h4>
            <EuiLink
              to={
                routeMap.ORGANIZATION_USAGE.dynamicPath({
                  tenantKey: currentTenant.key,
                }).path
              }
            >
              Usage
            </EuiLink>
          </h4>
        </EuiText>
      </EuiFlexItem>
      <EuiFlexItem>
        <PanelTenantUsageContent compact />
      </EuiFlexItem>
    </EuiFlexGroup>
  );
};

export const PageOrganizationSideBar = () => {
  return (
    <EuiFlexGroup direction="column" gutterSize="xl">
      <EuiFlexItem>
        <PageOrganizationSidebarOrg />
      </EuiFlexItem>
      <EuiFlexItem>
        <PageOrganizationSidebarPeople />
      </EuiFlexItem>
      <EuiFlexItem>
        <PageOrganizationSideBarUsage />
      </EuiFlexItem>
    </EuiFlexGroup>
  );
};
