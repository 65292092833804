/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { useState } from "react";
import { CellValue } from "@inscopix/ideas-hyperformula";

const styles = {
  root: css`
    border: none;
    background-color: transparent;
    width: 100%;
    &:focus {
      outline: none;
    }
  `,
};

interface CellEditorTextProps {
  value: CellValue;
  onChangeFormula: (newFormula: string) => void;
  stopEditing: (cancel?: boolean) => void;
}

/** Cell editor for cells in text columns */
export const CellEditorText = ({
  value,
  onChangeFormula,
  stopEditing,
}: CellEditorTextProps) => {
  const [newValue, setNewValue] = useState(() => {
    if (
      typeof value === "string" ||
      typeof value === "boolean" ||
      typeof value === "number"
    ) {
      return value.toString();
    } else {
      return "";
    }
  });

  return (
    <input
      autoFocus
      type="text"
      step={0.1}
      css={styles.root}
      value={newValue}
      onBlur={() => stopEditing()}
      onChange={(e) => {
        const newValue = e.target.value;
        setNewValue(newValue);
        // Prepend the value with ' to coerce it to a text formula
        const newFormula = newValue === "" ? newValue : `'${newValue}`;
        onChangeFormula(newFormula);
      }}
    />
  );
};
