import { EuiButtonEmpty } from "@inscopix/ideas-eui";
import { useDataTableContext } from "pages/gdt/store/DataTableProvider";
import { colGroupDefTask } from "../DataTable";

export const QuickActionGoToTaskInfo = () => {
  const gridApi = useDataTableContext((s) => s.gridApi);
  const selectedTableId = useDataTableContext((s) => s.selectedTableId);

  const handleClick = () => {
    const columnIds =
      gridApi
        ?.getProvidedColumnGroup(colGroupDefTask.groupId)
        ?.getChildren()
        .map((column) => column.getId()) ?? [];

    if (columnIds.length > 0 && gridApi !== undefined) {
      gridApi.ensureColumnVisible(columnIds[0], "start");
      // https://github.com/ag-grid/ag-grid/issues/5085
      setTimeout(() => {
        gridApi.flashCells({ columns: columnIds });
      }, 0);
    }
  };

  return (
    <EuiButtonEmpty
      size="xs"
      iconType="arrowEnd"
      iconSide="right"
      onClick={() => void handleClick()}
      disabled={selectedTableId === undefined}
    >
      Go to task info
    </EuiButtonEmpty>
  );
};
