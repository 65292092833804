import { EuiPageTemplate } from "@inscopix/ideas-eui";
import { PanelProjects } from "components/PanelProjects/PanelProjects";
import { PageDashboardSideBar } from "pages/dashboard/PageDashboard/PageDashboardSidebar";
import { Helmet } from "react-helmet-async";

export const PageHome = () => {
  return (
    <>
      <Helmet>
        <title>{`Dashboard`}</title>
      </Helmet>
      <EuiPageTemplate panelled={true} bottomBorder={"extended"}>
        <EuiPageTemplate.Sidebar minWidth={300} sticky={true}>
          <PageDashboardSideBar />
        </EuiPageTemplate.Sidebar>

        <EuiPageTemplate.Section
          grow
          restrictWidth={false}
          contentProps={{ style: { height: "100%" } }}
          style={{ minWidth: 500 }}
        >
          <PanelProjects />
        </EuiPageTemplate.Section>
      </EuiPageTemplate>
    </>
  );
};
