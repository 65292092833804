/** @jsxImportSource @emotion/react */
import { memo, useMemo } from "react";
import { FileBadge } from "components/FileBadge/FileBadge";
import { isNull } from "lodash";
import { FlyoutDrsFileInfoProps } from "components/FlyoutDrsFileInfo/FlyoutDrsFileInfo";
import { FileNotFoundBadge } from "components/FileBadge/FileNotFoundBadge";
import { useFileGridRendererStyles } from "styles/useFileGridRendererStyles/useFileGridRendererStyles";
import { File as DrsFile } from "graphql/_Types";
import { useGetProjectFilesByFileIds } from "./hooks/useGetProjectFilesByFileIds";

export interface GridRendererToolPathParamProps {
  fileIds: string[] | undefined;
  onClickFileBadge: (
    drsFile: FlyoutDrsFileInfoProps["drsFile"] &
      Pick<
        DrsFile,
        "source" | "projectId" | "seriesParentId" | "processingStatus"
      >,
  ) => void;
}

/**
 * A component for rendering `ToolPathParam` grid cells
 */
export const GridRendererToolPathParam = memo(
  function GridRendererToolPathParam({
    fileIds,
    onClickFileBadge,
  }: GridRendererToolPathParamProps) {
    const { getProjectFilesByFileIds } = useGetProjectFilesByFileIds();

    const fileGridRendererStyles = useFileGridRendererStyles();

    const drsFiles = useMemo(() => {
      if (fileIds === undefined) {
        return null;
      }
      return getProjectFilesByFileIds(fileIds);
    }, [fileIds, getProjectFilesByFileIds]);

    if (isNull(drsFiles)) {
      return null;
    }

    const isCompact =
      drsFiles.drsFilesFound.length + drsFiles.fileIdsNotFound.length > 1;

    return (
      <div css={fileGridRendererStyles.root}>
        <div>
          {drsFiles.drsFilesFound.map((drsFile) => (
            <FileBadge
              key={drsFile.id}
              drsFile={drsFile}
              compact={isCompact}
              onClick={(e) => {
                /* https://developer.mozilla.org/en-US/docs/Web/API/UIEvent/detail
          Only fire `handleClick` once per click. The additional click
          triggered by double click events will not trigger `handleClick`.
          This is done to mitigate a mysterious bug where the cell editor
          does not open when `openFlyout` is called multiple times in
          rapid succession when a user double clicks the file badge. */
                if (e.detail === 1) {
                  onClickFileBadge(drsFile);
                }
              }}
            />
          ))}
          {drsFiles.fileIdsNotFound.map((fileId) => (
            <FileNotFoundBadge key={fileId} isCompact={isCompact} />
          ))}
        </div>
      </div>
    );
  },
);
