import {
  EuiButtonEmpty,
  EuiButtonIcon,
  EuiFlexGroup,
  EuiFlexItem,
  EuiPopover,
} from "@inscopix/ideas-eui";
import {
  resendInvitation,
  revokeInvitation,
} from "components/PanelTenantAccess/PanelTenantAccess.helpers";
import { useState } from "react";
import { addUtilityToastFailure } from "utils/addUtilityToastFailure";
import { addUtilityToastSuccess } from "utils/addUtilityToastSuccess";
import { TenantUserInvite } from "./PanelTenantAccess.types";

interface PanelTenantAccessInvitationActionsProps {
  invitationId: TenantUserInvite["id"];
  onRevoke: () => void;
}

export const PanelTenantAccessInvitationActions = ({
  invitationId,
  onRevoke,
}: PanelTenantAccessInvitationActionsProps) => {
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);
  const togglePopover = () => setIsPopoverOpen((isOpen) => !isOpen);
  const closePopover = () => setIsPopoverOpen((isOpen) => !isOpen);
  const [action, setAction] = useState<"resend" | "revoke">();

  const handleResend = async () => {
    try {
      setAction("resend");
      await resendInvitation({ invitationId });
      addUtilityToastSuccess("Invitation resent");
    } catch (error) {
      addUtilityToastFailure("Failed to resend invitation");
    } finally {
      setAction(undefined);
      closePopover();
    }
  };

  const handleRevoke = async () => {
    try {
      setAction("revoke");
      await revokeInvitation({ invitationId });
      addUtilityToastSuccess("Invitation revoked");
    } catch (error) {
      addUtilityToastFailure("Failed to revoke invitation");
    } finally {
      setAction(undefined);
      closePopover();
      onRevoke();
    }
  };

  return (
    <EuiPopover
      button={
        <EuiButtonIcon
          aria-label="Modify invitation"
          color="text"
          iconType="boxesVertical"
          onClick={togglePopover}
        />
      }
      isOpen={isPopoverOpen}
      closePopover={closePopover}
      panelPaddingSize="none"
      anchorPosition="rightCenter"
      repositionOnScroll
    >
      <EuiFlexGroup
        alignItems="stretch"
        direction="column"
        gutterSize="none"
        justifyContent="center"
      >
        <EuiFlexItem>
          <EuiButtonEmpty
            color="primary"
            onClick={() => void handleResend()}
            isLoading={action === "resend"}
            disabled={action !== undefined}
          >
            Resend
          </EuiButtonEmpty>
        </EuiFlexItem>
        <EuiFlexItem>
          <EuiButtonEmpty
            color="danger"
            onClick={() => void handleRevoke()}
            isLoading={action === "revoke"}
            disabled={action !== undefined}
          >
            Revoke
          </EuiButtonEmpty>
        </EuiFlexItem>
      </EuiFlexGroup>
    </EuiPopover>
  );
};
