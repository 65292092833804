import { EuiFlexGroup, EuiFlexItem } from "@inscopix/ideas-eui";
import { useDataTableContext } from "../../store/DataTableProvider";
import { QuickActionInsertRow } from "./QuickActionInsertRow";
import { QuickActionGoToTaskInfo } from "./QuickActionGoToTaskInfo";
import { QuickActionGoToResults } from "./QuickActionGoToResults";
import { QuickActionJumpToColumn } from "./QuickActionJumpToColumn";
import { QuickActionDownloadTableAsCsv } from "./QuickActionDownloadTableAsCsv";
import { QuickActionRunSelectedRows } from "./QuickActionRunRow";
import { QuickActionCopySelectedRows } from "./QuickActionCopyRow";
import { QuickActionDeleteSelectedRows } from "./QuickActionDeleteRow";

/**
 * Component that renders groups of buttons that manipulate a data table through
 * single-click actions
 */
export const DataTableQuickActionBar = () => {
  const selectedTable = useDataTableContext((s) => {
    return s.tables.find(({ id }) => id === s.selectedTableId);
  });

  return (
    <EuiFlexGroup responsive={false} alignItems="center">
      <EuiFlexItem>
        <EuiFlexGroup gutterSize="xs" responsive={false}>
          <EuiFlexItem grow={false}>
            <QuickActionInsertRow />
          </EuiFlexItem>

          <EuiFlexItem grow={false}>
            <QuickActionCopySelectedRows />
          </EuiFlexItem>

          <EuiFlexItem grow={false}>
            <QuickActionDeleteSelectedRows />
          </EuiFlexItem>

          {selectedTable?.kind === "analysis" && (
            <EuiFlexItem grow={false}>
              <QuickActionRunSelectedRows />
            </EuiFlexItem>
          )}

          <EuiFlexItem grow={false}>
            <QuickActionDownloadTableAsCsv />
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFlexItem>

      <EuiFlexItem grow={false}>
        <EuiFlexGroup responsive={false}>
          <EuiFlexItem grow={false}>
            <QuickActionJumpToColumn />
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFlexItem>

      <EuiFlexItem>
        <EuiFlexGroup
          responsive={false}
          gutterSize="s"
          justifyContent="flexEnd"
        >
          {selectedTable?.kind === "analysis" && (
            <EuiFlexItem grow={false}>
              <QuickActionGoToResults />
            </EuiFlexItem>
          )}

          {selectedTable?.kind === "analysis" && (
            <EuiFlexItem grow={false}>
              <QuickActionGoToTaskInfo />
            </EuiFlexItem>
          )}
        </EuiFlexGroup>
      </EuiFlexItem>
    </EuiFlexGroup>
  );
};
