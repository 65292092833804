import { get } from "lodash";
import { AxiosError } from "axios";
import { isDefined } from "./isDefined";

/**
 * Checks whether an axios error is a Django error with a specified code.
 * @param error
 * @param code
 * @returns `true` if the axios error matches the code, `false` otherwise.
 */
export const hasDjangoErrorCode = (error: AxiosError, code: string) => {
  const errors = get(error, "response.data.errors") as
    | { code: string; detail: string }[]
    | undefined;
  return isDefined(errors) && errors.some((error) => error.code === code);
};
