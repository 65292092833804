import { Dataset, File as DrsFile, Tenant } from "graphql/_Types";
import { useDatasetDataContext } from "pages/project/dataset/DatasetDataProvider";
import { useCallback } from "react";
import { useProjectDataContext } from "pages/project/ProjectDataProvider";
import { useUploadFile } from "hooks/useUploadFile";
import { useProjectPermissionStore } from "pages/project/ProjectPermissionProvider";

type UploadFileParams = {
  blob: File;
  id: DrsFile["id"];
  name: DrsFile["id"];
  datasetId: Dataset["id"];
  tenantId: Tenant["id"];
  size: NonNullable<DrsFile["size"]>;
};

export const useActionUploadFile = () => {
  const { datasetMode } = useDatasetDataContext();
  const { project } = useProjectDataContext();

  const hasUploadPermission = useProjectPermissionStore(
    (s) => s.permissions["upload"],
  );

  const { writeCache, enqueueUpload } = useUploadFile();

  const getActionDef = useCallback(
    (params: UploadFileParams) => {
      const dateCreated = new Date().toISOString();

      return {
        onEnqueue: () => {
          writeCache({ ...params, project, dateCreated });
        },

        onDequeue: async () =>
          enqueueUpload({ ...params, project, dateCreated }),
      };
    },
    [enqueueUpload, project, writeCache],
  );

  const isDisabled = datasetMode.type !== "current" || !hasUploadPermission;

  return {
    getActionDef,
    isDisabled,
  };
};
