import axios from "axios";
import { getRequestHeaders } from "utils/getRequestHeaders";
import { getEnvVar } from "ideas.env";
import { USER_ACCESS_LEVELS_BY_KEY } from "types/UserAccessLevels";
import { Project } from "graphql/_Types";
import {
  CreateProjectDjangoResponse,
  CreateProjectInput,
  CreateProjectResult,
} from "./useCreateProjectDjango";

// We support setting a subset of values when cloning a project
export type CloneProjectInput = Pick<
  CreateProjectInput,
  "name" | "key" | "short_description" | "icon_color" | "tenant"
> & { include_data: boolean };

export const useCloneProjectDjango = () => {
  const cloneProjectDjango = async (
    project_id: Project["id"],
    input: CloneProjectInput,
  ): Promise<CreateProjectResult> => {
    const headers = await getRequestHeaders({ tenantId: input.tenant });
    const url = `${getEnvVar("URL_LIBRARY_PROJECT_CLONE")}${project_id}/`;
    const response = await axios.post<
      CloneProjectInput,
      CreateProjectDjangoResponse
    >(url, input, {
      headers,
    });

    const {
      id,
      date_archived,
      date_created,
      date_deleted,
      date_data_deleted,
      short_description,
      description,
      key,
      name,
      tenant,
      user,
      icon,
      icon_color,
      icon_text,
      status,
      date_last_active,
    } = response.data;

    return {
      __typename: "Project",
      id,
      dateArchived: date_archived,
      dateCreated: date_created,
      dateDeleted: date_deleted,
      dateDataDeleted: date_data_deleted,
      key,
      name,
      tenantId: tenant,
      userId: user,
      shortDescription: short_description,
      description,
      icon,
      iconColor: icon_color,
      iconText: icon_text,
      activeStorageSize: "0",
      archivedStorageSize: "0",
      status: status,
      defaultUserAccessLevel: USER_ACCESS_LEVELS_BY_KEY["RESTRICTED"].id,
      pinned: false,
      usedCredits: 0,
      dateLastActive: date_last_active,
    };
  };
  return { cloneProjectDjango };
};
