import { EmotionJSX } from "@emotion/react/types/jsx-namespace";
import { File as DrsFile } from "graphql/_Types";
import { useDrsFileDownloadPreviewDjango } from "hooks/useDrsFileDownloadPreviewDjango";
import { FileFormat } from "types/FileFormats";
import { FilePreviewHtml } from "./FilePreviewHtml";
import { FilePreviewJson } from "./FilePreviewJson";
import { FilePreviewMp4 } from "./FilePreviewMp4";
import { FilePreviewPng } from "./FilePreviewPng";
import { FilePreviewSvg } from "./FilePreviewSvg";

/** Represents file preview information with a presigned URL  */
export type PresignedFilePreview = NonNullable<
  Awaited<
    ReturnType<
      ReturnType<typeof useDrsFileDownloadPreviewDjango>["downloadFilePreview"]
    >
  >["data"]["previews"]
>[number];

/** Represents the props used by components rendering file previews */
export interface FilePreviewComponentProps {
  file: Pick<DrsFile, "fileType">;
  preview: PresignedFilePreview;
}

/** Represents a component that renders a file preview */
type FilePreviewComponent = (
  props: FilePreviewComponentProps,
) => EmotionJSX.Element | null;

/** Represents a lookup map from file format keys to file preview components */
export const FilePreviewComponentMap: Partial<{
  [key in FileFormat["key"]]: FilePreviewComponent;
}> = {
  json: FilePreviewJson,
  png: FilePreviewPng,
  mp4: FilePreviewMp4,
  html: FilePreviewHtml,
  svg: FilePreviewSvg,
};
