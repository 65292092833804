import axios from "axios";
import { Project } from "graphql/_Types";
import { getRequestHeaders } from "utils/getRequestHeaders";
import { getEnvVar } from "ideas.env";
import { mapKeys, snakeCase } from "lodash";
import { SnakeCasedProperties } from "type-fest";
import { UserAccessLevel } from "types/UserAccessLevels";

export type LibraryProjectPatchInput = Partial<
  Pick<
    Project,
    | "key"
    | "name"
    | "shortDescription"
    | "iconColor"
    | "userId"
    | "description"
    | "defaultUserAccessLevel"
  >
>;

export type LibraryProjectPatchDjangoResponse = {
  data: SnakeCasedProperties<LibraryProjectPatchInput>;
};

export const libraryProjectPatch = async (
  projectId: Project["id"],
  input: LibraryProjectPatchInput,
) => {
  const url = `${getEnvVar("URL_LIBRARY_PROJECT")()}${projectId}/`;
  const snakeCasedInput = mapKeys(input, (_, key) => snakeCase(key));
  const headers = await getRequestHeaders();
  const res = await axios.patch<
    LibraryProjectPatchInput,
    LibraryProjectPatchDjangoResponse
  >(url, { ...snakeCasedInput }, { headers });

  return {
    ...res.data,
    shortDescription: res.data.short_description,
    defaultUserAccessLevel: res.data
      .default_user_access_level as UserAccessLevel["id"],
    iconColor: res.data.icon_color,
  };
};
