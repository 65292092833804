import { EuiFlexGroup, EuiFlexItem } from "@inscopix/ideas-eui";
import { TaskCountBadge } from "components/AnalysisTableTaskCounts/TaskCountBadge";
import { TaskStatus } from "types/constants";
import { useDataTableContext } from "../store/DataTableProvider";
import { isNonNull } from "utils/isNonNull";
import { groupBy } from "lodash";

interface DataTableTaskCountsProps {
  tableId: string;
}

export const DataTableTaskCounts = ({ tableId }: DataTableTaskCountsProps) => {
  const tasks = useDataTableContext((s) => {
    const table = s.tables.find((table) => table.id === tableId);
    return (table?.rows ?? []).map((row) => row.task).filter(isNonNull);
  });

  const numTasksByStatus = (() => {
    const tasksByStatus = groupBy(tasks, ({ status }) => status);
    const numTasksComplete = tasksByStatus[TaskStatus.COMPLETE]?.length ?? 0;
    const numTasksRunning = tasksByStatus[TaskStatus.RUNNING]?.length ?? 0;
    const numTasksQueued = tasksByStatus[TaskStatus.QUEUED]?.length ?? 0;
    const numTasksFailed = tasksByStatus[TaskStatus.FAILED]?.length ?? 0;
    const numTasksErrored = tasksByStatus[TaskStatus.ERROR]?.length ?? 0;

    return {
      [TaskStatus.COMPLETE]: numTasksComplete,
      [TaskStatus.RUNNING]: numTasksRunning,
      [TaskStatus.QUEUED]: numTasksQueued,
      [TaskStatus.FAILED]: numTasksFailed + numTasksErrored,
    };
  })();

  return (
    <EuiFlexGroup gutterSize="xs">
      {(
        [
          TaskStatus.COMPLETE,
          TaskStatus.RUNNING,
          TaskStatus.QUEUED,
          TaskStatus.FAILED,
        ] as const
      )
        .filter((status) => numTasksByStatus[status] > 0)
        .map((status) => (
          <EuiFlexItem key={status}>
            <TaskCountBadge
              isLoading={false}
              numTasksByStatus={numTasksByStatus}
              taskStatus={status}
            />
          </EuiFlexItem>
        ))}
    </EuiFlexGroup>
  );
};
