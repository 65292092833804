import { EuiButtonProps } from "@inscopix/ideas-eui";
import { ButtonPermissioned } from "components/ButtonPermissioned/ButtonPermissioned";
import { useDatasetAction } from "hooks/useDatasetAction/useDatasetAction";
import { useDatasetDataContext } from "pages/project/dataset/DatasetDataProvider";
import { selectSystemFiles } from "utils/selectSystemFiles";
import { uuid } from "utils/uuid";

export type ButtonDatasetUploadProps = Omit<EuiButtonProps, "onClick">;

export const ButtonDatasetUpload = (props: ButtonDatasetUploadProps) => {
  const { dataset } = useDatasetDataContext();
  const uploadFileAction = useDatasetAction("uploadFile");

  const handleUpload = (files: File[]) => {
    files.forEach((file) => {
      const fileId = uuid();

      void uploadFileAction.enqueue({
        blob: file,
        id: fileId,
        name: file.name,
        datasetId: dataset.id,
        tenantId: dataset.tenantId,
        size: String(file.size),
      });
    });
  };

  return (
    <>
      <ButtonPermissioned
        onClick={() =>
          void selectSystemFiles(true).then((files) => {
            if (files !== undefined) {
              handleUpload(files);
            }
          })
        }
        {...props}
        requiredPermission="upload"
      >
        Upload
      </ButtonPermissioned>
    </>
  );
};
