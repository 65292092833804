import { EuiButtonIcon } from "@inscopix/ideas-eui";
import { Tooltip } from "components/Tooltip/Tooltip";
import { useDataTableContext } from "pages/gdt/store/DataTableProvider";
import { useState } from "react";
import { addUtilityToastFailure } from "utils/addUtilityToastFailure";
import { addUtilityToastSuccess } from "utils/addUtilityToastSuccess";

export const QuickActionInsertRow = () => {
  const selectedTableId = useDataTableContext((s) => s.selectedTableId);
  const createRows = useDataTableContext((s) => s.createRows);
  const [isLoading, setIsLoading] = useState(false);

  const handleClick = async () => {
    if (selectedTableId !== undefined) {
      setIsLoading(true);
      const { error } = await createRows({
        tableId: selectedTableId,
        numRows: 1,
      });
      setIsLoading(false);

      if (error === undefined) {
        addUtilityToastSuccess("Row inserted");
      } else {
        addUtilityToastFailure("Failed to insert row");
      }
    }
  };

  return (
    <Tooltip content="Insert row">
      <EuiButtonIcon
        aria-label="Insert row"
        size="xs"
        iconType="plusInCircleFilled"
        onClick={() => void handleClick()}
        disabled={selectedTableId === undefined}
        isLoading={isLoading}
      />
    </Tooltip>
  );
};
