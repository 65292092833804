/** @jsxImportSource @emotion/react */

import { EuiIcon, EuiLink } from "@inscopix/ideas-eui";
import { ColumnState, ICellRendererParams } from "ag-grid-enterprise";
import { AgGridReact } from "ag-grid-react";
import { ButtonProjectPin } from "components/ProjectCard/ButtonProjectPin";
import { EuiBadgeMemo } from "components/ToolParamsGrid/EuiBadgeMemo";
import { UserAvatarRenderer } from "components/UserAvatarRenderer/UserAvatarRenderer";
import { ApplicationUser, Project, Tenant } from "graphql/_Types";
import { useIdeasSearchParams } from "hooks/useIdeasSearchParams";
import { partition } from "lodash";
import { ModalProvider } from "providers/ModalProvider/ModalProvider";
import { useCallback, useMemo } from "react";
import { UserAccessLevel } from "types/UserAccessLevels";
import { filesize } from "utils/filesize";
import { formatDate } from "utils/formatDate";
import { isDefined } from "utils/isDefined";
import { isNonNullish } from "utils/isNonNullish";
import { roundToSignificant } from "utils/roundToSignificant";
import { ProjectsGridCellRendererName } from "./ProjectsGridCellRendererName";
import {
  ColDef,
  ColumnStateParams,
  GridReadyEvent,
  SortChangedEvent,
} from "ag-grid-community";
import { css } from "@emotion/react";
import { useRouteMapContext } from "providers/RouteMapProvider/RouteMapProvider";

export type ProjectsGridColDef = ColDef<ProjectsGridRowDatum> & {
  colId:
    | "name"
    | "owner"
    | "organization"
    | "dateCreated"
    | "recentActivity"
    | "activeStorage"
    | "archivedStorage"
    | "totalStorage"
    | "computeCredits"
    | "role"
    | "pinned"
    | "actions";
  // Gets the value that should be written for the cell to a CSV file. If null
  // is specified, the column will be omitted in the CSV.
  csvValueGetter: ((data: ProjectsGridRowDatum) => string) | null;
};

export type ProjectsGridSortableColumns = Exclude<
  ProjectsGridColDef["colId"],
  "actions" | "pinned" | "role"
>;

const projectsGridSortableColumns = new Set<string>([
  "name",
  "dateCreated",
  "recentActivity",
  "activeStorage",
  "archivedStorage",
  "totalStorage",
  "computeCredits",
  "owner",
  "organization",
] satisfies ProjectsGridSortableColumns[]);

export interface ProjectsGridProps {
  projects: (Pick<
    Project,
    | "id"
    | "name"
    | "userId"
    | "dateCreated"
    | "dateLastActive"
    | "usedCredits"
    | "activeStorageSize"
    | "archivedStorageSize"
    | "defaultUserAccessLevel"
    | "key"
    | "pinned"
    | "status"
  > & {
    user?: {
      email: ApplicationUser["email"];
      first_name: ApplicationUser["firstName"];
      last_name: ApplicationUser["lastName"];
    };
    role: string;
    userPermissions: {
      role: UserAccessLevel["id"];
      role_display: string; //"Admin" | "Owner" | "Viewer" | "Editor";
      download: boolean;
      edit: boolean;
      execute: boolean;
      grant_access: boolean;
      upload: boolean;
      view: boolean;
    };
    tenantKey: NonNullable<Tenant["key"]>;
    tenantName: NonNullable<Tenant["name"]>;
  })[];
  onGridReady?: (event: GridReadyEvent<ProjectsGridRowDatum>) => void;
  actions?: ProjectsGridColDef;
  suppressColumns?: ProjectsGridColDef["colId"][];
  // Prevent ag grid from sorting rows when sorting is controlled by parent
  suppressSortComparator?: boolean;
  // Don't pin pinned projects to top of grid (for admin table)
  suppressPinnedRows?: boolean;
}

export type ProjectsGridRowDatum = ProjectsGridProps["projects"][number];

export type ProjectsGridCellRendererParams =
  ICellRendererParams<ProjectsGridRowDatum>;

export const ProjectsGrid = ({
  projects,
  actions,
  onGridReady: _onGridReady,
  suppressColumns,
  suppressSortComparator,
  suppressPinnedRows,
}: ProjectsGridProps) => {
  const { setParam, deleteParam, getParam } = useIdeasSearchParams();
  const routeMap = useRouteMapContext((s) => s.routeMap);

  const columnDefs: ProjectsGridColDef[] = useMemo(() => {
    const colDefName: ProjectsGridColDef = {
      colId: "name",
      headerName: "Name",
      field: "name",
      // subscribe to name and role for re renders
      valueGetter: (project) =>
        `${project.data?.name ?? ""}${
          project.data?.userPermissions.role ?? ""
        }`,
      // custom sort comparator due to name value getter containing more than name
      comparator: (_, __, a, b) => {
        const valA = a.data?.name ?? "";

        const valB = b.data?.name ?? "";
        if (valA === valB) {
          return 0;
        }
        if (valA > valB) {
          return 1;
        }
        return -1;
      },
      cellRenderer: ProjectsGridCellRendererName,
      flex: 1,
      minWidth: 100,
      sortable: projectsGridSortableColumns.has("name"),
      csvValueGetter: (data) => data.name,
    };

    const colDefOwner: ProjectsGridColDef = {
      colId: "owner",
      headerName: "Owner",
      valueGetter: (project) =>
        (project.data?.user?.first_name ?? "") +
        (project.data?.user?.last_name ?? ""),
      cellRenderer: ({ data }: ProjectsGridCellRendererParams) => {
        const userId = data?.userId;
        if (userId !== undefined) {
          return <UserAvatarRenderer userId={userId} size="s" />;
        }
        return <EuiIcon type="warning" title="Failed to retrieve user info" />;
      },
      width: 70,
      sortable: projectsGridSortableColumns.has("owner"),
      // User will be undefined if removed from tenant
      csvValueGetter: (data) => data.user?.email ?? "",
    };

    const colDefCreated: ProjectsGridColDef = {
      colId: "dateCreated",
      headerName: "Created",
      valueGetter: (project) => project.data?.dateCreated,
      valueFormatter: (project) =>
        project.data?.dateCreated !== undefined
          ? formatDate(project.data?.dateCreated)
          : "",
      width: 150,
      sortable: projectsGridSortableColumns.has("dateCreated"),
      csvValueGetter: (data) => formatDate(data.dateCreated),
    };

    const colDefOrganization: ProjectsGridColDef = {
      colId: "organization",
      headerName: "Organization",
      valueGetter: ({ data }) => data?.tenantName,
      width: 110,
      cellRenderer: ({ data }: ProjectsGridCellRendererParams) => {
        if (data === undefined) {
          return null;
        }
        return (
          <EuiLink
            to={
              routeMap["ORGANIZATION"].dynamicPath({
                tenantKey: data.tenantKey,
              }).path
            }
          >
            {data.tenantName}
          </EuiLink>
        );
      },
      sortable: projectsGridSortableColumns.has("organization"),
      csvValueGetter: (project) => project.tenantName,
    };

    const colDefUpdated: ProjectsGridColDef = {
      colId: "recentActivity",
      headerName: "Updated",
      valueGetter: (project) => {
        const date = project.data?.dateLastActive;
        if (isNonNullish(date)) {
          return formatDate(date);
        }
      },
      width: 150,
      sortable: projectsGridSortableColumns.has("recentActivity"),
      csvValueGetter: (data) => formatDate(data.dateLastActive),
    };

    const colDefStorage: ProjectsGridColDef = {
      colId: "activeStorage",
      headerName: "Active Storage",
      valueGetter: (project) => Number(project.data?.activeStorageSize ?? "0"),
      valueFormatter: ({ value }: { value: number }) =>
        filesize(value) as string,
      width: 100,
      sortable: projectsGridSortableColumns.has("activeStorage"),
      csvValueGetter: (data) => data.activeStorageSize ?? "0",
    };

    const colDefArchivedStorage: ProjectsGridColDef = {
      colId: "archivedStorage",
      headerName: "Archived Storage",
      valueGetter: (project) =>
        Number(project.data?.archivedStorageSize ?? "0"),
      valueFormatter: ({ value }: { value: number }) =>
        filesize(value) as string,
      width: 100,
      sortable: projectsGridSortableColumns.has("archivedStorage"),
      csvValueGetter: (data) => data.archivedStorageSize ?? "0",
    };

    const colDefTotalStorage: ProjectsGridColDef = {
      colId: "totalStorage",
      headerName: "Total Storage",
      valueGetter: (project) =>
        Number(project.data?.archivedStorageSize ?? "0") +
        Number(project.data?.activeStorageSize ?? "0"),
      valueFormatter: ({ value }: { value: number }) =>
        filesize(value) as string,
      width: 100,
      sortable: projectsGridSortableColumns.has("totalStorage"),
      csvValueGetter: (data) => {
        const active = Number(data.activeStorageSize ?? "0");
        const archived = Number(data.archivedStorageSize ?? "0");
        return (active + archived).toString();
      },
    };

    const colDefComputeCredits: ProjectsGridColDef = {
      colId: "computeCredits",
      headerName: "Compute Credits",
      valueGetter: (project) => project.data?.usedCredits ?? 0,
      valueFormatter: ({ value }: { value: number }) =>
        String(roundToSignificant(value)),
      width: 80,
      sortable: projectsGridSortableColumns.has("computeCredits"),
      csvValueGetter: (data) => (data.usedCredits ?? 0).toString(),
    };

    const colDefRole: ProjectsGridColDef = {
      colId: "role",
      headerName: "Access Level",
      valueGetter: (project) => project.data?.role ?? "Restricted",
      cellRenderer: ({ data }: ProjectsGridCellRendererParams) => (
        <EuiBadgeMemo color="hollow">{data?.role ?? "Restricted"}</EuiBadgeMemo>
      ),
      width: 120,
      sortable: projectsGridSortableColumns.has("role"),
      csvValueGetter: (data) => data.role,
    };

    const colDefPinned: ProjectsGridColDef = {
      colId: "pinned",
      headerName: "Pinned",
      valueGetter: (project) => project.data?.pinned,
      cellRenderer: ({ data }: ProjectsGridCellRendererParams) => {
        if (data === undefined) {
          return null;
        }
        return (
          <ButtonProjectPin
            projectId={data?.id}
            pinned={data?.pinned ?? false}
          />
        );
      },
      width: 70,
      sortable: projectsGridSortableColumns.has("pinned"),
      csvValueGetter: (project) => String(project.pinned ?? false),
    };

    return [
      colDefPinned,
      colDefName,
      colDefRole,
      colDefOrganization,
      colDefOwner,
      colDefCreated,
      colDefUpdated,
      colDefStorage,
      colDefArchivedStorage,
      colDefTotalStorage,
      colDefComputeCredits,
      actions,
    ]
      .filter(isDefined)
      .filter(
        ({ colId }) =>
          !(suppressColumns ?? []).some(
            (suppressedColId) => suppressedColId === colId,
          ),
      )
      .map((colDef) => ({
        ...colDef,
        comparator: suppressSortComparator ? () => 0 : colDef?.comparator,
      }));
  }, [actions, routeMap, suppressColumns, suppressSortComparator]);

  const defaultColDef = useMemo(() => {
    const colDef: Omit<ProjectsGridColDef, "colId"> = {
      resizable: true,
      sortable: false,
      sortingOrder: ["asc", "desc"],
      suppressHeaderMenuButton: true,
      wrapHeaderText: true,
      csvValueGetter: null,
      // sorting controlled by parent this reduces redundant sorting by having the grid keep all items in same order from parent
    };
    if (suppressSortComparator) {
      colDef.comparator = () => 0;
    }
    return colDef;
  }, [suppressSortComparator]);

  const [pinnedTopRowData, rowData] = useMemo(() => {
    if (suppressPinnedRows) {
      return [undefined, projects];
    }

    return partition(projects, (project) => project.pinned);
  }, [projects, suppressPinnedRows]);

  const getRowId = useCallback(
    ({ data }: { data: ProjectsGridRowDatum }) => data.id,
    [],
  );

  const onGridReady = useCallback(
    (e: GridReadyEvent<ProjectsGridRowDatum>) => {
      const { api } = e;

      /**
       * Restore sort state from route params
       */
      const field = getParam("SORT_FIELD");
      const sortOrder = getParam("SORT_ORDER");
      if (
        field !== null &&
        projectsGridSortableColumns.has(field) &&
        sortOrder !== null &&
        new Set(["asc", "desc"] as const).has(sortOrder)
      ) {
        void api.applyColumnState({
          state: [{ colId: field, sort: sortOrder }],
        });
      } else {
        // default sort create desc
        void api.applyColumnState({
          state: [
            { colId: "dateCreated", sort: "desc" as const } satisfies {
              colId: ProjectsGridColDef["colId"];
              sort: ColumnStateParams["sort"];
            },
          ],
        });
      }

      if (_onGridReady !== undefined) {
        _onGridReady(e);
      }
    },
    [_onGridReady, getParam],
  );

  const onSortChanged = useCallback(
    (e: SortChangedEvent) => {
      // currently only single sort is enabled
      const sortedColumn = e.api
        .getColumnState()
        .filter(({ sort }) => sort !== null)[0] as ColumnState | undefined;
      const sortOrder = sortedColumn?.sort;
      if (sortedColumn !== undefined && typeof sortOrder === "string") {
        setParam("SORT_FIELD", sortedColumn.colId);
        setParam("SORT_ORDER", sortOrder);
      } else {
        deleteParam("SORT_FIELD");
        deleteParam("SORT_ORDER");
      }
    },
    [deleteParam, setParam],
  );

  return (
    <ModalProvider>
      <AgGridReact<ProjectsGridRowDatum>
        css={css`
          .ag-floating-top {
            border-bottom-width: 2px;
          }
        `}
        className="ag-theme-balham-cell-borders"
        getRowId={getRowId}
        headerHeight={45}
        defaultColDef={defaultColDef}
        columnDefs={columnDefs}
        rowData={rowData}
        rowHeight={35}
        onGridReady={onGridReady}
        // The column definitions used in this grid use custom attributes (e.g.
        // csvValueGetter). By default AG grid throws warnings when you do so.
        suppressPropertyNamesCheck
        suppressMultiSort
        pinnedTopRowData={pinnedTopRowData}
        onSortChanged={onSortChanged}
      />
    </ModalProvider>
  );
};
