import { FileBadge } from "components/FileBadge/FileBadge";
import { useCellValueRendererFileQuery } from "graphql/_Types";
import {
  CellError,
  DetailedCellError,
  ErrorType,
  IdeasFile,
} from "@inscopix/ideas-hyperformula";
import { useDataTableContext } from "../store/DataTableProvider";
import { gql } from "@apollo/client";
import { isNullish } from "@apollo/client/cache/inmemory/helpers";
import { CellValueRendererLoading } from "./CellValueRendererLoading";
import { CellValueRendererError } from "./CellValueRendererError";
import { ReactNode, useState } from "react";
import { ModalDeleteDrsFileGeneric } from "components/ModalDeleteDrsFile/ModalDeleteDrsFileGeneric";
import { ModalArchiveDrsFile } from "components/ModalArchiveDrsFile/ModalArchiveDrsFile";
import { ModalUnarchiveDrsFiles } from "components/ModalUnarchiveDrsFile/ModalUnarchiveDrsFile";
import { ModalRenameDrsFile } from "components/ModalRenameDrsFile/ModalRenameDrsFile";
import { ModalIdentifyFile } from "components/ModalIdentifyFile/ModalIdentifyFile";
import { useFileUploadContext } from "stores/upload/FileUploadProvider";
import { FileStatus } from "types/constants";

gql`
  query CellValueRendererFile($fileId: UUID!) {
    file: fileById(id: $fileId) {
      id
      name
      originalName
      status
      fileFormat
      fileType
      isSeries
      source
      processingStatus
      seriesParentId
      projectId
    }
  }
`;

interface CellValueRendererFileProps {
  file: IdeasFile;
  isCompact?: boolean;
}

/** Component that renders an `IdeasFile` cell value` */
export const CellValueRendererFile = (props: CellValueRendererFileProps) => {
  const fileId = props.file.attrs.id;
  const setSelectedFileId = useDataTableContext((s) => s.setSelectedFileId);
  const deleteFile = useDataTableContext((s) => s.deleteFile);
  const isSelected = useDataTableContext((s) => s.selectedFileId === fileId);
  const { data, loading } = useCellValueRendererFileQuery({
    variables: { fileId },
  });
  const [modal, setModal] = useState<ReactNode>(null);
  const isUploading = useFileUploadContext(
    (s) => s.filesById[fileId] !== undefined,
  );

  if (loading) {
    return <CellValueRendererLoading />;
  }

  const file = data?.file;
  if (isNullish(file)) {
    const msg = "File not found";
    const error = new DetailedCellError(
      new CellError(ErrorType.ERROR, msg),
      msg,
    );
    return <CellValueRendererError error={error} />;
  }

  return (
    <>
      {modal}
      <FileBadge
        drsFile={{
          id: file.id,
          name: file.name,
          // FIXME: Overwrite cell status for failed uploads. Ideally the
          // backend would do this cleanup when an upload is interrupted.
          status:
            (file.status === FileStatus.CREATED ||
              file.status === FileStatus.UPLOADING) &&
            !isUploading
              ? FileStatus.UPLOAD_CANCELLED
              : file.status,
          fileType: file.fileType,
          isSeries: file.isSeries,
          source: file.source,
          processingStatus: file.processingStatus,
          seriesParentId: file.seriesParentId,
        }}
        isSelected={isSelected}
        compact={props.isCompact}
        onClick={() => setSelectedFileId(file.id)}
        onDelete={() => {
          setModal(
            <ModalDeleteDrsFileGeneric
              file={file}
              onClose={() => setModal(null)}
            />,
          );
        }}
        onArchive={() => {
          setModal(
            <ModalArchiveDrsFile
              drsFiles={[file]}
              onClose={() => setModal(null)}
            />,
          );
        }}
        onUnarchive={() => {
          setModal(
            <ModalUnarchiveDrsFiles
              drsFiles={[file]}
              onClose={() => setModal(null)}
            />,
          );
        }}
        onRename={() => {
          setModal(
            <ModalRenameDrsFile
              drsFile={file}
              onClose={() => setModal(null)}
            />,
          );
        }}
        onIdentify={() => {
          setModal(
            <ModalIdentifyFile file={file} onClose={() => setModal(null)} />,
          );
        }}
        onCancelUpload={() => void deleteFile(file.id, "delete")}
      />
    </>
  );
};
