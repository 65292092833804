import { EuiHorizontalRule, EuiSpacer } from "@inscopix/ideas-eui";
import {
  ProjectCard,
  ProjectCardProps,
} from "components/ProjectCard/ProjectCard";
import { memo } from "react";
import { VList } from "virtua";
export interface ProjectCardsMemoProps {
  projects: ProjectCardProps["project"][];
}

export const ProjectCardsMemo = memo(function ProjectCardsMemo({
  projects,
}: ProjectCardsMemoProps) {
  return (
    <VList overscan={2} itemSize={200}>
      {projects.map((project, idx, arr) => {
        const isLastProject = idx === arr.length - 1;
        const isNextProjectPinned = !isLastProject && arr[idx + 1].pinned;
        return (
          <>
            {idx === 0 && (
              <>
                <EuiSpacer size="l" />
              </>
            )}

            {project.pinned && !isLastProject && !isNextProjectPinned ? (
              <>
                <ProjectCard key={project.id} project={project} />
                <EuiHorizontalRule />
              </>
            ) : (
              <>
                <ProjectCard key={project.id} project={project} />
                {!isLastProject && <EuiSpacer size="m" />}
              </>
            )}
          </>
        );
      })}
    </VList>
  );
});
