import { memo } from "react";

import {
  ButtonViewTaskLogs,
  ButtonViewTaskLogsProps,
} from "components/ButtonViewTaskLogs/ButtonViewTaskLogs";

/**
 * Cell renderer a button to view the logs of a completed task
 */
const CellRendererTaskLogs = (props: ButtonViewTaskLogsProps) => {
  return <ButtonViewTaskLogs {...props} />;
};

export const CellRendererTaskLogsMemo = memo(CellRendererTaskLogs);
