/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { memo } from "react";
import { useDataTableContext } from "../store/DataTableProvider";
import { TaskStatusIcon } from "components/TaskStatusIcon/TaskStatusIcon";
import { QuickActionCopyRow } from "../layout/DataTableQuickActionBar/QuickActionCopyRow";
import { QuickActionRunRow } from "../layout/DataTableQuickActionBar/QuickActionRunRow";
import { QuickActionDeleteRow } from "../layout/DataTableQuickActionBar/QuickActionDeleteRow";
import { QuickActionCancelTask } from "../layout/DataTableQuickActionBar/QuickActionCancelTask";
import { isTerminalTaskStatus } from "utils/isTerminalTaskStatus";

const styles = {
  root: css`
    align-items: center;
    display: flex;
    height: 100%;
    justify-content: center;
    width: 100%;
    padding: 0px 11px;
    background-color: inherit;
    gap: 2px;
  `,
};

interface CellRendererQuickActionsProps {
  rowId: string;
}

const CellRendererQuickActions = ({ rowId }: CellRendererQuickActionsProps) => {
  const selectedTableId = useDataTableContext((s) => s.selectedTableId);
  const row = useDataTableContext((s) => {
    const table = s.tables.find((table) => table.id === s.selectedTableId);
    return table?.rows.find((row) => row.id === rowId);
  });
  const columns = useDataTableContext((s) => {
    const table = s.tables.find((table) => table.id === s.selectedTableId);
    return table?.columns;
  });

  if (
    selectedTableId === undefined ||
    columns === undefined ||
    row === undefined
  ) {
    return null;
  }

  return (
    <span css={styles.root}>
      {row.task === null ? (
        <QuickActionRunRow tableId={selectedTableId} rowId={row.id} />
      ) : (
        <TaskStatusIcon
          taskId={row.task.id}
          taskStatus={row.task.status}
          taskCreated={row.task.created}
        />
      )}

      {row.task !== null && !isTerminalTaskStatus(row.task.status) ? (
        <QuickActionCancelTask tableId={selectedTableId} rowId={row.id} />
      ) : (
        <QuickActionDeleteRow tableId={selectedTableId} rowId={row.id} />
      )}

      <QuickActionCopyRow tableId={selectedTableId} rowId={row.id} />
    </span>
  );
};

export const CellRendererQuickActionsMemo = memo(CellRendererQuickActions);
