import { ButtonIconPermissioned } from "components/ButtonIconPermissioned/ButtonIconPermissioned";
import { ModalDeleteDrsFileProps } from "components/ModalDeleteDrsFile/ModalDeleteDrsFile";
import { useRouteMapContext } from "providers/RouteMapProvider/RouteMapProvider";
import { getDrsFileModifyPermissionByDrsFileAndAction } from "types/DrsFileModifyPermissions";

export interface ButtonDeleteDrsFileProps {
  drsFile: ModalDeleteDrsFileProps["drsFiles"][number];
  onClick: (file: ButtonDeleteDrsFileProps["drsFile"]) => void;
}

export const ButtonDeleteDrsFile = ({
  drsFile,
  onClick,
}: ButtonDeleteDrsFileProps) => {
  const isViewingHistory = useRouteMapContext(
    (s) =>
      s.isRouteMatch(s.routeMap.DATASET_HISTORY.parameterizedPath) ||
      s.isRouteMatch(s.routeMap.DATASET_VERSION.parameterizedPath),
  );
  return (
    <ButtonIconPermissioned
      aria-label="Delete file"
      defaultTooltip={
        isViewingHistory
          ? "Delete disabled while viewing history"
          : "Delete file"
      }
      color="danger"
      iconType="trash"
      disabled={
        isViewingHistory ||
        !getDrsFileModifyPermissionByDrsFileAndAction(drsFile, "DELETE")
          .isPermitted ||
        !getDrsFileModifyPermissionByDrsFileAndAction(drsFile, "DATA_DELETE")
          .isPermitted
      }
      onClick={() => onClick(drsFile)}
      requiredPermission="edit"
    />
  );
};
