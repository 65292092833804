import {
  EuiButtonIcon,
  EuiContextMenuItem,
  EuiContextMenuItemIcon,
  EuiContextMenuPanel,
  EuiPopover,
} from "@inscopix/ideas-eui";
import { ModalEditOrganization } from "components/ModalEditOrganization/ModalEditOrganization";
import { Tenant } from "graphql/_Types";
import { useRouteMapContext } from "providers/RouteMapProvider/RouteMapProvider";
import { useUserContext } from "providers/UserProvider/UserProvider";
import { useState } from "react";

type Actions = "people" | "usage" | "edit_name";

export interface OrganizationActionsProps {
  orgKey: Tenant["key"];
}
export const OrganizationActions = ({ orgKey }: OrganizationActionsProps) => {
  const currentUserTenants = useUserContext((s) => s.tenants);
  const routeMap = useRouteMapContext((s) => s.routeMap);

  const isOrgAdmin =
    currentUserTenants.find((tenant) => tenant.key === orgKey)?.role.key ===
    "admin";

  const [popoverOpen, setPopoverOpen] = useState(false);
  const [showModalEditOrgName, setShowModalEditOrgName] = useState(false);

  const togglePopover = () => {
    setPopoverOpen((prev) => !prev);
  };

  const actions: {
    key: Actions;
    adminAction?: boolean;
    text: string;
    icon: EuiContextMenuItemIcon;
    action: () => void;
  }[] = [
    {
      key: "people",
      text: "People",
      icon: "user",
      action: () =>
        routeMap.ORGANIZATION_PEOPLE.dynamicPath({
          tenantKey: orgKey,
        }).navigateTo(),
    },
    {
      key: "usage",
      text: "Usage",
      icon: "visBarHorizontal",
      action: () =>
        routeMap.ORGANIZATION_USAGE.dynamicPath({
          tenantKey: orgKey,
        }).navigateTo(),
    },
    {
      key: "edit_name",
      adminAction: true,
      text: "Edit Organization",
      icon: "pencil",
      action: () => setShowModalEditOrgName(true),
    },
  ];

  return (
    <>
      <EuiPopover
        button={
          <EuiButtonIcon
            aria-label="Actions"
            iconType="gear"
            size="s"
            color="text"
            onClick={togglePopover}
          />
        }
        isOpen={popoverOpen}
        closePopover={() => setPopoverOpen(false)}
        panelPaddingSize="none"
        anchorPosition="rightDown"
      >
        <EuiContextMenuPanel
          items={actions
            .filter(({ adminAction }) => {
              /**
               * Filter out org admin actions for non admins
               */
              if (adminAction && !isOrgAdmin) {
                return false;
              }
              return true;
            })
            .map(({ key, text, action, icon }) => (
              <EuiContextMenuItem
                key={key}
                icon={icon}
                onClick={(e) => {
                  e.stopPropagation();
                  setPopoverOpen(false);
                  action();
                }}
              >
                {text}
              </EuiContextMenuItem>
            ))}
        />
      </EuiPopover>
      {showModalEditOrgName && (
        <ModalEditOrganization onClose={() => setShowModalEditOrgName(false)} />
      )}
    </>
  );
};
