/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { EuiButtonIcon } from "@inscopix/ideas-eui";
import { useMouseOver } from "hooks/useMouseOver";
import { selectSystemFiles } from "utils/selectSystemFiles";
import { useDataTableContext } from "../store/DataTableProvider";
import { useState } from "react";
import { addUtilityToastSuccess } from "utils/addUtilityToastSuccess";
import { addUtilityToastFailure } from "utils/addUtilityToastFailure";

const styles = {
  root: css`
    margin: auto;
  `,
};

interface CellRendererFileEmptyProps {
  eParentOfValue: HTMLElement;
  tableId: string;
  columnId: string;
  rowId: string;
}

/** Cell renderer that renders empty cells in file columns */
export const CellRendererFileEmpty = ({
  eParentOfValue,
  tableId,
  columnId,
  rowId,
}: CellRendererFileEmptyProps) => {
  const uploadFiles = useDataTableContext((s) => s.uploadFiles);
  const [isLoading, setIsLoading] = useState(false);
  const { isMouseOver } = useMouseOver(eParentOfValue);

  const handleClick = async () => {
    try {
      setIsLoading(true);
      const blobs = (await selectSystemFiles(true)) ?? [];
      if (blobs.length > 0) {
        const address = { tableId, columnId, rowId };
        await uploadFiles({ blobs, address });
        setIsLoading(false);
        addUtilityToastSuccess("File upload initiated");
      }
    } catch (error) {
      setIsLoading(false);
      addUtilityToastFailure("Failed to initiate file upload");
    } finally {
      setIsLoading(false);
    }
  };

  // Only display the upload button on hover
  if (!isMouseOver) {
    return null;
  }

  return (
    <EuiButtonIcon
      aria-label="Upload files"
      css={styles.root}
      iconType="exportAction"
      isLoading={isLoading}
      onClick={() => void handleClick()}
    />
  );
};
