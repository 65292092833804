import { TenantProvider } from "providers/TenantProvider/TenantProvider";
import { Route, Switch, useRouteMatch } from "react-router-dom";
import { PageOrganization } from "pages/organization/PageOrganization";
import { PageProject } from "pages/project/PageProject";
import { ToolSpecProvider } from "providers/ToolSpecProvider/ToolSpecProvider";
import { Page404 } from "pages/404/Page404";
import { useRouteMapContext } from "providers/RouteMapProvider/RouteMapProvider";
import { OrganizationUsage } from "pages/organization/OrganizationUsage";
import { OrganizationPeople } from "pages/organization/OrganizationPeople";

export const OrganizationRoutes = () => {
  const routeMap = useRouteMapContext((s) => s.routeMap);
  const { path } = useRouteMatch();
  return (
    <TenantProvider>
      <ToolSpecProvider>
        <Switch>
          <Route exact path={path}>
            <PageOrganization />
          </Route>
          <Route path={routeMap["PROJECT"].parameterizedPath}>
            <PageProject />
          </Route>
          <Route path={routeMap["ORGANIZATION_USAGE"].parameterizedPath}>
            <OrganizationUsage />
          </Route>
          <Route path={routeMap["ORGANIZATION_PEOPLE"].parameterizedPath}>
            <OrganizationPeople />
          </Route>
          <Route path="*">
            <Page404 />
          </Route>
        </Switch>
      </ToolSpecProvider>
    </TenantProvider>
  );
};
