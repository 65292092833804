import React from "react";
import ReactDOM from "react-dom";
import App from "./pages/App/App";
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";
import { Amplify } from "aws-amplify";
import "@inscopix/ideas-eui/dist/eui_theme_light.css";
import "react-tooltip/dist/react-tooltip.css";
import { getEnvVar, AWS_CONFIG } from "./ideas.env";
import { LicenseManager } from "ag-grid-enterprise";
import { SentryErrorBoundary } from "./components/SentryErrorBoundary/SentryErrorBoundary";
import GlobalProviders from "./providers/GlobalProviders";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import { CompatRouter } from "react-router-dom-v5-compat";
import { Page404 } from "pages/404/Page404";
import { Helmet, HelmetProvider } from "react-helmet-async";
import { RouteMapProvider } from "providers/RouteMapProvider/RouteMapProvider";

Amplify.configure(AWS_CONFIG);

LicenseManager.setLicenseKey(getEnvVar("AG_GRID_LICENSE"));

// eslint-disable-next-line no-console
const nativeConsoleError = console.error;
// eslint-disable-next-line no-console
console.error = (message, ...optionalParams) => {
  // Suppressing the aria-description warning
  // Should be removed after upgrading to react 18. https://github.com/facebook/react/issues/21035
  if (
    typeof message === "string" &&
    message.startsWith("Warning: Invalid aria prop")
  ) {
    return;
  }

  // Suppressing unnecessary warning from Apollo when using the cache fragments API
  if (
    typeof message === "string" &&
    /Missing field '.*' while writing result/g.test(message)
  ) {
    return;
  }

  // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
  nativeConsoleError(message, ...optionalParams);
};

Sentry.init({
  dsn: window.location.hostname === "localhost" ? "" : getEnvVar("SENTRY_DSN"),
  environment:
    window.location.hostname === "localhost" ? "local" : getEnvVar("ENV"),
  //@TODO review this value for production use case
  tracesSampleRate: 1.0,
  // This sets the sample rate to be 10%.
  replaysSessionSampleRate: 0.1,
  // If the entire session is not sampled, use the below sample rate to sample
  // sessions when an error occurs.
  replaysOnErrorSampleRate: 1.0,
  integrations: [new BrowserTracing(), new Sentry.Replay()],
  ignoreErrors: [
    // benign error, https://stackoverflow.com/questions/49384120/resizeobserver-loop-limit-exceeded#comment86691361_49384120
    "ResizeObserver loop limit exceeded",
    "ResizeObserver loop completed with undelivered notifications",
  ],
});

ReactDOM.render(
  <React.StrictMode>
    <HelmetProvider>
      <Helmet titleTemplate="%s - IDEAS" />
      <BrowserRouter>
        <CompatRouter>
          <RouteMapProvider>
            <Switch>
              <Route path={["/:tenantKey", "/"]}>
                <GlobalProviders>
                  <SentryErrorBoundary tag={"app"}>
                    <App />
                  </SentryErrorBoundary>
                </GlobalProviders>
              </Route>

              <Route path="*">
                <Page404 />
              </Route>
            </Switch>
          </RouteMapProvider>
        </CompatRouter>
      </BrowserRouter>
    </HelmetProvider>
  </React.StrictMode>,
  document.getElementById("root"),
);
