import { EuiLink } from "@inscopix/ideas-eui";
import assert from "assert";
import { NotificationsFeedBodyQuery } from "graphql/_Types";
import { useRouteMapContext } from "providers/RouteMapProvider/RouteMapProvider";
import { isNonNull } from "utils/isNonNull";
import { NotificationAlert } from "./NotificationAlert";
import { TRegion } from "providers/RegionsProvider";
import { isUndefined } from "lodash";
import { RegionBadge } from "components/RegionBadge/RegionBadge";

interface NotificationAlertProjectClonedFailedProps {
  data: NonNullable<
    NotificationsFeedBodyQuery["notifications"]
  >["nodes"][number] & { region?: TRegion };
  onNotificationRead: () => void;
}

/** Component that renders a notification that a project has finished cloning */
export const NotificationAlertProjectClonedFailed = ({
  data,
  onNotificationRead,
}: NotificationAlertProjectClonedFailedProps) => {
  const routeMap = useRouteMapContext((s) => s.routeMap);

  const { dateCreated, hasSeen } = data;
  const attrs = data.projectClonedNotification;

  assert(isNonNull(attrs), "Expected attrs to be non null");

  const projectName = attrs.projectName;

  const srcProjectPath =
    attrs.srcProject !== null && attrs.srcProject.tenant !== null
      ? routeMap["PROJECT"].dynamicPath({
          projectKey: attrs.srcProject.key,
          tenantKey: attrs.srcProject.tenant.key,
        }).path
      : undefined;

  const action = (() => {
    const handleClick = () => {
      onNotificationRead();
    };

    if (srcProjectPath !== undefined) {
      return isUndefined(data.region) ? (
        <EuiLink to={srcProjectPath} onClick={() => void handleClick()}>
          Go to source project
        </EuiLink>
      ) : (
        <EuiLink
          href={`${data.region.url}${srcProjectPath}`}
          target="_blank"
          onClick={() => void handleClick()}
        >
          Go to source project <RegionBadge regionKey={data.region.key} />
        </EuiLink>
      );
    }
  })();

  return (
    <NotificationAlert
      dateCreated={dateCreated}
      hasSeen={hasSeen}
      title="Failed to copy project"
      onNotificationRead={onNotificationRead}
      text={
        <span>
          <strong>Failed to copy {projectName}</strong>.
        </span>
      }
      action={action}
    />
  );
};
