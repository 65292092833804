import { useQuery } from "@tanstack/react-query";
import { useCallback } from "react";

import { queryKeys } from "django/queryKeys";
import { getAdminProjectsDjango } from "django/libraryProjectDjango";

export const useAdminProjects = (
  filterParams?: Parameters<typeof getAdminProjectsDjango>[0],
) => {
  const queryFn = useCallback(async () => {
    const data = await getAdminProjectsDjango(filterParams);

    return data.map((project) => ({
      ...project,
      user: project.user,
      dateCreated: project.date_created,
      dateLastActive: project.date_last_active,
      activeStorageSize: project.active_storage_size,
      archivedStorageSize: project.archived_storage_size,
      usedCredits: project.used_credits,
      role: project.permissions.role_display,
      userId: project.user.id,
      defaultUserAccessLevel: project.default_user_access_level,
      userPermissions: project.permissions,
      tenantKey: project.tenant.key,
      tenantName: project.tenant.name,
    }));
  }, [filterParams]);

  const query = useQuery({
    queryFn: queryFn,
    queryKey: queryKeys.getAdminProjects(filterParams),
    refetchOnMount: "always",
  });

  return query;
};
