import { TaskStatus } from "types/constants";

const TERMINAL_TASK_STATUSES = new Set([
  TaskStatus.COMPLETE,
  TaskStatus.FAILED,
  TaskStatus.ERROR,
  TaskStatus.CANCELED,
]);

/**
 * Determines whether a task status represents a task that has finished
 * executing.
 * @param status
 * @returns `true` if the task has finished executing and `false` otherwise.
 */
export const isTerminalTaskStatus = (status: TaskStatus) => {
  return TERMINAL_TASK_STATUSES.has(status);
};
