/** @jsxImportSource @emotion/react */
import { useState } from "react";
import {
  EuiButtonEmpty,
  EuiContextMenuItem,
  EuiContextMenuPanel,
  EuiPopover,
  useEuiTheme,
} from "@inscopix/ideas-eui";
import { useProjectDataContext } from "pages/project/ProjectDataProvider";
import { useRouteMapContext } from "providers/RouteMapProvider/RouteMapProvider";
import { css } from "@emotion/react";
import { useTenantContext } from "providers/TenantProvider/TenantProvider";

export const DatasetBreadcrumbMenu = () => {
  const routeMap = useRouteMapContext((s) => s.routeMap);
  const { project, datasets } = useProjectDataContext();
  const { euiTheme } = useEuiTheme();
  const [isPopoverOpen, setPopover] = useState(false);
  const currentTenant = useTenantContext((s) => s.currentTenant);

  if (datasets.length === 0) return null;
  const styles = {
    datasetMenu: css`
      color: ${euiTheme.colors.subduedText};
    `,
  };

  const datasetMenuButton =
    datasets.length === 1 ? (
      // if only one dataset exists, show a button linking directly to it
      <EuiButtonEmpty
        color={"text"}
        css={styles.datasetMenu}
        onClick={() => {
          routeMap["DATASET"]
            .dynamicPath({
              tenantKey: currentTenant.key,
              projectKey: project.key,
              datasetId: datasets[0].id,
            })
            .navigateTo();
        }}
        flush={"both"}
      >
        {datasets[0].name}
      </EuiButtonEmpty>
    ) : (
      // otherwise, show the popover
      <EuiButtonEmpty
        color={"text"}
        css={styles.datasetMenu}
        iconType="arrowDown"
        iconSide="right"
        onClick={() => {
          setPopover(!isPopoverOpen);
        }}
        flush={"both"}
      >
        Go to dataset
      </EuiButtonEmpty>
    );
  const items = datasets.map((dataset) => (
    <EuiContextMenuItem
      key={dataset.id}
      onClick={() => {
        routeMap["DATASET"]
          .dynamicPath({
            tenantKey: currentTenant.key,
            projectKey: project.key,
            datasetId: dataset.id,
          })
          .navigateTo();
      }}
    >
      {dataset.name}
    </EuiContextMenuItem>
  ));
  return (
    <EuiPopover
      id={"datasetmenu"}
      button={datasetMenuButton}
      isOpen={isPopoverOpen}
      closePopover={() => {
        setPopover(false);
      }}
      panelPaddingSize="none"
      anchorPosition="downLeft"
    >
      <EuiContextMenuPanel size="s" items={items} />
    </EuiPopover>
  );
};
