import { useDatasetDataContext } from "pages/project/dataset/DatasetDataProvider";
import { useCallback } from "react";
import { useDeleteDatasetRecordingsTableColumnDjango } from "hooks/useDeleteDatasetRecordingsTableColumnDjango";
import { evictCacheFragment } from "utils/cache-fragments";
import { useProjectPermissionStore } from "pages/project/ProjectPermissionProvider";

type DeleteColumnParams = {
  columnId: string;
};

export const useActionDeleteColumn = () => {
  const { datasetMode } = useDatasetDataContext();
  const { deleteDatasetRecordingsTableColumnDjango } =
    useDeleteDatasetRecordingsTableColumnDjango();
  const hasEditPermission = useProjectPermissionStore(
    (s) => s.permissions["edit"],
  );
  const getActionDef = useCallback(
    (params: DeleteColumnParams) => {
      const dateDeleted = new Date().toISOString();

      return {
        onEnqueue: () => undefined,

        onDequeue: async () => {
          await deleteDatasetRecordingsTableColumnDjango(params.columnId, {
            date_assignment_ended: dateDeleted,
          });

          evictCacheFragment({
            __typename: "DatasetRecordingsTableColumn",
            id: params.columnId,
          });
        },
      };
    },
    [deleteDatasetRecordingsTableColumnDjango],
  );

  const isDisabled = datasetMode.type !== "current" || !hasEditPermission;

  return {
    getActionDef,
    isDisabled,
  };
};
