/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { useState } from "react";
import { CellValue } from "@inscopix/ideas-hyperformula";

const styles = {
  root: css`
    border: none;
    background-color: transparent;
    width: 100%;
    &:focus {
      outline: none;
    }
  `,
};

interface CellEditorFloatProps {
  value: CellValue;
  onChangeFormula: (newFormula: string) => void;
  stopEditing: (cancel?: boolean) => void;
}

/** Cell editor for cells in float columns */
export const CellEditorFloat = ({
  value,
  onChangeFormula,
  stopEditing,
}: CellEditorFloatProps) => {
  const [newValue, setNewValue] = useState(() => {
    if (typeof value === "number") {
      return value;
    } else if (typeof value === "string") {
      const float = parseFloat(value);
      return isNaN(float) ? undefined : float;
    } else {
      return undefined;
    }
  });

  return (
    <input
      autoFocus
      type="number"
      css={styles.root}
      value={newValue}
      onBlur={() => stopEditing()}
      // onChange is only fired when the value is a valid number. onInput is
      // fired every time the value changes. onInput allows users to clear the
      // input's value using the backspace key.
      onInput={(e) => {
        // Invalid inputs (e.g. "a") are coerced to empty strings in input
        // events. If this happens, we do not want to update state.
        if (
          e.nativeEvent instanceof InputEvent &&
          e.nativeEvent.inputType === "insertText" &&
          e.currentTarget.value === ""
        ) {
          return;
        }

        onChangeFormula(e.currentTarget.value);
        setNewValue(parseFloat(e.currentTarget.value));
      }}
    />
  );
};
