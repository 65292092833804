import { EuiButtonIcon, htmlIdGenerator } from "@inscopix/ideas-eui";
import { addToast } from "components/GlobalToastList/GlobalToastList";
import { Tooltip } from "components/Tooltip/Tooltip";
import { getValidationErrors } from "pages/gdt/cell-renderers/CellRendererBase.helpers";
import { useDataTableContext } from "pages/gdt/store/DataTableProvider";
import { ReactNode, useState } from "react";
import { addUtilityToastFailure } from "utils/addUtilityToastFailure";
import { addUtilityToastSuccess } from "utils/addUtilityToastSuccess";

interface QuickActionRunRowBaseProps {
  tableId: string;
  rowIds: string[];
  tooltipContent: ReactNode;
  isDisabled: boolean;
}

const QuickActionRunRowBase = ({
  tableId,
  rowIds,
  tooltipContent,
  isDisabled,
}: QuickActionRunRowBaseProps) => {
  const executeAnalysisTableRows = useDataTableContext(
    (s) => s.executeAnalysisTableRows,
  );
  const [isLoading, setIsLoading] = useState(false);

  const handleClick = async () => {
    setIsLoading(true);
    const { data, error } = await executeAnalysisTableRows({
      tableId,
      rowIds,
    });
    setIsLoading(false);

    if (error === undefined) {
      if (data.length === rowIds.length) {
        addUtilityToastSuccess("Row(s) running");
      } else {
        addToast({
          id: htmlIdGenerator()(),
          title: "Concurrent task limit reached",
          color: "warning",
          text: <p>Some rows were not run</p>,
        });
      }
    } else {
      addUtilityToastFailure("Failed to run row(s)");
    }
  };

  return (
    <Tooltip content={tooltipContent}>
      <EuiButtonIcon
        aria-label={tooltipContent?.toString()}
        size="xs"
        iconType="playFilled"
        onClick={() => void handleClick()}
        disabled={isDisabled}
        isLoading={isLoading}
      />
    </Tooltip>
  );
};

interface QuickActionRunRowProps {
  tableId: string;
  rowId: string;
}

export const QuickActionRunRow = ({
  tableId,
  rowId,
}: QuickActionRunRowProps) => {
  const invalidColumns = useDataTableContext((s) => {
    const table = s.tables.find(({ id }) => id === tableId);
    const row = table?.rows.find(({ id }) => id === rowId);

    if (table === undefined || row === undefined) {
      return [];
    }

    const invalidColumns: string[] = [];

    row.cells.forEach((cell, colIdx) => {
      const column = table.columns[colIdx];
      const errors = getValidationErrors({
        columnDefinition: column.definition,
        isColumnRequired: column.required,
        value: cell.value,
      });

      if (errors.length > 0) {
        invalidColumns.push(column.name);
      }
    });

    return invalidColumns;
  });

  const tooltipContent =
    invalidColumns.length === 0 ? (
      <span>Run row</span>
    ) : (
      <div>
        <b>Invalid entries</b>
        <ul>
          {invalidColumns.map((name) => (
            <li key={name}>• {name}</li>
          ))}
        </ul>
      </div>
    );

  return (
    <QuickActionRunRowBase
      tooltipContent={tooltipContent}
      tableId={tableId}
      rowIds={[rowId]}
      isDisabled={invalidColumns.length > 0}
    />
  );
};

export const QuickActionRunSelectedRows = () => {
  const selectedTableId = useDataTableContext((s) => s.selectedTableId);
  const validRowIds = useDataTableContext((s) => {
    const table = s.tables.find(({ id }) => id === s.selectedTableId);
    const rows = table?.rows.filter(({ id }) => s.selectedRowIds.includes(id));

    if (table === undefined) {
      return [];
    }

    return (rows ?? [])
      .filter((row) => {
        return (
          row.task === null &&
          row.cells.every((cell, colIdx) => {
            const column = table.columns[colIdx];
            const errors = getValidationErrors({
              columnDefinition: column.definition,
              isColumnRequired: column.required,
              value: cell.value,
            });
            return errors.length === 0;
          })
        );
      })
      .map((row) => row.id);
  });

  if (selectedTableId === undefined) {
    return null;
  }

  return (
    <QuickActionRunRowBase
      tooltipContent={`Run selected rows: ${validRowIds.length}`}
      tableId={selectedTableId}
      rowIds={validRowIds}
      isDisabled={validRowIds.length === 0}
    />
  );
};
