import {
  AnalysisTableGroup,
  useAnalysisTableTaskCountsQuery,
} from "graphql/_Types";
import { groupBy } from "lodash";
import { TaskStatus } from "types/constants";
import { isNonNull } from "utils/isNonNull";
import { TaskCountBadge } from "./TaskCountBadge";
import { EuiFlexGroup, EuiFlexItem } from "@inscopix/ideas-eui";

interface AnalysisTableGroupTaskCountsProps {
  analysisTableGroup: Pick<AnalysisTableGroup, "id" | "projectId">;
}

export const AnalysisTableGroupTaskCounts = ({
  analysisTableGroup,
}: AnalysisTableGroupTaskCountsProps) => {
  const { data } = useAnalysisTableTaskCountsQuery({
    variables: { projectId: analysisTableGroup.projectId },
    fetchPolicy: "cache-and-network",
  });

  const numTasksByStatus = (() => {
    const tables = data?.projectById?.analysisTables.nodes.filter(
      (table) => table.groupId === analysisTableGroup.id,
    );
    const tableRows = tables?.flatMap((table) => table.rows.nodes);
    const tasks = tableRows?.map((row) => row.task).filter(isNonNull);

    if (tasks === undefined) {
      return {
        [TaskStatus.COMPLETE]: 0,
        [TaskStatus.RUNNING]: 0,
        [TaskStatus.QUEUED]: 0,
        [TaskStatus.FAILED]: 0,
      };
    }

    const tasksByStatus = groupBy(tasks, ({ status }) => status);
    const numTasksComplete = tasksByStatus[TaskStatus.COMPLETE]?.length ?? 0;
    const numTasksRunning = tasksByStatus[TaskStatus.RUNNING]?.length ?? 0;
    const numTasksQueued = tasksByStatus[TaskStatus.QUEUED]?.length ?? 0;
    const numTasksFailed = tasksByStatus[TaskStatus.FAILED]?.length ?? 0;
    const numTasksErrored = tasksByStatus[TaskStatus.ERROR]?.length ?? 0;

    return {
      [TaskStatus.COMPLETE]: numTasksComplete,
      [TaskStatus.RUNNING]: numTasksRunning,
      [TaskStatus.QUEUED]: numTasksQueued,
      [TaskStatus.FAILED]: numTasksFailed + numTasksErrored,
    };
  })();

  // Hide task statuses with no tasks
  const visibleStatuses = [
    TaskStatus.COMPLETE as const,
    TaskStatus.RUNNING as const,
    TaskStatus.QUEUED as const,
    TaskStatus.FAILED as const,
  ].filter((status) => numTasksByStatus[status] > 0);

  if (visibleStatuses.length === 0) {
    return null;
  }

  return (
    <EuiFlexGroup gutterSize="xs">
      {visibleStatuses.map((status) => (
        <EuiFlexItem key={status}>
          <TaskCountBadge
            isLoading={data === undefined}
            numTasksByStatus={numTasksByStatus}
            taskStatus={status}
          />
        </EuiFlexItem>
      ))}
    </EuiFlexGroup>
  );
};
