import {
  EuiText,
  EuiSpacer,
  EuiTitle,
  EuiSkeletonRectangle,
} from "@inscopix/ideas-eui";
import { CallOutError } from "components/CallOutError/CallOutError";
import { useFetchAndSetHasActiveContract } from "hooks/useFetchAndSetHasActiveContract";
import { useEffect, useState } from "react";
import {
  TenantResourceSummary,
  useFetchResourceSummary,
} from "./useFetchResourceSummary";
import { PanelTenantUsageQuotas } from "./PanelTenantUsageQuotas";
import { CallOutContract } from "components/CallOutContract/CallOutContract";

// Threshold for warning when quota is nearly exceeded
export const isThresholdNearlyExceeded = (percentage: number) =>
  percentage >= 90;

interface PanelTenantUsageContentProps {
  compact?: boolean;
}

/**
 * Displays a panel showing resource consumption for the entire tenant
 */
export const PanelTenantUsageContent = ({
  compact = false,
}: PanelTenantUsageContentProps) => {
  const { fetchAndSetContracts } = useFetchAndSetHasActiveContract();
  const { fetchResourceSummary } = useFetchResourceSummary();

  const [resourceSummary, setResourceSummary] =
    useState<TenantResourceSummary>();
  const [isLoading, setIsLoading] = useState(true);

  const panelMinWidth = compact ? 0 : 400;

  //
  // Set `hasActiveContract` and `hasUpcomingContract` state
  //
  useEffect(() => {
    void fetchAndSetContracts();
  }, [fetchAndSetContracts]);

  //
  // Get consumption data from Django API
  //
  useEffect(() => {
    const callFetchResourceSummary = async () => {
      const data = await fetchResourceSummary();
      setResourceSummary(data);
      setIsLoading(false);
    };

    void callFetchResourceSummary();
  }, [fetchResourceSummary]);

  if (isLoading) {
    return (
      <EuiSkeletonRectangle
        style={{ minWidth: panelMinWidth }}
        height={344} // 344 is the height of the panel when it's fully loaded, for visual consistency
        width="100%"
      />
    );
  }

  if (resourceSummary === undefined) {
    return <CallOutError />;
  }

  return (
    <>
      {compact ? (
        <>
          <EuiText size="m" color="subdued">
            <CallOutContract format="text" />
          </EuiText>
          <EuiSpacer size="m" />
        </>
      ) : (
        <>
          <EuiTitle>
            <EuiText>
              <h3>
                Subscription details
                <EuiSpacer size="xs" />
                <EuiText size="m" color="subdued">
                  <CallOutContract format="text" />
                </EuiText>
              </h3>
            </EuiText>
          </EuiTitle>
          <EuiSpacer size="xl" />
        </>
      )}

      <PanelTenantUsageQuotas
        resourceSummary={resourceSummary}
        compact={compact}
      />
    </>
  );
};
