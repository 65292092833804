/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { EuiLink, EuiLoadingChart, useEuiTheme } from "@inscopix/ideas-eui";
import { CallOutError } from "components/CallOutError/CallOutError";
import { UserAvatar } from "components/UserAvatar/UserAvatar";
import {
  ApplicationUser,
  Project,
  Tenant,
  useButtonProjectInfoQuery,
} from "graphql/_Types";
import { useRouteMapContext } from "providers/RouteMapProvider/RouteMapProvider";
import moment from "moment";
import { useMemo } from "react";
import { formatDate } from "utils/formatDate";
import { isNonNullish } from "utils/isNonNullish";
import { isNullish } from "utils/isNullish";

type TProject = Pick<Project, "dateCreated" | "dateLastActive"> & {
  cloneSource?: Pick<Project, "id" | "key" | "name"> & {
    tenantKey: Tenant["key"];
  };
  user: Pick<ApplicationUser, "id" | "firstName" | "lastName">;
};

export interface ButtonProjectInfoBodyProps {
  projectId: Project["id"];
}
export const ButtonProjectInfoBody = ({
  projectId,
}: ButtonProjectInfoBodyProps) => {
  const routeMap = useRouteMapContext((s) => s.routeMap);
  const { euiTheme } = useEuiTheme();
  const styles = {
    dl: css`
      display: grid;
      grid-template-columns: max-content auto;
    `,
    dt: css`
      grid-column-start: 1;
      margin-right: 0.5rem;
      font-weight: bold;
      padding: 5px 0px;
    `,
    dd: css`
      padding: 5px 0px;
    `,
    subContent: css`
      color: ${euiTheme.colors.subduedText};
    `,
  };
  const { data, loading } = useButtonProjectInfoQuery({
    variables: { id: projectId },
  });

  const projectData: TProject | undefined = useMemo(() => {
    const project = data?.project;
    const user = project?.user;
    if (isNullish(project) || isNullish(user)) {
      return;
    }

    const cloneSource:
      | (Pick<Project, "id" | "name" | "key"> & { tenantKey: string })
      | undefined =
      isNonNullish(project.cloneSource) &&
      isNonNullish(project.cloneSource.tenant)
        ? {
            ...project.cloneSource,
            tenantKey: project.cloneSource.tenant.key,
          }
        : undefined;

    return { ...project, user, cloneSource };
  }, [data?.project]);

  if (loading) {
    return <EuiLoadingChart />;
  }

  if (projectData === undefined) {
    return <CallOutError />;
  }

  return (
    <dl css={styles.dl}>
      <dt css={styles.dt}>Owner</dt>
      <dd css={styles.dd}>
        <UserAvatar userId={projectData.user.id} size="s" />
        <br />
        <span css={styles.subContent}>
          {projectData.user.firstName} {projectData.user.lastName}
        </span>
      </dd>

      <dt css={styles.dt}>Created</dt>
      <dd css={styles.dd}>
        {moment(projectData.dateCreated).fromNow()}
        <br />
        <span css={styles.subContent}>
          {formatDate(projectData.dateCreated)}
        </span>
      </dd>

      <dt css={styles.dt}>Updated</dt>
      <dd css={styles.dd}>
        {moment(projectData.dateLastActive).fromNow()}
        <br />
        <span css={styles.subContent}>
          {formatDate(projectData.dateLastActive)}
        </span>
      </dd>

      {projectData.cloneSource !== undefined && (
        <>
          <dt css={styles.dt}>Copied from</dt>
          <dd css={styles.dd}>
            <EuiLink
              to={
                routeMap.PROJECT.dynamicPath({
                  projectKey: projectData.cloneSource.key,
                  tenantKey: projectData.cloneSource.tenantKey,
                }).path
              }
            >
              {projectData.cloneSource.name}
            </EuiLink>
          </dd>
        </>
      )}
    </dl>
  );
};
