/** @jsxImportSource @emotion/react */

import {
  EuiEmptyPrompt,
  EuiFlexGroup,
  EuiFlexItem,
  EuiSearchBar,
  EuiSpacer,
  EuiText,
} from "@inscopix/ideas-eui";
import "./ProjectCards.scss";
import { isDefined } from "utils/isDefined";
import { useEuiSearchBar } from "hooks/useEuiSearchBar";
import { ProjectCardProps } from "components/ProjectCard/ProjectCard";
import { ButtonCreateProjectFilled } from "../ButtonCreateProject/ButtonCreateProjectFilled";
import { Project, Tenant } from "graphql/_Types";
import { useEffect, useMemo, useState } from "react";
import { isUndefined, orderBy as _orderBy } from "lodash";
import { useIdeasSearchParams } from "hooks/useIdeasSearchParams";
import { css } from "@emotion/react";
import { ToggleFilter, ToggleFilterProps } from "./ToggleFilter";
import { htmlIdGenerator } from "@inscopix/ideas-eui";
import { captureException } from "@sentry/react";
import {
  ProjectsGrid,
  ProjectsGridCellRendererParams,
  ProjectsGridProps,
  ProjectsGridSortableColumns,
} from "components/ProjectsTable/ProjectsGrid";
import { ProjectsCardActions } from "./ProjectsCardActions";
import { ProjectStatus } from "types/constants";
import { ProjectCardsMemo } from "./ProjectCardsMemo";

interface ProjectCardsProps {
  projects: (ProjectCardProps["project"] &
    Pick<Project, "dateCreated" | "dateLastActive">)[];
  tenantId?: Tenant["id"];
  view: "GRID" | "CARDS";
}

const sortStates = [
  {
    icon: "sortDown",
    value: "desc" as const,
  },
  {
    icon: "sortUp",
    value: "asc" as const,
  },
];

const sortMap: Record<
  ProjectsGridSortableColumns,
  { name: string; states: typeof sortStates }
> = {
  name: { name: "Name", states: [...sortStates].reverse() },
  dateCreated: {
    name: "Date Created",
    states: sortStates,
  },
  recentActivity: {
    name: "Recent Activity",
    states: sortStates,
  },
  activeStorage: {
    name: "Active Storage",
    states: sortStates,
  },
  archivedStorage: {
    name: "Archived Storage",
    states: sortStates,
  },
  totalStorage: {
    name: "Total Storage",
    states: sortStates,
  },
  computeCredits: {
    name: "Compute Credits",
    states: sortStates,
  },
  owner: {
    name: "Owner",
    states: [...sortStates].reverse(),
  },
  organization: {
    name: "Organization",
    states: [...sortStates].reverse(),
  },
};

const items = Object.entries(sortMap).map(([key, val]) => ({
  value: key as ProjectsGridSortableColumns,
  ...val,
}));

const onSort = <
  T extends Pick<
    ProjectCardsProps["projects"][number],
    | "name"
    | "dateCreated"
    | "usedCredits"
    | "activeStorageSize"
    | "archivedStorageSize"
    | "dateLastActive"
    | "pinned"
    | "user"
    | "tenantName"
  >,
  U extends (typeof items)[number],
>(
  projects: T[],
  selectedItemKey: U["value"],
  selectedDir: U["states"][number]["value"],
) => {
  /**
   * Sort pinned first
   */
  const orderBy: typeof _orderBy = (
    projects: T[],
    comparators: ((project: T) => T[keyof T])[],
    orders: ("asc" | "desc")[],
  ) => {
    return _orderBy(
      projects,
      [(project) => project.pinned, ...comparators],
      ["desc", ...orders],
    );
  };

  switch (selectedItemKey) {
    case "name":
      switch (selectedDir) {
        case "asc":
          return orderBy(projects, [(project) => project.name], ["asc"]);
        case "desc":
          return orderBy(projects, [(project) => project.name], ["desc"]);
      }
      break;

    case "owner":
      switch (selectedDir) {
        case "asc":
          return orderBy(
            projects,
            [
              (project) =>
                (project.user?.first_name ?? "") +
                (project.user?.last_name ?? ""),
            ],
            ["asc"],
          );
        case "desc":
          return orderBy(
            projects,
            [
              (project) =>
                (project.user?.first_name ?? "") +
                (project.user?.last_name ?? ""),
            ],
            ["desc"],
          );
      }
      break;

    case "organization":
      switch (selectedDir) {
        case "asc":
          return orderBy(projects, [(project) => project.tenantName], ["asc"]);
        case "desc":
          return orderBy(projects, [(project) => project.tenantName], ["desc"]);
      }
      break;
    case "dateCreated":
      switch (selectedDir) {
        case "asc":
          return orderBy(projects, [(project) => project.dateCreated], ["asc"]);
        case "desc":
          return orderBy(
            projects,
            [(project) => project.dateCreated],
            ["desc"],
          );
      }
      break;
    case "recentActivity":
      switch (selectedDir) {
        case "asc":
          return orderBy(
            projects,
            [(project) => project.dateLastActive ?? project.dateCreated],
            ["asc"],
          );
        case "desc":
          return orderBy(
            projects,
            [(project) => project.dateLastActive ?? project.dateCreated],
            ["desc"],
          );
      }
      break;

    case "activeStorage":
      switch (selectedDir) {
        case "asc":
          return orderBy(
            projects,
            [(project) => Number(project.activeStorageSize ?? 0)],
            ["asc"],
          );
        case "desc":
          return orderBy(
            projects,
            [(project) => Number(project.activeStorageSize ?? 0)],
            ["desc"],
          );
      }
      break;

    case "archivedStorage":
      switch (selectedDir) {
        case "asc":
          return orderBy(
            projects,
            [(project) => Number(project.archivedStorageSize ?? 0)],
            ["asc"],
          );

        case "desc":
          return orderBy(
            projects,
            [(project) => Number(project.archivedStorageSize ?? 0)],
            ["desc"],
          );
      }
      break;

    case "computeCredits":
      switch (selectedDir) {
        case "asc":
          return orderBy(
            projects,
            [(project) => Number(project.usedCredits ?? 0)],
            ["asc"],
          );

        case "desc":
          return orderBy(
            projects,
            [(project) => Number(project.usedCredits ?? 0)],
            ["desc"],
          );
      }
      break;

    case "totalStorage":
      switch (selectedDir) {
        case "asc":
          return orderBy(
            projects,
            [
              (project) =>
                Number(project.archivedStorageSize ?? 0) +
                Number(project.activeStorageSize ?? 0),
            ],
            ["asc"],
          );

        case "desc":
          return orderBy(
            projects,
            [
              (project) =>
                Number(project.archivedStorageSize ?? 0) +
                Number(project.activeStorageSize ?? 0),
            ],
            ["desc"],
          );
      }
      break;
  }
  return projects;
};

/**
 * Displays a table of projects, allows selection and provides context menu for edit/deletion
 */
export const ProjectCards = ({
  projects,
  tenantId,
  view,
}: ProjectCardsProps) => {
  const [searchBarContainerId] = useState(
    htmlIdGenerator("search-bar-container")(),
  );
  const { setParam, getParam } = useIdeasSearchParams();

  const { query, executeQuery, onChange, searchBarRef, roleFilter } =
    useEuiSearchBar();

  const [selectedOption, setSelectedOption] = useState<
    ToggleFilterProps<(typeof items)[number]>["selected"]
  >({ item: "dateCreated", state: 0 });

  const filteredProjects = useMemo(() => {
    const selectedItem = items.find(
      (item) => item.value === selectedOption.item,
    );

    const selectedItemKey = selectedItem?.value;
    const selectedDir = selectedItem?.states[selectedOption.state]?.value;
    const queriedProjects = isDefined(query)
      ? executeQuery(query, projects)
      : projects;

    if (isUndefined(selectedItemKey) || isUndefined(selectedDir)) {
      captureException("Unexpected state in ProjectCards");
      return queriedProjects;
    }

    return onSort(queriedProjects, selectedItemKey, selectedDir);
  }, [executeQuery, projects, query, selectedOption]);

  /**
   * Load sort route params into state on mount
   */
  useEffect(() => {
    const sortField = getParam("SORT_FIELD");
    const sortOrder = getParam("SORT_ORDER");

    if (sortField !== undefined && sortOrder !== undefined) {
      const selectedItem = items.find((item) => item.value === sortField);
      const stateIdx =
        selectedItem?.states.findIndex((state) => state.value === sortOrder) ??
        -1;
      if (stateIdx > -1 && selectedItem !== undefined) {
        setSelectedOption({ item: selectedItem.value, state: stateIdx });
      } else {
        captureException("Failed to restore route params in Project cards");
      }
    }
  }, [getParam]);

  const filterItems = (() => {
    /**
     * Organization suppressed when viewing org page
     */
    if (tenantId !== undefined) {
      return items.filter((item) => item.value !== "organization");
    }
    return items;
  })();

  const gridActions: NonNullable<ProjectsGridProps["actions"]> = useMemo(() => {
    return {
      colId: "actions",
      csvValueGetter: null,
      headerName: "",
      width: 40,
      wrapHeaderText: true,
      resizable: false,
      sortable: false,
      pinned: "right",
      cellRenderer: ({ data }: ProjectsGridCellRendererParams) =>
        data !== undefined && (
          <ProjectsCardActions
            permissions={data.userPermissions}
            projectId={data.id}
            disabled={data.status === ProjectStatus.CLONING}
          />
        ),
    };
  }, []);

  /**
   * Hide organization column when showing single organization view
   */
  const gridSuppressColumns = useMemo(
    () => (tenantId !== undefined ? ["organization" as const] : []),
    [tenantId],
  );

  if (projects.length === 0) {
    return (
      <EuiEmptyPrompt
        title={<h2>Start your first project!</h2>}
        style={{ maxWidth: "100%" }}
        titleSize="xs"
        iconType="layers"
        body={
          <p>
            <EuiText>
              All data in IDEAS is contained within projects. Start your first
              project to begin uploading and analyzing data.
            </EuiText>
          </p>
        }
        actions={[
          <ButtonCreateProjectFilled
            key="create-project-button"
            tenantId={tenantId}
          />,
        ]}
      />
    );
  }

  return (
    <EuiFlexGroup direction="column" gutterSize="none">
      <EuiFlexGroup
        gutterSize="none"
        responsive={false}
        css={css`
          #${searchBarContainerId} {
            .euiButtonEmpty {
              min-width: 70px;
              .euiFilterButton__notification {
                margin-right: 5px;
              }
              .euiButtonEmpty__content {
                display: flex;
                align-items: center;
                justify-content: center;
              }
            }
          }
        `}
      >
        <EuiFlexItem id={searchBarContainerId}>
          <EuiSearchBar
            ref={searchBarRef}
            query={query}
            onChange={onChange}
            filters={[roleFilter]}
            box={{ placeholder: "Find a project...", incremental: true }}
          />
        </EuiFlexItem>
        {view === "CARDS" && (
          <EuiFlexItem
            grow={false}
            css={css`
              margin-left: 12px;
            `}
          >
            <ToggleFilter
              items={filterItems}
              selected={selectedOption}
              onChange={(newSelectedOption) => {
                const selectedItem = items.find(
                  (item) => item.value === newSelectedOption.item,
                );
                const selectedItemOrder =
                  selectedItem?.states?.[newSelectedOption.state]?.value;

                if (selectedItemOrder === undefined) {
                  captureException("Route params out of sync in Project Cards");
                } else {
                  setParam("SORT_FIELD", newSelectedOption.item);
                  setParam("SORT_ORDER", selectedItemOrder);
                }

                setSelectedOption(newSelectedOption);
              }}
            />
          </EuiFlexItem>
        )}
      </EuiFlexGroup>
      <EuiSpacer size="xs" />
      <EuiFlexGroup
        direction="column"
        gutterSize="s"
        style={{ height: "100%", minHeight: 400 }}
      >
        {view === "GRID" ? (
          <EuiFlexItem grow>
            <ProjectsGrid
              projects={filteredProjects}
              actions={gridActions}
              suppressColumns={gridSuppressColumns}
              suppressSortComparator
            />
          </EuiFlexItem>
        ) : (
          <ProjectCardsMemo projects={filteredProjects} />
        )}
      </EuiFlexGroup>
    </EuiFlexGroup>
  );
};
