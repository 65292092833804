import {
  EuiButtonEmpty,
  EuiCallOut,
  EuiIcon,
  EuiLink,
  EuiModal,
  EuiModalBody,
  EuiModalFooter,
  EuiModalHeader,
  EuiModalHeaderTitle,
  EuiText,
  EuiTextColor,
} from "@inscopix/ideas-eui";
import { useEffect, useState } from "react";
import {
  OpenContract,
  useFetchAndSetHasActiveContract,
} from "hooks/useFetchAndSetHasActiveContract";
import { useTenantContext } from "providers/TenantProvider/TenantProvider";
import moment from "moment";

interface CallOutContractExpiredProps {
  format: "call_out" | "text";
}

const CallOutContractExpired = ({ format }: CallOutContractExpiredProps) => {
  if (format === "text") {
    return (
      <EuiTextColor color="danger">
        Your IDEAS subscription has expired.
      </EuiTextColor>
    );
  }

  return (
    <EuiCallOut
      style={{ textAlign: "center" }}
      size="s"
      title={
        <>
          Your IDEAS subscription has expired. Please contact{" "}
          <EuiLink role="button" href="mailto:support.inscopix@bruker.com">
            support.inscopix@bruker.com
          </EuiLink>{" "}
          to renew.
        </>
      }
      iconType="warning"
      color="danger"
    />
  );
};

interface CallOutContractExpiringProps {
  contract: OpenContract;
  format: "call_out" | "text";
}

const CallOutContractExpiring = ({
  contract,
  format,
}: CallOutContractExpiringProps) => {
  const formattedContractEndDate = moment(contract.end_date).format(
    "YYYY-MM-DD",
  );
  const daysUntilContractEnd = moment(contract.end_date).diff(moment(), "days");
  const daysNoun = daysUntilContractEnd === 1 ? "day" : "days";

  if (format === "text") {
    return (
      <EuiText>{`Valid thru ${formattedContractEndDate} (${daysUntilContractEnd} ${daysNoun} remaining).`}</EuiText>
    );
  }

  return (
    <EuiCallOut
      style={{ textAlign: "center" }}
      size="s"
      title={
        <>
          Your IDEAS subscription will expire in {daysUntilContractEnd}{" "}
          {daysNoun}. Please contact{" "}
          <EuiLink role="button" href="mailto:support.inscopix@bruker.com">
            support.inscopix@bruker.com
          </EuiLink>{" "}
          to renew.
        </>
      }
      iconType="warning"
      color="warning"
    />
  );
};

interface CallOutContractInactiveProps {
  contract: OpenContract;
  format: "call_out" | "text";
}

const CallOutContractInactive = ({
  contract,
  format,
}: CallOutContractInactiveProps) => {
  const daysUntilContractStart = moment(contract.start_date).diff(
    moment(),
    "days",
  );
  const daysNoun = daysUntilContractStart === 1 ? "day" : "days";
  const formattedContractStartDate = moment(contract.start_date).format(
    "YYYY-MM-DD",
  );
  const message = `No currently active IDEAS subscription. An upcoming subscription is scheduled to begin in ${daysUntilContractStart} ${daysNoun} (${formattedContractStartDate}).`;

  if (format === "text") {
    return <EuiTextColor color="primary">{message}</EuiTextColor>;
  }

  return (
    <EuiCallOut
      style={{ textAlign: "center" }}
      size="s"
      title={message}
      iconType="warning"
      color="primary"
    />
  );
};

interface CallOutContractProps {
  format: "call_out" | "text";
}

export const CallOutContract = ({ format }: CallOutContractProps) => {
  const {
    activeContract,
    upcomingContract,
    fetchAndSetContracts,
    resetContracts,
  } = useFetchAndSetHasActiveContract();
  const currentTenant = useTenantContext((s) => s.currentTenant);
  const sessionStorageKey = `${currentTenant.key}CallOutContractModalClosed`;
  const showModalOnLoad =
    window.sessionStorage.getItem(sessionStorageKey) !== "true";
  const [isLoading, setIsLoading] = useState(true);
  const [isModalVisible, setIsModalVisible] = useState(showModalOnLoad); // The modal is visible in every reload of the app when the contract is expired

  const closeModal = () => {
    window.sessionStorage.setItem(sessionStorageKey, "true");
    setIsModalVisible(false);
  };

  useEffect(() => {
    void fetchAndSetContracts().finally(() => setIsLoading(false));
    return () => {
      resetContracts();
    };
  }, [fetchAndSetContracts, resetContracts]);

  if (isLoading) {
    return null;
  }

  const daysUntilActiveContractEnd = activeContract
    ? moment(activeContract.end_date).diff(moment(), "days")
    : 0;

  // Warn user if active contract expires soon
  if (activeContract && daysUntilActiveContractEnd <= 30) {
    return (
      <CallOutContractExpiring contract={activeContract} format={format} />
    );
  }

  // Warn user if their next contract has not started
  if (!activeContract && upcomingContract) {
    return (
      <CallOutContractInactive contract={upcomingContract} format={format} />
    );
  }

  // Warn user if they have no open contract and tenant is not internal
  // (internal tenants get unlimited quota)
  if (!activeContract && !upcomingContract && !currentTenant.internal) {
    return (
      <>
        {isModalVisible && format !== "text" && (
          <EuiModal onClose={closeModal} maxWidth={580}>
            <EuiModalHeader>
              <EuiModalHeaderTitle>
                <EuiTextColor color="danger">
                  <EuiIcon type="warning" color="danger" size="xl" />
                  Your IDEAS subscription has expired
                </EuiTextColor>
              </EuiModalHeaderTitle>
            </EuiModalHeader>

            <EuiModalBody>
              <EuiText>
                Please contact{" "}
                <EuiLink
                  role="button"
                  href="mailto:support.inscopix@bruker.com"
                >
                  support.inscopix@bruker.com
                </EuiLink>{" "}
                to renew. You may close this window and continue viewing your
                projects. Uploads, downloads, and running analysis tasks require
                an active subscription.
              </EuiText>
            </EuiModalBody>

            <EuiModalFooter>
              <EuiButtonEmpty onClick={closeModal}>Close</EuiButtonEmpty>
            </EuiModalFooter>
          </EuiModal>
        )}

        <CallOutContractExpired format={format} />
      </>
    );
  }

  // Display nothing if user has an active contract that is not expiring soon
  return null;
};
