import { EuiCallOut, EuiLink, EuiPageTemplate } from "@inscopix/ideas-eui";
import { ModalProjectSharingAdminManage } from "components/ModalProjectSharing/ModalProjectSharingAdminManage";
import { AdminProjectDjango } from "django/libraryProjectDjango";
import { useRouteMapContext } from "providers/RouteMapProvider/RouteMapProvider";
import { useState } from "react";

export interface AdminProjectRestrictedProps {
  project: AdminProjectDjango;
}
export const PageProjectAdminRestricted = ({
  project,
}: AdminProjectRestrictedProps) => {
  const [showModal, setShowModal] = useState(false);
  const routeMap = useRouteMapContext((s) => s.routeMap);

  return (
    <>
      <EuiPageTemplate>
        <EuiCallOut title="Project Restricted">
          <p>
            {project.name} is currently set to restricted by its owner. As an
            organization admin, you may{" "}
            <EuiLink onClick={() => setShowModal(true)}>
              Modify Permissions
            </EuiLink>{" "}
            settings for this project.
          </p>{" "}
          <p>
            You may also visit the{" "}
            <EuiLink
              to={
                routeMap.ORGANIZATION_USAGE.dynamicPath({
                  tenantKey: project.tenant.key,
                }).path
              }
            >
              Admin Dashboard
            </EuiLink>{" "}
            to view all projects in your organization.
          </p>
        </EuiCallOut>
      </EuiPageTemplate>
      {showModal && (
        <ModalProjectSharingAdminManage
          projectId={project.id}
          onClose={() => setShowModal(false)}
        />
      )}
    </>
  );
};
