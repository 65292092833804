import {
  EuiText,
  EuiSpacer,
  EuiContextMenuItem,
  EuiContextMenuPanel,
  EuiFlexGroup,
  EuiFlexItem,
  EuiPopover,
  htmlIdGenerator,
  EuiTextColor,
  EuiHorizontalRule,
} from "@inscopix/ideas-eui";
import { useTenantContext } from "providers/TenantProvider/TenantProvider";
import { UserAvatar } from "components/UserAvatar/UserAvatar";
import { Fragment, useState } from "react";
import { captureException } from "@sentry/react";
import { useInvalidateModalProjectSharingQuery } from "./ModalProjectSharing";
import { addUtilityToastSuccess } from "utils/addUtilityToastSuccess";
import { addUtilityToastFailure } from "utils/addUtilityToastFailure";
import {
  UserAccessLevel,
  USER_ACCESS_LEVELS_BY_ID,
  USER_ACCESS_LEVELS_BY_KEY,
} from "types/UserAccessLevels";
import { Project } from "graphql/_Types";
import { permissions, UserProjectAccessDjango } from "./permissions";
import { ButtonEmptyPermissionedBase } from "components/ButtonEmptyPermissioned/ButtonEmptyPermissioned";

export interface UserWithAccessProps {
  projectId: Project["id"];
  userProjectAccess: Pick<
    UserProjectAccessDjango,
    "id" | "user" | "access_level"
  >;
  updateInternalUserAccessLevel: typeof permissions.userFunctions.internalAccess.updateInternalUserAccessLevel;
  revokeInternalUserAccess: typeof permissions.userFunctions.internalAccess.revokeInternalUserAccess;
  userHasGrantAccessPermission: boolean;
}

const UserWithAccess = ({
  projectId,
  userProjectAccess,
  updateInternalUserAccessLevel,
  revokeInternalUserAccess,
  userHasGrantAccessPermission,
}: UserWithAccessProps) => {
  const { invalidateModalProjectSharingQuery } =
    useInvalidateModalProjectSharingQuery();
  const [isPopoverOpen, setPopover] = useState(false);
  const [isWorking, setWorking] = useState(false);

  const usersById = useTenantContext((s) => s.usersById);
  const user = usersById.get(userProjectAccess.user);

  if (!user) {
    captureException(
      new Error(
        `User with id ${userProjectAccess.user} not found in ModalProjectSharing > UserWithAccess component`,
      ),
    );
    return null;
  }

  const closePopover = () => {
    setPopover(false);
  };
  const togglePopover = () => {
    setPopover((isOpen) => !isOpen);
  };

  const handleAccessLevelChange = async (
    accessLevel: UserAccessLevel["id"],
  ) => {
    if (
      accessLevel !== userProjectAccess.access_level //no need to change the role if the role is the same
    ) {
      setWorking(true);
      try {
        await updateInternalUserAccessLevel({
          projectId,
          accessId: userProjectAccess.id,
          userId: userProjectAccess.user,
          accessLevel,
        });
        await invalidateModalProjectSharingQuery(projectId);

        addUtilityToastSuccess("User role updated");
      } catch (e) {
        captureException(e as Error);
        await invalidateModalProjectSharingQuery(projectId);
        addUtilityToastFailure("Failed to update user role");
      } finally {
        setWorking(false);
      }
    }
  };

  const handleRemove = async () => {
    setWorking(true);
    try {
      await revokeInternalUserAccess({
        projectId,
        accessId: userProjectAccess.id,
      });
      addUtilityToastSuccess("User removed");
      await invalidateModalProjectSharingQuery(projectId);
    } catch (e) {
      captureException(e as Error);
      addUtilityToastFailure("Failed to remove user");
      setWorking(false);
    }
  };

  return (
    <Fragment key={userProjectAccess.user}>
      <EuiFlexGroup gutterSize="xs" alignItems="center">
        <EuiFlexItem grow={false}>
          <UserAvatar userId={user.id} size="m" />
        </EuiFlexItem>
        <EuiFlexItem grow={true}>
          <EuiText size="xs">
            <b>{`${user?.firstName ?? ""} ${user?.lastName ?? ""}`}</b>
          </EuiText>
          <EuiText size="xs" color="subdued">
            {user.email}
          </EuiText>
        </EuiFlexItem>
        <EuiFlexItem grow={false}>
          <EuiPopover
            id={htmlIdGenerator()()}
            button={
              <ButtonEmptyPermissionedBase
                isLoading={isWorking}
                size="xs"
                iconSide="right"
                iconType="arrowDown"
                onClick={() => togglePopover()}
                isPermitted={userHasGrantAccessPermission}
              >
                {USER_ACCESS_LEVELS_BY_ID[userProjectAccess.access_level].name}
              </ButtonEmptyPermissionedBase>
            }
            isOpen={isPopoverOpen}
            closePopover={() => setPopover(false)}
            panelPaddingSize="none"
            anchorPosition="downRight"
          >
            <EuiContextMenuPanel
              size="s"
              items={[
                <EuiContextMenuItem
                  key="viewer"
                  onClick={() => {
                    void handleAccessLevelChange(
                      USER_ACCESS_LEVELS_BY_KEY["VIEWER"].id,
                    );
                    closePopover();
                  }}
                >
                  Viewer
                </EuiContextMenuItem>,
                <EuiContextMenuItem
                  key="editor"
                  onClick={() => {
                    void handleAccessLevelChange(
                      USER_ACCESS_LEVELS_BY_KEY["EDITOR"].id,
                    );
                    closePopover();
                  }}
                >
                  Editor
                </EuiContextMenuItem>,
                <EuiContextMenuItem
                  key="admin"
                  onClick={() => {
                    void handleAccessLevelChange(
                      USER_ACCESS_LEVELS_BY_KEY["ADMIN"].id,
                    );
                    closePopover();
                  }}
                >
                  Admin
                </EuiContextMenuItem>,
              ]}
            />
            <EuiHorizontalRule margin="none" />
            <EuiContextMenuPanel
              size="s"
              items={[
                <EuiContextMenuItem
                  key="remove"
                  onClick={() => {
                    void handleRemove();
                    closePopover();
                  }}
                >
                  <EuiTextColor color="danger">Remove</EuiTextColor>
                </EuiContextMenuItem>,
              ]}
            />
          </EuiPopover>
        </EuiFlexItem>
      </EuiFlexGroup>
      <EuiSpacer size="m" />
    </Fragment>
  );
};

export { UserWithAccess };
