import { RefObject, useCallback, useMemo } from "react";
import { ModalDandi } from "./ModalDandi";
import { AgGridReact } from "ag-grid-react";
import { useDatasetNwbFiles } from "./ModalDandi.hooks";
import { useProjectDataContext } from "pages/project/ProjectDataProvider";
import { useDatasetLayoutContext } from "pages/project/dataset/DatasetLayoutProvider";
import { captureException } from "@sentry/react";
import { EuiTableComputedColumnType } from "@inscopix/ideas-eui";
import { FileBadge } from "components/FileBadge/FileBadge";
import { RecordingIdentifierBadge } from "components/RecordingIdentifierBadge/RecordingIdentifierBadge";
import { filesize } from "utils/filesize";
import { chain } from "lodash";
import { ProjectFile } from "stores/project-files/ProjectFilesManager";
import { useDatasetDataContext } from "pages/project/dataset/DatasetDataProvider";

/**
 * Determines whether a file belongs to specified data column.
 * @param file
 * @param columnId
 * @returns `true` if the file belongs to the data column, `false` otherwise.
 */
const isFileInColumn = (file: ProjectFile, columnId: string) => {
  return file.columns.some(({ id }) => id === columnId);
};

// Columns rendered in the file selection table
const fileTableColumns: EuiTableComputedColumnType<ProjectFile>[] = [
  {
    name: "Recording Session ID",
    render: (file) => (
      <>
        {file.recordings.map((recording) => (
          <RecordingIdentifierBadge
            key={recording.id}
            recordingId={recording.id}
          />
        ))}
      </>
    ),
  },
  {
    name: "File",
    render: (file) => <FileBadge drsFile={file} />,
  },
  {
    name: "Size",
    render: (file) => filesize(file.size),
  },
];

export interface ModalDandiRecordingsTableProps {
  gridRef: RefObject<AgGridReact>;
  onClose: () => void;
}

/** Component for exporting files from recordings tables to DANDI */
export const ModalDandiRecordingsTable = ({
  gridRef,
  onClose,
}: ModalDandiRecordingsTableProps) => {
  const { project } = useProjectDataContext();
  const { dataset } = useDatasetDataContext();
  const { buttonExportsRef } = useDatasetLayoutContext();
  const files = useDatasetNwbFiles();

  /** Opens the "Exports" popover from the bottom bar */
  const openExportsPopover = useCallback(() => {
    const buttonExports = buttonExportsRef.current;
    if (buttonExports !== null) {
      buttonExports.click();
    } else {
      captureException("buttonExportsRef referenced before being set");
    }
  }, [buttonExportsRef]);

  // Columns containing NWB files
  const dataColumnIds = useMemo(() => {
    return chain(files)
      .flatMap((file) => file.columns.map((column) => column.id))
      .uniq()
      .value();
  }, [files]);

  return (
    <ModalDandi
      gridApi={gridRef.current?.api}
      onClose={onClose}
      files={files}
      isFileInColumn={isFileInColumn}
      projectId={project.id}
      datasetId={dataset.id}
      onComplete={openExportsPopover}
      fileTableColumns={fileTableColumns}
      dataColumnIds={dataColumnIds}
    />
  );
};
