/** @jsxImportSource @emotion/react */

import {
  EuiFlexGroup,
  EuiFlexItem,
  EuiFormRow,
  EuiIcon,
  EuiSpacer,
  EuiSuperSelectOption,
  EuiText,
} from "@inscopix/ideas-eui";
import { captureException } from "@sentry/react";
import { SuperSelectPermissionedBase } from "components/SuperSelectPermissioned/SuperSelectPermissioned";
import { Project } from "graphql/_Types";
import { useState } from "react";
import {
  UserAccessLevel,
  USER_ACCESS_LEVELS_BY_ID,
  USER_ACCESS_LEVELS_BY_KEY,
} from "types/UserAccessLevels";
import { addUtilityToastFailure } from "utils/addUtilityToastFailure";
import { addUtilityToastSuccess } from "utils/addUtilityToastSuccess";
import {
  ModalProjectSharingProps,
  useInvalidateModalProjectSharingQuery,
} from "./ModalProjectSharing";

export interface ModalProjectSharingDefaultAccessLevelProps {
  updateInternalDefaultAccessLevel: NonNullable<
    ModalProjectSharingProps["updateInternalDefaultAccessLevel"]
  >;
  defaultAccessLevel: UserAccessLevel["id"];
  projectId: Project["id"];
  accessLevelOptions: EuiSuperSelectOption<`${UserAccessLevel["id"]}`>[];
  userHasGrantAccessPermission: boolean;
}
export const ModalProjectSharingDefaultAccessLevel = ({
  projectId,
  defaultAccessLevel,
  accessLevelOptions,
  updateInternalDefaultAccessLevel,
  userHasGrantAccessPermission,
}: ModalProjectSharingDefaultAccessLevelProps) => {
  const [loading, setLoading] = useState(false);
  const { invalidateModalProjectSharingQuery } =
    useInvalidateModalProjectSharingQuery();

  const handleRoleChangeOrg = async (
    defaultUserAccessLevel: UserAccessLevel["id"],
  ) => {
    setLoading(true);

    try {
      await updateInternalDefaultAccessLevel({
        projectId,
        defaultUserAccessLevel,
      });
      await invalidateModalProjectSharingQuery(projectId);

      addUtilityToastSuccess("Successfully updated organization access level");
    } catch (error) {
      addUtilityToastFailure("Failed to update organization access level");
      captureException(error);
    }
    setLoading(false);
  };

  const defaultAccessMode = (() => {
    const role_key = USER_ACCESS_LEVELS_BY_ID[defaultAccessLevel].key;

    if (role_key === "RESTRICTED") {
      return "restricted";
    }
    return "shared";
  })();

  return (
    <EuiFormRow
      fullWidth
      error={"You do not have sufficient permission to change this setting"}
      label="Access for all users in the organization"
    >
      <>
        <SuperSelectPermissionedBase
          isPermitted={userHasGrantAccessPermission}
          isLoading={loading}
          fullWidth
          compressed
          options={[
            {
              value: "restricted",
              inputDisplay: (
                <EuiFlexGroup alignItems="center" gutterSize="s">
                  <EuiFlexItem grow={false}>
                    <EuiIcon type="lock" size="m" />
                  </EuiFlexItem>
                  <EuiFlexItem>
                    <EuiText size="s">Restricted</EuiText>
                  </EuiFlexItem>
                </EuiFlexGroup>
              ),
              dropdownDisplay: (
                <>
                  <strong>Restricted</strong>
                  <EuiText size="s" color="subdued">
                    <p>
                      Only visible to you and users granted individual access
                    </p>
                  </EuiText>
                </>
              ),
            },
            {
              value: "shared",
              inputDisplay: (
                <EuiFlexGroup alignItems="center" gutterSize="s">
                  <EuiFlexItem grow={false}>
                    <EuiIcon type="users" size="m" />
                  </EuiFlexItem>
                  <EuiFlexItem>
                    <EuiText size="s">Shared</EuiText>
                  </EuiFlexItem>
                </EuiFlexGroup>
              ),
              dropdownDisplay: (
                <>
                  <strong>Shared</strong>
                  <EuiText size="s" color="subdued">
                    <p>Any user in the organization can access</p>
                  </EuiText>
                </>
              ),
            },
          ]}
          valueOfSelected={defaultAccessMode}
          placeholder="Select an option"
          onChange={(value) => {
            switch (value) {
              case "restricted":
                return void handleRoleChangeOrg(
                  USER_ACCESS_LEVELS_BY_KEY["RESTRICTED"].id,
                );
              case "shared":
                return void handleRoleChangeOrg(
                  USER_ACCESS_LEVELS_BY_KEY["COPIER"].id,
                );
            }
          }}
          itemLayoutAlign="top"
          hasDividers
          data-test-subj="org-user-access"
        />

        {defaultAccessMode === "shared" && (
          <>
            <EuiSpacer size="s" />
            <EuiText size="xs">Access level</EuiText>
            <EuiSpacer size="xs" />
            <SuperSelectPermissionedBase
              isLoading={loading}
              fullWidth
              compressed
              options={accessLevelOptions}
              valueOfSelected={`${defaultAccessLevel}`}
              placeholder="Select an option"
              onChange={(value) => {
                void handleRoleChangeOrg(
                  Number(value) as UserAccessLevel["id"],
                );
              }}
              itemLayoutAlign="top"
              hasDividers
              isPermitted={userHasGrantAccessPermission}
              data-test-subj="org-user-access-level"
            />
          </>
        )}
      </>
    </EuiFormRow>
  );
};
