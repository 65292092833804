/**
 * Type guard to check if a value is of type `string[]`.
 * @param value
 * @returns true if the value is a string array, false otherwise.
 */
const isStringArray = <T>(value: T | string[]): value is string[] => {
  return Array.isArray(value) && value.every((elm) => typeof elm === "string");
};

/**
 * Asserts whether a value if of type `string[]` or coalesces it to an empty
 * array if it is not.
 * @param value
 * @returns The coalesced value.
 */
export const coalesceToStringArray = <T>(value: T | string[]): string[] => {
  return isStringArray(value) ? value : [];
};
