import { EuiSelect, EuiSelectOption } from "@inscopix/ideas-eui";
import { DandiServer, ModalDandiState } from "./ModalDandi.types";

interface ModalDandiFieldServerProps {
  server: ModalDandiState["server"];
  setServer: (newValue: ModalDandiState["server"]) => void;
}

/** Component that renders the server select field. */
export const ModalDandiFieldServer = ({
  server,
  setServer,
}: ModalDandiFieldServerProps) => {
  const options: EuiSelectOption[] = [
    {
      text: "Production",
      value: "dandi" satisfies DandiServer,
    },
    {
      text: "Staging",
      value: "dandi-staging" satisfies DandiServer,
    },
  ];

  return (
    <EuiSelect
      options={options}
      value={server}
      onChange={(e) => {
        const server = e.target.value as ModalDandiState["server"];
        setServer(server);
      }}
    />
  );
};
