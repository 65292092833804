/** @jsxImportSource @emotion/react */
import {
  EuiProgress,
  EuiText,
  EuiSpacer,
  EuiFlexGroup,
  EuiFlexItem,
  EuiIcon,
  EuiButtonIcon,
} from "@inscopix/ideas-eui";
import { filesize } from "utils/filesize";
import { QUOTA_TYPES_BY_KEY } from "types/QuotaType";
import { roundToSignificant } from "utils/roundToSignificant";
import { TenantResourceSummary } from "./useFetchResourceSummary";
import { css } from "@emotion/react";

// Threshold for warning when quota is nearly exceeded
export const isThresholdNearlyExceeded = (percentage: number) =>
  percentage >= 90;

interface PanelTenantUsageQuotasProps {
  resourceSummary: TenantResourceSummary;
  compact?: boolean;
}

/**
 * Displays a panel showing resource consumption for the entire tenant
 */
export const PanelTenantUsageQuotas = ({
  resourceSummary,
  compact = false,
}: PanelTenantUsageQuotasProps) => {
  // We will always display these quota types in the following order
  // And we will always display them even if the consumption or allowance is 0
  const quotaTypesToDisplay: (keyof TenantResourceSummary)[] = [
    "storage",
    "archived_storage",
    "credits",
    "network",
  ];

  //
  // Build the list of items to display
  //
  const items = quotaTypesToDisplay.map((quotaType) => {
    const { id, name, unit, icon, tipsOnLimit, documentation } =
      QUOTA_TYPES_BY_KEY[quotaType];

    const formaQuotaValue = (amount: number): string =>
      unit === "GB"
        ? filesize(amount * 1000 ** 3).toString() // Convert from GB (base-10) to bytes for filesize function
        : roundToSignificant(amount).toString() + " " + unit;

    const percentageConsumed = resourceSummary[quotaType].percentage ?? 0;
    const allowance = formaQuotaValue(resourceSummary[quotaType].allowed);
    const consumed = formaQuotaValue(resourceSummary[quotaType].consumed);

    const chartColor =
      percentageConsumed >= 99
        ? "danger"
        : percentageConsumed >= 90
        ? "warning"
        : "primary";

    return {
      id,
      name,
      icon,
      tipsOnLimit,
      documentation,
      allowance,
      consumed,
      percentageConsumed,
      chartColor,
    };
  });

  return (
    <>
      {items.map((item) => (
        <div key={item.id}>
          <EuiFlexGroup
            gutterSize="xs"
            alignItems={compact ? "flexStart" : "center"}
            direction={compact ? "column" : "row"}
          >
            <EuiFlexGroup
              gutterSize="xs"
              alignItems="center"
              css={css`
                flex-grow: 0;
              `}
            >
              <EuiFlexItem grow={false}>
                {item.icon !== undefined && (
                  <EuiIcon type={item.icon} size="m" />
                )}
              </EuiFlexItem>

              <EuiFlexItem grow={false}>
                <EuiText size="s">
                  <b>{item.name}</b>
                </EuiText>
              </EuiFlexItem>
            </EuiFlexGroup>

            <EuiFlexItem>
              <EuiText color="subdued" textAlign="right" size="s">
                {item.consumed} of {item.allowance} used{" "}
                {item.documentation !== undefined && (
                  <EuiButtonIcon
                    aria-label="View documentation"
                    iconType="popout"
                    href={item.documentation}
                    target="_blank"
                  />
                )}
              </EuiText>
            </EuiFlexItem>
          </EuiFlexGroup>
          <EuiSpacer size="xs" />
          <EuiProgress
            value={item.percentageConsumed}
            max={100}
            color={item.chartColor}
          />
          {isThresholdNearlyExceeded(item.percentageConsumed) && (
            <>
              <EuiSpacer size="s" />
              <EuiText size="s">💡 {item.tipsOnLimit}</EuiText>
            </>
          )}
          <EuiSpacer size="l" />
        </div>
      ))}
    </>
  );
};
