import { File as DrsFile } from "graphql/_Types";
import { uniqBy } from "lodash";
import { useCallback } from "react";
import {
  ProjectFile,
  useProjectFilesStore,
} from "stores/project-files/ProjectFilesManager";
import { ToolParamValue } from "../ToolParamsGrid.types";

export const useGetProjectFilesByFileIds = () => {
  const files = useProjectFilesStore((s) => s.files);

  /**
   *
   * @param fileIds list of file ids from a cell renderer or cell editor
   * @param filesById the filesById lookup object from the ToolParamsGridProvider
   * @returns returns a list of drsFiles matching each file id, or undefined if the drsFile for that
   *          file could not be found in the recording
   */

  const getProjectFilesByFileIds = useCallback(
    (
      fileIds:
        | DrsFile["id"][]
        // This is used in a bunch of places in analysis tables
        // Ag grid has been setting row data with values like empty strings when hitting delete
        // without our permission even though row data is meant to be in read only mode
        // This is just a safety fallback in case we hit an unexpected value at point of use
        | ToolParamValue,
    ) => {
      let drsFilesFound: ProjectFile[] = [];
      const fileIdsNotFound: string[] = [];

      const coercedFileIds =
        Array.isArray(fileIds) &&
        fileIds.every((fileId) => typeof fileId === "string")
          ? fileIds
          : [];

      // handle unexpected data dtype
      coercedFileIds.forEach((fileId) => {
        const file = files.find(({ id }) => fileId === id);

        // Is a non-series file?
        if (file !== undefined) {
          drsFilesFound.push(file);
          return;
        }

        // Find the series the file belongs to
        const seriesFile = files.find((file) => {
          return (
            file.isSeries &&
            file.seriesFiles.some((member) => member.id === fileId)
          );
        });

        // Check that all members are listed in `fileIds`
        const areAllMembersSelected =
          seriesFile?.seriesFiles?.every((member) =>
            coercedFileIds.includes(member.id),
          ) ?? false;

        // Is a series member file?
        if (seriesFile !== undefined && areAllMembersSelected) {
          drsFilesFound.push(seriesFile);
          return;
        }

        // File is a stale reference
        fileIdsNotFound.push(fileId);
      });

      // because series objects are stored as a list of their file ids,
      // we don't want to display the same object twice for series
      drsFilesFound = uniqBy(drsFilesFound, ({ id }) => id);

      return { drsFilesFound, fileIdsNotFound };
    },
    [files],
  );

  return { getProjectFilesByFileIds };
};
