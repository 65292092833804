import { memo } from "react";
import { ShortTaskId } from "components/ShortTaskId/ShortTaskId";

interface CellRendererTaskIdProps {
  taskId: string;
}

/**
 * Cell renderer that renders the short ID of a task
 */
const CellRendererTaskId = ({ taskId }: CellRendererTaskIdProps) => {
  return <ShortTaskId taskId={taskId} />;
};

export const CellRendererTaskIdMemo = memo(CellRendererTaskId);
