/** @jsxImportSource @emotion/react */
import { ReactNode, useState } from "react";
import {
  EuiButtonIcon,
  EuiFormRow,
  EuiIcon,
  EuiLink,
  EuiPopover,
} from "@inscopix/ideas-eui";
import { IHeaderGroupParams } from "ag-grid-community";
import { css } from "@emotion/react";
import { AnalysisTable, AnalysisTableGroup, Project } from "graphql/_Types";
import { useRouteMapContext } from "providers/RouteMapProvider/RouteMapProvider";
import { ButtonEmptyPermissioned } from "components/ButtonEmptyPermissioned/ButtonEmptyPermissioned";
import { useDatasetLayoutContext } from "pages/project/dataset/DatasetLayoutProvider";
import { useDatasetAction } from "hooks/useDatasetAction/useDatasetAction";
import { useTenantContext } from "providers/TenantProvider/TenantProvider";

interface HeaderMenuProps {
  children: ReactNode;
}

const HeaderMenu = ({ children }: HeaderMenuProps) => {
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);
  const togglePopover = () => setIsPopoverOpen((isOpen) => !isOpen);
  const closePopover = () => setIsPopoverOpen(false);

  return (
    <EuiPopover
      button={
        <span className="ag-header-cell-menu-button" onClick={togglePopover}>
          <span className="ag-icon ag-icon-menu" unselectable="on" />
        </span>
      }
      isOpen={isPopoverOpen}
      closePopover={closePopover}
      anchorPosition="downLeft"
      panelPaddingSize="none"
      ownFocus={false}
    >
      {children}
    </EuiPopover>
  );
};

const styles = {
  root: css`
    align-items: center;
    display: flex;
    height: 100%;
    width: 100%;
  `,
  name: css`
    background: none;
    border: none;
    cursor: default;
    flex: 1;
    margin: 0px 5px;
    overflow: hidden;
    padding: 0;
    text-overflow: ellipsis;
  `,
  /* EuiButtonIcons do not shrink with their parents in the same way that
     EuiIcons do. These styles are necessary to match the sizing of non-button
     icons in the other column headers. */
  icon: css`
    height: 12px !important;
    width: 12px !important;
    & > * {
      block-size: 12px !important;
      inline-size: 12px !important;
    }
  `,
};

interface AnalysisResultColumnGroupHeaderProps extends IHeaderGroupParams {
  analysisTableGroup: Pick<AnalysisTableGroup, "id" | "name">;
  analysisTable: Pick<AnalysisTable, "id" | "name">;
  project: Pick<Project, "key">;
}

export const AnalysisResultColumnGroupHeader = ({
  analysisTableGroup,
  analysisTable,
  columnGroup,
  displayName,
  project,
}: AnalysisResultColumnGroupHeaderProps) => {
  const routeMap = useRouteMapContext((s) => s.routeMap);
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);
  const togglePopover = () => setIsPopoverOpen((isOpen) => !isOpen);
  const closePopover = () => setIsPopoverOpen(false);
  const { openModal } = useDatasetLayoutContext();
  const deleteColumnAction = useDatasetAction("deleteColumn");
  const currentTenant = useTenantContext((s) => s.currentTenant);

  return (
    <div css={styles.root}>
      <EuiPopover
        anchorPosition="downLeft"
        button={
          <EuiButtonIcon
            aria-label="Link"
            color="primary"
            css={styles.icon}
            onClick={togglePopover}
            iconType="link"
          />
        }
        closePopover={closePopover}
        isOpen={isPopoverOpen}
        panelPaddingSize="s"
      >
        <EuiFormRow label="Analysis Table">
          <EuiLink
            to={
              routeMap.PROJECT_ANALYSIS_TABLE_GROUP.dynamicPath({
                tenantKey: currentTenant.key,
                projectKey: project.key,
                analysisTableGroupId: analysisTableGroup.id,
              }).path
            }
          >
            <EuiIcon type="indexMapping" size="s" /> {analysisTableGroup.name} (
            {analysisTable.name})
          </EuiLink>
        </EuiFormRow>
      </EuiPopover>
      <span css={styles.name}>{displayName}</span>
      <HeaderMenu>
        <ButtonEmptyPermissioned
          onClick={() =>
            openModal({
              type: "removeColumns",
              props: {
                targetColumn: columnGroup,
              },
            })
          }
          iconType="trash"
          color="text"
          size="s"
          disabled={deleteColumnAction.isDisabled}
          requiredPermission="edit"
        >
          Remove group columns
        </ButtonEmptyPermissioned>
      </HeaderMenu>
    </div>
  );
};
