/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";

const styles = {
  root: css`
    border: none;
    background-color: transparent;
    width: 100%;
    color: green;
    font-family: monospace;
    &:focus {
      outline: none;
    }
  `,
};

interface CellEditorFormulaProps {
  formula: string;
  onChangeFormula: (newFormula: string) => void;
  stopEditing: (cancel?: boolean) => void;
}

/**
 * Cell editor for cells in formula columns
 */
export const CellEditorFormula = ({
  formula,
  onChangeFormula,
  stopEditing,
}: CellEditorFormulaProps) => {
  return (
    <input
      css={styles.root}
      type="text"
      value={formula}
      onChange={(e) => onChangeFormula(e.target.value)}
      onBlur={() => stopEditing()}
      autoFocus
    />
  );
};
