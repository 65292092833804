import { EuiFlexItem, EuiText, EuiLink } from "@inscopix/ideas-eui";
import { getEnvVar } from "ideas.env";
import { useFlyoutContext } from "providers/FlyoutProvider/FlyoutProvider";

interface SupportLinksProps {
  closePopover?: () => void;
}

export const SupportLinks = ({ closePopover }: SupportLinksProps) => {
  const { setFlyout } = useFlyoutContext();
  const URL_DOCS = getEnvVar("URL_DOCS");

  const handleContactSupportClick = () => {
    setFlyout("contactSupport");
    if (closePopover) {
      closePopover();
    }
  };

  return (
    <EuiFlexItem>
      <EuiText>
        <EuiLink href={`${URL_DOCS}/create-project.html`} target={"_blank"}>
          Getting Started
        </EuiLink>
      </EuiText>
      <EuiText>
        <EuiLink href={`${URL_DOCS}/tools/`} target={"_blank"}>
          Analysis Tools
        </EuiLink>
      </EuiText>
      <EuiText>
        <EuiLink onClick={handleContactSupportClick}>Contact Support</EuiLink>
      </EuiText>
    </EuiFlexItem>
  );
};
