import axios from "axios";
import {
  NotificationsFeedBodyDocument,
  NotificationsFeedBodyQuery,
  NotificationsFeedBodyQueryVariables,
} from "graphql/_Types";
import { getEnvVar } from "ideas.env";
import { sum } from "lodash";
import { client } from "providers/ApolloProvider/ApolloProvider";
import { useRegionsContext } from "providers/RegionsProvider";
import { useCallback, useEffect, useMemo, useState } from "react";
import { getRequestHeaders } from "utils/getRequestHeaders";

/**
 * Fetches the unread notification count.
 * @param baseUrl Base URL used by the network request.
 * @returns The unread notification count.
 */
const getUnreadCount = async (baseUrl?: string) => {
  try {
    const url = getEnvVar("URL_NOTIFICATION_NOTIFICATION_UNREAD_COUNT")(
      baseUrl,
    );
    const headers = await getRequestHeaders();
    const res = await axios.get<{ count: number }>(url, { headers });
    return res.data.count;
  } catch (error) {
    return 0;
  }
};

/**
 * Hook that fetches the unread notification count.
 * @param scope Whether the internal region or external regions should be
 * queried.
 * @returns The unread count and refetch function.
 */
export const useNotificationUnreadCount = (scope: "internal" | "external") => {
  const { externalRegions } = useRegionsContext();
  const [unreadCount, setUnreadCount] = useState(0);

  /**
   * Fetches the unread count from the internal region and sets the state.
   */
  const fetchCountInternal = useCallback(() => {
    void getUnreadCount().then(setUnreadCount);
  }, []);

  /**
   * Fetches the unread count from all external regions and sets the state.
   */
  const fetchCountExternal = useCallback(() => {
    const promises = externalRegions.map(({ urlDjango }) =>
      getUnreadCount(urlDjango),
    );

    void Promise.all(promises).then((counts) => {
      const totalCount = sum(counts);
      setUnreadCount(totalCount);
    });
  }, [externalRegions]);

  // Derive the fetch function based on the scope
  const fetchCount = useMemo(() => {
    return scope === "internal" ? fetchCountInternal : fetchCountExternal;
  }, [fetchCountExternal, fetchCountInternal, scope]);

  // Derive the polling internal based on the scope
  const pollInterval = useMemo(() => {
    const oneMinute = 60 * 1000;
    const fiveMinutes = 5 * 60 * 1000;
    return scope === "internal" ? oneMinute : fiveMinutes;
  }, [scope]);

  // Fetch the unread count at a regular internal
  useEffect(() => {
    fetchCount();
    const timer = setInterval(fetchCount, pollInterval);
    return () => clearInterval(timer);
  }, [fetchCount, pollInterval]);

  return { unreadCount, refetch: fetchCount };
};

/**
 * Fetches the IDs of all valid notifications.
 * @param baseUrl Base URL used by the network request.
 * @returns The notification IDs.
 */
const getValidNotificationIds = async (baseUrl?: string) => {
  try {
    const url = getEnvVar("URL_NOTIFICATION_NOTIFICATION_VALID")(baseUrl);
    const headers = await getRequestHeaders();
    const res = await axios.get<{ notifications: string[] }>(url, { headers });
    return res.data.notifications;
  } catch (error) {
    return [];
  }
};

/**
 * Hook that fetches valid notifications.
 * @param scope Whether the internal region or external regions should be
 * queried.
 * @returns The notifications, loading state and error state.
 */
export const useValidNotifications = (
  scope: "internal" | "external",
  onlyShowUnread: boolean,
) => {
  const { externalRegions } = useRegionsContext();
  const [notifications, setNotifications] =
    useState<NotificationsFeedBodyQuery>();
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<Error>();

  /**
   * Fetches the valid notifications from the internal region and sets the
   * state.
   */
  const fetchNotificationsInternal = useCallback(async () => {
    try {
      const notificationIds = await getValidNotificationIds();
      if (notificationIds.length > 0) {
        const { data } = await client.query<
          NotificationsFeedBodyQuery,
          NotificationsFeedBodyQueryVariables
        >({
          query: NotificationsFeedBodyDocument,
          fetchPolicy: "network-only",
          variables: {
            notificationIds,
            ...(onlyShowUnread ? { condition: { hasSeen: false } } : {}),
          },
        });
        setNotifications(data);
      } else {
        setNotifications({
          __typename: "Query",
          notifications: {
            __typename: "NotificationsConnection",
            nodes: [],
          },
        });
      }
    } catch (error) {
      setError(error as Error);
    } finally {
      setIsLoading(false);
    }
  }, [onlyShowUnread]);

  /**
   * Fetches the valid notifications from all external regions and sets the
   * state.
   */
  const fetchNotificationsExternal = useCallback(async () => {
    try {
      const promises = externalRegions.map(({ urlDjango }) =>
        getValidNotificationIds(urlDjango),
      );

      const notificationIds = (await Promise.all(promises)).flat();
      if (notificationIds.length > 0) {
        const { data } = await client.query<
          NotificationsFeedBodyQuery,
          NotificationsFeedBodyQueryVariables
        >({
          query: NotificationsFeedBodyDocument,
          fetchPolicy: "network-only",
          variables: {
            notificationIds,
            ...(onlyShowUnread ? { condition: { hasSeen: false } } : {}),
          },
        });
        setNotifications(data);
      }
    } catch (error) {
      setError(error as Error);
    } finally {
      setIsLoading(false);
    }
  }, [externalRegions, onlyShowUnread]);

  // Derive the fetch function based on the scope
  const fetchNotifications = useMemo(() => {
    return scope === "internal"
      ? fetchNotificationsInternal
      : fetchNotificationsExternal;
  }, [fetchNotificationsExternal, fetchNotificationsInternal, scope]);

  // Derive the polling internal based on the scope
  const pollInterval = useMemo(() => {
    const oneMinute = 60 * 1000;
    const fiveMinutes = 5 * 60 * 1000;
    return scope === "internal" ? oneMinute : fiveMinutes;
  }, [scope]);

  // Fetch the valid notifications at a regular internal
  useEffect(() => {
    setIsLoading(true);
    void fetchNotifications();
    const timer = setInterval(() => void fetchNotifications(), pollInterval);
    return () => clearInterval(timer);
  }, [fetchNotifications, pollInterval]);

  return {
    notifications,
    isLoading,
    error,
    refetch: fetchNotifications,
  };
};
