import {
  RECORDINGS_GRID_DEFAULT_COLUMN_WIDTH,
  RecordingsGridColDef,
} from "components/RecordingsGrid/RecordingsGrid.types";
import {
  CreateDatasetRecordingsTableColumnInput,
  useCreateDatasetRecordingsTableColumnDjango,
} from "hooks/useCreateDatasetRecordingsTableColumnDjango";
import { useTenantContext } from "providers/TenantProvider/TenantProvider";
import { useDatasetDataContext } from "pages/project/dataset/DatasetDataProvider";
import { useCallback } from "react";
import { uuid } from "utils/uuid";
import { cloneDeep } from "lodash";
import { updateCacheFragment, writeCacheFragment } from "utils/cache-fragments";
import { useProjectPermissionStore } from "pages/project/ProjectPermissionProvider";

type CreateColumnParams = {
  colDef: RecordingsGridColDef;
};

export const useActionCreateColumn = () => {
  const currentTenant = useTenantContext((s) => s.currentTenant);
  const { datasetMode, recordingsTable, project } = useDatasetDataContext();
  const { createDatasetRecordingsTableColumnDjango } =
    useCreateDatasetRecordingsTableColumnDjango();
  const hasEditPermission = useProjectPermissionStore(
    (s) => s.permissions["edit"],
  );
  const getActionDef = useCallback(
    (params: CreateColumnParams) => {
      const createColumnInput: CreateDatasetRecordingsTableColumnInput = {
        id: uuid(),
        col_def: {
          key: "datasetRecordingsTableColumn.colDef",
          display_name: "Column Definition",
          id: uuid(),
          values: [{ id: uuid(), value: params.colDef }],
        },
        dataset_recordings_table: recordingsTable.id,
        date_created: new Date().toISOString(),
      };

      return {
        onEnqueue: () => {
          const columnOrderId = uuid();

          // Create cache record for column order
          writeCacheFragment({
            __typename: "DatasetRecordingsTableColumnOrder",
            id: columnOrderId,
            data: {
              __typename: "DatasetRecordingsTableColumnOrder",
              id: columnOrderId,
              dateCreated: createColumnInput.date_created,
              datasetRecordingsTableColumnId:
                createColumnInput.dataset_recordings_table,
              tenantId: currentTenant.id,
              value: recordingsTable.datasetRecordingsTableColumns.nodes.length,
              column: {
                __typename: "DatasetRecordingsTableColumn",
                id: createColumnInput.id,
              },
              tenant: {
                __typename: "Tenant",
                id: currentTenant.id,
              },
              project: {
                __typename: "Project",
                id: project.id,
              },
              projectId: project.id,
            },
          });

          // Create cache record for new column
          writeCacheFragment({
            __typename: "DatasetRecordingsTableColumn",
            id: createColumnInput.id,
            data: {
              __typename: "DatasetRecordingsTableColumn",
              id: createColumnInput.id,
              datasetRecordingsTableId:
                createColumnInput.dataset_recordings_table,
              colDefId: createColumnInput.col_def.id,
              dateCreated: createColumnInput.date_created,
              dateAssignmentEnded: null,
              tenantId: currentTenant.id,
              pinned: false,
              width: RECORDINGS_GRID_DEFAULT_COLUMN_WIDTH,
              metadata: null,
              identifierPosition: null,
              recordingsGroupFiles: {
                __typename: "RecordingGroupFilesConnection",
                nodes: [],
              },
              datasetRecordingsTable: {
                __typename: "DatasetRecordingsTable",
                id: createColumnInput.dataset_recordings_table,
              },
              activeColDef: createColumnInput.col_def.values[0].value,
              activeOrder:
                recordingsTable.datasetRecordingsTableColumns.nodes.length,
              orders: {
                __typename: "DatasetRecordingsTableColumnOrdersConnection",
                nodes: [
                  {
                    __typename: "DatasetRecordingsTableColumnOrder",
                    id: columnOrderId,
                  },
                ],
              },
              tenant: {
                __typename: "Tenant",
                id: currentTenant.id,
              },
              project: {
                __typename: "Project",
                id: project.id,
              },
              projectId: project.id,
            },
          });

          // Add column to the recordings table cache
          updateCacheFragment({
            __typename: "DatasetRecordingsTable",
            id: createColumnInput.dataset_recordings_table,
            update: (data) => {
              const newData = cloneDeep(data);

              if (newData.activeColumns === undefined) {
                newData.activeColumns = {
                  __typename: "DatasetRecordingsTableColumnsConnection",
                  nodes: [],
                };
              }

              newData.activeColumns.nodes.push({
                __typename: "DatasetRecordingsTableColumn",
                id: createColumnInput.id,
              });

              return newData;
            },
          });
        },

        onDequeue: async () =>
          await createDatasetRecordingsTableColumnDjango(createColumnInput),
      };
    },
    [
      createDatasetRecordingsTableColumnDjango,
      currentTenant.id,
      project.id,
      recordingsTable.datasetRecordingsTableColumns.nodes.length,
      recordingsTable.id,
    ],
  );

  const isDisabled = datasetMode.type !== "current" || !hasEditPermission;

  return {
    getActionDef,
    isDisabled,
  };
};
