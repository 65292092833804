/** @jsxImportSource @emotion/react */
import { EuiText, EuiCallOut } from "@inscopix/ideas-eui";
import {
  ToolCroppingFrameParam,
  ToolParamsGridRowDatum,
} from "../ToolParamsGrid.types";
import { useProjectFilesStore } from "stores/project-files/ProjectFilesManager";
import { useToolParamsGridContext } from "../ToolParamsGridProvider";
import { isToolPathParam } from "../ToolParamsGrid.helpers";
import { captureException } from "@sentry/react";
import { CallOutError } from "components/CallOutError/CallOutError";
import { ModalToolCroppingFrameParamWrapper } from "./ModalToolCroppingFrameParamWrapper";
import {
  CroppingCoordinates,
  CroppingFrameSelectorProps,
} from "components/CroppingFrameSelector/CroppingFrameSelector";
import { ModalToolCroppingFrameParamWithFile } from "./ModalToolCroppingFrameParamWithFile";
import { ModalToolCroppingFrameParamNoSourceFile } from "./ModalToolCroppingFrameParamNoSourceFile";
import { FILE_FORMATS_BY_KEY } from "types/FileFormats";
import { stringifyCroppingCoordinates } from "components/CroppingFrameSelector/CroppingFrameSelector.helpers";
import { useToolParamsGridRowDataContext } from "../ToolParamsGridRowDataProvider";
import assert from "assert";
import { useProjectPermissionStore } from "pages/project/ProjectPermissionProvider";
import { coalesceToStringArray } from "utils/coalesceToStringArray";

export type ModalToolCroppingFrameParamProps = {
  toolParam: ToolCroppingFrameParam;
  rowId: ToolParamsGridRowDatum["id"];
  onClose: () => void;
} & Pick<
  CroppingFrameSelectorProps,
  "initialCroppingCoordinates" | "isInvalidInitialCoordinates"
>;

/**
 * A component for editing any `ToolCroppingFrameParam` grid cells
 */
export const ModalToolCroppingFrameParam = ({
  rowId,
  toolParam,
  initialCroppingCoordinates,
  isInvalidInitialCoordinates,
  onClose,
}: ModalToolCroppingFrameParamProps) => {
  const data = useToolParamsGridRowDataContext((s) =>
    s.rowData.find((row) => row.id === rowId),
  );
  assert(
    data !== undefined,
    "Expected row data to be defined in ModalToolCroppingFrameParam",
  );

  const files = useProjectFilesStore((s) => s.files);
  const hasEditPermission = useProjectPermissionStore(
    (s) => s.permissions["edit"],
  );

  const { toolSpec } = useToolParamsGridContext();
  const updateRowDatum = useToolParamsGridRowDataContext(
    (s) => s.updateRowDatum,
  );

  const sourceFileParamKey = toolParam.type.source_file.key;

  const sourceFileParam = toolSpec.params.find(
    (param) => param.key === sourceFileParamKey,
  );

  // handle invalid linked param
  if (sourceFileParam === undefined) {
    captureException(
      "Source file param key points to param that does not exist",
      {
        extra: {
          sourceFileParamKey,
        },
      },
    );
    return (
      <ModalToolCroppingFrameParamWrapper onClose={onClose}>
        <CallOutError />
      </ModalToolCroppingFrameParamWrapper>
    );
  }

  if (!isToolPathParam(sourceFileParam)) {
    captureException(
      "Unexpected param type for ToolCroppingFrameParam source file",
      {
        extra: {
          sourceFileParam,
        },
      },
    );
    return (
      <ModalToolCroppingFrameParamWrapper onClose={onClose}>
        <CallOutError />
      </ModalToolCroppingFrameParamWrapper>
    );
  }

  // multiple files not supported for cropping frame params
  const sourceFileId = coalesceToStringArray(
    data.params[sourceFileParamKey],
  )[0];

  const onAcceptCoordinates = (selectedCoordinates: CroppingCoordinates) => {
    const newParamValue = stringifyCroppingCoordinates(selectedCoordinates);

    updateRowDatum(data.id, {
      params: {
        [toolParam.key]: newParamValue,
      },
    });
    onClose();
  };

  const readOnly = data.task_id !== undefined || !hasEditPermission;

  if (sourceFileId === undefined) {
    return (
      <ModalToolCroppingFrameParamNoSourceFile
        rowDatum={data}
        initialCroppingCoordinates={initialCroppingCoordinates}
        isInvalidInitialCoordinates={isInvalidInitialCoordinates}
        onAcceptCoordinates={onAcceptCoordinates}
        sourceFileParam={sourceFileParam}
        onClose={onClose}
        readOnly={readOnly}
      />
    );
  }

  const sourceFile = files.find(({ id }) => id === sourceFileId);

  if (sourceFile === undefined) {
    return (
      <ModalToolCroppingFrameParamWrapper onClose={onClose}>
        <EuiCallOut>
          <EuiText>
            <p>File not found.</p>
            <p>
              The selected file could not be located. It may have been deleted.{" "}
            </p>
          </EuiText>
        </EuiCallOut>
      </ModalToolCroppingFrameParamWrapper>
    );
  }

  const previewFormat =
    FILE_FORMATS_BY_KEY[toolParam.type.source_file.file_format]?.id;

  if (previewFormat === undefined) {
    return (
      <ModalToolCroppingFrameParamWrapper onClose={onClose}>
        <CallOutError>Improper tool configuration.</CallOutError>
      </ModalToolCroppingFrameParamWrapper>
    );
  }

  return (
    <ModalToolCroppingFrameParamWrapper onClose={onClose} readOnly={readOnly}>
      <ModalToolCroppingFrameParamWithFile
        onCancel={onClose}
        initialCroppingCoordinates={initialCroppingCoordinates}
        isInvalidInitialCoordinates={isInvalidInitialCoordinates}
        file={sourceFile}
        previewType={{
          fileFormat: previewFormat,
        }}
        onAcceptCoordinates={onAcceptCoordinates}
        readOnly={readOnly}
      />
    </ModalToolCroppingFrameParamWrapper>
  );
};
