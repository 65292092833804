import { EuiPageTemplate, EuiSkeletonText } from "@inscopix/ideas-eui";
import { CallOutError } from "components/CallOutError/CallOutError";
import { useAdminProjects } from "components/ProjectsTable/useAdminProjects";
import { Project } from "graphql/_Types";
import { PageProjectAdminRestricted } from "./PageProjectAdminRestricted";

export interface Project404Props {
  projectKey: Project["key"];
}
export const PageProject404 = ({ projectKey }: Project404Props) => {
  const { isLoading, isError, data } = useAdminProjects({ key: projectKey });

  if (isLoading) {
    return <EuiSkeletonText lines={5} />;
  }

  const project = data?.length === 1 ? data[0] : undefined;

  if (isError || project === undefined) {
    return (
      <EuiPageTemplate>
        <CallOutError>
          The project you are trying to access does not exist or you do not have
          access to it.
        </CallOutError>
      </EuiPageTemplate>
    );
  }

  return <PageProjectAdminRestricted project={project} />;
};
