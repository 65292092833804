import { ApplicationUser, Project } from "graphql/_Types";
import {
  UserAccessLevel,
  USER_ACCESS_LEVELS_BY_ID,
  USER_ACCESS_LEVELS_BY_KEY,
  USER_PERMISSIONS,
} from "types/UserAccessLevels";
import Axios, { AxiosResponse } from "axios";
import { getEnvVar } from "ideas.env";
import { cloneDeep, fill, zipObject } from "lodash";
import { getRequestHeaders } from "utils/getRequestHeaders";
import { libraryProjectPatch } from "hooks/useLibraryProjectPatchDjango";
import { updateCacheFragment } from "utils/cache-fragments";
import { ProjectSharingInviteStatus } from "types/constants";

export type UserProjectAccessDjango = {
  date_granted: string;
  download: boolean;
  edit: boolean;
  execute: boolean;
  grant_access: boolean;
  granted_by: string;
  id: string;
  project: Project["id"];
  upload: boolean;
  user: ApplicationUser["id"];
  view: boolean;
  access_level: UserAccessLevel["id"];
};

type UserAccessLevelId = UserAccessLevel["id"];

type CreateInternalUserAccessInput = Pick<
  UserProjectAccessDjango,
  | "project"
  | "user"
  | "edit"
  | "download"
  | "execute"
  | "grant_access"
  | "upload"
  | "view"
>;

type CreateInternalUserAccessResponse = CreateInternalUserAccessInput & {
  id: UserProjectAccessDjango["id"];
  date_granted: UserProjectAccessDjango["date_granted"];
  granted_by: ApplicationUser["id"];
};

const _createInternalUserAccess = async ({
  baseUrl,
  projectId,
  users,
}: {
  baseUrl: string;
  projectId: Project["id"];
  users: {
    userId: ApplicationUser["id"];
    userAccessLevel: UserAccessLevel["id"];
  }[];
}) => {
  const headers = await getRequestHeaders();
  const requests = users.map((user) => {
    const body: CreateInternalUserAccessInput = {
      project: projectId,
      user: user.userId,
      download: false,
      edit: false,
      execute: false,
      grant_access: false,
      upload: false,
      view: false,
    };

    const permissions =
      USER_ACCESS_LEVELS_BY_ID[user.userAccessLevel].permissions;
    permissions.forEach((p) => {
      body[p] = true;
    });

    return Axios.post<
      CreateInternalUserAccessResponse,
      AxiosResponse<CreateInternalUserAccessResponse>,
      CreateInternalUserAccessInput
    >(baseUrl, body, { headers });
  });

  await Axios.all(requests);
};

const _revokeInternalUserAccess = async ({
  baseUrl,
  accessId,
  projectId,
}: {
  baseUrl: string;
  projectId: Project["id"];
  accessId: UserProjectAccessDjango["id"];
}) => {
  const url = `${baseUrl}${accessId}/`;
  const headers = await getRequestHeaders();
  return Axios.delete(url, {
    headers,
    data: {
      project: projectId,
    },
  });
};

const _updateInternalUserAccessLevelDjango = async ({
  accessId,
  baseUrl,
  projectId,
  accessLevel,
  userId,
}: {
  baseUrl: string;
  projectId: Project["id"];
  accessId: UserProjectAccessDjango["id"];
  userId: ApplicationUser["id"];
  accessLevel: UserAccessLevelId;
}) => {
  const url = `${baseUrl}${accessId}/`;

  const permissionKeys = USER_ACCESS_LEVELS_BY_ID[accessLevel].permissions;

  // Create an object with all permissions set to false
  const permissionObj = zipObject(
    USER_PERMISSIONS,
    fill(Array(USER_PERMISSIONS.length), false),
  );

  // Set the permissions that are required by the access level to true
  permissionKeys.forEach((key) => {
    permissionObj[key] = true;
  });

  const response = await Axios.patch(
    url,
    {
      project: projectId,
      user: userId,
      ...permissionObj,
    },
    {
      headers: await getRequestHeaders(),
    },
  );

  return response;
};

export type ProjectSharingInviteDjango = {
  date_accepted: string | null;
  date_created: string;
  date_revoked: string | null;
  email: string;
  id: string;
  invitee: ApplicationUser["id"] | null;
  inviter: ApplicationUser["id"];
  project: Project["id"];
  status: ProjectSharingInviteStatus;
  project_access_level: UserAccessLevel["id"];
};

const _revokeExternalProjectAccessInvitation = async ({
  baseUrl,
  invitationId,
  projectId,
}: {
  baseUrl: string;
  invitationId: ProjectSharingInviteDjango["id"];
  projectId: Project["id"];
}) => {
  const url = `${baseUrl}revoke/${invitationId}/`;

  const headers = await getRequestHeaders();
  const response = await Axios.post<ProjectSharingInviteDjango>(
    url,
    { project: projectId },
    {
      headers,
    },
  );
  return response.data;
};

const _resendExternalProjectAccessInvitation = async ({
  baseUrl,
  invitationId,
  projectId,
}: {
  baseUrl: string;
  invitationId: ProjectSharingInviteDjango["id"];
  projectId: Project["id"];
}) => {
  const url = `${baseUrl}resend/${invitationId}/`;

  const headers = await getRequestHeaders();
  const response = await Axios.post<ProjectSharingInviteDjango>(
    url,
    { project: projectId },
    {
      headers,
    },
  );
  return response.data;
};

/**
 * Updates the project access level of an existing invitation.
 * @param options
 * @returns The updated invitation.
 */
const _updateExternalProjectSharingInvitation = async ({
  baseUrl,
  invitationId,
  projectAccessLevel,
  projectId,
}: {
  baseUrl: string;
  invitationId: ProjectSharingInviteDjango["id"];
  projectAccessLevel: UserAccessLevel["id"];
  projectId: Project["id"];
}) => {
  const url = `${baseUrl}update_access_level/${invitationId}/`;
  const headers = await getRequestHeaders();
  const body = { project_access_level: projectAccessLevel, project: projectId };
  const response = await Axios.patch<ProjectSharingInviteDjango>(url, body, {
    headers,
  });
  return response.data;
};

const _getInternalUsersWithProjectAccess = async ({
  projectId,
  baseUrl,
}: {
  baseUrl: string;
  projectId: Project["id"];
}) => {
  const headers = await getRequestHeaders();
  const { data } = await Axios.get<UserProjectAccessDjango[]>(
    `${baseUrl}?project=${projectId}`,
    { headers },
  );

  return data;
};

const _getProjectSharingExternalInvitations = async ({
  baseUrl,
  projectId,
}: {
  baseUrl: string;
  projectId: Project["id"];
}) => {
  const params = { project: projectId };
  const headers = await getRequestHeaders();
  const response = await Axios.get<
    {
      date_accepted: string | null;
      date_created: string;
      date_revoked: string | null;
      email: string;
      id: string;
      invitee: ApplicationUser["id"] | null;
      inviter: ApplicationUser["id"];
      project: Project["id"];
      status: ProjectSharingInviteStatus;
      project_access_level: UserAccessLevel["id"];
    }[]
  >(baseUrl, {
    headers,
    params,
  });
  /**
   * TODO MOVE TO BACKEND
   */
  // do not show revoked invitations
  return response.data.filter(
    (item) => item.status !== ProjectSharingInviteStatus.REVOKED,
  );
};

const _sendExternalProjectAccessInvitation = async ({
  baseUrl,
  email,
  projectId,
}: {
  baseUrl: string;
  projectId: string;
  email: string;
}) => {
  const headers = await getRequestHeaders();
  const response = await Axios.post<{
    date_accepted: string | null;
    date_created: string;
    date_revoked: string | null;
    email: string;
    id: string;
    invitee: ApplicationUser["id"] | null;
    inviter: ApplicationUser["id"];
    project_id: Project["id"];
    status: ProjectSharingInviteStatus;
    project_access_level: UserAccessLevel["id"];
  }>(
    baseUrl,
    {
      email,
      project: projectId,
      project_access_level: USER_ACCESS_LEVELS_BY_KEY["COPIER"].id,
    },
    {
      headers,
    },
  );
  return response.data;
};

const userFunctions = {
  /**
   * REGULAR USER MANAGEMENT EXTERNAL ACCESS
   */

  externalAccess: {
    sendExternalProjectAccessInvitation: (
      params: Omit<
        Parameters<typeof _sendExternalProjectAccessInvitation>[0],
        "baseUrl"
      >,
    ) =>
      _sendExternalProjectAccessInvitation({
        baseUrl: getEnvVar("URL_LIBRARY_PROJECT_SHARE"),
        ...params,
      }),
    revokeExternalProjectAccessInvitation: (
      params: Omit<
        Parameters<typeof _revokeExternalProjectAccessInvitation>[0],
        "baseUrl"
      >,
    ) =>
      _revokeExternalProjectAccessInvitation({
        baseUrl: getEnvVar("URL_LIBRARY_PROJECT_SHARE"),
        ...params,
      }),

    resendExternalProjectAccessInvitation: (
      params: Omit<
        Parameters<typeof _resendExternalProjectAccessInvitation>[0],
        "baseUrl"
      >,
    ) =>
      _resendExternalProjectAccessInvitation({
        baseUrl: getEnvVar("URL_LIBRARY_PROJECT_SHARE"),
        ...params,
      }),

    updateExternalProjectSharingInvitation: (
      params: Omit<
        Parameters<typeof _updateExternalProjectSharingInvitation>[0],
        "baseUrl"
      >,
    ) =>
      _updateExternalProjectSharingInvitation({
        baseUrl: getEnvVar("URL_LIBRARY_PROJECT_SHARE"),
        ...params,
      }),

    getProjectSharingExternalInvitations: (
      params: Omit<
        Parameters<typeof _getProjectSharingExternalInvitations>[0],
        "baseUrl"
      >,
    ) =>
      _getProjectSharingExternalInvitations({
        baseUrl: getEnvVar("URL_LIBRARY_PROJECT_SHARE"),
        ...params,
      }),
  },
  /**
   * REGULAR USER MANAGEMENT INTERNAL ACCESS
   */
  internalAccess: {
    getInternalUsersWithProjectAccess: (
      params: Omit<
        Parameters<typeof _getInternalUsersWithProjectAccess>[0],
        "baseUrl"
      >,
    ) =>
      _getInternalUsersWithProjectAccess({
        baseUrl: getEnvVar("URL_LIBRARY_PROJECT_ACCESS"),
        ...params,
      }),
    createInternalUserAccess: (
      params: Omit<Parameters<typeof _createInternalUserAccess>[0], "baseUrl">,
    ) => {
      return _createInternalUserAccess({
        baseUrl: getEnvVar("URL_LIBRARY_PROJECT_ACCESS"),
        ...params,
      });
    },
    revokeInternalUserAccess: (
      params: Omit<Parameters<typeof _revokeInternalUserAccess>[0], "baseUrl">,
    ) => {
      return _revokeInternalUserAccess({
        baseUrl: getEnvVar("URL_LIBRARY_PROJECT_ACCESS"),
        ...params,
      });
    },

    updateInternalUserAccessLevel: (
      params: Omit<
        Parameters<typeof _updateInternalUserAccessLevelDjango>[0],
        "baseUrl"
      >,
    ) => {
      return _updateInternalUserAccessLevelDjango({
        baseUrl: getEnvVar("URL_LIBRARY_PROJECT_ACCESS"),
        ...params,
      });
    },

    updateInternalDefaultAccessLevel: async ({
      projectId,
      defaultUserAccessLevel,
    }: {
      projectId: Project["id"];
      defaultUserAccessLevel: UserAccessLevelId;
    }): Promise<{ defaultUserAccessLevel: UserAccessLevelId }> => {
      const res = await libraryProjectPatch(projectId, {
        defaultUserAccessLevel,
      });

      updateCacheFragment({
        __typename: "Project",
        id: projectId,
        update: (data) => {
          const newData = cloneDeep(data);
          newData.defaultUserAccessLevel = res.defaultUserAccessLevel;
          return newData;
        },
      });
      return res;
    },
  },
};

const adminFunctions = {
  /**
   * ADMIN MANAGEMENT EXTERNAL ACCESS
   */

  externalAccess: {
    getProjectSharingExternalInvitations: (
      params: Omit<
        Parameters<typeof _getProjectSharingExternalInvitations>[0],
        "baseUrl"
      >,
    ) =>
      _getProjectSharingExternalInvitations({
        baseUrl: getEnvVar("URL_LIBRARY_PROJECT_SHARE_ADMIN_MANAGE"),
        ...params,
      }),

    sendExternalProjectAccessInvitation: (
      params: Omit<
        Parameters<typeof _sendExternalProjectAccessInvitation>[0],
        "baseUrl"
      >,
    ) =>
      _sendExternalProjectAccessInvitation({
        baseUrl: getEnvVar("URL_LIBRARY_PROJECT_SHARE_ADMIN_MANAGE"),
        ...params,
      }),
    revokeExternalProjectAccessInvitation: (
      params: Omit<
        Parameters<typeof _revokeExternalProjectAccessInvitation>[0],
        "baseUrl"
      >,
    ) =>
      _revokeExternalProjectAccessInvitation({
        baseUrl: getEnvVar("URL_LIBRARY_PROJECT_SHARE_ADMIN_MANAGE"),
        ...params,
      }),

    resendExternalProjectAccessInvitation: (
      params: Omit<
        Parameters<typeof _resendExternalProjectAccessInvitation>[0],
        "baseUrl"
      >,
    ) =>
      _resendExternalProjectAccessInvitation({
        baseUrl: getEnvVar("URL_LIBRARY_PROJECT_SHARE_ADMIN_MANAGE"),
        ...params,
      }),

    updateExternalProjectSharingInvitation: (
      params: Omit<
        Parameters<typeof _updateExternalProjectSharingInvitation>[0],
        "baseUrl"
      >,
    ) =>
      _updateExternalProjectSharingInvitation({
        baseUrl: getEnvVar("URL_LIBRARY_PROJECT_SHARE_ADMIN_MANAGE"),
        ...params,
      }),
  },

  /**
   * ADMIN MANAGEMENT INTERNAL ACCESS
   */

  internalAccess: {
    getInternalUsersWithProjectAccess: (
      params: Omit<
        Parameters<typeof _getInternalUsersWithProjectAccess>[0],
        "baseUrl"
      >,
    ) =>
      _getInternalUsersWithProjectAccess({
        baseUrl: getEnvVar("URL_LIBRARY_PROJECT_ACCESS_ADMIN_MANAGE"),
        ...params,
      }),

    createInternalUserAccess: (
      params: Omit<Parameters<typeof _createInternalUserAccess>[0], "baseUrl">,
    ) => {
      return _createInternalUserAccess({
        baseUrl: getEnvVar("URL_LIBRARY_PROJECT_ACCESS_ADMIN_MANAGE"),
        ...params,
      });
    },

    revokeInternalUserAccess: (
      params: Omit<Parameters<typeof _revokeInternalUserAccess>[0], "baseUrl">,
    ) => {
      return _revokeInternalUserAccess({
        baseUrl: getEnvVar("URL_LIBRARY_PROJECT_ACCESS_ADMIN_MANAGE"),
        ...params,
      });
    },

    updateInternalUserAccessLevel: (
      params: Omit<
        Parameters<typeof _updateInternalUserAccessLevelDjango>[0],
        "baseUrl"
      >,
    ) => {
      return _updateInternalUserAccessLevelDjango({
        baseUrl: getEnvVar("URL_LIBRARY_PROJECT_ACCESS_ADMIN_MANAGE"),
        ...params,
      });
    },
    updateInternalDefaultAccessLevel: async ({
      projectId,
      defaultUserAccessLevel,
    }: {
      projectId: Project["id"];
      defaultUserAccessLevel: UserAccessLevelId;
    }) => {
      const headers = await getRequestHeaders();
      const res = await Axios.patch<
        { default_user_access_level: UserAccessLevelId },
        { data: { default_user_access_level: UserAccessLevelId } }
      >(
        getEnvVar("URL_LIBRARY_PROJECT_ADMIN_MANAGE") + projectId + "/",
        {
          default_user_access_level: defaultUserAccessLevel,
        },
        { headers },
      );

      return { defaultUserAccessLevel: res.data.default_user_access_level };
    },
  },
};

export const permissions = {
  userFunctions,
  adminFunctions,
};
