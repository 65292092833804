import { EuiLink } from "@inscopix/ideas-eui";
import assert from "assert";
import { useRouteMapContext } from "providers/RouteMapProvider/RouteMapProvider";
import { memo } from "react";
import { USER_ACCESS_LEVELS_BY_KEY } from "types/UserAccessLevels";
import { ProjectsGridCellRendererParams } from "./ProjectsGrid";

type ProjectsGridCellRendererNameProps = ProjectsGridCellRendererParams;

export const ProjectsGridCellRendererName = memo(
  function ProjectsGridCellRendererName({
    data,
  }: ProjectsGridCellRendererNameProps) {
    assert(
      data !== undefined,
      "Expected data to be defined in ProjectsGridCellRendererName",
    );

    const routeMap = useRouteMapContext((s) => s.routeMap);
    if (
      // org admins are given temp view permissions on restricted projects in the org admin view but they still can't route to and see the a restricted
      // under regular permissions of the project route, so this accounts for that to make sure we don't link org admins to restricted projects
      data.userPermissions.role !==
        USER_ACCESS_LEVELS_BY_KEY["RESTRICTED"].id &&
      data.userPermissions.view
    ) {
      return (
        <EuiLink
          to={
            routeMap.PROJECT.dynamicPath({
              projectKey: data.key,
              tenantKey: data.tenantKey,
            }).path
          }
        >
          {data.name}
        </EuiLink>
      );
    }
    return <>{data.name}</>;
  },
);
