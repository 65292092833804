import { useCallback, useMemo } from "react";
import { useToolParamsGridContext } from "../ToolParamsGridProvider";
import { useValueValidator } from "components/ToolParamsGrid/ToolParamsGridValueValidatorContext";
import { EuiText } from "@inscopix/ideas-eui";
import { isEqual, isUndefined } from "lodash";

export const useStartTaskToolTipContent = (rowId: string) => {
  const { toolSpec } = useToolParamsGridContext();
  const errorsByRowId = useValueValidator(
    (s) => s.errorsByRowId[rowId],
    isEqual,
  );

  const rowErrorsByParamKey = useValueValidator(
    (s) => s.errorsByRowId?.[rowId] ?? {},
    isEqual,
  );
  const isRowValid = Object.values(rowErrorsByParamKey).every(isUndefined);

  /**
   * Gets the name of a tool param by its key
   * @param paramKey
   * @returns The tool param name
   */
  const getParamName = useCallback(
    (paramKey: string) => {
      const paramNamesByParamKey: Record<string, string> = {};

      toolSpec.params.forEach(({ key, name }) => {
        paramNamesByParamKey[key] = name;
      });

      return paramNamesByParamKey[paramKey];
    },
    [toolSpec.params],
  );

  /**
   * An unordered list of param names with invalid values
   */
  const toolTipContent = useMemo(() => {
    // If the row is valid, there is no need to check for any errors
    if (isRowValid) {
      return null;
    }

    const errorsByParamKey = errorsByRowId;
    const paramNames = Object.entries(errorsByParamKey ?? {})
      .filter(([, error]) => error !== undefined)
      .map(([paramKey]) => paramKey)
      .map(getParamName);

    return (
      <>
        <EuiText>
          <h5>Invalid entries:</h5>
        </EuiText>
        <ul>
          {paramNames.map((paramName) => (
            <li key={paramName}>• {paramName}</li>
          ))}
        </ul>
      </>
    );
  }, [errorsByRowId, getParamName, isRowValid]);

  return toolTipContent;
};
