import { EuiButtonIcon } from "@inscopix/ideas-eui";
import { useQueryClient } from "@tanstack/react-query";
import { Tooltip } from "components/Tooltip/Tooltip";
import { queryKeys } from "django/queryKeys";
import { Project } from "graphql/_Types";
import { libraryPinnedProjectDjango } from "hooks/useLibraryPinnedProjectDjango";
import { useState } from "react";
import { addUtilityToastFailure } from "utils/addUtilityToastFailure";

export interface ButtonProjectPinProps {
  projectId: Project["id"];
  pinned: boolean;
}

export const ButtonProjectPin = ({
  pinned,
  projectId,
}: ButtonProjectPinProps) => {
  const queryClient = useQueryClient();
  const [loading, setLoading] = useState(false);

  const onPin = async () => {
    setLoading(true);
    try {
      await libraryPinnedProjectDjango.pin({ project: projectId });
      await queryClient.invalidateQueries({
        queryKey: queryKeys.getAllProjects(),
        exact: false,
      });
    } catch (err) {
      addUtilityToastFailure("Failed to pin project");
    }
    setLoading(false);
  };

  const onUnpin = async () => {
    setLoading(true);
    try {
      await libraryPinnedProjectDjango.unpin({ project: projectId });
      await queryClient.invalidateQueries({
        queryKey: queryKeys.getAllProjects(),
        exact: false,
      });
    } catch (err) {
      addUtilityToastFailure("Failed to unpin project");
    }
    setLoading(false);
  };
  return (
    <Tooltip
      content={pinned ? "Unpin project" : "Pin project to top"}
      delayShow={500}
    >
      <EuiButtonIcon
        aria-label={pinned ? "Unpin project" : "Pin project"}
        onClick={pinned ? () => void onUnpin() : () => void onPin()}
        isLoading={loading}
        iconType={pinned ? "starFilled" : "starEmpty"}
        iconSize={loading ? "m" : "l"}
        color={"text"}
        size="s"
      />
    </Tooltip>
  );
};
