import { EuiFlexGroup, EuiLink } from "@inscopix/ideas-eui";
import { NotificationsFeedBodyQuery } from "graphql/_Types";
import { useRouteMapContext } from "providers/RouteMapProvider/RouteMapProvider";
import { NotificationAlert } from "./NotificationAlert";
import { useCallback, useState } from "react";
import { TRegion } from "providers/RegionsProvider";
import { FileBadge } from "components/FileBadge/FileBadge";
import { RegionBadge } from "components/RegionBadge/RegionBadge";
import { isUndefined } from "lodash";
import assert from "assert";
import { isNonNull } from "utils/isNonNull";
import { ModalTaskLogsFileProcessing } from "./ModalTaskLogsFileProcessing";
import { useFlyoutContext } from "providers/FlyoutProvider/FlyoutProvider";

export interface NotificationAlertFileProcessingProps {
  data: NonNullable<
    NotificationsFeedBodyQuery["notifications"]
  >["nodes"][number] & { region?: TRegion };
  onNotificationRead: (
    notificationId: string,
    region?: TRegion,
    refetchNotifications?: () => void,
  ) => void;
  refetchNotifications: () => void;
}

/** Component that renders a notification that a project was shared with the user */
export const NotificationAlertFileProcessing = ({
  data,
  onNotificationRead,
  refetchNotifications,
}: NotificationAlertFileProcessingProps) => {
  const { dateCreated, hasSeen, id: notificationId } = data;
  const attrs = data.fileProcessingNotification;
  assert(isNonNull(attrs));
  const { file } = attrs;
  assert(isNonNull(file));
  const project = file.project;
  assert(isNonNull(project));

  const routeMap = useRouteMapContext((s) => s.routeMap);
  const { closeFlyout } = useFlyoutContext();
  const [showModalTaskLogs, setShowModalTaskLogs] = useState(false);

  const onNotificationReadMemoized = useCallback(() => {
    if (!hasSeen) {
      onNotificationRead(notificationId, data.region, refetchNotifications);
    }
  }, [
    hasSeen,
    notificationId,
    onNotificationRead,
    refetchNotifications,
    data.region,
  ]);

  const actionProjectLink = (() => {
    if (project.tenant === null) {
      return null;
    }

    const tenantKey = project.tenant.key;
    const projectPath = routeMap["PROJECT"].dynamicPath({
      projectKey: project.key,
      tenantKey: tenantKey,
    }).path;

    const handleClick = () => {
      closeFlyout();
      onNotificationReadMemoized();
    };

    return isUndefined(data.region) ? (
      <EuiLink to={projectPath} onClick={() => void handleClick()}>
        Go to project
      </EuiLink>
    ) : (
      <EuiLink
        href={`${data.region.url}${projectPath}`}
        target="_blank"
        onClick={() => void handleClick()}
      >
        Go to project <RegionBadge regionKey={data.region.key} />
      </EuiLink>
    );
  })();

  return (
    <>
      {showModalTaskLogs && (
        <ModalTaskLogsFileProcessing
          fileId={file.id}
          onClose={() => setShowModalTaskLogs(false)}
        />
      )}
      <NotificationAlert
        dateCreated={dateCreated}
        hasSeen={hasSeen}
        title="Failed to process file"
        onNotificationRead={onNotificationReadMemoized}
        text={
          <span>
            An error occurred when reading metadata and generating previews for
            the file <FileBadge drsFile={file} /> in the project{" "}
            <strong>{project.name}</strong>
          </span>
        }
        action={
          <EuiFlexGroup>
            {actionProjectLink}
            {
              <EuiLink onClick={() => setShowModalTaskLogs(true)}>
                View log
              </EuiLink>
            }
          </EuiFlexGroup>
        }
      />
    </>
  );
};
