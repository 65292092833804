/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { ModalSelectDataset } from "components/ButtonProjectUpload/ModalSelectDataset";
import {
  DropZoneBasic,
  DropZoneBasicProps,
} from "components/DropZoneBasic/DropZoneBasic";
import { File as DrsFile } from "graphql/_Types";
import { useUploadFile } from "hooks/useUploadFile";
import { useProjectDataContext } from "pages/project/ProjectDataProvider";
import { useState } from "react";
import { addUtilityToastSuccess } from "utils/addUtilityToastSuccess";
import { uuid } from "utils/uuid";

export type ProjectDropZoneProps = Pick<DropZoneBasicProps, "isClickable">;
export const ProjectDropZone = ({ isClickable }: ProjectDropZoneProps) => {
  const { project } = useProjectDataContext();
  const { writeCache, enqueueUpload } = useUploadFile();

  const [selectedFiles, setSelectedFiles] = useState<File[]>();

  const handleUpload = (files: File[], datasetId: DrsFile["datasetId"]) => {
    files.forEach((file) => {
      const dateCreated = new Date().toISOString();
      const fileId = uuid();

      const params = {
        id: fileId,
        dateCreated,
        datasetId: datasetId,
        name: file.name,
        project,
        tenantId: project.tenantId,
        size: String(file.size),
      };

      writeCache(params);

      void enqueueUpload({ ...params, blob: file });
    });
    addUtilityToastSuccess(
      `Successfully queued ${files.length} files for upload.`,
    );
  };

  return (
    <>
      <DropZoneBasic
        isClickable={isClickable}
        onSelectFiles={(files) => setSelectedFiles(Array.from(files))}
        css={css`
          height: 100%;
          width: 100%;
        `}
      />
      {selectedFiles && (
        <ModalSelectDataset
          onSelectDataset={(datasetId) => {
            handleUpload(selectedFiles, datasetId);
            setSelectedFiles(undefined);
          }}
          onCancel={() => setSelectedFiles(undefined)}
        />
      )}
    </>
  );
};
