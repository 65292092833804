/** @jsxImportSource @emotion/react */
import { useCallback, useMemo, useState } from "react";
import assert from "assert";
import {
  EuiBasicTable,
  EuiBasicTableColumn,
  EuiButton,
  EuiButtonEmpty,
  EuiButtonIcon,
  EuiCallOut,
  EuiEmptyPrompt,
  EuiFieldText,
  EuiFlexGroup,
  EuiFlexItem,
  EuiFormRow,
  EuiIcon,
  EuiModal,
  EuiModalBody,
  EuiModalFooter,
  EuiModalHeader,
  EuiModalHeaderTitle,
  EuiText,
} from "@inscopix/ideas-eui";
import { css } from "@emotion/react";
import { chain, cloneDeep, remove } from "lodash";
import { captureException } from "@sentry/react";
import { File as DrsFile, FileSource } from "graphql/_Types";
import {} from "@apollo/client";
import { FILE_TYPES_BY_ID, FileType } from "../../types/FileTypes";
import { addUtilityToastFailure } from "../../utils/addUtilityToastFailure";
import { useCreateSeriesFromDrsFilesDjango } from "../../hooks/useCreateSeriesFromDrsFilesDjango";
import { uuid } from "../../utils/uuid";
import {
  FileStatus,
  FileUploadStatus,
  ProcessingStatus,
} from "../../types/constants";
import { useTenantContext } from "providers/TenantProvider/TenantProvider";
import { Tooltip } from "../Tooltip/Tooltip";
import { isDefined } from "utils/isDefined";
import { updateCacheFragment, writeCacheFragment } from "utils/cache-fragments";
import { useProjectDataContext } from "pages/project/ProjectDataProvider";
import { useUserContext } from "providers/UserProvider/UserProvider";
import { ModalLoading } from "components/ModalLoading/ModalLoading";
import { useModalCreateSeriesData } from "./useModalCreateSeriesData";
import { ModalError } from "components/ModalError/ModalError";
import { filesize } from "utils/filesize";

export type ModalCreateSeriesFile = Pick<
  DrsFile,
  | "id"
  | "name"
  | "status"
  | "isSeries"
  | "seriesParentId"
  | "fileType"
  | "fileFormat"
  | "fileStructure"
  | "datasetId"
  | "size"
> & { index: number };
export type ModalCreateSeriesProps = {
  onClose: () => void;
  selectedFileIds: DrsFile["id"][];
  onComplete?: (consumedDrsFiles: Pick<DrsFile, "id">[]) => void;
  onError?: (error: Error) => void;
};

const styles = {
  modalBody: css`
    max-height: 80%;
    overflow-y: scroll;
  `,
};

export const validateSeriesFiles = (
  files: Omit<ModalCreateSeriesFile, "index">[],
):
  | { isValid: true; validationError: undefined; seriesType: FileType }
  | { isValid: false; validationError: string } => {
  // if there's less than 2 objects, can't make a series
  if (files.length < 2) {
    return {
      isValid: false,
      validationError: "At least 2 files must be selected to create a series",
    };
  }

  const datasetIds = chain(files)
    .map((file) => file.datasetId)
    .uniq()
    .value();

  if (datasetIds.length > 1) {
    return {
      isValid: false,
      validationError: "All series members must belong to the same dataset",
    };
  }

  // we do not support creating series from series
  if (
    files.some(({ isSeries }) => isSeries) ||
    files.some(({ seriesParentId }) => seriesParentId !== null)
  ) {
    return {
      isValid: false,
      validationError:
        "Creating series from series or series members is not supported",
    };
  }

  // if an object does not have a populated type, it can't be used to create a series (yet)
  const invalidFiles = files.filter(
    (drsFile) =>
      drsFile.status !== FileStatus["AVAILABLE"] || drsFile.fileType === null,
  );

  const filesWithKnownFileType = files
    .map(({ fileType, ...file }) => {
      if (fileType !== null) {
        return { ...file, fileType };
      }

      return undefined;
    })
    .filter(isDefined);

  if (
    invalidFiles.length > 0 ||
    filesWithKnownFileType.length !== files.length
  ) {
    return {
      isValid: false,
      validationError: `File type(s) could not be read for ${invalidFiles
        .map(({ name }) => name)
        .join(",")} - they may need to finish uploading or importing metadata`,
    };
  }

  // all object types must match - set the series type to the first object's type
  const seriesType = FILE_TYPES_BY_ID[filesWithKnownFileType[0].fileType];
  assert(seriesType !== undefined, "Unknown file type");

  // the type must be one that we support creating a series with
  if (!seriesType.series) {
    return {
      isValid: false,
      validationError: `File type ${seriesType.name} is not supported for series`,
    };
  }

  // all objects must have the same type
  if (
    !filesWithKnownFileType.every(({ fileType }) => fileType === seriesType.id)
  ) {
    return {
      isValid: false,
      validationError: "File types do not match, cannot create series",
    };
  }
  return { isValid: true, validationError: undefined, seriesType: seriesType };
};

/**
 * A modal to create a series from a list of selected objects
 */

function ModalCreateSeries({
  onClose,
  selectedFileIds,
  onComplete,
  onError,
}: ModalCreateSeriesProps) {
  const currentUser = useUserContext((s) => s.currentUser);
  const currentTenant = useTenantContext((s) => s.currentTenant);
  const { project } = useProjectDataContext();
  const { createSeriesDjango } = useCreateSeriesFromDrsFilesDjango();

  const {
    loading: loadingFileData,
    files,
    setFiles,
    isOrderedChronologically,
    canOrderChronologically,
    seriesName,
    setSeriesName,
  } = useModalCreateSeriesData(selectedFileIds);

  // set the default series name to the first object's name (sorted alphabetically)
  const [isLoading, setIsLoading] = useState(false);

  /**
   * VALIDATION & FILE ID EXTRACTION
   * We need to determine what kind of series we're making and which files should be submitted to create the series.
   * This also contains a lot of validation logic - most of it should never be seen, but if we somehow end up in an
   * invalid state this will report back an error string to present to the user.
   */

  const createSeries = async (files: ModalCreateSeriesFile[]) => {
    try {
      setIsLoading(true);
      const seriesFile = await createSeriesDjango({
        id: uuid(),
        name: seriesName,
        series_files: files.map(({ id }) => id),
      });

      /* Series members are validated upstream to all belong to the same
         dataset. This will be null if all of the files are not assigned
         to a dataset. */
      const { datasetId } = files[0];

      writeCacheFragment({
        __typename: "File",
        id: seriesFile.id,
        data: {
          __typename: "File",
          ...seriesFile,
          originalName: seriesFile.name,
          name: seriesFile.name,
          tenantId: currentTenant.id,
          userId: currentUser.id,
          dateCreated: new Date().toISOString(),
          dateDeleted: null,
          dateDataDeleted: null,
          dateArchived: null,
          datasetId,
          status: FileStatus.AVAILABLE,
          isSeries: true,
          projectId: project.id,
          source: FileSource.Uploaded,
          multipartUploadId: null,
          preview: null,
          parentIds: null,
          seriesOrder: null,
          location: null,
          partSize: null,
          assignment: null,
          signal: null,
          size: seriesFile.size,
          seriesParentId: null,
          fileBySeriesParentId: null,
          fileFormat: files[0].fileFormat,
          fileStructure: files[0].fileStructure,
          fileType: files[0].fileType,
          uploadStatus: FileUploadStatus["COMPLETE"],
          processingStatus: ProcessingStatus["SKIPPED"],
          dataset:
            datasetId !== null
              ? { __typename: "Dataset", id: datasetId }
              : null,
          tenant: {
            __typename: "Tenant",
            id: currentTenant.id,
          },
          fileMetadata: {
            __typename: "FileMetadataConnection",
            nodes: [],
          },
          outputGroupFiles: {
            __typename: "OutputGroupFilesConnection",
            nodes: [],
          },
          recordingGroupFiles: {
            __typename: "RecordingGroupFilesConnection",
            nodes: [],
          },
          user: {
            __typename: "ApplicationUser",
            id: currentUser.id,
          },
          seriesFiles: {
            __typename: "FilesConnection",
            nodes: seriesFile.series_files.map((id) => ({
              __typename: "File",
              id: id,
            })),
          },
          project: {
            __typename: "Project",
            id: project.id,
          },
          fileActivities: {
            __typename: "FileActivitiesConnection",
            nodes: [],
          },
          taskSources: {
            __typename: "TaskSourcesConnection",
            nodes: [],
          },
          fileProcessingNotifications: {
            __typename: "FileProcessingNotificationsConnection",
            nodes: [],
          },
        },
      });

      files.forEach(({ id: seriesMemberId }, idx) => {
        updateCacheFragment({
          __typename: "File",
          id: seriesMemberId,
          update: (data) => {
            const newData = cloneDeep(data);
            newData.seriesParentId = seriesFile.id;
            newData.seriesOrder = idx;
            return newData;
          },
        });
      });

      updateCacheFragment({
        __typename: "Project",
        id: project.id,
        update: (data) => {
          const newData = cloneDeep(data);

          if (newData?.activeFiles?.nodes) {
            // Remove selected objects from project cache
            remove(newData.activeFiles.nodes, ({ id }) =>
              files.map(({ id }) => id).includes(id),
            );

            // Add new series object to project cache
            newData.activeFiles.nodes.push({
              __typename: "File",
              id: seriesFile.id,
            });
          }

          return newData;
        },
      });

      if (isDefined(onComplete)) {
        onComplete(files);
      }
    } catch (err) {
      if (isDefined(onError)) {
        onError(err as Error);
      }
      captureException(err);
      addUtilityToastFailure("Failed to create series");
    } finally {
      setIsLoading(false);
      onClose();
    }
  };

  const columns: EuiBasicTableColumn<ModalCreateSeriesFile>[] = useMemo(() => {
    if (files === undefined) {
      return [];
    }
    const columns: EuiBasicTableColumn<ModalCreateSeriesFile>[] = [];

    /**
     * Only allow users to reorder files if they can't be ordered automatically
     */
    if (!canOrderChronologically) {
      columns.push({
        width: "30px",
        field: "_",
        name: "",
        render: (_, { index, id, name }) => {
          return (
            <EuiFlexGroup gutterSize="none">
              <EuiButtonIcon
                aria-label={`Move file ${name} up`}
                iconType="arrowUp"
                onClick={() => {
                  setFiles((files) => {
                    const newFiles = [...files];
                    const index = newFiles.findIndex((file) => file.id === id);
                    const file = newFiles[index];
                    newFiles.splice(index, 1);
                    const newIndex = Math.max(index - 1, 0);
                    newFiles.splice(newIndex, 0, file);
                    return newFiles;
                  });
                }}
              />
              <EuiButtonIcon
                aria-label={`Move file ${name} down`}
                id={id + "down"}
                iconType="arrowDown"
                onClick={() => {
                  setFiles((files) => {
                    const newFiles = [...files];
                    const index = newFiles.findIndex((file) => file.id === id);
                    const file = newFiles[index];
                    newFiles.splice(index, 1);
                    const newIndex = Math.min(index + 1, files.length - 1);
                    newFiles.splice(newIndex, 0, file);
                    return newFiles;
                  });
                }}
              />
            </EuiFlexGroup>
          );
        },
      });
    }

    return [
      ...columns,
      {
        name: "#",
        field: "index",
        render: (_, file) => file.index + 1,
      },
      {
        field: "name",
        name: "Name",
        render: (_, file) => file.name,
      },
      {
        field: "size",
        name: "Size",
        render: (_, file) => filesize(file.size),
      },
    ];
  }, [canOrderChronologically, files, setFiles]);

  /**
   * Gets the ID for each row (item) in the table.
   * @param file
   * @returns The item ID.
   */
  const getItemId = useCallback((file: { id: string }) => {
    return file.id;
  }, []);

  if (loadingFileData) {
    return <ModalLoading onClose={onClose} />;
  }

  if (files === undefined) {
    return <ModalError onClose={onClose} />;
  }
  const validationResult = validateSeriesFiles(files);

  const isSubmitDisabled = !validationResult.isValid || seriesName === "";

  const submitButtonTooltip = (() => {
    if (!validationResult.isValid) {
      return validationResult.validationError;
    }
    if (seriesName === "") {
      return "Enter a name for the series";
    }
  })();

  const handleSubmit = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();
    void createSeries(files);
  };

  return (
    <EuiModal onClose={onClose}>
      <EuiModalHeader>
        <EuiModalHeaderTitle component="h3">Create Series</EuiModalHeaderTitle>
      </EuiModalHeader>
      <EuiModalBody css={styles.modalBody}>
        <EuiFlexGroup style={{ width: "580px" }} direction={"column"}>
          {!validationResult.isValid ? (
            <EuiEmptyPrompt
              iconType="warning"
              color="danger"
              title={<h2>Unable to validate files....</h2>}
              body={<p>{validationResult.validationError}</p>}
            />
          ) : (
            <>
              <EuiFlexItem>
                <EuiText>
                  <p>
                    Please confirm you wish to create a{" "}
                    <strong>
                      <EuiIcon type={validationResult.seriesType.icon} />{" "}
                      {validationResult.seriesType.name}
                    </strong>{" "}
                    series from the following files:
                  </p>
                </EuiText>
              </EuiFlexItem>
              {isOrderedChronologically ? (
                <EuiCallOut
                  size="s"
                  title="Files have been automatically ordered by start time"
                  iconType="sortable"
                />
              ) : (
                <EuiCallOut
                  size="s"
                  title="Series order could not be determined by reading timing metadata"
                  iconType="sortable"
                  color="warning"
                >
                  A start time could not be read from one or more files. Files
                  in this series will be ordered by file name alphabetically by
                  default, or can be manually reordered below.
                </EuiCallOut>
              )}
              <EuiBasicTable<ModalCreateSeriesFile>
                tableLayout="auto"
                columns={columns}
                items={files}
                itemId={getItemId}
              />
              <EuiFlexItem>
                <EuiFormRow fullWidth label="Series name">
                  <EuiFieldText
                    aria-label="Series name"
                    fullWidth
                    value={seriesName}
                    onChange={(e) => {
                      setSeriesName(e.target.value);
                    }}
                  />
                </EuiFormRow>
              </EuiFlexItem>
            </>
          )}
        </EuiFlexGroup>
      </EuiModalBody>
      <EuiModalFooter>
        <EuiButtonEmpty onClick={onClose}>Cancel</EuiButtonEmpty>
        <Tooltip content={submitButtonTooltip}>
          <EuiButton
            type="submit"
            onClick={handleSubmit}
            fill
            disabled={isSubmitDisabled}
            isLoading={isLoading}
          >
            Create Series
          </EuiButton>
        </Tooltip>
      </EuiModalFooter>
    </EuiModal>
  );
}

export default ModalCreateSeries;
