import { ButtonIconPermissioned } from "components/ButtonIconPermissioned/ButtonIconPermissioned";

import {
  ModalRenameDrsFile,
  ModalRenameDrsFileProps,
} from "components/ModalRenameDrsFile/ModalRenameDrsFile";
import { useRouteMapContext } from "providers/RouteMapProvider/RouteMapProvider";
import { useState } from "react";
import { getDrsFileModifyPermissionByDrsFileAndAction } from "types/DrsFileModifyPermissions";

export interface ButtonRenameDrsFileProps {
  drsFile: Parameters<typeof getDrsFileModifyPermissionByDrsFileAndAction>[0] &
    ModalRenameDrsFileProps["drsFile"];
}

export const ButtonRenameDrsFile = ({ drsFile }: ButtonRenameDrsFileProps) => {
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const isViewingHistory = useRouteMapContext(
    (s) =>
      s.isRouteMatch(s.routeMap.DATASET_HISTORY.parameterizedPath) ||
      s.isRouteMatch(s.routeMap.DATASET_VERSION.parameterizedPath),
  );

  return (
    <>
      <ButtonIconPermissioned
        aria-label="Rename file"
        defaultTooltip={
          isViewingHistory
            ? "Rename disabled while viewing history"
            : "Rename file"
        }
        color="primary"
        iconType="pencil"
        disabled={
          isViewingHistory ||
          !getDrsFileModifyPermissionByDrsFileAndAction(drsFile, "RENAME")
            .isPermitted
        }
        onClick={() => {
          setShowConfirmModal(true);
        }}
        requiredPermission="edit"
      />
      {showConfirmModal && (
        <ModalRenameDrsFile
          drsFile={drsFile}
          onClose={() => setShowConfirmModal(false)}
        />
      )}
    </>
  );
};
