import { ReactNode, useMemo } from "react";
import { fileBadgeColors } from "./FileBadge";
import { File as DrsFile } from "graphql/_Types";
import { EuiText } from "@inscopix/ideas-eui";
import { useProcessFileFailedTaskError } from "hooks/useProcessFileFailedTaskError";

export interface FileBadgeTooltipContentProps {
  fileId: DrsFile["id"];
  fileName: DrsFile["name"];
  isUploading: boolean;
  isUploadFailed: boolean;
  isProcessing: boolean;
  isUnreadable: boolean;
  isArchived: boolean;
  isUnarchiving: boolean;
  isDataDeleted: boolean;
  isMisplaced: boolean;
  uploadProgress?: number;
}

export const FileBadgeTooltipContent = ({
  fileId,
  fileName,
  isUploading,
  isUploadFailed,
  isProcessing,
  isUnreadable,
  isArchived,
  isUnarchiving,
  isDataDeleted,
  isMisplaced,

  uploadProgress,
}: FileBadgeTooltipContentProps) => {
  const {
    loading: isFailedTaskLoading,
    errorDescription: failedTaskErrorDescription,
  } = useProcessFileFailedTaskError(fileId);

  const tooltipContent = useMemo(() => {
    const tooltipFragments: ReactNode[] = [];
    const addTooltipFragment = (message: string, color = "white") => {
      tooltipFragments.push(
        <EuiText color={color} size="s">
          {message}
        </EuiText>,
      );
    };

    if (isUploading) {
      addTooltipFragment(`Uploading ${(uploadProgress ?? 0).toString()}%...`);
    }

    if (isUploadFailed) {
      addTooltipFragment("Upload failed", fileBadgeColors.uploadFailed);
    }

    if (isProcessing) {
      addTooltipFragment(
        "Extracting metadata and generating preview...",
        fileBadgeColors.processing,
      );
    }

    if (isUnreadable) {
      if (failedTaskErrorDescription !== undefined) {
        addTooltipFragment(
          failedTaskErrorDescription,
          fileBadgeColors.unreadable,
        );
      } else if (isFailedTaskLoading) {
        addTooltipFragment(
          "Loading error details...",
          fileBadgeColors.unreadable,
        );
      }
    }

    if (isArchived) {
      addTooltipFragment("Archived", fileBadgeColors.archived);
    }

    if (isUnarchiving) {
      addTooltipFragment("Requested unarchive", fileBadgeColors.unarchiving);
    }

    if (isDataDeleted) {
      addTooltipFragment("Data deleted", fileBadgeColors.dataDeleted);
    }

    if (isMisplaced) {
      addTooltipFragment(
        "File does not match assigned column's data type",
        fileBadgeColors.misplaced,
      );
    }

    return (
      <div>
        <EuiText size="s">{fileName}</EuiText>
        {tooltipFragments}
      </div>
    );
  }, [
    failedTaskErrorDescription,
    fileName,
    isArchived,
    isDataDeleted,
    isFailedTaskLoading,
    isMisplaced,
    isProcessing,
    isUnarchiving,
    isUnreadable,
    isUploadFailed,
    isUploading,
    uploadProgress,
  ]);

  return tooltipContent;
};
