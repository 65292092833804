import {
  EuiEmptyPrompt,
  EuiPageTemplate,
  EuiSkeletonText,
  EuiText,
} from "@inscopix/ideas-eui";
import { Route, Switch, useParams } from "react-router-dom";
import { Project } from "components/Project/Project";
import { usePageProjectData } from "./usePageProjectData";
import { ProjectDataProvider } from "./ProjectDataProvider";
import { PageProjectDataset } from "./dataset/PageProjectDataset";
import { Page404 } from "pages/404/Page404";
import { useRouteMapContext } from "providers/RouteMapProvider/RouteMapProvider";
import { Helmet } from "react-helmet-async";
import { ProjectFilesManager } from "stores/project-files/ProjectFilesManager";
import { ProjectAccessCallout } from "components/Project/ProjectAccessCallout";
import { PageProjectTasks } from "./tasks/PageProjectTasks";
import { ProjectLayoutProvider } from "components/Project/ProjectLayoutProvider";
import { useEffect } from "react";
import { pollAnalysisResults } from "./PageProject.helpers";
import { CallOutQuotas } from "components/CallOutQuotas/CallOutQuotas";
import { ToolSpecProvider } from "providers/ToolSpecProvider/ToolSpecProvider";
import { CallOutContract } from "components/CallOutContract/CallOutContract";
import { ProjectStatus } from "types/constants";
import { ideasFeatures } from "ideas.features";
import { PageGenericDataTables } from "pages/gdt/PageGenericDataTables";
import { ProjectAnalysisRoutes } from "pages/routes/ProjectAnalysisRoutes";
import { RecordingIdentifierProvider } from "providers/RecordingIdentifierProvider/RecordingIdentifierProvider";
import { ProjectPermissionProvider } from "./ProjectPermissionProvider";
import { PageProject404 } from "./PageProject404";
import { ModalProvider } from "providers/ModalProvider/ModalProvider";

export const PageProject = () => {
  const projectKey = useParams<{ projectKey: string }>().projectKey;
  const routeMap = useRouteMapContext((s) => s.routeMap);

  // Poll for analysis results until the component unmounts
  useEffect(() => {
    const stopPolling = pollAnalysisResults(projectKey);
    return stopPolling;
  }, [projectKey]);

  const { loading, error, data } = usePageProjectData({ projectKey });

  if (loading && data === undefined) {
    return <EuiSkeletonText lines={5} />;
  }

  if (error) {
    return <PageProject404 projectKey={projectKey} />;
  }

  const { project } = data;

  if (project.status === ProjectStatus.CLONING) {
    return (
      <>
        <Helmet>
          <title>{project.name}</title>
        </Helmet>
        <EuiPageTemplate>
          <EuiPageTemplate>
            <EuiEmptyPrompt
              iconType="copy"
              title={
                <EuiText>
                  <h1>Copying in progress</h1>
                </EuiText>
              }
              body={
                <EuiText>
                  <p>
                    The project you are trying to access is in the process of
                    being copied. Please check back later.
                  </p>
                </EuiText>
              }
            />
          </EuiPageTemplate>
        </EuiPageTemplate>
      </>
    );
  }

  return (
    <>
      <Helmet>
        <title>{`${project.name}`}</title>
      </Helmet>
      <Switch>
        <ToolSpecProvider>
          <ProjectDataProvider {...data}>
            <ProjectPermissionProvider projectId={project.id}>
              {ideasFeatures.genericDataTables ? (
                <PageGenericDataTables />
              ) : (
                <RecordingIdentifierProvider>
                  <ProjectAccessCallout>
                    <EuiPageTemplate style={{ maxHeight: "100vh" }}>
                      <CallOutContract format="call_out" />
                      <CallOutQuotas />
                      <Route
                        path={
                          routeMap["PROJECT_ANALYSIS_TABLE_GROUP"]
                            .parameterizedPath
                        }
                        exact
                      >
                        <ProjectFilesManager projectId={project.id}>
                          <ProjectAnalysisRoutes
                            projectId={project.id}
                            projectKey={project.key}
                          />
                        </ProjectFilesManager>
                      </Route>
                      <Route path={routeMap.DATASET.parameterizedPath}>
                        <PageProjectDataset />
                      </Route>
                      <Route path={routeMap.PROJECT.parameterizedPath} exact>
                        <ProjectFilesManager projectId={project.id}>
                          <ModalProvider>
                            <ProjectLayoutProvider>
                              <Project />
                            </ProjectLayoutProvider>
                          </ModalProvider>
                        </ProjectFilesManager>
                      </Route>
                      <Route path={routeMap.PROJECT_TASKS.parameterizedPath}>
                        <ProjectFilesManager projectId={project.id}>
                          <PageProjectTasks />
                        </ProjectFilesManager>
                      </Route>
                    </EuiPageTemplate>
                  </ProjectAccessCallout>
                </RecordingIdentifierProvider>
              )}
            </ProjectPermissionProvider>
          </ProjectDataProvider>
        </ToolSpecProvider>
        <Route path="*">
          <Page404 />
        </Route>
      </Switch>
    </>
  );
};
