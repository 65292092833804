import { ToolParamsGridRowDatum } from "./ToolParamsGrid.types";
import { useJobExecutor } from "./hooks/useJobExecutor";
import { useProjectDataContext } from "pages/project/ProjectDataProvider";
import { useToolParamsGridContext } from "./ToolParamsGridProvider";
import { useStartTaskToolTipContent } from "./hooks/useStartTaskToolTipContent";
import { useValueValidator } from "components/ToolParamsGrid/ToolParamsGridValueValidatorContext";
import { ButtonIconPermissioned } from "components/ButtonIconPermissioned/ButtonIconPermissioned";
import { isEqual, isUndefined } from "lodash";

type ButtonStartTaskProps = {
  rowData: ToolParamsGridRowDatum;
};

export const ButtonStartTask = ({ rowData }: ButtonStartTaskProps) => {
  const { project } = useProjectDataContext();
  const { analysisTable } = useToolParamsGridContext();
  const rowErrorsByParamKey = useValueValidator(
    (s) => s.errorsByRowId?.[rowData.id] ?? {},
    isEqual,
  );
  const isRowValid = Object.values(rowErrorsByParamKey).every(isUndefined);

  const toolTipContent = useStartTaskToolTipContent(rowData.id);

  const { loading, startJobs } = useJobExecutor({
    projectId: project.id,
    analysisTableId: analysisTable.id,
    projectKey: project.key,
  });

  const isTaskExecuted = rowData.task_status !== undefined;

  return (
    <ButtonIconPermissioned
      style={{
        visibility: isTaskExecuted ? "hidden" : "visible",
      }}
      requiredPermission="edit"
      defaultTooltip={toolTipContent ?? "Start Task"}
      isLoading={loading}
      disabled={!isRowValid || isTaskExecuted}
      color="primary"
      iconType="playFilled"
      aria-label="Run row"
      onClick={() => {
        void startJobs([rowData]);
      }}
    />
  );
};
