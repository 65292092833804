import { css } from "@emotion/react";

export const fileRendererCellPadding = 4;
export const fileBadgeWidth = 36;

export const useFileGridRendererStyles = () => {
  const styles = {
    root: css`
      &,
      & > * {
        align-items: center;
        display: flex;
        height: 100%;
        justify-content: center;
        width: 100%;
        gap: 5px;
      }
    `,
    dropZone: (backgroundColor: string) => css`
      background-color: ${backgroundColor};
    `,
    button: css`
      align-items: center;
      display: flex;
      height: 100%;
      justify-content: center;
      width: 100%;
    `,
    invisible: css`
      visibility: hidden;
    `,
  };

  return styles;
};
