import {
  EuiButton,
  EuiButtonEmpty,
  EuiFieldPassword,
  EuiFlexGroup,
  EuiFlexItem,
  EuiForm,
  EuiFormRow,
  EuiHorizontalRule,
  EuiModal,
  EuiModalBody,
  EuiModalFooter,
  EuiModalHeader,
  EuiModalHeaderTitle,
  EuiSpacer,
  EuiTableComputedColumnType,
  htmlIdGenerator,
} from "@inscopix/ideas-eui";
import { useCallback, useState } from "react";
import { filesize } from "utils/filesize";
import { isFieldStateValid } from "./ModalDandi.helpers";
import { ModalDandiFieldDandiset } from "./ModalDandiFieldDandiset";
import { ModalDandiFieldDataColumn } from "./ModalDandiFieldDataColumn";
import { ModalDandiFieldServer } from "./ModalDandiFieldServer";
import { ModalDandiFieldFiles } from "./ModalDandiFieldFiles";
import { ModalDandiState } from "./ModalDandi.types";
import { Tooltip } from "components/Tooltip/Tooltip";
import { addUtilityToastFailure } from "utils/addUtilityToastFailure";
import { useExportToDandiDjango } from "hooks/useExportToDandiDjango";
import assert from "assert";
import { addUtilityToastSuccess } from "utils/addUtilityToastSuccess";
import { GridApi } from "ag-grid-community";

export interface ModalDandiProps<T extends { id: string; size: string }> {
  gridApi: GridApi | undefined;
  projectId: string;
  datasetId?: string;
  dataColumnIds: string[];
  files: T[];
  onClose: () => void;
  onComplete?: () => void;
  isFileInColumn: (file: T, columnId: string) => boolean;
  // Columns rendered in the file selection table
  fileTableColumns: EuiTableComputedColumnType<T>[];
}

/** Base component for exporting files to DANDI */
export const ModalDandi = <T extends { id: string; size: string }>({
  gridApi,
  projectId,
  datasetId,
  files,
  onComplete,
  onClose,
  isFileInColumn,
  fileTableColumns: fileColumns,
  dataColumnIds,
}: ModalDandiProps<T>) => {
  const [fields, setFields] = useState<ModalDandiState<T>>({
    apiKey: "",
    dandisetId: "",
    dataColumnId: "",
    server: "dandi",
    files: [],
  });
  const [isLoading, setIsLoading] = useState(false);
  const { exportToDandi } = useExportToDandiDjango();

  const setApiKey = useCallback((apiKey: ModalDandiState["apiKey"]) => {
    setFields((prevFields) => ({
      ...prevFields,
      apiKey,
    }));
  }, []);

  const setDandisetId = useCallback(
    (dandisetId: ModalDandiState["dandisetId"]) => {
      setFields((prevFields) => ({
        ...prevFields,
        dandisetId,
      }));
    },
    [],
  );

  const setDataColumnId = useCallback(
    (dataColumnId: ModalDandiState["dataColumnId"]) => {
      setFields((prevFields) => ({
        ...prevFields,
        dataColumnId,
        files: files.filter((file) => isFileInColumn(file, dataColumnId)),
      }));
    },
    [files, isFileInColumn],
  );

  const setServer = useCallback((server: ModalDandiState["server"]) => {
    setFields((prevFields) => ({
      ...prevFields,
      server,
    }));
  }, []);

  const setFiles = useCallback((files: (typeof fields)["files"]) => {
    setFields((prevFields) => ({
      ...prevFields,
      files,
    }));
  }, []);

  const exportButtonText = (() => {
    const numFiles = fields.files.length;
    const noun = numFiles === 1 ? "file" : "files";
    const totalSize = fields.files.reduce(
      (sum, file) => sum + BigInt(file.size),
      BigInt(0),
    );
    const formattedSize = filesize(totalSize).toString();

    return numFiles === 0
      ? "Export"
      : `Export (${numFiles} ${noun}, ${formattedSize})`;
  })();

  const handleSubmit = async () => {
    try {
      setIsLoading(true);
      assert(isFieldStateValid(fields));
      await exportToDandi({
        projectId,
        datasetId,
        apiKey: fields.apiKey,
        server: fields.server,
        dandisetId: fields.dandisetId,
        fileIds: fields.files.map((file) => file.id),
      });
      addUtilityToastSuccess("Files have begun exporting to DANDI");
      onComplete?.();
    } catch (error) {
      addUtilityToastFailure("Failed to export files to DANDI");
    } finally {
      setIsLoading(false);
      onClose();
    }
  };

  return (
    <EuiModal onClose={onClose}>
      <EuiModalHeader>
        <EuiModalHeaderTitle>Export to DANDI</EuiModalHeaderTitle>
      </EuiModalHeader>
      <EuiModalBody>
        <EuiForm
          component="form"
          role="form"
          id={htmlIdGenerator()()}
          style={{ width: 600 }}
        >
          <EuiFlexGroup>
            <EuiFlexItem>
              <EuiFormRow
                label="API key"
                helpText={
                  <span>
                    See the{" "}
                    <a
                      href="https://docs.dandiarchive.org/13_upload/"
                      target="_blank"
                      rel="noreferrer"
                    >
                      DANDI documentation
                    </a>{" "}
                    for details
                  </span>
                }
              >
                <EuiFieldPassword
                  onChange={(e) => setApiKey(e.target.value)}
                  value={fields.apiKey}
                  placeholder="Enter your API key"
                  type="dual"
                />
              </EuiFormRow>
            </EuiFlexItem>
            <EuiFlexItem>
              <EuiFormRow label="Server">
                <ModalDandiFieldServer
                  server={fields.server}
                  setServer={setServer}
                />
              </EuiFormRow>
            </EuiFlexItem>
          </EuiFlexGroup>
          <EuiSpacer />
          <EuiFormRow label="Dandiset">
            <ModalDandiFieldDandiset
              apiKey={fields.apiKey}
              server={fields.server}
              dandisetId={fields.dandisetId}
              setDandisetId={setDandisetId}
            />
          </EuiFormRow>
          <EuiHorizontalRule />
          <EuiFormRow
            label="Data column"
            helpText={
              "Select a data column/analysis result column with a data type of NWB."
            }
          >
            <ModalDandiFieldDataColumn
              gridApi={gridApi}
              dataColumnId={fields.dataColumnId}
              setDataColumnId={setDataColumnId}
              columnIds={dataColumnIds}
            />
          </EuiFormRow>
          {fields.dataColumnId !== undefined && (
            <EuiFormRow label="Files to export..." fullWidth>
              <ModalDandiFieldFiles
                files={files}
                isFileInColumn={isFileInColumn}
                columns={fileColumns}
                fields={fields}
                setFiles={setFiles}
              />
            </EuiFormRow>
          )}
        </EuiForm>
      </EuiModalBody>
      <EuiModalFooter>
        <EuiButtonEmpty onClick={onClose}>Cancel</EuiButtonEmpty>
        <Tooltip
          content={
            !isFieldStateValid(fields)
              ? "Select a dandiset and at least one file to export"
              : undefined
          }
        >
          <EuiButton
            disabled={!isFieldStateValid(fields)}
            onClick={() => void handleSubmit()}
            isLoading={isLoading}
          >
            {exportButtonText}
          </EuiButton>
        </Tooltip>
      </EuiModalFooter>
    </EuiModal>
  );
};
