/** @jsxImportSource @emotion/react */
import { useDndContext } from "@dnd-kit/core";
import { EuiBadge, EuiPopover, EuiSpacer } from "@inscopix/ideas-eui";
import { File as DrsFile } from "graphql/_Types";
import { useDatasetLayoutContext } from "pages/project/dataset/DatasetLayoutProvider";
import { memo, useState } from "react";
import { useResizeDetector } from "react-resize-detector";
import { DraggableFileBadge } from "../DraggableFileBadge";
import { useFileGridRendererStyles } from "../../../styles/useFileGridRendererStyles/useFileGridRendererStyles";
import { getNumBadgesFit } from "styles/useFileGridRendererStyles/getNumBadgesFit";
interface GridRendererDrsFileWithFileProps {
  drsFiles: Pick<
    DrsFile,
    | "id"
    | "name"
    | "originalName"
    | "status"
    | "fileType"
    | "fileFormat"
    | "source"
    | "dateCreated"
    | "datasetId"
    | "isSeries"
    | "seriesParentId"
    | "projectId"
    | "processingStatus"
  >[];
}

export const GridRendererDrsFileWithFiles = memo(
  function GridRendererDrsFileWithFiles({
    drsFiles,
  }: GridRendererDrsFileWithFileProps) {
    const fileGridRendererStyles = useFileGridRendererStyles();
    const { active } = useDndContext();
    const { width, ref } = useResizeDetector();
    const { openFlyout } = useDatasetLayoutContext();
    const [isPopoverVisible, setIsPopoverVisible] = useState(false);
    const togglePopover = () => setIsPopoverVisible((isOpen) => !isOpen);
    const closePopover = () => setIsPopoverVisible(false);

    if (drsFiles.length === 1) {
      const handleClick = () => {
        openFlyout({
          type: "fileInfo",
          props: {
            drsFile: drsFiles[0],
          },
        });
      };

      return (
        <div css={fileGridRendererStyles.root}>
          <DraggableFileBadge drsFile={drsFiles[0]} onClick={handleClick} />
        </div>
      );
    }

    // Calculate the number of badges that fit in the cell
    const numBadgesFit = getNumBadgesFit(width);

    const isOverflow = numBadgesFit < drsFiles.length;
    const visibleFiles = isOverflow
      ? drsFiles.slice(0, numBadgesFit - 1)
      : drsFiles;

    const button = (
      <div
        css={fileGridRendererStyles.button}
        ref={ref}
        onClick={togglePopover}
      >
        {visibleFiles.map((drsFile) => {
          return (
            <DraggableFileBadge
              key={drsFile.id}
              drsFile={drsFile}
              compact
              disabled
            />
          );
        })}
        {isOverflow && (
          <EuiBadge>+{drsFiles.length - visibleFiles.length}</EuiBadge>
        )}
      </div>
    );

    return (
      <EuiPopover
        anchorPosition="downCenter"
        button={button}
        css={fileGridRendererStyles.root}
        closePopover={closePopover}
        isOpen={isPopoverVisible}
        // Hide popover when dragging
        panelProps={{
          css: active === null ? undefined : fileGridRendererStyles.invisible,
        }}
      >
        {drsFiles.map((drsFile, idx, arr) => {
          const isLast = idx === arr.length - 1;

          const handleClick = () => {
            openFlyout({
              type: "fileInfo",
              props: {
                drsFile,
              },
            });
          };

          return (
            <div key={drsFile.id} onClick={handleClick}>
              <DraggableFileBadge drsFile={drsFile} />
              {!isLast && <EuiSpacer size="s" />}
            </div>
          );
        })}
      </EuiPopover>
    );
  },
);
