/** @jsxImportSource @emotion/react */
import {
  EuiButtonEmpty,
  EuiEmptyPrompt,
  EuiFlexGroup,
  EuiFlexItem,
  EuiSpacer,
} from "@inscopix/ideas-eui";
import { CallOutError } from "components/CallOutError/CallOutError";
import { NotificationAlertSkeleton } from "./alerts/NotificationAlertSkeleton";
import { NotificationsFeedBodyQuery } from "graphql/_Types";
import { NotificationAlertProjectShared } from "./alerts/NotificationAlertProjectShared";
import { captureException } from "@sentry/react";
import { isNullish } from "utils/isNullish";
import { TRegion } from "providers/RegionsProvider";
import { ErrorBoundary } from "react-error-boundary";
import { NotificationAlertError } from "./alerts/NotificationAlertError";
import { ReactElement, useCallback } from "react";
import { NotificationAlertFileProcessing } from "./alerts/NotificationAlertFileProcessing";
import { NotificationAlertProjectCloned } from "./alerts/NotificationAlertProjectCloned";
import { NotificationAlertRoleChange } from "./alerts/NotificationAlertRoleChange";
import { NotificationAlertTenantUserMembership } from "./alerts/NotificationAlertTenantUserMembership";
import { useValidNotifications } from "components/Notifications/Notifications.hooks";
import { css } from "@emotion/react";
import { BulkMarkAllNotificationsAsReadDjangoInput } from "hooks/useUpdateNotificationDjango";

export interface NotificationsFeedBodyProps {
  onlyShowUnread: boolean;
  onNotificationRead: (
    notificationId: string,
    region?: TRegion,
    refetchNotifications?: () => void,
  ) => void;
  onMarkAllAsRead: (
    unreadNotifications: BulkMarkAllNotificationsAsReadDjangoInput,
  ) => Promise<void>;
}

/** Component that renders the body of the `NotificationsFeed` component */
export const NotificationsFeedBody = ({
  onlyShowUnread,
  onNotificationRead,
  onMarkAllAsRead,
}: NotificationsFeedBodyProps) => {
  const {
    notifications: notificationsInternal,
    isLoading: isLoadingNotificationsInternal,
    error: errorInternal,
    refetch: refetchNotificationsInternal,
  } = useValidNotifications("internal", onlyShowUnread);
  const {
    notifications: notificationsExternal,
    refetch: refetchNotificationsExternal,
  } = useValidNotifications("external", onlyShowUnread);

  /**
   * Refetches notifications from every region
   */
  const refetchNotifications = useCallback(() => {
    void refetchNotificationsInternal();
    void refetchNotificationsExternal();
  }, [refetchNotificationsExternal, refetchNotificationsInternal]);

  const notifications:
    | (NonNullable<
        NotificationsFeedBodyQuery["notifications"]
      >["nodes"][number] & {
        region?: TRegion;
      })[]
    | null = isNullish(notificationsInternal?.notifications?.nodes)
    ? null
    : [
        ...(notificationsInternal?.notifications?.nodes ?? []),
        ...(notificationsExternal?.notifications?.nodes ?? []),
      ].sort((a, b) => {
        if (a.dateCreated === b.dateCreated) {
          return 0;
        }
        return a.dateCreated > b.dateCreated ? -1 : 1;
      });

  const handleMarkAllAsRead = useCallback(async () => {
    const unreadNotifications =
      notifications?.filter(({ hasSeen }) => !hasSeen) ?? [];
    await onMarkAllAsRead(unreadNotifications);
    refetchNotifications();
  }, [notifications, onMarkAllAsRead, refetchNotifications]);

  if (isLoadingNotificationsInternal) {
    return (
      <>
        <NotificationAlertSkeleton />
        <NotificationAlertSkeleton />
        <NotificationAlertSkeleton />
      </>
    );
  }

  if (errorInternal !== undefined || isNullish(notifications)) {
    return <CallOutError />;
  }

  if (notifications.length === 0) {
    return (
      <EuiEmptyPrompt
        iconType="bell"
        body={
          onlyShowUnread ? (
            <p>
              You have no <strong>unread</strong> notifications.
            </p>
          ) : (
            <p>You have no notifications.</p>
          )
        }
      />
    );
  }

  /* Map each notification to its sub-type component. Since notification
     sub-types each require different data, each sub-type component is
     responsible for asserting that their required data is present. Sub-type
     components are free to throw errors when data is missing. Exceptions are
     captured for notifications that cannot be mapped to a sub-type component
     and for notifications with missing data. */

  return (
    <>
      <EuiFlexGroup
        alignItems="center"
        gutterSize="none"
        css={css`
          margin-top: -20px;
        `}
      >
        <EuiFlexItem>
          <EuiButtonEmpty
            onClick={() => {
              void handleMarkAllAsRead();
            }}
            size="xs"
            disabled={notifications.every(
              (notification) => notification.hasSeen,
            )}
          >
            Mark all as read
          </EuiButtonEmpty>
        </EuiFlexItem>
      </EuiFlexGroup>
      <EuiSpacer size="s" />
      {notifications.map((notification) => {
        let element: ReactElement | undefined = undefined;

        if (notification.projectSharedNotification !== null) {
          element = (
            <NotificationAlertProjectShared
              data={notification}
              onNotificationRead={onNotificationRead}
              refetchNotifications={refetchNotifications}
            />
          );
        }

        if (notification.fileProcessingNotification !== null) {
          const { file } = notification.fileProcessingNotification;
          if (file !== null) {
            element = (
              <NotificationAlertFileProcessing
                data={notification}
                onNotificationRead={onNotificationRead}
                refetchNotifications={refetchNotifications}
              />
            );
          }
        }

        if (notification.projectClonedNotification !== null) {
          element = (
            <NotificationAlertProjectCloned
              data={notification}
              onNotificationRead={onNotificationRead}
              refetchNotifications={refetchNotifications}
            />
          );
        }

        if (notification.tenantUserMembershipNotification !== null) {
          element = (
            <NotificationAlertTenantUserMembership
              data={notification}
              onNotificationRead={onNotificationRead}
              refetchNotifications={refetchNotifications}
            />
          );
        }

        if (notification.roleChangeNotification !== null) {
          element = (
            <NotificationAlertRoleChange
              data={notification}
              onNotificationRead={onNotificationRead}
              refetchNotifications={refetchNotifications}
            />
          );
        }

        if (element === undefined) {
          captureException("Unexpected notification sub-type", {
            extra: { notificationId: notification.id },
          });

          return (
            <NotificationAlertError
              key={notification.id}
              dateCreated={notification.dateCreated}
              hasSeen={notification.hasSeen}
              onNotificationRead={() => onNotificationRead(notification.id)}
            />
          );
        }

        return (
          <ErrorBoundary
            key={notification.id}
            onError={() => {
              captureException("Missing notification attributes", {
                extra: { notificationId: notification.id },
              });
            }}
            fallback={
              <NotificationAlertError
                dateCreated={notification.dateCreated}
                hasSeen={notification.hasSeen}
                onNotificationRead={() => onNotificationRead(notification.id)}
              />
            }
          >
            {element}
          </ErrorBoundary>
        );
      })}
    </>
  );
};
