/** @jsxImportSource @emotion/react */

import { EuiPageTemplate } from "@inscopix/ideas-eui";
import { css } from "@emotion/react";
import { useProjectDataContext } from "../../pages/project/ProjectDataProvider";
import { addUtilityToastFailure } from "utils/addUtilityToastFailure";
import { addUtilityToastSuccess } from "utils/addUtilityToastSuccess";
import { MarkdownEditor } from "components/MarkdownEditor/MarkdownEditor";
import { captureException } from "@sentry/react";
import { updateCacheFragment } from "utils/cache-fragments";
import { libraryProjectPatch } from "hooks/useLibraryProjectPatchDjango";

const styles = {
  root: css`
    max-height: 515px;
    overflow-y: auto;
  `,
};

export const ProjectDescription = () => {
  const { project } = useProjectDataContext();

  const onSave = async (markdown: string) => {
    try {
      const projectUpdated = await libraryProjectPatch(project.id, {
        description: markdown,
      });

      updateCacheFragment({
        __typename: "Project",
        id: project.id,
        update: (data) => ({ ...data, ...projectUpdated }),
      });

      addUtilityToastSuccess("Successfully updated project description");
    } catch (err) {
      captureException(err);
      addUtilityToastFailure("Failed to update project description");
    }
  };

  return (
    <EuiPageTemplate.Section grow={false} bottomBorder={true} css={styles.root}>
      <MarkdownEditor
        initialValue={project.description ?? ""}
        emptyPrompt="Add markdown documentation here describing this project."
        onSave={onSave}
        height={400}
      />
    </EuiPageTemplate.Section>
  );
};
