import { EuiButtonEmpty } from "@inscopix/ideas-eui";
import { withUserPermission } from "hocs/withUserPermission";
import { withUserPermissionBase } from "hocs/withUserPermissionBase";

/**
 * Enhanced {@link EuiButtonEmpty} that is disabled when the user lacks a
 * required permission.
 *
 * Context independent.
 */
export const ButtonEmptyPermissionedBase =
  withUserPermissionBase(EuiButtonEmpty);

/**
 * Enhanced {@link EuiButtonEmpty} that is disabled when the user lacks a
 * required permission.
 *
 * Reads permissions from project permissions context.
 */
export const ButtonEmptyPermissioned = withUserPermission(
  ButtonEmptyPermissionedBase,
);
