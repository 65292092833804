/** @jsxImportSource @emotion/react */
import { useState } from "react";
import {
  EuiButtonIcon,
  EuiContextMenuItem,
  EuiContextMenuPanel,
  EuiPopover,
} from "@inscopix/ideas-eui";
import { useProjectDataContext } from "pages/project/ProjectDataProvider";
import { useRouteMapContext } from "providers/RouteMapProvider/RouteMapProvider";
import { ToolParamsGridRowDatum } from "../ToolParamsGrid/ToolParamsGrid.types";
import moment from "moment/moment";
import { isDefined } from "../../utils/isDefined";
import { Tooltip } from "components/Tooltip/Tooltip";
import { useTenantContext } from "providers/TenantProvider/TenantProvider";

interface DatasetHistoryMenuProps {
  datasetIds: ToolParamsGridRowDatum["datasets"];
  taskDateCreated?: string;
}
export const DatasetHistoryMenu = ({
  datasetIds,
  taskDateCreated,
}: DatasetHistoryMenuProps) => {
  const routeMap = useRouteMapContext((s) => s.routeMap);
  const { project, datasets } = useProjectDataContext();
  const [isPopoverOpen, setPopover] = useState(false);
  const currentTenant = useTenantContext((s) => s.currentTenant);

  const { disabled, onClick, items } = (() => {
    if (!datasetIds || datasetIds.length === 0) {
      return { disabled: true, onClick: undefined, items: [] };
    } else if (datasetIds.length === 1) {
      return {
        disabled: taskDateCreated === undefined,
        onClick: routeMap["DATASET_HISTORY"].dynamicPath({
          tenantKey: currentTenant.key,
          projectKey: project.key,
          datasetId: datasetIds[0],
          cutoffTime: moment(taskDateCreated).unix(),
        }).navigateTo,
        items: [],
      };
    } else {
      const items = (datasetIds ?? [])
        .map((datasetId) => {
          const dataset = datasets.find(({ id }) => id === datasetId);
          if (!dataset) return undefined;
          return (
            <EuiContextMenuItem
              key={datasetId}
              onClick={
                routeMap["DATASET_HISTORY"].dynamicPath({
                  tenantKey: currentTenant.key,
                  projectKey: project.key,
                  datasetId: datasetId,
                  cutoffTime: moment(taskDateCreated).unix(),
                }).navigateTo
              }
            >
              {dataset.name}
            </EuiContextMenuItem>
          );
        })
        .filter(isDefined);
      return {
        disabled: taskDateCreated === undefined || items.length === 0,
        onClick: () => setPopover(!isPopoverOpen),
        items,
      };
    }
  })();

  const datasetMenuButton = (
    <Tooltip content="View dataset at the time this task was submitted">
      <EuiButtonIcon
        aria-label="View dataset"
        color="primary"
        disabled={disabled}
        iconType="inspect"
        onClick={onClick}
      />
    </Tooltip>
  );

  return (
    <EuiPopover
      id={"datasetmenu"}
      button={datasetMenuButton}
      isOpen={isPopoverOpen}
      closePopover={() => {
        setPopover(false);
      }}
      panelPaddingSize="none"
      anchorPosition="downLeft"
    >
      <EuiContextMenuPanel size="s" items={items} />
    </EuiPopover>
  );
};
