/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { useState } from "react";
import { CellValue } from "@inscopix/ideas-hyperformula";
import {
  EuiListGroup,
  EuiListGroupItem,
  EuiPopover,
} from "@inscopix/ideas-eui";
import { CellValueRendererBase } from "../cell-value-renderers/CellValueRendererBase";
import { DataTableColumnDefinition } from "../store/DataTableProvider.types";

const styles = {
  popover: css`
    & {
      height: 100%;
      width: 100%;
      display: flex;
      align-items: center;
    }
  `,
  panel: css`
    max-height: max(200px, 50vh);
    overflow-y: scroll;
  `,
};

interface CellEditorChoiceProps {
  value: CellValue;
  columnDefinition: Extract<DataTableColumnDefinition, { kind: "choice" }>;
  onChangeFormula: (newFormula: string) => void;
  stopEditing: (cancel?: boolean) => void;
}

/** Cell editor for cells in choice columns */
export const CellEditorChoice = ({
  value,
  columnDefinition,
  onChangeFormula,
  stopEditing,
}: CellEditorChoiceProps) => {
  const [newValue, setNewValue] = useState(value);

  return (
    <EuiPopover
      anchorPosition="downCenter"
      button={<CellValueRendererBase value={newValue} />}
      isOpen
      panelPaddingSize="none"
      css={styles.popover}
      closePopover={stopEditing}
      onKeyDown={(e) => {
        if (e.key === "Escape") {
          e.preventDefault();
          stopEditing(true);
        }
      }}
      display="block"
      repositionOnScroll={false}
    >
      <EuiListGroup css={styles.panel} maxWidth="100%">
        {columnDefinition.choices.map((choice) => (
          <EuiListGroupItem
            id={choice.value.toString()}
            key={choice.value}
            iconType={newValue === choice.value ? "check" : "empty"}
            label={<CellValueRendererBase value={choice.label} />}
            onClick={() => {
              if (newValue === choice.value) {
                onChangeFormula("");
                setNewValue(null);
              } else {
                onChangeFormula(choice.value.toString());
                setNewValue(choice.value);
              }
            }}
          />
        ))}
      </EuiListGroup>
    </EuiPopover>
  );
};
