import { gql } from "@apollo/client";
import {
  File as DrsFile,
  useGetFileProcessTasksLazyQuery,
} from "graphql/_Types";
import { useGetDrsFileProcessingStatus } from "hooks/useGetDrsFileProcessingStatus";
import { chain } from "lodash";
import { useCallback, useEffect, useState } from "react";
import { TaskStatus } from "types/constants";
import { isNonNullish } from "utils/isNonNullish";
import { isNullish } from "utils/isNullish";

gql`
  query GetFileProcessTasks(
    $drsFileId: UUID!
    # Making this a variable so we don't have to hardcode statuses as magic numbers
    # in the query
    $failedStatuses: [Int!]!
  ) {
    file: fileById(id: $drsFileId) {
      id
      taskSources: taskSourcesByFileId(
        filter: {
          taskByTaskId: {
            and: [
              {
                toolVersionByToolVersionId: {
                  toolByToolId: { key: { equalTo: "process_file" } }
                }
              }
              { status: { in: $failedStatuses } }
            ]
          }
        }
      ) {
        nodes {
          id
          task: taskByTaskId {
            id
            status
            errorDescription
            created
            toolVersion: toolVersionByToolVersionId {
              id
              tool: toolByToolId {
                id
                name
                key
              }
            }
          }
        }
      }
    }
  }
`;
const failedStatuses = [TaskStatus["FAILED"], TaskStatus["ERROR"]];
export const useGetFileProcessFailedTask = () => {
  const [getFileProcessTasksQuery] = useGetFileProcessTasksLazyQuery();

  const getFileProcessTask = useCallback(
    async (drsFileId: DrsFile["id"]) => {
      const err = new Error("Failed to fetch task");
      const { data } = await getFileProcessTasksQuery({
        variables: {
          drsFileId,
          failedStatuses,
        },
        fetchPolicy: "network-only",
      });
      const file = data?.file;
      if (isNullish(file)) {
        throw err;
      }

      const task = chain(file?.taskSources.nodes ?? [])
        .filter(({ task }) => task !== null)
        .orderBy(({ task }) => task?.created, "asc")
        .last()
        .value()?.task;

      if (isNullish(task)) {
        throw err;
      }
      return task;
    },

    [getFileProcessTasksQuery],
  );

  return { getFileProcessTask };
};

const UNKNOWN_ERROR_TEXT = "Failed to read file";

export const useProcessFileFailedTaskError = (fileId: string) => {
  const [loading, setIsFailedTaskLoading] = useState(false);
  const [errorDescription, setErrorDescription] = useState<string>();
  const { getFileProcessTask } = useGetFileProcessFailedTask();

  const { processingStatus } = useGetDrsFileProcessingStatus(fileId);

  useEffect(() => {
    if (processingStatus === "error") {
      setIsFailedTaskLoading(true);
      getFileProcessTask(fileId)
        .then((task) => {
          setErrorDescription(
            isNonNullish(task.errorDescription)
              ? JSON.stringify(task.errorDescription)
              : UNKNOWN_ERROR_TEXT,
          );
        })
        .catch(() => setErrorDescription(UNKNOWN_ERROR_TEXT))
        .finally(() => {
          setErrorDescription(UNKNOWN_ERROR_TEXT);
          setIsFailedTaskLoading(false);
        });
    }
  }, [fileId, getFileProcessTask, processingStatus]);

  return { loading, errorDescription };
};
