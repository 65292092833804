import { useMemo, useState } from "react";
import {
  EuiButton,
  EuiButtonEmpty,
  EuiColorPicker,
  EuiFieldText,
  EuiForm,
  EuiFormRow,
  EuiModal,
  EuiModalBody,
  EuiModalFooter,
  EuiModalHeader,
  EuiModalHeaderTitle,
  euiPaletteColorBlind,
  EuiSelect,
  EuiSpacer,
  EuiSwitch,
  EuiTextArea,
} from "@inscopix/ideas-eui";
import { ProjectFieldsFragment, Tenant } from "../../graphql/_Types";
import { isDefined } from "utils/isDefined";
import { RequireExactlyOne } from "type-fest";
import { addUtilityToastFailure } from "utils/addUtilityToastFailure";
import { addUtilityToastSuccess } from "utils/addUtilityToastSuccess";
import { stringToSlug } from "utils/stringToSlug";
import { useCloneProjectDjango } from "./hooks/useCloneProjectDjango";
import { useCreateProjectDjango } from "./hooks/useCreateProjectDjango";
import { uuid } from "utils/uuid";
import { captureException } from "@sentry/react";
import { updateCacheFragment } from "utils/cache-fragments";
import { AxiosError } from "axios";
import { useRouteMapContext } from "providers/RouteMapProvider/RouteMapProvider";
import { useUserContext } from "providers/UserProvider/UserProvider";
import assert from "assert";
import { isUndefined } from "lodash";
import { customRandom, random } from "nanoid";
import { ProjectStatus } from "types/constants";
import { useQueryClient } from "@tanstack/react-query";
import { libraryProjectPatch } from "hooks/useLibraryProjectPatchDjango";
import { queryKeys } from "django/queryKeys";

type TFields = {
  name: string;
  organization: number;
  key: string;
  shortDescription: string;
  iconColor: string;
};

type TFieldValidationErrors = Partial<
  Record<Exclude<keyof TFields, "organization">, string>
> & {
  organization: string[];
};

export interface ModalProjectProps {
  onClose: () => void;
  mode: ModalProjectMode;
  project?: Pick<
    ProjectFieldsFragment,
    | "id"
    | "key"
    | "name"
    | "shortDescription"
    | "iconColor"
    | "userId"
    | "tenantId"
  >;
  tenantId?: Tenant["id"];
}

const MAX_KEY_LENGTH = 56;
const KEY_UNIQUENESS_BUFFER = 6;

// Defines which mode the modal operates in (e.g., which fields are editable)
export enum ModalProjectMode {
  CREATE,
  EDIT,
  CLONE,
}

/**
 * Displays a modal to create, edit, or clone a project
 */
export function ModalProject({
  onClose,
  mode,
  project,
  tenantId,
}: ModalProjectProps) {
  const queryClient = useQueryClient();

  const invalidateProjectQuery = async () => {
    await queryClient.invalidateQueries({
      queryKey: queryKeys.getAllProjects("internal"),
    });
  };
  const currentUser = useUserContext((s) => s.currentUser);
  const tenants = useUserContext((s) => s.tenants);
  const tenantOptions = tenants.map((tenant) => ({
    value: tenant.id,
    text: tenant.name,
  }));
  const colorHexCodes = euiPaletteColorBlind({ rotations: 1 });
  // Whether the project will contain copies of the original data
  const [isDataCopied, setIsDataCopied] = useState(true);

  const initialFields: TFields = {
    name: project?.name ?? "",
    organization:
      tenantId ??
      tenants.find((tenant) => tenant.id === project?.tenantId)?.id ??
      tenantOptions[0]?.value,
    key: project?.key ?? "",
    shortDescription: project?.shortDescription ?? "",
    iconColor:
      project?.iconColor ??
      colorHexCodes[Math.floor(Math.random() * colorHexCodes.length)],
  };

  if (mode === ModalProjectMode.CLONE) {
    // When copying, we suggest some changes to the name and key to prevent conflicts or confusion
    assert(
      isDefined(project),
      "Project must be defined when editing or copying",
    );
    // Prepend 'Copy of' to name
    initialFields.name = `Copy of ${project.name}`;
    // Generate a new random icon color
    initialFields.iconColor =
      colorHexCodes[Math.floor(Math.random() * colorHexCodes.length)];
    // Suggest a random suffix to the project key, which will conflict by default as the
    // organization is defaulted to the project being copied. If the user starts changing the name,
    // the key will be synced as it is on project creation
    const randomSuffix = customRandom(
      "abcdefghijklmnopqrstuvwxyz0123456789",
      5,
      random,
    )();
    initialFields.key =
      project.key.slice(0, MAX_KEY_LENGTH - KEY_UNIQUENESS_BUFFER) +
      "-" +
      randomSuffix;
  }

  const [fields, setFields] = useState<TFields>(initialFields);
  const [showErrors, setShowErrors] = useState(false);
  const [colorPickerError, setColorPickerError] = useState<string>();
  const [keyError, setKeyError] = useState<string>();
  const [organizationErrors, setOrganizationErrors] = useState<string[]>([]);
  const [syncNameAndKey, setSyncNameAndKey] = useState(
    mode === ModalProjectMode.CREATE || mode === ModalProjectMode.CLONE,
  );
  const [loading, setLoading] = useState(false);
  const routeMap = useRouteMapContext((s) => s.routeMap);

  const { createProjectDjango } = useCreateProjectDjango();
  const { cloneProjectDjango } = useCloneProjectDjango();

  const selectedTenant = tenants.find(
    (tenant) => tenant.id === fields.organization,
  );

  const fieldErrors = useMemo(() => {
    const errors: TFieldValidationErrors = {
      key: keyError,
      iconColor: colorPickerError,
      organization: [...organizationErrors],
    };
    const requiredStringFields: (keyof Pick<TFields, "name" | "key">)[] = [
      "name",
      "key",
    ];
    for (const key of requiredStringFields) {
      if (fields[key].length === 0) {
        errors[key] = `Please enter a ${key}`;
      }
    }
    if (tenantOptions.length === 0) {
      errors["organization"].push(
        "You do not have access to any organizations where you can create a project",
      );
    } else if (fields["organization"] === undefined) {
      errors["organization"].push("Please select an organization");
    }
    return errors;
  }, [
    colorPickerError,
    fields,
    keyError,
    organizationErrors,
    tenantOptions.length,
  ]);

  const isFormInvalid = Object.values(fieldErrors).some((error) => {
    if (error === undefined) {
      return false;
    }
    if (typeof error === "string") {
      return true;
    }
    return error.length > 0;
  });

  const handleFieldChange = (field: RequireExactlyOne<TFields>) => {
    setFields((prevFields) => {
      const newFields = { ...prevFields, ...field };
      if (isDefined(field.name) && syncNameAndKey) {
        const key = stringToSlug(field.name.slice(0, MAX_KEY_LENGTH));
        newFields.key = key;
      }
      if (isDefined(field.key)) {
        setKeyError(undefined);
        newFields.key = stringToSlug(field.key);
      }
      if (isDefined(field.organization)) {
        setOrganizationErrors([]);
      }
      if (isDefined(field.shortDescription)) {
        newFields.shortDescription = (field.shortDescription ?? "").slice(
          0,
          200,
        );
      }
      return newFields;
    });
  };

  const createProject = async () => {
    assert(isDefined(selectedTenant), "a valid organization must be selected");
    const { iconColor, key, name, shortDescription } = fields;
    try {
      const projectCreated = await createProjectDjango({
        id: uuid(),
        user: currentUser.id,
        tenant: selectedTenant.id,
        short_description: shortDescription,
        description: "",
        icon_color: iconColor,
        icon_text: null,
        key,
        name,
      });

      await invalidateProjectQuery();
      addUtilityToastSuccess("Successfully created project");
      onClose();
      // Redirect to the newly created project page
      routeMap["PROJECT"]
        .dynamicPath(
          {
            tenantKey: selectedTenant.key,
            projectKey: projectCreated.key,
          },
          { openShareModal: true },
        )
        .navigateTo();
    } catch (err) {
      handleProjectDjangoError(err as Error);
    }
  };

  const handleProjectDjangoError = (err: Error) => {
    // Specifically handles errors on the key field, which would most likely be due to a conflict
    // as the key must be unique within an organization

    // TODO can we use drf-standardized-errors to get the typing here?
    const errors = (
      err as AxiosError<
        | { errors?: { attr: string; code: string; detail: string }[] }
        | undefined
      >
    ).response?.data?.errors;

    (errors ?? []).forEach((error) => {
      // Handle empty project key errors
      if (error.attr === "key") {
        setKeyError(error.detail);
        setShowErrors(true);
      }
      // Handle duplicate project key errors
      else if (
        error.attr === "non_field_errors" &&
        error.detail === "The fields key, tenant must make a unique set."
      ) {
        setKeyError("URL suffix used by another project in the organization");
        setShowErrors(true);
      }
      // Handle quota and contract errors
      else if (
        error.code === "quota_exceeded" ||
        error.code === "contract_expired"
      ) {
        setOrganizationErrors((errors) => [...errors, error.detail]);
        setShowErrors(true);
      }
      // Handle project permission errors
      else if (error.code === "permission_denied") {
        addUtilityToastFailure(
          "You do not have permission to perform this action",
        );
        onClose();
      }
      // Fail operation if the error is an unhandled case
      else {
        captureException(err);
        addUtilityToastFailure("Failed to create project");
        onClose();
      }
    });
  };

  const copyProject = async () => {
    assert(
      isDefined(project),
      "Project must be supplied when copying a project",
    );
    assert(isDefined(selectedTenant), "a valid organization must be selected");
    try {
      const { iconColor, key, name, shortDescription } = fields;
      const projectCreated = await cloneProjectDjango(project?.id, {
        tenant: selectedTenant.id,
        short_description: shortDescription,
        icon_color: iconColor,
        key,
        name,
        include_data: isDataCopied,
      });

      // Show a toast if data is being copied, otherwise navigate to the new project
      if (projectCreated.status === ProjectStatus.AVAILABLE) {
        const tenant = tenants.find(({ id }) => id === projectCreated.tenantId);
        assert(isDefined(tenant));
        routeMap.PROJECT.dynamicPath({
          tenantKey: tenant.key,
          projectKey: projectCreated.key,
        }).navigateTo();
      } else if (projectCreated.status === ProjectStatus.CLONING) {
        addUtilityToastSuccess("Started copying project");
      }

      await invalidateProjectQuery();

      onClose();
    } catch (err) {
      handleProjectDjangoError(err as Error);
    }
  };

  const handleSubmit = async () => {
    if (isFormInvalid) {
      setShowErrors(true);
      return;
    }

    setLoading(true);

    switch (mode) {
      case ModalProjectMode.EDIT: {
        assert(
          isDefined(project),
          "Project must be supplied when editing a project",
        );
        try {
          const projectUpdated = await libraryProjectPatch(project.id, {
            ...fields,
          });

          await invalidateProjectQuery();

          updateCacheFragment({
            __typename: "Project",
            id: project?.id,
            update: (project) => {
              return { ...project, ...projectUpdated };
            },
          });
          addUtilityToastSuccess("Successfully updated project");
        } catch (err) {
          addUtilityToastFailure("Failed to update project");
          captureException(err);
        }
        onClose();
        break;
      }
      case ModalProjectMode.CREATE: {
        await createProject();
        break;
      }
      case ModalProjectMode.CLONE: {
        await copyProject();
      }
    }
    setLoading(false);
  };

  const modalTitle = (mode: ModalProjectMode) => {
    switch (mode) {
      case ModalProjectMode.CREATE:
        return "Create Project";
      case ModalProjectMode.EDIT:
        return "Edit Project";
      case ModalProjectMode.CLONE:
        return "Copy Project";
    }
  };

  return (
    <EuiModal onClose={onClose} initialFocus="[name=name]">
      <EuiModalHeader>
        <EuiModalHeaderTitle>{modalTitle(mode)}</EuiModalHeaderTitle>
      </EuiModalHeader>

      <EuiModalBody>
        <EuiForm>
          <EuiFormRow
            label="Name*"
            isInvalid={showErrors && isDefined(fieldErrors.name)}
            error={fieldErrors.name}
          >
            <EuiFieldText
              placeholder="Project Name"
              name="name"
              value={fields.name}
              onChange={(e) => handleFieldChange({ name: e.target.value })}
              icon="copyClipboard"
              aria-label="Project Name"
              maxLength={255}
              isInvalid={showErrors && isDefined(fieldErrors.name)}
            />
          </EuiFormRow>
          {
            // only show organization selection if no tenant ID provided (on home page) and not in edit mode
            isUndefined(tenantId) && mode !== ModalProjectMode.EDIT && (
              <EuiFormRow
                label="Organization*"
                helpText={
                  "Select the organization you want to create the project under."
                }
                isInvalid={
                  showErrors &&
                  (fieldErrors.organization.length > 0 ||
                    tenantOptions.length === 0)
                }
                error={fieldErrors.organization}
              >
                <EuiSelect
                  options={tenantOptions}
                  value={fields.organization}
                  onChange={(e) =>
                    handleFieldChange({ organization: Number(e.target.value) })
                  }
                  isInvalid={
                    showErrors &&
                    (fieldErrors.organization.length > 0 ||
                      tenantOptions.length === 0)
                  }
                  hasNoInitialSelection
                />
              </EuiFormRow>
            )
          }
          <EuiFormRow
            label="URL*"
            helpText={
              "Enter a unique identifier to use as this project's URL - allows letters, numbers, dashes, and underscores."
            }
            isInvalid={showErrors && isDefined(fieldErrors.key)}
            error={fieldErrors.key}
          >
            <EuiFieldText
              name="key"
              prepend={`${selectedTenant?.key ?? ""}/project/`}
              value={fields.key}
              onChange={(e) =>
                handleFieldChange({
                  key: e.target.value.slice(0, MAX_KEY_LENGTH),
                })
              }
              disabled={mode === ModalProjectMode.EDIT}
              maxLength={MAX_KEY_LENGTH}
              isInvalid={showErrors && isDefined(fieldErrors.key)}
              onFocus={() => setSyncNameAndKey(false)}
            />
          </EuiFormRow>
          <EuiFormRow
            label="Short summary description"
            helpText={<>{fields.shortDescription.length}/200 characters</>}
          >
            <EuiTextArea
              name="shortDescription"
              value={fields.shortDescription}
              onChange={(e) =>
                handleFieldChange({ shortDescription: e.target.value })
              }
              compressed={true}
            />
          </EuiFormRow>
          <EuiFormRow
            label="Icon color"
            isInvalid={showErrors && isDefined(fieldErrors.iconColor)}
            error={fieldErrors.iconColor}
          >
            <EuiColorPicker
              aria-label="Color Picker"
              onChange={(color, { isValid }) => {
                setColorPickerError(isValid ? undefined : "Invalid color");
                handleFieldChange({ iconColor: color });
              }}
              color={fields.iconColor}
              isInvalid={showErrors && isDefined(fieldErrors.iconColor)}
              swatches={colorHexCodes}
            />
          </EuiFormRow>
          {mode === ModalProjectMode.CLONE && (
            <EuiFormRow
              label="Copy data"
              helpText="Choose whether to copy the files and analysis tasks to the new project or to only copy the project's description, datasets, and analysis tables without data populated."
            >
              <EuiSwitch
                name="template-switch"
                label="Copy files & tasks"
                checked={isDataCopied}
                onChange={() => {
                  setIsDataCopied((isDataCopied) => !isDataCopied);
                }}
              />
            </EuiFormRow>
          )}
          <EuiSpacer size="xl" />
        </EuiForm>
      </EuiModalBody>

      <EuiModalFooter>
        <EuiButtonEmpty onClick={onClose}>Cancel</EuiButtonEmpty>
        <EuiButton fill isLoading={loading} onClick={() => void handleSubmit()}>
          Submit
        </EuiButton>
      </EuiModalFooter>
    </EuiModal>
  );
}
