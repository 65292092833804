/** @jsxImportSource @emotion/react */
import {
  EuiBadge,
  EuiButtonEmpty,
  EuiFlexGroup,
  EuiFlexItem,
  EuiPanel,
  EuiSkeletonText,
  EuiSpacer,
  EuiText,
  EuiTitle,
} from "@inscopix/ideas-eui";
import { CallOutError } from "components/CallOutError/CallOutError";
import { useCallback, useMemo, useState } from "react";
import { ProjectsGridSearchable } from "./ProjectsGridSearchable";
import { Tooltip } from "components/Tooltip/Tooltip";
import { css } from "@emotion/react";
import { useTenantContext } from "providers/TenantProvider/TenantProvider";
import { TENANT_MEMBERSHIP_ROLES_BY_KEY } from "types/TenantMembershipRole";
import { useUserContext } from "providers/UserProvider/UserProvider";
import { useAdminProjects } from "components/ProjectsTable/useAdminProjects";
import { GridApi, GridReadyEvent } from "ag-grid-community";
import {
  ProjectsGridColDef,
  ProjectsGridRowDatum,
} from "components/ProjectsTable/ProjectsGrid";
import { downloadFile } from "utils/downloadFile";

export const PanelTenantUsageProjects = () => {
  const { data, isLoading, isError } = useAdminProjects();
  const [isDownloading, setIsDownloading] = useState(false);
  const [gridApi, setGridApi] = useState<GridApi<ProjectsGridRowDatum>>();

  const currentTenant = useTenantContext((s) => s.currentTenant);
  const currentUser = useUserContext((s) => s.currentUser);
  const currentUserRole = useTenantContext((s) => {
    const defaultRole = TENANT_MEMBERSHIP_ROLES_BY_KEY["member"];
    const user = s.usersById.get(currentUser.id);
    return user?.role ?? defaultRole;
  });

  const projects = data;

  const handleGridReady = useCallback(
    (e: GridReadyEvent<ProjectsGridRowDatum>) => setGridApi(e.api),
    [],
  );

  const body = useMemo(() => {
    if (isLoading) {
      return <EuiSkeletonText lines={5} />;
    }

    if (projects === undefined || isError) {
      return <CallOutError />;
    }
    return (
      <ProjectsGridSearchable
        suppressColumns={["pinned", "organization"]}
        projects={projects}
        onGridReady={handleGridReady}
      />
    );
  }, [handleGridReady, isError, isLoading, projects]);

  const downloadTableAsCsv = () => {
    if (gridApi !== undefined) {
      setIsDownloading(true);

      // Remove column definitions without CSV value getters
      const columnDefs = (
        (gridApi.getColumnDefs() ?? []) as ProjectsGridColDef[]
      ).filter((def) => def.csvValueGetter !== null);

      // Format column headers
      const headerRow = columnDefs.map((def) => def.headerName ?? "");

      // Format cell values
      const contentRows: string[][] = [];
      gridApi.forEachNode((row) => {
        const serializedRow: string[] = [];
        columnDefs.forEach((def) => {
          if (def.csvValueGetter !== null && row.data !== undefined) {
            serializedRow.push(def.csvValueGetter(row.data));
          } else {
            serializedRow.push("");
          }
        });
        contentRows.push(serializedRow);
      });

      // Combine row data into a CSV string
      const csv = [headerRow, ...contentRows]
        .map((row) => row.join(","))
        .join("\n");

      // Download CSV file
      const blob = new Blob(["\ufeff", csv]);
      const url = URL.createObjectURL(blob);
      const filename = `${currentTenant.name} IDEAS Usage.csv`;
      downloadFile(url, filename);

      setIsDownloading(false);
    }
  };

  return (
    <EuiPanel grow={true}>
      <EuiFlexGroup
        direction="column"
        style={{ height: "100%" }}
        gutterSize="none"
      >
        <EuiFlexItem grow={false}>
          <EuiFlexGroup responsive={false} alignItems="center" gutterSize={"s"}>
            <EuiFlexItem grow={false}>
              <EuiTitle>
                <EuiText>
                  <h3>Manage Projects</h3>
                </EuiText>
              </EuiTitle>
            </EuiFlexItem>
            {currentUserRole.key === "admin" && (
              <EuiFlexItem grow={false}>
                <Tooltip
                  content={
                    "You can see and manage all projects in this organization (including ones not shared with you) because you are an organization admin"
                  }
                  css={css`
                    max-width: 350px;
                    text-wrap: wrap;
                  `}
                >
                  <EuiBadge color="hollow">Admin</EuiBadge>
                </Tooltip>
              </EuiFlexItem>
            )}
            {/* Spacer */}
            <EuiFlexItem grow />
            <EuiFlexItem grow={false}>
              <EuiButtonEmpty
                iconType="download"
                isLoading={isDownloading}
                disabled={isLoading || gridApi === undefined}
                onClick={downloadTableAsCsv}
              >
                Download CSV
              </EuiButtonEmpty>
            </EuiFlexItem>
          </EuiFlexGroup>
        </EuiFlexItem>
        <EuiSpacer size="l" />
        <EuiFlexItem>{body}</EuiFlexItem>
      </EuiFlexGroup>
    </EuiPanel>
  );
};
